import * as React from 'react';


export type FilterPanelType = React.ReactNode;
export type MaterialTableType = React.ReactNode;

export interface FilterableTableLayoutProps {
  filterPanel?: FilterPanelType;
  table?: MaterialTableType;
}

const style: React.CSSProperties = {
  flex: '1 1 100%',
  display: 'flex',
  padding: '20px 10px',
};


export const FilterableTableLayout: React.FC<FilterableTableLayoutProps> = ({ 
  table, filterPanel, ...other
}) => (
  <div style={ style }>
    { filterPanel }
    { table }
  </div>
);
