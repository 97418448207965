import * as React from 'react';

import { Queries } from 'src/service/organizations';
import { graphql } from '@apollo/client/react/hoc';
import { DataLoaderDecorator, DataProps } from 'src/decorators/DataLoaderDecorator';
import { normalizePaginatedResponse, PaginationQueryType } from 'src/utils/PaginationUtils';
import { PaginatedTable, PaginatedTableColumnWidthInfo } from 'src/components/table';
import { OrganizationTableCell } from './OrganizationTableCell';



export const DefaultEventListPagination: PaginationQueryType = {
  orderby: 'NAME',
  page: '1',
  pagesize: '25',
  direction: 'desc',
};


type OrganizationColumn = 'code' | 'name';
export type OrganizationListColumnNames = OrganizationColumn[];

const Columns: OrganizationListColumnNames = [
  'name',
  'code',
];

const DefaultColumnWidths: PaginatedTableColumnWidthInfo[] = [
  {
    columnName: 'name',
    width: '20%',
    minWidth: '100px',
  }, {
    columnName: 'code',
    width: '40%',
    minWidth: '150px',
  }
];

export interface OrganizationTableProps {

}

const OrganizationView: React.FC<OrganizationTableProps & DataProps<Queries.OrganizationListResponse>> = ((
  { data }
) => {
  const organizations = normalizePaginatedResponse(data.organizations!);
  return (
    <PaginatedTable
      columns={ Columns }
      rows={ organizations }
      totalCount={ data.organizations!.total }
      defaultPagination={ DefaultEventListPagination }
      defaultColumnWidths={ DefaultColumnWidths }
      cellComponent={ cellProps => (
        <OrganizationTableCell
          { ...cellProps }
        />
      ) }
    />
  );
});

const withOrganizationData = graphql<
  OrganizationTableProps, 
  Queries.OrganizationListResponse
>(
  Queries.OrganizationListQuery
);

const OrganizationTableWithData = withOrganizationData(
  DataLoaderDecorator<OrganizationTableProps, Queries.OrganizationListResponse>(OrganizationView)
);

export { OrganizationTableWithData as OrganizationTable };