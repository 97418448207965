export enum Permission {
  MANUFACTURERS_CREATE = 'manufacturers_create',
  MANUFACTURERS_EDIT = 'manufacturers_edit',
  MANUFACTURERS_DELETE = 'manufacturers_delete',
  MATERIALS_CREATE = 'materials_create',
  MATERIALS_EDIT = 'materials_edit',
  MATERIALS_DELETE = 'materials_delete',
  MATERIAL_CATEGORIES_CREATE = 'material_categories_create',
  MATERIAL_CATEGORIES_EDIT = 'material_categories_edit',
  MATERIAL_CATEGORIES_DELETE = 'material_categories_delete',
  MATERIAL_VARIANTS_CREATE = 'material_variants_create',
  MATERIAL_VARIANTS_EDIT = 'material_variants_edit',
  MATERIAL_VARIANTS_DELETE = 'material_variants_delete',
  //BASELINE_VARIANTS_EDIT = 'baseline_variants_edit',
  //BASELINE_VARIANTS_DELETE = 'baseline_variants_delete',
  //ORGANIZATION_VARIANTS_CREATE = 'organization_variants_create',
  //ORGANIZATION_VARIANTS_EDIT = 'organization_variants_edit',
  //ORGANIZATION_VARIANTS_DELETE = 'organization_variants_delete',
  LABELS_CREATE = 'labels_create',
  LABELS_EDIT = 'labels_edit',
  LABELS_DELETE = 'labels_delete',
  LABEL_CATEGORIES_CREATE = 'label_categories_create',
  LABEL_CATEGORIES_EDIT = 'label_categories_edit',
  LABEL_CATEGORIES_DELETE = 'label_categories_delete',
  ADMIN = 'admin'
}