import * as React from 'react';

import { graphql } from '@apollo/client/react/hoc';
import { Queries } from 'src/service/providers';

import { DataLoaderDecorator, DataProps } from 'src/decorators/DataLoaderDecorator';

import { RouteComponentProps } from 'react-router';


export type ProviderMaterialRouteProps = RouteComponentProps<{ 
  materialId: string;
  providerId: string;
}>;

type ProviderMaterialDataDecoratorProps = ProviderMaterialRouteProps;
export type ProviderMaterialDataDecoratorChildProps = 
  ProviderMaterialDataDecoratorProps & DataProps<Queries.GetProviderMaterialResponse>;

export const ProviderMaterialDataDecorator = function <T>(
  Component: React.ComponentType<T & ProviderMaterialDataDecoratorChildProps>
) {
  const withProvidersData = graphql<ProviderMaterialDataDecoratorProps & T, Queries.GetProviderMaterialResponse>(
    Queries.ProviderMaterialGetQuery, 
    {
      options: ({ match }) => ({ 
        variables: { 
          providerId: match.params.providerId,
          materialId: match.params.materialId,
        } 
      })
    }
  );

  return withProvidersData(
    DataLoaderDecorator<ProviderMaterialDataDecoratorProps & T, Queries.GetProviderMaterialResponse>(Component)
  );
};

//export { RouteProps };
