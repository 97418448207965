import * as React from 'react';

import { GBLabelType, GBMaterialIdType, GBLabelIdType, GBLabelCategoryIdType, GBMaterialType } from 'src/types';
import { Queries } from 'src/service/materials';
import { SuggestionSection, SuggestionSectionProps } from 'src/components/form/select-field/SuggestionSection';
import { graphql } from '@apollo/client/react/hoc';
import { DataLoaderDecorator } from 'src/decorators/DataLoaderDecorator';
import { Typography } from '@material-ui/core';
import { Trans } from 'react-i18next';


interface LabelSuggestionProps extends Pick<SuggestionSectionProps<GBLabelType>, 'onSuggestionSelected'> {
  materialId: GBMaterialIdType;
  categoryId?: GBLabelCategoryIdType;
  labelIds?: GBLabelIdType[];
  material: GBMaterialType;
}

const withLabelSuggestionsData = graphql<
  LabelSuggestionProps, 
  Queries.MaterialLabelSuggestionsResponse
>(
  Queries.MaterialLabelSuggestionsQuery, 
  {
    options: ({ materialId }) => ({ 
      variables: { 
        materialId,
        first: 10
      } 
    }),
  }
);

const LabelSuggestions = withLabelSuggestionsData(
  DataLoaderDecorator(({ onSuggestionSelected, data, labelIds, categoryId, material }) => {
    const options = data!.material!.suggestedLabels
      .filter(suggestion => {
        if (!suggestion.label) {
          return false;
        }

        if (!!categoryId && suggestion.label.category.id !== categoryId) {
          return false;
        }

        if (material.labels.find(label => {
          return label.id === suggestion.label!.id || 
            label.category.id === suggestion.label!.category.id;
        })) {
          return false;
        }

        return !labelIds || labelIds.indexOf(suggestion.label.id) === -1;
      })
      .map(suggestion => ({
        label: `${suggestion.label!.category.name} > ${suggestion.label!.name}`,
        value: suggestion.label,
        rewardId: suggestion.rewardId
      }));

    if (!options.length) {
      return null;
    }

    return (
      <>
        <Typography variant="subtitle1" style={{ marginTop: '10px' }}>
          <Trans i18nKey="suggestedLabels"/>
        </Typography>
        <SuggestionSection<GBLabelType>
          onSuggestionSelected={ onSuggestionSelected }
          options={ options }
        />
      </>
    );
  })
);

export { LabelSuggestions };
