import * as React from 'react';

import Paper from '@material-ui/core/Paper';


interface ActionBarProps {
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
}

const ActionBar: React.FC<ActionBarProps> = ({ leftContent, rightContent, ...other }) => (
  <Paper className="action-bar">
    { !!leftContent && (
      <div className="left" style={{ float: 'left' }}>
        { leftContent }
      </div> 
    ) }
    { !!rightContent && (
      <div className="right" style={{ float: 'right', display: 'flex' }}>
        { rightContent }
      </div>
    ) }
  </Paper>
);

export { ActionBar };