import * as React from 'react';

import { graphql } from '@apollo/client/react/hoc';
import { Location } from 'history';

import { 
  MaterialListColumnNames, 
  MaterialTableConfigProps, MaterialTable, DefaultLocalMaterialListPagination
} from 'src/components/browser-layout';

import { DataLoaderDecorator, DataProps } from 'src/decorators/DataLoaderDecorator';
import { Queries } from 'src/service/manufacturers';

import { parsePaginationVariables } from 'src/utils/PaginationUtils';
import { GBManufacturerType } from 'src/types';
import { parseMaterialFilterVariables } from 'src/utils/MaterialFilterUtils';
import { SelectedOrganizationContext } from 'src/decorators/SelectedOrganizationProvider';
import { localMaterialsToViewMaterials } from 'src/utils/MaterialVariantUtils';
import { useTranslation } from 'react-i18next';
import { MaterialSelectionActions } from 'src/actions/MaterialSelectionActions';


export interface ManufacturerMaterialTableProps extends MaterialTableConfigProps {
  manufacturer: GBManufacturerType;
  location: Location;
}

const withManufacturer = graphql<
  ManufacturerMaterialTableProps, 
  Queries.ManufacturerMaterialsResponse
> (
  Queries.ManufacturerMaterialsQuery, {
    options: ({ manufacturer, location }) => {
      return {
        variables: { 
          manufacturerId: manufacturer.id,
          ...parsePaginationVariables(location, DefaultLocalMaterialListPagination).query,
          ...parseMaterialFilterVariables(location).query,
        }
      };
    },
  }
);

type Props = ManufacturerMaterialTableProps & 
  DataProps<Queries.ManufacturerMaterialsResponse>;

const Columns: MaterialListColumnNames = [ 'image', 'name', 'category' ];

const ManufacturerMaterialTable: React.FC<Props> = ({ data, manufacturer }) => {
  const organizationContext = React.useContext(SelectedOrganizationContext)!;
  const { materials } = data.manufacturer!;
  const { t } = useTranslation();
  return (
    <MaterialTable
      materials={ localMaterialsToViewMaterials(materials, organizationContext, t) }
      totalCount={ materials.total }
      columns={ Columns }
      pageTitle={ manufacturer.name }
      selectionActions={ MaterialSelectionActions }
    />
  );
};

const ManufacturerMaterialTableDecorated = withManufacturer(
  DataLoaderDecorator<
    ManufacturerMaterialTableProps, 
    Queries.ManufacturerMaterialsResponse
  >(
    ManufacturerMaterialTable
  )
);

export { ManufacturerMaterialTableDecorated as ManufacturerMaterialTable };