import * as React from 'react';

import { DropdownMenuItem } from 'src/components/DropdownMenuItem';
import { ActionDecorator, ActionDecoratorChildProps } from 'src/decorators/ActionDecorator';
import { DropdownMenu, DropdownMenuProps } from 'src/components/DropdownMenu';
import { filterActions } from 'src/utils/ActionUtils';
import { ActionList } from 'src/types';
import { Divider } from '@material-ui/core';
//import { Action } from './Actions';


export interface ActionDropdownMenuProps<
  PropsT extends object, 
  ActionListT extends ActionList<PropsT>
> extends Omit<DropdownMenuProps, 'children'> {
  actions: ActionList<PropsT>;
  actionIds?: Array<keyof ActionListT>;
}


//type Props<PropsT extends object> = ActionDropdownMenuProps & Omit<ActionDecoratorChildProps<PropsT>, 'history'>;


class ActionDropdownMenu<PropsT extends object, ActionListT extends ActionList<PropsT>> extends React.PureComponent<
  Omit<PropsT, keyof ActionDropdownMenuProps<PropsT, ActionListT> | keyof ActionDecoratorChildProps<PropsT>> & 
  //PropsT &
  ActionDropdownMenuProps<PropsT, ActionListT> & 
  ActionDecoratorChildProps<PropsT>
> {
  render() {
    // tslint:disable-next-line:max-line-length
    //type TMP = Omit<PropsT, keyof ActionDropdownMenuProps<PropsT, ActionListT> | keyof ActionDecoratorChildProps<PropsT>>;

    const { 
      actions: propActions, auth, onClickAction, actionIds, ...other
    } = this.props;

    const actions = filterActions(propActions as any, auth, this.props, actionIds as string[]);
    if (!actions.length || actions.every(action => !action)) {
      return null;
    }

    return (
      <DropdownMenu
        { ...other }
      >
        { actions.map((action, index) => {
          if (!action) {
            return <Divider key={ `divider${index}` }/>;
          }

          return (
            <DropdownMenuItem
              key={ action.title }
              onClick={ () => onClickAction(action as any) }
              icon={ <action.icon/> }
              captionKey={ action.title }
            />
          );
        }) }
      </DropdownMenu>
    );
  }
}

/*function Decorated<PropsT>() {
  return ActionDecorator<PropsT & ActionDropdownMenuProps>(ActionDropdownMenu as any);
}*/

//type tmp = typeof ActionDropdownMenu;

const Decorated = ActionDecorator<any>(ActionDropdownMenu);

export { Decorated as ActionDropdownMenu };
