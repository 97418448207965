import gql from 'graphql-tag';


// Queries
export const CreateMaterialCategoryMutation = gql`
  mutation CreateMaterialCategoryMutation($input: CreateMaterialCategoryInput!, $languageCode: LanguageCode!) {
    createMaterialCategory(input: $input, languageCode: $languageCode) {
      id,
      parent {
        id
      }
    }
  }
`;

export const UpdateMaterialCategoryMutation = gql`
  mutation UpdateMaterialCategoryMutation(
    $id: Int!, 
    $input: UpdateMaterialCategoryInput!, 
    $languageCode: LanguageCode!
  ) {
    updateMaterialCategory(id: $id, input: $input, languageCode: $languageCode) {
      id,
      parent {
        id
      }
    }
  }
`;

export const DeleteMaterialCategoryMutation = gql`
  mutation DeleteMaterialCategoryMutation($id: Int!) {
    deleteMaterialCategory(id: $id) {
      id,
      parent {
        id
      }
    }
  }
`;


// Response types
export interface CreateMaterialCategoryResponse {
  createMaterialCategory: {
    id: number;
    parent: {
      id: number;
    }
  };
}

export interface UpdateMaterialCategoryResponse {
  updateMaterialCategory: {
    id: number;
    parent: {
      id: number;
    }
  };
}

export interface DeleteMaterialCategoryResponse {
  deleteMaterialCategory: {
    id: number;
    parent: {
      id: number;
    }
  };
}
