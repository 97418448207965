import React, { useState } from 'react';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { WithTranslation } from 'react-i18next';
import { useRouter } from 'src/effects';
import { ProviderType } from 'src/types';
import { toReturnInfo, ReturnInfoState } from 'src/utils/BrowserUtils';


interface MaterialInputFieldProps extends Pick<WithTranslation, 't'> {
  materialSectionUrl: string;
  provider: ProviderType;
}


const MaterialInputField = React.memo<MaterialInputFieldProps>(props => {
  const [ materialId, setMaterialId ] = useState('');
  const { history, location } = useRouter<{}, any, ReturnInfoState>();

  const handleSubmit = () => {
    history.push({
      pathname: `${props.materialSectionUrl}/${materialId}`,
      state: {
        returnInfo: toReturnInfo(location, props.provider.name)
      }
    });
  };

  const { t } = props;
  return (
    <form 
      noValidate 
      autoComplete="off" 
      onSubmit={ handleSubmit }
    >
      <TextField
        id="name"
        onChange={ e => setMaterialId(e.target.value) }
        placeholder={ t('materialId') }
        margin="normal"
      />
      <Button
        variant="contained"
        disabled={ !materialId }
        type="submit"
        style={{
          margin: '10px',
        }}
      >
        { t('actions.viewMaterial') }
      </Button>
    </form>
  );
});

export { MaterialInputField };