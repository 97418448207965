import * as React from 'react';

import {
  MaterialCategorySelectField, MaterialCategorySelectFieldComponent 
} from 'src/components/form';

import { InjectedEditorFormProps } from 'src/components/editor';
import { Trans } from 'react-i18next';
import { GBMaterialCategoryIdType } from 'src/types';


export interface MaterialMoveFieldTypes {
  categoryId: GBMaterialCategoryIdType;
}

type MaterialCategoryEditorFormProps = 
  InjectedEditorFormProps<MaterialMoveFieldTypes, {}>;

const MaterialMoveEditorForm: React.FC<MaterialCategoryEditorFormProps> = () => {
  return (
    <>
      <MaterialCategorySelectField 
        name="categoryId" 
        component={ MaterialCategorySelectFieldComponent } 
        label={ <Trans i18nKey="category"/> }
      />
    </>
  );
};

export { MaterialMoveEditorForm };
