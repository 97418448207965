import * as React from 'react';

import { ListItemText, ListItem } from '@material-ui/core';
import { Trans } from 'react-i18next';


interface MaterialProductInfoListItemProps {
  title: string;
  showEmpty?: boolean;
  disableTypography?: boolean;
}

const MaterialProductInfoListItem: React.FC<MaterialProductInfoListItemProps> = (
  { title, showEmpty = false, disableTypography = false, children }
) => {
  const empty = !children || React.Children.count(children) === 0;
  if (empty && !showEmpty) {
    return null;
  }

  return (
    <ListItem>
      <ListItemText 
        primary={ title } 
        secondary={ empty ? <Trans i18nKey="na"/> : children }
        disableTypography={ !empty && disableTypography }
      />
    </ListItem>
  );
};

export { MaterialProductInfoListItem };