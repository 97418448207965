import * as React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { Queries } from 'src/service/materials';

import { RouteComponentProps } from 'react-router-dom';

import { ModalRoute } from 'src/components/ModalRoute';
import { DataLoaderDecorator, DataProps } from 'src/decorators/DataLoaderDecorator';

import { MaterialVariantEditorDialog } from '../variant-editor';

import { 
  parseVariantLanguage, parseCurrentVariant, parseLocalizableVariantProperties, variantSort
} from 'src/utils/MaterialVariantUtils';
import { parseIntRouteParam, parseReturnInfo, ReturnInfoState } from 'src/utils/BrowserUtils';
import { Message } from 'src/components/Message';
import { MaterialVariantLayout } from './MaterialVariantLayout';
import { MainLayout, ReturnButton } from 'src/components/main-layout';

import { MaterialVariantActionPanel } from './MaterialVariantActionMenu';
import { SelectedOrganizationContext } from 'src/decorators/SelectedOrganizationProvider';
import { useTranslation } from 'react-i18next';


type RouteProps = RouteComponentProps<{
  materialId: string;
  materialVariantId: string;
}, any, ReturnInfoState>;

type MaterialViewProps = RouteProps;

type Props = MaterialViewProps & DataProps<Queries.MaterialResponse>;

const MaterialVariantListLayout: React.FC<Props> = props => {
  const organizationContext = React.useContext(SelectedOrganizationContext)!;
  let material = props.data.material!;

  const { t } = useTranslation();
  const { variants } = material;
  const { location } = props;
  const defaultLocalization = parseLocalizableVariantProperties(
    parseCurrentVariant(variants, location, organizationContext), t
  );

  const returnInfo = parseReturnInfo(location, {
    pathname: `/materials/${material.id}`,
    name: t('material')
  })!;

  return (
    <MainLayout 
      id="material-variants-list"
      title={ (
        <div>
          { defaultLocalization.name }
        </div>
      ) }
      subTitle={ t('materialVariants') }
      actionBarLeft={(
        <ReturnButton
          returnInfo={ returnInfo }
        />
      )}
      actionBarRight={(
        <MaterialVariantActionPanel 
          material={ material }
          location={ location }
        />
      )}
    >
      { !variants.length ? (
        <Message
          title={ t('noExistingVariants') }
        />
      ) : [ ...variants ]
            .sort(variantSort(organizationContext))
            .map(variant => (
              <MaterialVariantLayout
                key={ variant.id }
                variant={ variant }
                material={ material }
                t={ t }
              />
            )) 
      }
      <ModalRoute
        component={ MaterialVariantEditorDialog }
        path="/materials/:materialId(\d+)/variants/:materialVariantId(\d+)/:actionType(copy|edit)"
      />
      <ModalRoute
        component={ MaterialVariantEditorDialog }
        path="/materials/:materialId(\d+)/variants/:actionType(create)"
      />
    </MainLayout>
  );
};

const withMaterialVariantData = graphql<MaterialViewProps, Queries.MaterialResponse>(
  Queries.MaterialWithVariantsQuery, {
    options: ({ match, location }) => ({ 
      variables: { 
        materialId: parseIntRouteParam(match.params.materialId),
        languageCode: parseVariantLanguage(location)
      },
      errorPolicy: 'all',
    })
  }
);

const MaterialVariantListLayoutWithData = withMaterialVariantData(
  DataLoaderDecorator<MaterialViewProps, Queries.MaterialResponse>(
    MaterialVariantListLayout
  )
);

export { MaterialVariantListLayoutWithData as MaterialVariantListLayout };
