import * as React from 'react';

import { graphql } from '@apollo/client/react/hoc';
import { Location } from 'history';

import { 
  BrowserLayout, MaterialBrowserPropsType,
  ensureParentCategory,
  RootCategoryStr
} from 'src/components/browser-layout';
import { ProviderTitle } from 'src/modules/providers';
import { ProviderMaterialCategoryIdType } from 'src/types';

import { DataLoaderDecorator } from 'src/decorators/DataLoaderDecorator';
import { Queries } from 'src/service/providers';

import { normalizePaginatedResponse } from 'src/utils/PaginationUtils';
import { ProviderMaterialCategoryMaterialTable } from './ProviderMaterialCategoryMaterialTable';


export interface ProviderCategoryPagePropsType extends MaterialBrowserPropsType<ProviderMaterialCategoryIdType> {
  providerId: string;
  categoryId?: string;
  location: Location;
}

const withCategory = graphql<ProviderCategoryPagePropsType, Queries.MaterialCategoryCategoriesResponse>(
  Queries.ProviderMaterialCategoryCategoriesQuery, 
  {
    options: ({ categoryId, providerId, location }) => {
      return {
        variables: { 
          categoryId,
          providerId,
        }
      };
    },
  }
);

const ProviderCategoryPageLayout = withCategory(DataLoaderDecorator(({ providerId, data, location, ...other }) => {
  const { provider } = data;
  const { materials, materialCategories, ...category } = provider!.materialCategory;
  return (
    <BrowserLayout<ProviderMaterialCategoryIdType>
      category={ ensureParentCategory(category, RootCategoryStr) }
      childCategories={ normalizePaginatedResponse(materialCategories) }
      materialTable={ 
        <ProviderMaterialCategoryMaterialTable
          providerId={ providerId }
          category={ category }
          location={ location }
          { ...other }
        /> 
      }
      totalMaterialCount={ materials.total }
      subTitle={ <ProviderTitle provider={ provider! }/> }
      { ...other }
    />
  );
}));

export { ProviderCategoryPageLayout };