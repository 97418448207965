import * as React from 'react';

import { ListItemText, ListItem } from '@material-ui/core';
import { GBLabelType, GBLabelCategoryType, GBMaterialType } from 'src/types';
import Edit from '@material-ui/icons/Edit';

import { 
  MaterialProductInfoLink 
} from 'src/components/material-layout';
import { ActionDropdownMenu } from 'src/components/ActionDropdownMenu';
import { MaterialLabelEditActions } from 'src/actions/MaterialLabelActions';


const formatLabels = (labels: GBLabelType[]) => {
  return labels
    .map((label, index) => (
      <React.Fragment 
        key={ label.id }
      >
        <MaterialProductInfoLink 
          to={ `/labels/${label.id}` }
        >
          { label.name }
        </MaterialProductInfoLink>
        { index !== labels.length - 1 && <span>, </span>  }
      </React.Fragment>
    ));
};


interface MaterialLabelListItemProps {
  labels: GBLabelType[];
  category: GBLabelCategoryType;
  material: GBMaterialType;
}

const MaterialLabelListItem: React.FC<MaterialLabelListItemProps> = (
  { labels, category, material }
) => {
  return (
    <ListItem>
      <ListItemText 
        primary={ category.name } 
        secondary={ formatLabels(labels) }
      />
      <ActionDropdownMenu
        labels={ labels }
        category={ category }
        material={ material }
        actions={ MaterialLabelEditActions }
        caption={ <Edit/> }
      />
    </ListItem>
  );
};

export { MaterialLabelListItem };