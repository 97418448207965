import i18n, { DEFAULT_LANGUAGE } from 'src/i18n';

export const parseLanguage = (language: string) => {
  const ret = language.indexOf('-') === -1 ? language : language.substring(0, 2);
  return ret.toLowerCase();
};

export const parseCurrentLanguage = () => {
  const ret = parseLanguage(i18n.language);
  if (!i18n.getResourceBundle(ret, '')) {
    return DEFAULT_LANGUAGE;
  }

  return ret;
};

interface LocalizableEntity {
  localization?: {
    language: {
      code: string;
    }
  };
}

export const parseLocalizationLanguage = (currentEntity: LocalizableEntity | undefined): string | undefined => {
  const currentLanguageCode = parseCurrentLanguage();
  if (!currentEntity) {
    return currentLanguageCode;
  }

  // Editing existing, convert the data
  const { localization } = currentEntity;
  if (!!localization && localization.language.code === currentLanguageCode) {
    return localization.language.code;
  }
  
  return currentLanguageCode;
};
