import gql from 'graphql-tag';
import { 
  GBMaterialType, PaginatedResponse, 
  MaterialCategoryCountType, ManufacturerCountType, LabelCountType, TotalResponse, MaterialLabelSuggestion, GBLabelType
} from 'src/types';

import { Fragments as FragmentsRaw } from './materialFragments';
import { Fragments as DataSourceItemFragmentsRaw } from 'src/service/dataSourceItems/dataSourceItemFragments';
import { Fragments as MaterialCountFragmentsRaw } from 'src/service/materialCounts/materialCountFragments';
import { Fragments as LabelFragmentsRaw } from 'src/service/labels/labelFragments';

import { Fragments as MaterialVariantFragmentsRaw } from 'src/service/materialVariants/materialVariantFragments';
import { 
  Fragments as MaterialVariantLocalizationFragmentsRaw 
} from 'src/service/materialVariantLocalizations/materialVariantLocalizationFragments';

const MaterialCountFragments = MaterialCountFragmentsRaw();

const Fragments = FragmentsRaw();
const DataSourceItemFragments = DataSourceItemFragmentsRaw();
const MaterialVariantFragments = MaterialVariantFragmentsRaw();
const MaterialLocalizationFragments = MaterialVariantLocalizationFragmentsRaw();
const LabelFragments = LabelFragmentsRaw();


// Queries
export const MaterialWithVariantsQuery = gql`
  query MaterialWithVariantsQuery(
    $materialId: Int!
    $languageCode: LanguageCode
  ) {
    material(id: $materialId) {
      ...MaterialBasic
      ...MaterialExtra
      ...MaterialManufacturer
      ...MaterialCategory
      ...MaterialLabels
      dataSourceItems {
        ...DataSourceItemBasic
        ...DataSourceItemProvider
        providerMaterial {
          id
        }
      }
      variants {
        ...MaterialVariantBasic
        ...MaterialVariantOrganization
        ...MaterialVariantProvider
        ...MaterialVariantLocalizations
        localization(languageCode: $languageCode) {
          ...MaterialVariantLocalizationBasic
        }
      }
    }
  }
  ${Fragments.basic}
  ${Fragments.extra}
  ${Fragments.manufacturer}
  ${Fragments.materialCategory}
  ${Fragments.labels}
  ${DataSourceItemFragments.basic}
  ${DataSourceItemFragments.provider}
  ${MaterialVariantFragments.basic}
  ${MaterialVariantFragments.organization}
  ${MaterialVariantFragments.provider}
  ${MaterialVariantFragments.localizations}
  ${MaterialLocalizationFragments.basic}
`;


export const MaterialQuery = gql`
  query MaterialQuery(
    $materialId: Int!
  ) {
    material(id: $materialId) {
      ...MaterialBasic
      ...MaterialExtra
      ...MaterialManufacturer
      ...MaterialCategory
      ...MaterialLabels
    }
  }
  ${Fragments.basic}
  ${Fragments.extra}
  ${Fragments.manufacturer}
  ${Fragments.materialCategory}
  ${Fragments.labels}
`;

export const MaterialLabelSuggestionsQuery = gql`
  query MaterialLabelSuggestionsQuery(
    $materialId: Int!,
    $first: Int!
  ) {
    material(id: $materialId) {
      ...MaterialBasic
      suggestedLabels(first: $first) {
        id
        score
        rewardId
        label {
          ...LabelBasic
          ...LabelCategory
        }
      }
    }
  }
  ${Fragments.basic}
  ${LabelFragments.basic}
  ${LabelFragments.category}
`;


export const MaterialGTINQuery = gql`
  query MaterialGtinQuery(
    $gtin: String!
    $languageCode: LanguageCode
  ) {
    material(gtin: $gtin) {
      ...MaterialBasic
      variants {
        ...MaterialVariantBasic
        ...MaterialVariantOrganization
        ...MaterialVariantProvider
        localization(languageCode: $languageCode) {
          ...MaterialVariantLocalizationBasic
        }
      }
    }
  }
  ${Fragments.basic}
  ${MaterialVariantFragments.basic}
  ${MaterialVariantFragments.organization}
  ${MaterialVariantFragments.provider}
  ${MaterialLocalizationFragments.basic}
`;

export const MaterialSKUQuery = gql`
  query MaterialSkuQuery(
    $sku: String!
    $languageCode: LanguageCode
  ) {
    material(sku: $sku) {
      ...MaterialBasic
      variants {
        ...MaterialVariantBasic
        ...MaterialVariantOrganization
        ...MaterialVariantProvider
        localization(languageCode: $languageCode) {
          ...MaterialVariantLocalizationBasic
        }
      }
    }
  }
  ${Fragments.basic}
  ${MaterialVariantFragments.basic}
  ${MaterialVariantFragments.organization}
  ${MaterialVariantFragments.provider}
  ${MaterialLocalizationFragments.basic}
`;

export const MaterialSearchCountsQuery = gql`
  query MaterialSearchQuery(
    $types: [SearchTypes]!, 
    $query: String!
    $labels: [[Int]],
    $manufacturers: [Int],
    $materialCategories: [Int],
  ) {
    search(types: $types, query: $query) {
      materials(
        labels: $labels,
        manufacturers: $manufacturers,
        materialCategories: $materialCategories
      ) {
        total
      }
      materialCategoryCounts {
        ...MaterialCategoryCount
      }
      materialLabelCounts {
        ...LabelCount
      }
      materialManufacturerCounts {
        ...ManufacturerCount
      }
    }
  }
  ${MaterialCountFragments.materialCategoryCount}
  ${MaterialCountFragments.labelCount}
  ${MaterialCountFragments.manufacturerCount}
`;

export const MaterialSearchListQuery = gql`
  query MaterialSearchMaterialsQuery(
    $types: [SearchTypes]!, 
    $query: String!
    $first: Int, 
    $after: String, 
    $orderBy: MaterialsOrder, 
    $labels: [[Int]],
    $manufacturers: [Int],
    $materialCategories: [Int],
    $languageCode: LanguageCode
  ) {
    search(types: $types, query: $query) {
      materials(
        first: $first, 
        after: $after, 
        orderBy: $orderBy, 
        labels: $labels,
        manufacturers: $manufacturers,
        materialCategories: $materialCategories
      ) {
        total,
        edges {
          node {
            ...MaterialBasic
            ...MaterialCategory
            ...MaterialManufacturer
            variants {
              ...MaterialVariantBasic
              ...MaterialVariantOrganization
              ...MaterialVariantProvider
              localization(languageCode: $languageCode) {
                ...MaterialVariantLocalizationBasic
              }
            }
          }
        }
      }
    }
  }
  ${Fragments.basic}
  ${Fragments.materialCategory}
  ${Fragments.manufacturer}
  ${MaterialVariantFragments.basic}
  ${MaterialVariantFragments.organization}
  ${MaterialVariantFragments.provider}
  ${MaterialLocalizationFragments.basic}
`;



export const MaterialsCountsQuery = gql`
  query MaterialsCountsQuery(
    $statuses: [MaterialStatusTypes], 
    $labels: [[Int]],
    $manufacturers: [Int],
    $materialCategories: [Int],
  ) {
    materials(
      statuses: $statuses,
      labels: $labels,
      manufacturers: $manufacturers,
      materialCategories: $materialCategories
    ) {
      total
    }
  }
`;

export const MaterialsListQuery = gql`
  query MaterialListQuery(
    $statuses: [MaterialStatusTypes], 
    $first: Int, 
    $after: String, 
    $orderBy: MaterialsOrder, 
    $labels: [[Int]],
    $manufacturers: [Int],
    $materialCategories: [Int],
    $languageCode: LanguageCode
  ) {
    materials(
      first: $first, 
      after: $after, 
      orderBy: $orderBy, 
      statuses: $statuses,
      labels: $labels,
      manufacturers: $manufacturers,
      materialCategories: $materialCategories
    ) {
      total,
      edges {
        node {
          ...MaterialBasic
          ...MaterialExtra
          ...MaterialCategory
          ...MaterialManufacturer
          variants {
            ...MaterialVariantBasic
            
            ...MaterialVariantOrganization
            ...MaterialVariantProvider
            localization(languageCode: $languageCode) {
              ...MaterialVariantLocalizationBasic
            }
          }
        }
      }
    }
  }
  ${Fragments.basic}
  ${Fragments.extra}
  ${Fragments.materialCategory}
  ${Fragments.manufacturer}
  ${MaterialVariantFragments.basic}
  ${MaterialVariantFragments.organization}
  ${MaterialVariantFragments.provider}
  ${MaterialLocalizationFragments.basic}
`;

// Response types
export interface MaterialResponse {
  material: GBMaterialType;
}

export interface MaterialLabelSuggestionsResponse {
  material: GBMaterialType & {
    suggestedLabels: Array<MaterialLabelSuggestion & { label: GBLabelType | null }>;
  };
}

export interface MaterialGTINResponse {
  material: GBMaterialType;
}

export interface MaterialSearchCountsResponse {
  search: {
    materials: TotalResponse;
    materialManufacturerCounts: ManufacturerCountType[];
    materialCategoryCounts: MaterialCategoryCountType[];
    materialLabelCounts: LabelCountType[];
  };
}

export interface MaterialSearchListResponse {
  search: {
    materials: PaginatedResponse<GBMaterialType>;
    materialManufacturerCounts: ManufacturerCountType[];
    materialCategoryCounts: MaterialCategoryCountType[];
    materialLabelCounts: LabelCountType[];
  };
}

export interface MaterialsCountsResponse {
  materials: TotalResponse;
}

export interface MaterialsListResponse {
  materials: PaginatedResponse<GBMaterialType>;
}
