import { Location } from 'history';

const loadProperty = (storage: Storage, storageKey: string | undefined, defaultData?: any) => {
  if (storageKey) {
    const savedItem = storage.getItem(storageKey);
    if (savedItem !== undefined && savedItem !== null) { // 'false' should be loaded
      try {
        return JSON.parse(savedItem);
      } catch (e) {
        if (e instanceof Error) {
          console.warn('Failed to load storage property', e.message);
        }
      }
    }
  }

  return defaultData;
};

const saveProperty = (storage: Storage, storageKey: string | undefined, data: any) => {
  if (!storageKey) {
    return;
  }

  storage.setItem(storageKey, JSON.stringify(data));
};

export const loadLocalProperty = (storageKey: string | undefined, defaultData?: any) => {
  return loadProperty(localStorage, storageKey, defaultData);
};

export const saveLocalProperty = (storageKey: string | undefined, data: any) => {
  saveProperty(localStorage, storageKey, data);
};

export const removeLocalProperty = (storageKey: string) => {
  localStorage.removeItem(storageKey);
};

export const loadSessionProperty = (storageKey: string | undefined, defaultData?: any) => {
  return loadProperty(sessionStorage, storageKey, defaultData);
};

export const saveSessionProperty = (storageKey: string | undefined, data: any) => {
  saveProperty(sessionStorage, storageKey, data);
};

export const removeSessionProperty = (storageKey: string) => {
  sessionStorage.removeItem(storageKey);
};


// https://stackoverflow.com/a/15203639
export const isElementVisible = (el: Element) => {
  const rect   = el.getBoundingClientRect(),
      vWidth   = window.innerWidth || document.documentElement.clientWidth,
      vHeight  = window.innerHeight || document.documentElement.clientHeight,
      efp      = (x: number, y: number) => document.elementFromPoint(x, y);     

  // Return false if it's not in the viewport
  if (rect.right < 0 || rect.bottom < 0 || rect.left > vWidth || rect.top > vHeight) {
      return false;
  }

  // Return true if any of its four corners are visible
  return (
        el.contains(efp(rect.left,  rect.top))
    ||  el.contains(efp(rect.right, rect.top))
    ||  el.contains(efp(rect.right, rect.bottom))
    ||  el.contains(efp(rect.left,  rect.bottom))
  );
};

export type ReturnInfo = {
  name?: string;
  pathname: string;
  search?: string;
  state?: object;
};

export interface ReturnInfoState {
  returnInfo?: ReturnInfo;
}

export const parseReturnInfo = (location: Location<ReturnInfoState>, defaultReturn: ReturnInfo | undefined) => {
  const returnInfo = location.state && location.state.returnInfo ? location.state.returnInfo : defaultReturn;
  return returnInfo;
};

export const toReturnInfo = (location: Location<any>, name: string | undefined): ReturnInfo => ({
  name,
  state: location.state,
  search: location.search,
  pathname: location.pathname,
});

export const parseIntRouteParam = (param: string) => {
  return parseInt(param);
};

export const parseOptionalIntRouteParam = (param: string | undefined) => {
  return param ? parseIntRouteParam(param) : undefined;
};
