import * as React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { Queries } from 'src/service/materials';

import { RouteComponentProps } from 'react-router-dom';

import { ModalRoute } from 'src/components/ModalRoute';
import { DataLoaderDecorator, DataProps, ErrorPolicy } from 'src/decorators/DataLoaderDecorator';

import { MaterialActionPanel } from './MaterialActionPanel';
import { MaterialProperties } from './MaterialProperties';
import { MaterialEditorDialog } from './material-editor';

import { MaterialLayout } from 'src/components/material-layout';
import { MaterialLabelList } from './label-layout/MaterialLabelList';
import { MaterialLabelEditorDialog } from './label-editor/MaterialLabelEditorDialog';
import { useTranslation } from 'react-i18next';

import { MaterialVariantSelector } from './variants-layout';
import { MaterialVariantLanguageSelector } from './variants-layout/MaterialVariantLanguageSelector';

import { RouteButton } from 'src/components/RouteButton';
import Edit from '@material-ui/icons/Edit';

import { 
  parseVariantLanguage, parseCurrentVariant, localMaterialToViewMaterial
} from 'src/utils/MaterialVariantUtils';
import { SelectedOrganizationContext } from 'src/decorators/SelectedOrganizationProvider';
import { ViewMaterialType, GBMaterialType, MaterialStatus } from 'src/types';
import { Message } from 'src/components';

import Archive from '@material-ui/icons/Archive';
import { TFunction } from 'i18next';
import { MaterialEventsDialog } from './events-dialog/MaterialEventsDialog';
import { parseIntRouteParam, toReturnInfo } from 'src/utils/BrowserUtils';


type RouteProps = RouteComponentProps<{
  materialId: string;
}>;

type MaterialViewProps = RouteProps;
type Props = MaterialViewProps & DataProps<Queries.MaterialResponse>;

const getMaterialDescription = (
  viewMaterial: ViewMaterialType, 
  gbMaterial: GBMaterialType,
  t: TFunction
) => {
  if (gbMaterial.status === MaterialStatus.ARCHIVED) {
    return (
      <>
        <Message 
          style={{ marginBottom: '15px' }} 
          title={ t('archivedMaterial') }
          icon={ <Archive/> }
        />
        <span style={{ color: 'gray' }}>
          { viewMaterial.description }
        </span>
      </>
    );
  }

  return viewMaterial.description;
};

const MaterialView: React.FC<Props> = props => {
  const { t } = useTranslation();
  const organizationContext = React.useContext(SelectedOrganizationContext)!;

  const { data, location, match } = props;

  const localMaterial = data.material;
  if (!localMaterial) {
    // Errors will be printed by the decorator
    return null;
  }

  const variant = parseCurrentVariant(localMaterial.variants, location, organizationContext);
  const viewMaterial = localMaterialToViewMaterial(localMaterial, organizationContext, t, location);
  return (
    <div id="material-page">
      <MaterialLayout
        material={{
          ...viewMaterial,
          description: getMaterialDescription(viewMaterial, localMaterial, t),
        }}
        actionPanel={ <MaterialActionPanel material={ localMaterial }/> }
        materialPropertiesRenderer={ _ => (
          <MaterialProperties
            material={ localMaterial }
            variant={ variant! }
          />
        ) }
        materialLabelsRenderer={ _ => (
          <MaterialLabelList
            material={ localMaterial }
          />
        ) }
        subTitle={ t('material') }
      >
        <MaterialVariantSelector
          variants={ localMaterial.variants }
          t={ t }
        />
        <RouteButton
          to={{ 
            pathname: `${match.url}/variants`,
            state: {
              returnInfo: toReturnInfo(location, t('material'))
            }
          }}
        >
          <Edit/>
        </RouteButton>
        <MaterialVariantLanguageSelector
          variant={ variant }
        />
      </MaterialLayout>
      <ModalRoute
        component={ MaterialEditorDialog }
        path="/materials/:materialId(\d+)/:actionType(edit|approve)"
      />
      <ModalRoute
        component={ MaterialLabelEditorDialog }
        path="/materials/:materialId(\d+)/labels/:labelCategoryId(\d+)?"
      />
      <ModalRoute
        component={ MaterialEventsDialog }
        path="/materials/:materialId(\d+)/events"
      />
    </div>
  );
};

const withMaterialData = graphql<MaterialViewProps, Queries.MaterialResponse>(
  Queries.MaterialWithVariantsQuery, {
    options: ({ match, location }) => ({ 
      variables: { 
        materialId: parseIntRouteParam(match.params.materialId),
        languageCode: parseVariantLanguage(location)
      },
      errorPolicy: ErrorPolicy.ALL,
    })
  }
);

const MaterialViewWithData = withMaterialData(
  DataLoaderDecorator<MaterialViewProps, Queries.MaterialResponse>(
    MaterialView, 
    ErrorPolicy.ALL
  )
);

export { MaterialViewWithData as MaterialView };
