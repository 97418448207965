import * as React from 'react';

import { ActionPanelProps as BrowserLayoutActionPanelProps, isRootCategory } from 'src/components/browser-layout';
import { GBManufacturerIdType, GBManufacturerType } from 'src/types';
import { ActionDropdownMenu } from 'src/components';
import { ManufacturerEditActions, ManufacturerCreateActions } from 'src/actions/ManufacturerActions';

import Add from '@material-ui/icons/Add';
import Edit from '@material-ui/icons/Edit';
import { Trans } from 'react-i18next';

interface ActionPanelProps {
  manufacturer: GBManufacturerType;
}

const ActionPanel: React.FC<ActionPanelProps> = ({ manufacturer }) => (
  <>
    <ActionDropdownMenu
      caption={       
        <>
          <Add/>
          <Trans i18nKey="actions.create"/>
        </> 
      }
      actions={ ManufacturerCreateActions }
      manufacturer={ manufacturer }
    />
    { !isRootCategory(manufacturer) && (
      <ActionDropdownMenu
        caption={       
          <>
            <Edit/>
            <Trans i18nKey="actions.modify"/>
          </> 
        }
        actions={ ManufacturerEditActions }
        manufacturer={ manufacturer }
      />
    ) }
  </>
);


// Helper component that will convert the 'category' prop used by BrowserLayout to 'manufacturer'
type ManufacturerActionPanelProps = BrowserLayoutActionPanelProps<GBManufacturerIdType>;

const ManufacturerActionPanel: React.FC<ManufacturerActionPanelProps> = ({ category, ...other }) => (
  <ActionPanel
    manufacturer={{
      id: category.id,
      name: category.name,
    }}
  />
);

export { ManufacturerActionPanel };