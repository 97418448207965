import * as React from 'react';

import { Table as TableBase } from '@devexpress/dx-react-grid';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import Edit from '@material-ui/icons/Edit';
import { OrganizationType } from 'src/types';
import { ActionDropdownMenu } from 'src/components';
import { OrganizationEditActions } from 'src/actions/OrganizationActions';


export interface OrganizationTableCellProps extends TableBase.DataCellProps {

}

const OrganizationTableCell = React.memo<OrganizationTableCellProps>(props => {
  const organization: OrganizationType = props.tableRow.row;
  if (props.column.name === 'name') {
    return (
      <Table.Cell
        { ...props }
        value={ (
          <>
            { props.value }
            <ActionDropdownMenu
              organization={ organization }
              actions={ OrganizationEditActions }
              caption={ <Edit/> }
              buttonProps={{
                size: 'small',
                style: {
                  padding: '0px',
                  marginLeft: '5px',
                  minHeight: '24px',
                }
              }}
            />
          </>
        ) }
      />
    );
  }

  return (
    <Table.Cell
      { ...props }
    />
  );
});

export { OrganizationTableCell };
