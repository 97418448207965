import * as React from 'react';

import { RouteComponentProps, Switch, Route } from 'react-router-dom';

import { MaterialVariantListLayout } from 'src/modules/materials/components/variants-layout/MaterialVariantListLayout';
import { MaterialView } from 'src/modules/materials/components/MaterialView';
import { MaterialsLayout } from './material-browser';


type RouteProps = RouteComponentProps<{
  materialId: string;
  materialVariantId: string;
}>;

type MaterialViewProps = RouteProps;

class MaterialRoute extends React.PureComponent<MaterialViewProps> {
  render() {
    return (
      <Switch>
        <Route
          component={ MaterialsLayout }
          path="/materials/unapproved"
        />
        <Route
          component={ MaterialVariantListLayout }
          path="/materials/:materialId(\d+)/variants"
        />
        <Route
          path="/materials/:materialId(\d+)"
          component={ MaterialView }
        />
      </Switch>
    );
  }
}

export { MaterialRoute };
