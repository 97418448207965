import { ApolloError } from '@apollo/client';


export enum Action {
  FETCHED = 'fetched',
  FETCH_FAILED = 'fetch_error',
  RESET = 'reset'
}

export interface ActionType<DataT> {
  type: Action;
  data?: DataT | DataT[];
  error?: ApolloError;
}

export interface State<DataT> {
  data: DataT[];
  done: number;
  errors: ApolloError[];
  total: number;
}

export const getEmptyState = <DataT>(total: number): State<any> => ({
  data: [] as DataT[],
  errors: [] as ApolloError[],
  done: 0,
  total,
});

export type MultiFetchReducerType<DataT> = (
  state: State<DataT>, 
  action: ActionType<DataT>
) => State<DataT>;

const parseData = <DataT>(currentData: DataT[], newData: DataT | DataT[] | undefined): DataT[] => {
  if (!newData) {
    return currentData;
  }

  if (Array.isArray(newData)) {
    return [
      ...currentData,
      ...newData,
    ];
  }

  return [
    ...currentData,
    newData, 
  ];
};

export const MultiFetchReducer = <DataT>(
  state: State<DataT>, 
  action: ActionType<DataT>
): State<DataT> => {
  switch (action.type) {
    case Action.FETCHED: {
      return {
        ...state,
        data: parseData(state.data, action.data),
        done: state.done + 1,
      };
    }
    case Action.FETCH_FAILED: {
      return {
        ...state,
        errors: [
          ...state.errors,
          action.error!,
        ],
        done: state.done + 1,
      };
    }
    case Action.RESET: {
      return {
        ...getEmptyState(state.total),
      };
    }
    default: return state;
  }
};