import { ApolloClient } from '@apollo/client';
import { Queries as MaterialQueries } from 'src/service/materials';

import i18n from 'src/i18n';
import { PaginatedResponse, GBMaterialType } from 'src/types';
import { DocumentNode } from 'graphql';
import { ConfigProps } from 'redux-form';


export type AsyncValidator<ValueT, PropsT> = (
  values: ValueT, 
  props: PropsT, 
  client: ApolloClient<any>
) => Promise<void>;

export type AsyncFormValidator<ValueT, PropsT> = {
  asyncValidate: AsyncValidator<ValueT, PropsT>;
  asyncChangeFields: string[];
};


export const asyncValidatorComposer = <ValueT, PropsT>(
  validators: AsyncFormValidator<ValueT, PropsT>[], 
  client: ApolloClient<any>
): Pick<ConfigProps<ValueT, PropsT>, 'asyncValidate' | 'asyncChangeFields' | 'shouldAsyncValidate'> => {
  const asyncValidate: ConfigProps<ValueT, PropsT>['asyncValidate'] = async (
    values, 
    dispatch, 
    props
  ) => {
    const promises = validators.map(v => v.asyncValidate(values, props, client));
    await Promise.all(promises);
  };

  const asyncChangeFields = validators.reduce(
    (reduced, v) => {
      reduced.push(...v.asyncChangeFields);
      return reduced;
    }, 
    [] as string[]
  );

  return {
    asyncValidate,
    asyncChangeFields,
    shouldAsyncValidate: ({syncValidationPasses, trigger}) => {
      // For some reason this remains false when async validation 
      // is performed if the field is updated with a valid value...
      //if (!syncValidationPasses) {
      //  return false;
      //}

      return trigger !== 'submit';
    }
  };
};

interface GTINValidatorProps {
  material?: GBMaterialType;
}

export const gtinUniquenessValidator: AsyncFormValidator<{ gtin?: string }, GTINValidatorProps> = {
  asyncValidate: async (values, props, client) => {
    if (values.gtin) {
      let response: MaterialQueries.MaterialGTINResponse | undefined;

      try {
        response = (await client.query<MaterialQueries.MaterialGTINResponse>({
          query: MaterialQueries.MaterialGTINQuery,
          variables: {
            gtin: values.gtin,
          }
        })).data;
      } catch (e) {
        // OK
        return;
      }

      if (!!props.material && response!.material.id === props.material.id) {
        return;
      }

      // eslint-disable-next-line no-throw-literal
      throw {  
        gtin: i18n.t('validation.materialGtinExists', { material: response!.material }),
      };
    }
  },
  asyncChangeFields: [ 'gtin' ],
};


interface SearchResponse { 
  name: string; 
  id: number; 
}

export const nameUniquenessValidator = (
  query: DocumentNode, 
  type: string,
  entityId: number | undefined
): AsyncFormValidator<{ name?: string }, any> => {
  return {
    asyncValidate: async (values, props, client) => {
      if (values.name) {
        let response;

        try {
          response = (await client.query<any>({
            query,
            variables: {
              query: values.name,
              types: [ type ],
            }
          })).data;
        } catch (e) {
          // ...
          return;
        }

        const results: PaginatedResponse<SearchResponse> = response.search[Object.keys(response.search)[0]];
        const found = results.edges.find(edge => 
          edge.node.name.toLocaleLowerCase() === values.name!.toLocaleLowerCase()
        );

        if (found && found.node.id !== entityId) {
          // eslint-disable-next-line no-throw-literal
          throw {  
            name: i18n.t('validation.nameExists', { name: values.name }),
          };
        }
      }
    },
    asyncChangeFields: [ 'name' ],
  };
};