import * as React from 'react';

import { LocationDescriptorObject } from 'history';
import { RouteProps, Route } from 'react-router';
import { useRouter } from 'src/effects';

import { RouterModalProps } from 'src/types';


interface ModalRouteProps extends RouteProps {
  component: React.ComponentType<RouterModalProps<any>>;
}

const ModalRoute: React.FC<ModalRouteProps> = ({ component: Component, ...other }) => {
  const { match, location, history } = useRouter();
  const [ returnInfo, setReturnInfo ] = React.useState<LocationDescriptorObject>({});
  React.useEffect(
    () => {
      if (location.pathname === match.url) {
        setReturnInfo({
          state: location.state,
          search: location.search,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ location.state, location.search ]
  );

  return (
    <Route
      { ...other }
      render={ props => {
        return (
          <Component
            closeModal={ () => {
              history.push({
                pathname: match.url,
                ...returnInfo,
              });
            } }
            { ...props }
          />
        ); 
      } }
    />
  );
};

export { ModalRoute };
