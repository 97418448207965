import * as React from 'react';

import { 
  MaterialCategoryEditorDataDecorator, MaterialCategoryEditorDataDecoratorChildProps
} from '../../decorators/MaterialCategoryEditorDataDecorator';

import { MaterialCategoryEditorForm, mapFields } from './MaterialCategoryEditorForm';

import { RouterModalProps, MaterialCategoryFieldTypes } from 'src/types';
import { createEditorDialog } from 'src/components/editor';
import { Trans } from 'react-i18next';


type MaterialCategoryEditorDialogProps = 
  MaterialCategoryEditorDataDecoratorChildProps & RouterModalProps;

const EditorDialog = createEditorDialog<
  MaterialCategoryFieldTypes, 
  MaterialCategoryEditorDataDecoratorChildProps
>('materialCategoryEditor', mapFields);

class MaterialCategoryEditorDialog extends React.PureComponent<MaterialCategoryEditorDialogProps> {
  render() {
    const { category, ...other } = this.props;
    return (
      <EditorDialog
        title={ <Trans i18nKey={ !!category ? 'actions.editMaterialCategory' : 'actions.createMaterialCategory' }/> }
        category={ category }
        fieldsComponent={ MaterialCategoryEditorForm }
        { ...other }
      />
    );
  }
}

const MaterialCategoryEditorDialogDecorated = (
  MaterialCategoryEditorDataDecorator(MaterialCategoryEditorDialog)
);

export { MaterialCategoryEditorDialogDecorated as MaterialCategoryEditorDialog };
