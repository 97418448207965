import gql from 'graphql-tag';


// Queries
export const CreateManufacturerMutation = gql`
  mutation CreateManufacturerMutation($input: CreateManufacturerInput!) {
    createManufacturer(input: $input) {
      id
    }
  }
`;

export const UpdateManufacturerMutation = gql`
  mutation UpdateManufacturerMutation($id: Int!, $input: UpdateManufacturerInput!) {
    updateManufacturer(id: $id, input: $input) {
      id
    }
  }
`;

export const DeleteManufacturerMutation = gql`
  mutation DeleteManufacturerMutation($id: Int!) {
    deleteManufacturer(id: $id) {
      id
    }
  }
`;


// Response types
export interface CreateManufacturerResponse {
  createManufacturer: {
    id: number;
  };
}

export interface UpdateManufacturerResponse {
  updateManufacturer: {
    id: number;
  };
}

export interface DeleteManufacturerResponse {
  deleteManufacturer: {
    id: number;
  };
}
