import gql from 'graphql-tag';
import { PaginatedResponse, OrganizationType } from 'src/types';

import { Fragments as FragmentsRaw } from './organizationFragments';

const Fragments = FragmentsRaw();


// Queries
export const OrganizationQuery = gql`
  query OrganizationQuery($organizationId: Int!) {
    organization(id: $organizationId) {
      ...OrganizationBasic
    }
  }
  ${Fragments.basic}
`;

export const OrganizationListQuery = gql`
  query OrganizationListQuery {
    organizations(orderBy: { field: NAME, direction: ASC }) {
      edges {
        node {
          ...OrganizationBasic
        }
      }
      total
    }
  }
  ${Fragments.basic}
`;


// Response types
export interface OrganizationResponse {
  organization: OrganizationType;
}

export interface OrganizationListResponse {
  organizations: PaginatedResponse<OrganizationType>;
}
