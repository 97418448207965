import * as React from 'react';

import { ModalRoute } from 'src/components/ModalRoute';

import { ProviderMaterialProperties } from './ProviderMaterialProperties';

import { MaterialLayout } from 'src/components/material-layout';
import { 
  ProviderMaterialDataDecorator, ProviderMaterialDataDecoratorChildProps
} from '../decorators/ProviderMaterialDataDecorator';

import { ProviderMaterialImportDialog } from './import-dialog/ProviderMaterialImportDialog';
import { ProviderTitle } from 'src/modules/providers';
import { ProviderFeatures, hasProviderFeature } from 'src/constants/ProviderFeatures';


class ProviderMaterialView extends React.PureComponent<ProviderMaterialDataDecoratorChildProps> {
  render() {
    const provider = this.props.data.provider!;
    const { material } = provider;

    return (
      <div id="provider-material-page">
        <MaterialLayout
          material={ material }
          browserBaseUrl={ `/providers/${provider.code}` }
          materialPropertiesRenderer={ _ => (
            <ProviderMaterialProperties
              material={ material }
              provider={ provider }
            />
          ) }
          showDefaultReturn={ hasProviderFeature(provider, ProviderFeatures.BROWSER) }
          subTitle={ <ProviderTitle provider={ provider }/> }
        />
        <ModalRoute
          component={ ProviderMaterialImportDialog as any }
          path={ `/providers/:providerId(\\w+)/materials/:materialId/import` }
        />
      </div>
    );
  }
}

const ProviderMaterialViewWithData = ProviderMaterialDataDecorator(ProviderMaterialView);

export { ProviderMaterialViewWithData as ProviderMaterialView };
