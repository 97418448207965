import { Permission } from 'src/constants';

import { 
  ActionHandlerProps, ActionList, DataSourceItem, GBMaterialType, 
  ProviderType, ProviderMaterialType, Action 
} from 'src/types';

import { Actions } from 'src/service/dataSourceItems';

import Autorenew from '@material-ui/icons/Autorenew';
import Delete from '@material-ui/icons/Delete';
import Link from '@material-ui/icons/Link';
import CloudDownload from '@material-ui/icons/CloudDownload';


interface DataSourceItemEditActionProps {
  dataSourceItem: DataSourceItem;
  material: GBMaterialType;
}

interface DataSourceItemLinkActionProps {
  material: GBMaterialType;
  provider: ProviderType;
  providerMaterial: ProviderMaterialType;
}

interface DataSourceItemImportActionProps {
  providerMaterial: ProviderMaterialType;
}

type ImportProps = ActionHandlerProps & DataSourceItemImportActionProps;
type LinkProps = ActionHandlerProps & DataSourceItemLinkActionProps;
type EditProps = ActionHandlerProps & DataSourceItemEditActionProps;


const handleToggleActive = (props: EditProps) => {
  const { dataSourceItem, client, material } = props;
  const dataSourceItemId = dataSourceItem.active ? null : dataSourceItem.id;

  return Actions.setActiveMaterialDataSourceItem(
    {
      materialId: material.id,
      dataSourceItemId,
    },
    client
  );
};

const handleDelete = (props: EditProps) => {
  const { dataSourceItem, client } = props;
  return Actions.sendRemoveDataSourceItem(
    dataSourceItem.id,
    client
  );
};

const handleImportMaterial = (props: ImportProps) => {
  const { location, history } = props;
  const url = `${location.pathname}/import`;
  history.push(url);
};

const handleLinkMaterial = async (props: LinkProps) => {
  const { client, providerMaterial, provider, material } = props;
  await Actions.sendAddMaterialDataSourceItem(
    {
      materialId: material.id,
      providerId: provider.id,
      providerMaterialId: providerMaterial.id,
    },
    client
  );
};


export const DataSourceItemImportAction: Action<DataSourceItemImportActionProps> = {
  title: 'actions.import',
  icon: CloudDownload,
  handler: handleImportMaterial,
  access: Permission.MATERIALS_CREATE,
};


export const DataSourceItemLinkAction: Action<DataSourceItemLinkActionProps> = {
  title: 'actions.linkMaterial',
  icon: Link,
  handler: handleLinkMaterial,
  access: Permission.MATERIALS_EDIT,
  onSuccess: 'actions.notifications.providerMaterialLinked',
  onFailed: 'actions.notifications.providerMaterialLinkFailed',
};


const syncEnabled = ({ dataSourceItem }: EditProps) => dataSourceItem.active;

export const DataSourceItemEditActions: ActionList<DataSourceItemEditActionProps> = {
  ENABLE_SYNC: {
    title: 'actions.enableSync',
    icon: Autorenew,
    handler: handleToggleActive,
    access: Permission.MATERIALS_EDIT,
    onSuccess: 'actions.notifications.dataSourceSyncEnabled',
    onFailed: 'actions.notifications.dataSourceUpdateFailed',
    filter: props => !syncEnabled(props),
  },
  DISABLE_SYNC: {
    title: 'actions.disableSync',
    icon: Autorenew,
    handler: handleToggleActive,
    access: Permission.MATERIALS_EDIT,
    onSuccess: 'actions.notifications.dataSourceSyncDisabled',
    onFailed: 'actions.notifications.dataSourceUpdateFailed',
    filter: syncEnabled,
  },
  DELETE: {
    title: 'actions.delete',
    icon: Delete,
    handler: handleDelete,
    onSuccess: 'actions.notifications.dataSourceRemoved',
    onFailed: 'actions.notifications.dataSourceRemoveFailed',
    access: Permission.MATERIALS_EDIT,
    confirmation: {
      title: 'actions.confirmations.removeDataSource',
      description: 'actions.confirmations.removeDataSourceMessage',
    },
  }
};
