import gql from 'graphql-tag';
import { MaterialVariant, GBMaterialType } from 'src/types';

import { Fragments as FragmentsRaw } from './materialVariantFragments';
import { 
  Fragments as MaterialFragmentsRaw 
} from 'src/service/materials/materialFragments';
import { 
  Fragments as MaterialVariantLocalizationFragmentsRaw 
} from 'src/service/materialVariantLocalizations/materialVariantLocalizationFragments';

const Fragments = FragmentsRaw();
const MaterialFragments = MaterialFragmentsRaw();
const LocalizationFragments = MaterialVariantLocalizationFragmentsRaw();


// Queries
export const MaterialVariantQuery = gql`
  query MaterialVariantQuery(
    $materialId: Int!
    $materialVariantId: Int!
    $languageCode: LanguageCode
  ) {
    materialVariant(id: $materialVariantId) {
      ...MaterialVariantBasic
      ...MaterialVariantOrganization
      ...MaterialVariantLocalizations
      localization(languageCode: $languageCode) {
        ...MaterialVariantLocalizationBasic
      }
    }
    material(id: $materialId) {
      ...MaterialBasic
      variants {
        ...MaterialVariantBasic
      }
    }
  }
  ${Fragments.basic}
  ${Fragments.organization}
  ${Fragments.localizations}
  ${MaterialFragments.basic}
  ${LocalizationFragments.basic}
`;

// Response types
export interface MaterialVariantResponse {
  material: GBMaterialType;
  materialVariant: MaterialVariant;
}
