import { DocumentNode } from 'graphql';
import { ApolloClient, ApolloError } from '@apollo/client';


export const createMutation = async <ResponseT extends object, InputT extends object = {}>(
  variables: InputT,
  mutation: DocumentNode,
  client: ApolloClient<any>,
  error: string = 'Failed to create entity'
): Promise<ResponseT> => {
  try {
    const response = (await client.mutate({
      mutation: mutation,
      variables,
    })).data as ResponseT;

    return response;
  } catch (err) {
    if (err instanceof ApolloError) {
      console.log(error, err.message, JSON.stringify(err));
    }
    
    throw err;
  }
};

export const inputMutation = createMutation;

export const updateMutation = async <ResponseT extends object, InputT extends object = {}, IdTypeT = unknown>(
  id: IdTypeT,
  variables: RecursivePartial<InputT>,
  mutation: DocumentNode,
  client: ApolloClient<any>,
  error: string = 'Failed to update entity'
): Promise<ResponseT> => {
  try {
    const response = (await client.mutate({
      mutation: mutation,
      variables: {
        id,
        ...variables as object, 
      }
    })).data as ResponseT;

    return response;
  } catch (err) {
    if (err instanceof ApolloError) {
      console.log(error, err.message, JSON.stringify(err));
    }

    throw err;
  }
};

export const deleteMutation = async <ResponseT extends object, IdType = unknown>(
  id: IdType,
  mutation: DocumentNode,
  client: ApolloClient<any>,
  onDeletedCallback?: (response: ResponseT) => void,
  error: string = 'Failed to delete entity'
): Promise<ResponseT> => {
  try {
    const response = (await client.mutate({
      mutation: mutation,
      variables: {
        id,
      }
    })).data as ResponseT;

    if (onDeletedCallback) {
      onDeletedCallback(response);
    }

    return response;
  } catch (err) {
    if (err instanceof ApolloError) {
      console.log(error, err.message, JSON.stringify(err));
    }
    
    throw err;
  }
};

export const resetCache = (client: ApolloClient<any>) => {
  if (process.env.NODE_ENV === 'development') {
    console.log('Resetting cache');
  }

  return new Promise<void>((resolve, reject) => {
    setTimeout(async () => {
      try {
        await client.resetStore();
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  });
};
