import * as React from 'react';

import { LabelCategoryHomeLayout } from './LabelCategoryHomeLayout';
import { LabelCategoryPageLayout } from './LabelCategoryPageLayout';

import { RouteComponentProps } from 'react-router';

import { LabelEditorDialog } from 'src/modules/labels';
import { LabelCategoryEditorDialog } from './labelcategory-dialog';

import { ModalRoute } from 'src/components/ModalRoute';
import { isRootCategory } from 'src/components/browser-layout';
import { Trans } from 'react-i18next';
import { parseOptionalIntRouteParam } from 'src/utils/BrowserUtils';


interface RouteProps {
  labelCategoryId?: string;
}

class LabelCategoryView extends React.PureComponent<RouteComponentProps<RouteProps>, {}> {
  render() {
    const { match, location } = this.props;

    const labelCategoryId = parseOptionalIntRouteParam(match.params.labelCategoryId);
    const MainLayout = labelCategoryId ? LabelCategoryPageLayout : LabelCategoryHomeLayout;
    return (
      <div id="label-category-page">
        <MainLayout
          labelCategoryId={ labelCategoryId }
          browserBaseUrl={ category => {
            if (!!match.params.labelCategoryId && !isRootCategory(category)) {
              return '/labels';
            }

            return '/label-categories';
          }}
          location={ location }
          indexTitle={ <Trans i18nKey="labelCategories"/> }
        />
        <ModalRoute
          component={ LabelCategoryEditorDialog }
          path={ `/label-categories/create` }
          exact={ true }
        />
        <ModalRoute
          component={ LabelCategoryEditorDialog }
          path={ `/label-categories/:labelCategoryId(\\d+)/edit` }
        />
        <ModalRoute
          component={ LabelEditorDialog }
          path={ `/label-categories/:labelCategoryId(\\d+)/label` }
        />
      </div>
    );
  }
}

export { LabelCategoryView };
