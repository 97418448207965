import * as React from 'react';

import { graphql } from '@apollo/client/react/hoc';
import { Location } from 'history';

import { 
  BrowserLayout, MaterialBrowserPropsType
} from 'src/components/browser-layout';

import { DataLoaderDecorator } from 'src/decorators/DataLoaderDecorator';
import { Queries } from 'src/service/materialCategories';

import { normalizePaginatedResponse } from 'src/utils/PaginationUtils';

import { MaterialFilterPanel } from 'src/components/browser-layout';

import { MaterialCategoryMaterialTable } from './MaterialCategoryMaterialTable';
import { Trans } from 'react-i18next';
import { GBMaterialCategoryIdType } from 'src/types';


export interface MaterialCategoryPagePropsType extends MaterialBrowserPropsType<GBMaterialCategoryIdType> {
  categoryId?: number;
  location: Location;
}

const withCategory = graphql<MaterialCategoryPagePropsType, Queries.MaterialCategoryCategoriesResponse>(
  Queries.MaterialCategoryCategoriesQuery, {
    options: ({ categoryId }) => {
      return {
        variables: { 
          categoryId
        }
      };
    },
  }
);

const MaterialCategoryPageLayout = withCategory(DataLoaderDecorator(({ data, location, ...other }) => {
  const  { materials, categories, ...category } = data.materialCategory!;
  return (
    <BrowserLayout<GBMaterialCategoryIdType>
      category={ category }
      childCategories={ normalizePaginatedResponse(categories) }
      subTitle={ <Trans i18nKey="materialCategory"/> }
      materialTable={ 
        <MaterialCategoryMaterialTable
          category={ category }
          location={ location }
        /> 
      }
      filterPanel={ 
        <MaterialFilterPanel
          labelCounts={ category.labelCounts }
          manufacturerCounts={ category.manufacturerCounts }
        /> 
      }
      totalMaterialCount={ materials.total }
      { ...other }
    />
  );
}));

export { MaterialCategoryPageLayout };