import * as React from 'react';

import { GBMaterialType } from 'src/types';
import { Location } from 'history';
import { ActionButton } from 'src/components/ActionButton';
import { MaterialVariantCreateAction } from 'src/actions/MaterialVariantActions';


interface ActionPanelProps {
  material: GBMaterialType;
  location: Location;
}

const ActionPanel: React.FC<ActionPanelProps> = ({ material, location }) => (
  <>
    <ActionButton
      action={ MaterialVariantCreateAction }
      material={ material }
    />
  </>
);

export { ActionPanel as MaterialVariantActionPanel };