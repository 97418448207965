import * as React from 'react';

import { 
  MaterialCategorySelectField, ManufacturerSelectField, 
  MaterialCategorySelectFieldComponent, ManufacturerSelectFieldComponent 
} from 'src/components/form';

import { InjectedEditorFormProps, FieldMapperConfigProps } from 'src/components/editor';
import { 
  ProviderImportFieldTypes, ProviderImportDataDecoratorChildProps 
} from '../../decorators/ProviderImportDataDecorator';
import { Trans } from 'react-i18next';


type ProviderMaterialImportFormProps = 
  InjectedEditorFormProps<ProviderImportFieldTypes, ProviderImportDataDecoratorChildProps>;

class ProviderMaterialImportForm extends React.PureComponent<ProviderMaterialImportFormProps> {
  render() {
    const { material } = this.props;
    return (
      <>
        <MaterialCategorySelectField 
          required 
          name="categoryId" 
          component={ MaterialCategorySelectFieldComponent } 
          label={ <Trans i18nKey="category"/> }
        />
        <ManufacturerSelectField 
          required 
          name="manufacturerId" 
          component={ ManufacturerSelectFieldComponent } 
          label={ <Trans i18nKey="manufacturer"/> }
          suggestedValue={ !!material ? material.manufacturer.name : undefined }
        />
      </>
    );
  }
}

const mapFields = (
  state: any
): FieldMapperConfigProps<ProviderImportFieldTypes> => {
  return {};
};

export { ProviderMaterialImportForm, mapFields };
