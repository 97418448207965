import * as React from 'react';

import { MessageCard } from './MessageCard';
import { ProgressCard } from './ProgressCard';

import { Route, Switch, RouteComponentProps } from 'react-router-dom';

import { SnackbarContext } from 'src/decorators/NotificationDecorator';

import Card from '@material-ui/core/Card';
import { Trans } from 'react-i18next';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import Auth from 'src/service/Auth';
import { withAuth, AuthProps } from 'src/decorators/AuthDecorator';


interface State {
  error?: string;
}

type LoginProps = RouteComponentProps<{}, any, { redirectTo?: string; }>;

class Login extends React.Component<WithApolloClient<{}> & LoginProps & AuthProps, State> {
  static contextType = SnackbarContext;
  context!: SnackbarContext;

  state: State = {
    error: undefined,
  };

  shouldComponentUpdate(nextProps: LoginProps, nextState: State) {
    return this.state.error !== nextState.error;
  }

  onAuthCompleted = async (auth: Auth) => {
    try {
      // Parse query
      await auth.handleAuthentication(this.props.client!);

      const redirectTo = sessionStorage.getItem('redirectTo');
      this.props.history.replace(redirectTo ? JSON.parse(redirectTo) : '/');
    } catch (err) {
      if (err instanceof Error) {
        // Failed
        this.context({
          text: <span>{ err.message }</span>,
        });

        this.setState({
          error: err.message,
        });
      }
    }
  }

  handleLogin = (auth: Auth) => {
    const { state } = this.props.location;
    if (state && state.redirectTo) {
      sessionStorage.setItem('redirectTo', JSON.stringify(state.redirectTo));
    } else {
      // Remove possible previously set locations...
      sessionStorage.removeItem('redirectTo');
    }

    auth.login();
  }

  render() {
    const { match, auth } = this.props;
    const { error } = this.state;
    if (!!error) {
      return (
        <MessageCard
          handleLogin={ () => this.handleLogin(auth) }
        >
          { error }
        </MessageCard>
      );
    }

    return (
      <Card
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Switch>
          <Route 
            path={ `${match.url}/callback` } 
            render={ () => {
              this.onAuthCompleted(auth);
              return (
                <ProgressCard>
                  <Trans i18nKey="processing"/>
                </ProgressCard>
              );
            } }
          />
          <Route 
            path={ `${match.url}` } 
            render={ () => {
              return (
                <MessageCard
                  handleLogin={ () => this.handleLogin(auth) }
                >
                  <Trans i18nKey="materialDatabase"/>
                </MessageCard>
              );
            } }
          />
        </Switch>
      </Card>
    );
  }
}

const LoginDecorated = withApollo<LoginProps>(withAuth(Login));

export { LoginDecorated as Login };
