import gql from 'graphql-tag';
import { 
  ProviderMaterialType, ProviderMaterialCategoryType, 
  ProviderType, PaginatedResponse, TotalResponse 
} from 'src/types';

import { 
  ProviderFragments as ProviderFragmentsRaw, 
  ProviderMaterialFragments as ProviderMaterialFragmentsRaw, 
  ProviderMaterialCategoryFragments as ProviderMaterialCategoryFragmentsRaw 
} from './providerFragments';

import { Fragments as DataSourceItemFragmentsRaw } from 'src/service/dataSourceItems/dataSourceItemFragments';

import { Fragments as MaterialVariantFragmentsRaw } from 'src/service/materialVariants/materialVariantFragments';
import { 
  Fragments as MaterialVariantLocalizationFragmentsRaw 
} from 'src/service/materialVariantLocalizations/materialVariantLocalizationFragments';

const ProviderFragments = ProviderFragmentsRaw();
const ProviderMaterialFragments = ProviderMaterialFragmentsRaw();
const ProviderMaterialCategoryFragments = ProviderMaterialCategoryFragmentsRaw();
const DataSourceItemFragments = DataSourceItemFragmentsRaw();

const MaterialVariantFragments = MaterialVariantFragmentsRaw();
const MaterialLocalizationFragments = MaterialVariantLocalizationFragmentsRaw();


// Queries
export const ProvidersGetQuery = gql`
  query ProvidersGetQuery {
    providers {
      ...ProviderBasic
    }
  }
  ${ProviderFragments.basic}
`;

export const ProviderGetQuery = gql`
  query ProviderGetQuery($providerId: String!) {
    provider(code: $providerId) {
      ...ProviderBasic
    }
  }
  ${ProviderFragments.basic}
`;

export const ProviderMaterialGetQuery = gql`
  query ProviderMaterialGetQuery(
    $providerId: String!, 
    $materialId: String!,
    $languageCode: LanguageCode
  ) {
    provider(code: $providerId) {
      ...ProviderBasic
      material(id: $materialId) {
        ...ProviderMaterialBasic
        ...ProviderMaterialExtra
        ...ProviderMaterialManufacturer
        ...ProviderMaterialCategory
        ...ProviderMaterialAttachments
        dataSourceItems {
          ...DataSourceItemBasic
          material {
            id
            variants {
              ...MaterialVariantBasic
              ...MaterialVariantOrganization
              ...MaterialVariantProvider
              localization(languageCode: $languageCode) {
                ...MaterialVariantLocalizationBasic
              }
            }
          }
        }
      }
    }
  }
  ${ProviderFragments.basic}
  ${ProviderMaterialFragments.basic}
  ${ProviderMaterialFragments.extra}
  ${ProviderMaterialFragments.materialCategory}
  ${ProviderMaterialFragments.manufacturer}
  ${ProviderMaterialFragments.attachments}
  ${DataSourceItemFragments.basic}
  ${MaterialVariantFragments.basic}
  ${MaterialVariantFragments.organization}
  ${MaterialVariantFragments.provider}
  ${MaterialLocalizationFragments.basic}
`;

export const ProviderMaterialCategoryCategoriesQuery = gql`
  query ProviderMaterialCategoryCategoriesQuery(
    $providerId: String!,
    $categoryId: String!, 
  ) {
    provider(code: $providerId) {
      ...ProviderBasic
      materialCategory(id: $categoryId) {
        ...ProviderMaterialCategoryBasic
        parent {
          ...ProviderMaterialCategoryBasic
          parent {
            ...ProviderMaterialCategoryBasic
            parent {
              ...ProviderMaterialCategoryBasic
              parent {
                ...ProviderMaterialCategoryBasic
              }
            }
          }
        }
        materialCategories(orderBy: { field: NAME, direction: ASC }) {
          edges {
            node {
              ...ProviderMaterialCategoryBasic
            }
          }
        }
        materials {
          total
        }
      }
    }
  }
  ${ProviderFragments.basic}
  ${ProviderMaterialCategoryFragments.basic}
`;

export const ProviderMaterialCategoryMaterialsQuery = gql`
  query ProviderMaterialCategoryMaterialsQuery(
    $providerId: String!,
    $categoryId: String!, 
    $first: Int, 
    $after: String, 
    $orderBy: ProviderMaterialOrder,
  ) {
    provider(code: $providerId) {
      id
      materialCategory(id: $categoryId) {
        id
        materials(first: $first, after: $after, orderBy: $orderBy) {
          total,
          edges {
            node {
              ...ProviderMaterialBasic
              ...ProviderMaterialManufacturer
            }
          }
        }
      }
    }
  }
  ${ProviderMaterialFragments.basic}
  ${ProviderMaterialFragments.manufacturer}
`;

export const ProviderMaterialCategoryListQuery = gql`
  query ProviderMaterialCategoryListQuery($providerId: String!) {
    provider(code: $providerId) {
      ...ProviderBasic
      materialCategories {
        ...ProviderMaterialCategoryBasic
      }
    }
  }
  ${ProviderFragments.basic}
  ${ProviderMaterialCategoryFragments.basic}
`;

export const ProviderMaterialSearchListQuery = gql`
  query ProviderMaterialSearchQuery(
    $query: String!,
    $providerId: String!,
    $first: Int, 
    $after: String, 
    $orderBy: ProviderMaterialOrder,
  ) {
    provider(code: $providerId) {
      id
      search(
        query: $query,
        first: $first, 
        after: $after, 
        orderBy: $orderBy,
      ) {
        total,
        edges {
          node {
            ...ProviderMaterialBasic
            ...ProviderMaterialManufacturer
            ...ProviderMaterialCategory
          }
        }
      }
    }
  }
  ${ProviderMaterialFragments.basic}
  ${ProviderMaterialFragments.manufacturer}
  ${ProviderMaterialFragments.materialCategory}
`;

export const ProviderMaterialSearchTotalQuery = gql`
  query ProviderMaterialSearchTotalQuery(
    $query: String!,
    $providerId: String!
  ) {
    provider(code: $providerId) {
      ...ProviderBasic
      search(
        query: $query,
      ) {
        total
      }
    }
  }
  ${ProviderFragments.basic}
`;


// Response types
export interface GetProviderResponse {
  provider: ProviderType;
}

export interface GetProvidersResponse {
  providers: ProviderType[];
}

interface MaterialWithProviderType extends ProviderType {
  material: ProviderMaterialType;
}

// Category
interface CategoriesResponse extends ProviderMaterialCategoryType {
  materialCategories: PaginatedResponse<ProviderMaterialType>;
  materials: TotalResponse;
}

interface MaterialsResponse extends ProviderMaterialCategoryType {
  materials: PaginatedResponse<ProviderMaterialType>;
}


interface CategoryWithProviderType<ResponseType> extends ProviderType {
  materialCategory: ResponseType;
}

interface CategoriesWithProviderType extends ProviderType {
  materialCategories: ProviderMaterialCategoryType[];
}

interface SearchTotalType extends ProviderType {
  search: TotalResponse;
}

interface SearchListType {
  search: TotalResponse & PaginatedResponse<ProviderMaterialType>;
}

export interface MaterialCategoryCategoriesResponse {
  provider: CategoryWithProviderType<CategoriesResponse>;
}

export interface MaterialCategoryMaterialsResponse {
  provider: CategoryWithProviderType<MaterialsResponse>;
}

// Material
export interface GetProviderMaterialResponse {
  provider: MaterialWithProviderType;
}

// Root categories
export interface ProviderMaterialCategoryListResponse {
  provider: CategoriesWithProviderType;
}

// Search
export interface ProviderMaterialSearchTotalResponse {
  provider: SearchTotalType;
}

export interface ProviderMaterialSearchListResponse {
  provider: SearchListType;
}
