import * as React from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import { DropdownMenu } from 'src/components/DropdownMenu';

import { Queries } from 'src/service/organizations';

import { DataLoaderDecorator, DataProps } from 'src/decorators/DataLoaderDecorator';
import { graphql } from '@apollo/client/react/hoc';
//import { OrganizationType } from 'src/types';
import { normalizePaginatedResponse } from 'src/utils/PaginationUtils';
//import { OrganizationType } from 'src/types';
//import { BrowserSettings } from 'src/constants';
//import { loadLocalProperty, saveLocalProperty } from 'src/utils/BrowserUtils';
import { SelectedOrganizationContext } from 'src/decorators/SelectedOrganizationProvider';
//import { Trans } from 'react-i18next';


interface OrganizationSelectorProps {

}

type OrganizationSelectorDataProps = DataProps<Queries.OrganizationListResponse>;


/*const parseSelectedOrganization = (organizations: OrganizationType[]) => {
  const lastId = loadLocalProperty(BrowserSettings.LAST_ORGANIZATION_ID);
  const selectedOrganization = organizations.find(org => org.id === lastId);
  if (selectedOrganization) {
    return selectedOrganization;
  }

  if (!organizations.length) {
    throw 'Organizations should not be empty';
  }

  return organizations[0];
};*/


/*const OrganizationSelector: React.FC<OrganizationSelectorProps & OrganizationSelectorDataProps> = ({ data }) => {
  const organizations = normalizePaginatedResponse(data.organizations!);
  return (
    <DropdownMenu
      caption={ parseSelectedOrganization(organizations).name }
      buttonProps={{
        fullWidth: true,
        style: { marginTop: '5px' },
      }}
    >
      { organizations.map(organization => (
        <MenuItem 
          key={ organization.id }
          onClick={ () => {
            saveLocalProperty(BrowserSettings.LAST_ORGANIZATION_ID, organization.id);
          }}
        >
          { organization.name }
        </MenuItem>
      )) }
    </DropdownMenu>
  );
};*/

type Props = OrganizationSelectorProps & OrganizationSelectorDataProps;

const OrganizationSelector: React.FC<Props> = ({ data }) => {
  const organizations = normalizePaginatedResponse(data.organizations!);
  const organizationContext = React.useContext(SelectedOrganizationContext)!;
  return (
    <DropdownMenu
      caption={ organizationContext.selectedOrganization.name }
      buttonProps={{
        fullWidth: true,
        style: { marginTop: '5px' },
      }}
    >
      { organizations.map(organization => (
        <MenuItem 
          key={ organization.id }
          onClick={ () => {
            //saveLocalProperty(BrowserSettings.LAST_ORGANIZATION_ID, organization.id);
            organizationContext.setOrganization(organization);
          }}
        >
          { organization.name }
        </MenuItem>
      )) }
    </DropdownMenu>
  );
};

const withOrganizationData = graphql<
  OrganizationSelectorProps, 
  Queries.OrganizationListResponse
>(
  Queries.OrganizationListQuery
);

const OrganizationSelectorWithData = withOrganizationData(
  DataLoaderDecorator<OrganizationSelectorProps, Queries.OrganizationListResponse>(
    OrganizationSelector
  )
);

export { OrganizationSelectorWithData as OrganizationSelector };
