import * as React from 'react';

import { graphql } from '@apollo/client/react/hoc';
import { RouteComponentProps } from 'react-router';

import { Queries as MaterialQueries } from 'src/service/materials';
import { Actions as MaterialsLabelsActions } from 'src/service/materialsLabels';
import { ApolloClient } from '@apollo/client';

import { DataLoaderDecorator, DataProps } from 'src/decorators/DataLoaderDecorator';
import { 
  FormSubmitHandler, GBMaterialType, MaterialLabelInput, GBLabelType 
} from 'src/types';
import { normalizeValues } from 'src/utils/FormUtils';
import { Trans } from 'react-i18next';
import { SnackbarContext } from 'src/decorators/NotificationDecorator';
import { SuggestionOption } from 'src/components/form/select-field/SuggestionSection';
import { parseIntRouteParam } from 'src/utils/BrowserUtils';


export type MaterialLabelFieldTypes = MaterialLabelInput & { suggestions?: SuggestionOption<GBLabelType>[]; };


type RouteProps = RouteComponentProps<{
  materialId: string;
  labelCategoryId?: string;
}>;

type MaterialLabelEditorDataDecoratorProps = RouteProps;

export interface MaterialLabelEditorDataDecoratorChildProps extends RouteProps {
  onSubmit: FormSubmitHandler<MaterialLabelFieldTypes>;
  material: GBMaterialType;
  client: ApolloClient<any>;
}


type Props = MaterialLabelEditorDataDecoratorProps & DataProps<MaterialQueries.MaterialResponse>;

function MaterialLabelEditorDataDecorator<T>(
  Component: React.ComponentType<T & MaterialLabelEditorDataDecoratorChildProps>
) {
  const withLabelData = graphql<MaterialLabelEditorDataDecoratorProps & T, MaterialQueries.MaterialResponse>(
    MaterialQueries.MaterialQuery, 
    {
      skip: ({ match }) => {
        return !match.params.materialId;
      },
      options: ({ match }) => ({ 
        variables: { 
          materialId: parseIntRouteParam(match.params.materialId) 
        } 
      }),
    }
  );

  class Decorator extends React.PureComponent<Props & T> {
    static contextType = SnackbarContext;
    context!: SnackbarContext;

    parseLabels = (labelData: MaterialLabelFieldTypes) => {
      const { material } = this.props.data;
      const { labelCategoryId } = this.props.match.params;
      if (material && labelCategoryId) {
        const categoryId = parseInt(labelCategoryId);
        const previousLabelIds = material.labels
          .filter(label => label.category.id === categoryId)
          .map(label => label.id);

        let addedLabels = labelData.labelIds
          .filter(id => previousLabelIds.indexOf(id) === -1);
        let removedLabels = previousLabelIds
          .filter(id => labelData.labelIds.indexOf(id) === -1);
        
        return {
          addedLabels,
          removedLabels,
        };
      } else {
        return {
          addedLabels: labelData.labelIds,
          removedLabels: [],
        };
      }
    }

    handleSubmit: FormSubmitHandler<MaterialLabelFieldTypes> = async (labelData, dispatch, formProps) => {
      const { data, client } = this.props;

      const { addedLabels, removedLabels } = this.parseLabels(await normalizeValues(labelData as any) as any);
  
      // Add new labels
      try {
        await MaterialsLabelsActions.sendUpdateMaterialLabels(
          addedLabels,
          removedLabels,
          labelData.suggestions,
          data.material!.id,
          client
        );
      } catch (error) {
        this.context({
          text: <Trans i18nKey="actions.notifications.materialLabelCategorySaveFailed" values={{ error }}/>,
        });
      }

      // Report
      this.context({
        text: <Trans i18nKey="actions.notifications.materialLabelCategorySaved"/>,
      });

      return true;
    }

    render() {
      const { data } = this.props;
      return (
        <Component
          { ...this.props }
          onSubmit={ this.handleSubmit }
          material={ data.material! }
        />
      );
    }
  }

  return withLabelData(
    DataLoaderDecorator<MaterialLabelEditorDataDecoratorProps & T, MaterialQueries.MaterialResponse>(Decorator)
  );
}

export { 
  MaterialLabelEditorDataDecorator
};
