import * as React from 'react';

import { MaterialLabelEditorForm, FORM_ID } from './MaterialLabelEditorForm';

import { 
  MaterialLabelEditorDataDecorator,
  MaterialLabelEditorDataDecoratorChildProps, MaterialLabelFieldTypes 
} from '../../decorators/MaterialLabelEditorDataDecorator';

import { RouterModalProps } from 'src/types';
import { createEditorDialog } from 'src/components/editor';
import { mapFields } from 'src/modules/materials/components/label-editor/MaterialLabelEditorForm';
import { Trans } from 'react-i18next';


type LabelEditorDialogProps = MaterialLabelEditorDataDecoratorChildProps & RouterModalProps;

const EditorDialog = createEditorDialog<
  MaterialLabelFieldTypes, 
  MaterialLabelEditorDataDecoratorChildProps
>(FORM_ID, mapFields);

class LabelEditorDialog extends React.PureComponent<LabelEditorDialogProps> {
  render() {
    const { material, match, ...other } = this.props;
    const editing = !!match.params.labelCategoryId;
    return (
      <EditorDialog
        title={ 
          <Trans
            i18nKey={ editing ? 'actions.editMaterialLabelCategory' : 'actions.addMaterialLabelCategory' }
          /> 
        }
        material={ material }
        match={ match }
        fieldsComponent={ MaterialLabelEditorForm }
        { ...other }
      />
    );
  }
}

const MaterialLabelEditorDialogDecorated = MaterialLabelEditorDataDecorator(LabelEditorDialog);

export { MaterialLabelEditorDialogDecorated as MaterialLabelEditorDialog };
