import * as React from 'react';

import {
  PagingState,
  SortingState,
  CustomPaging,
  PagingPanel as PagingPanelBase,
  SelectionState,
  IntegratedSelection
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableProps,
  TableSelection
} from '@devexpress/dx-react-grid-material-ui';

import {
  Plugin,
  Template,
  TemplatePlaceholder
} from '@devexpress/dx-react-core';

import styled from 'styled-components';

import Paper from '@material-ui/core/Paper';

import { 
  PaginationQueryType, parsePaginationVariables 
} from 'src/utils/PaginationUtils';
import { isElementVisible, saveLocalProperty } from 'src/utils/BrowserUtils';
import { updateLocationParams } from 'src/utils/QueryUtils';

import { ActionList, ActionHandlerProps } from 'src/types';
import { ActionDropdownMenu } from '../ActionDropdownMenu';
import { useRouter } from 'src/effects';
import { useTranslation } from 'react-i18next';


const PageSizes = [ 5, 15, 25, 50 ];


export interface PaginatedTableColumnWidthInfo {
  minWidth?: string;
  width: string;
  columnName: string;
}

export const TableLinkStyle = {
  color: 'inherit',
  textDecoration: 'underline',
};

export interface SelectionActionProps<RowT> {
  selection: RowT[];
}

export interface PaginatedTableProps<ColumnKeys extends string[], RowT> extends Pick<TableProps, 'cellComponent'> {
  columns: ColumnKeys;
  sortableColumns?: ColumnKeys;
  totalCount: number | null;
  rows: RowT[];
  pageSizes?: number[];
  defaultPagination: PaginationQueryType;
  defaultColumnWidths: PaginatedTableColumnWidthInfo[];
  selectionActions?: ActionList<SelectionActionProps<RowT> & ActionHandlerProps>;
}


interface StyledPaperProps {
  defaultColumnWidths: PaginatedTableColumnWidthInfo[];
}

const StyledPaper = styled(Paper)`
  && {
    table tr th:nth-child(1) {
      width: ${(props: StyledPaperProps) => props.defaultColumnWidths[0].width} 
      min-width: ${(props: StyledPaperProps) => props.defaultColumnWidths[0].minWidth}
    }
    table tr th:nth-child(2) {
      width: ${(props: StyledPaperProps) => props.defaultColumnWidths[1].width} 
      min-width: ${(props: StyledPaperProps) => props.defaultColumnWidths[1].minWidth}
    }
    table tr th:nth-child(3) {
      width: ${(props: StyledPaperProps) => !!props.defaultColumnWidths[2] && props.defaultColumnWidths[2].width} 
      min-width: ${(props: StyledPaperProps) => !!props.defaultColumnWidths[2] && props.defaultColumnWidths[2].minWidth}
    }
    table tr th:nth-child(4) {
      width: ${(props: StyledPaperProps) => !!props.defaultColumnWidths[3] && props.defaultColumnWidths[3].width} 
      min-width: ${(props: StyledPaperProps) => !!props.defaultColumnWidths[3] && props.defaultColumnWidths[3].minWidth}
    }
  }
`;

export const getTableRowId = (id: string | number) => `table-row-${id}`;

const TableRow = ({ row, ...other }: Table.DataRowProps) => (
  <Table.Row 
    id={ getTableRowId(row.id) }
    row={ row } 
    { ...other }
  />
);

interface LocationState {
  rowId?: number | string;
}

const PaginatedTable = <ColumnKeys extends string[], RowT extends { id: string | number; }>(
  props: PaginatedTableProps<ColumnKeys, RowT>
) =>  {
  const [ selection, setSelection ] = React.useState<Array<string | number>>([]);
  const { history, location } = useRouter<{}, any, LocationState>();
  const { t } = useTranslation();
  const { 
    rows, totalCount, columns, cellComponent, pageSizes, selectionActions, defaultColumnWidths, defaultPagination
  } = props;

  React.useEffect(
    () => {
      // Reset selections if the data has changed
      setSelection([]);
    },
    [ location.search ]
  );


  React.useEffect(
    () => {
      if (!!location.state && !!location.state.rowId) {
        // Restore the scroll position
        const item = document.querySelector(
          `#${getTableRowId(location.state.rowId)}`
        );

        if (!!item && !isElementVisible(item)) {
          item.scrollIntoView();
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getPaginationInfo = () => {
    return parsePaginationVariables(location, defaultPagination).table;
  };

  const pagination = getPaginationInfo();

  const updateParams = (params: Partial<PaginationQueryType>) => {
    updateLocationParams(history, params);
  };

  const changeSorting = (sorting: Array<any>) => {
    const { sortableColumns } = props;
    if (!!sortableColumns && sortableColumns!.indexOf(sorting[0].columnName) !== -1) {
      updateParams({
        orderby: sorting[0].columnName,
        direction: sorting[0].direction,
      });
    }
  };

  const changeCurrentPage = (currentPage: number) => {
    updateParams({
      page: (currentPage + 1).toString(),
    });
  };

  const changePageSize = (pageSize: number) => {
    if (totalCount) {
      const totalPages = Math.ceil(totalCount / pageSize);
      const currentPage = Math.min(pagination.currentPage, totalPages);

      if (!!defaultPagination.pageSizeStorageKey) {
        saveLocalProperty(defaultPagination.pageSizeStorageKey, pageSize);
      }

      updateParams({
        pagesize: pageSize.toString(),
        page: currentPage.toString(),
      });
    }
  };

  const getPagingLocalization = (): PagingPanelBase.LocalizationMessages => {
    return {
      showAll: 'All', // Not being used
      rowsPerPage: `${t('table.rowsPerPage')}:`,
      info: ({ from, to, count }) => t('table.fromToOfCount', {
        from,
        to,
        count
      }),
    };
  };

  return (
    <StyledPaper
      defaultColumnWidths={ defaultColumnWidths }
    >
      <Grid
        rows={ rows }
        columns={
          columns
            .map(key => ({
              name: key,
              title: t(key),
            }))
        }
        getRowId={ row => row.id }
      >
        { !!selectionActions && (
          <SelectionState
            selection={ selection }
            onSelectionChange={ sel => setSelection(sel) }
          />
        ) }
        { !!selectionActions && (
          <IntegratedSelection/>
        ) }
        <SortingState
          sorting={ pagination.sorting }
          onSortingChange={ changeSorting }
        />
        <PagingState
          currentPage={ pagination.currentPage }
          onCurrentPageChange={ changeCurrentPage }
          pageSize={ pagination.pageSize }
          onPageSizeChange={ changePageSize }
        />
        <CustomPaging
          totalCount={ totalCount || 0 }
        />
        <Table
          cellComponent={ cellComponent }
          rowComponent={ TableRow }
        />
        <TableHeaderRow showSortingControls />
        { !!selectionActions && <TableSelection showSelectAll={ true }/> }
        <PagingPanel
          pageSizes={ pageSizes || PageSizes }
          messages={ getPagingLocalization() }
        />
        <Plugin name="SelectionPanel">
          <Template name="footer">
            <div>
              { !!selectionActions && !!selection.length && (
                <div
                  style={{
                    float: 'left',
                    padding: '12px'
                  }}
                >
                  <ActionDropdownMenu
                    selection={ selection
                      .map(id => rows.find(row => row.id === id))
                      .filter(sel => !!sel)
                    }
                    actions={ selectionActions }
                    caption={ t('actions.actions') }
                  />
                </div>
              ) }
              <TemplatePlaceholder />
            </div>
          </Template>
        </Plugin>
      </Grid>
    </StyledPaper>
  );
};


export { 
  PaginatedTable
};
