import gql from 'graphql-tag';

export const Fragments = () => ({
  basic: gql`
    fragment EventBasic on Event {
      id
      time
    }
  `,
  details: gql`
    fragment EventDetails on Event {
      event {
        id
        action
      }
    }
  `,
  user: gql`
    fragment EventUser on Event {
      user {
        id
        name
        email
      }
    }
  `,
  entityBasic: gql`
    fragment EventEntityBasic on EventEntity {
      id
      type
      name
      updatedFields
    }
  `,
  entityGroupBasic: gql`
    fragment EventEntityGroupBasic on EventEntityGroup {
      id
      type
      name
    }
  `,
});
