import * as React from 'react';

import Typography from '@material-ui/core/Typography';

import { Trans } from 'react-i18next';
import { ActionButton } from 'src/components';
import { DataSourceItemImportAction } from 'src/actions/DataSourceItemActions';


export interface MaterialImportLayoutProps {

}

const MaterialImportLayout: React.FC<MaterialImportLayoutProps> = () => (
  <>
    <Typography style={{ color: 'gray' }}>
      <Trans i18nKey="noMatchingMaterials"/>
    </Typography>
    <ActionButton
      action={ DataSourceItemImportAction }
      variant="contained" 
      style={{
        marginTop: '10px',
      }}
    />
  </>
);

export { MaterialImportLayout };
