import * as React from 'react';
import { SnackbarContext } from 'src/decorators/NotificationDecorator';
import { DataLoaderDecorator, DataProps } from 'src/decorators/DataLoaderDecorator';

import { graphql } from '@apollo/client/react/hoc';
import { RouteComponentProps } from 'react-router';

import { Actions, Queries } from 'src/service/manufacturers';
import { ApolloClient } from '@apollo/client';

import { normalizeUpdateFields, normalizeCreateFields } from 'src/utils/FormUtils';
import { GBManufacturerType, FormSubmitHandler, ManufacturerFieldTypes } from 'src/types';
import { Trans } from 'react-i18next';
import { parseIntRouteParam } from 'src/utils/BrowserUtils';


type RouteProps = RouteComponentProps<{
  manufacturerId?: string;
}>;

type ManufacturerEditorDataDecoratorProps = RouteProps;

export interface ManufacturerEditorDataDecoratorChildProps extends RouteProps {
  onSubmit: FormSubmitHandler<ManufacturerFieldTypes>;
  manufacturer?: GBManufacturerType;
  client: ApolloClient<any>;
}


type Props = ManufacturerEditorDataDecoratorProps & DataProps<Queries.ManufacturerResponse>;

function ManufacturerEditorDataDecorator<T>(
  Component: React.ComponentType<T & ManufacturerEditorDataDecoratorChildProps>
) {
  const withManufacturerData = graphql<RouteProps & T, Queries.ManufacturerResponse>(
    Queries.ManufacturerQuery, 
    {
      skip: ({ match }) => {
        return !match.params.manufacturerId;
      },
      options: ({ match }) => ({ 
        variables: { 
          manufacturerId: parseIntRouteParam(match.params.manufacturerId!) 
        } 
      }),
    }
  );

  class Decorator extends React.Component<Props & T> {
    static contextType = SnackbarContext;
    context!: SnackbarContext;

    onSubmit: FormSubmitHandler<ManufacturerFieldTypes> = async (manufacturerData, dispatch, formProps) => {
      const { data, client } = this.props;

      // Create/update manufacturer
      try {
        const response = data && data.manufacturer ? 
          await Actions.sendUpdateManufacturer(
            data.manufacturer.id, 
            await normalizeUpdateFields(manufacturerData, formProps.initialValues), 
            client
          ) :
          await Actions.sendCreateManufacturer(
            await normalizeCreateFields(manufacturerData), 
            client
          );

        if (response) {
          this.context({
            text: (
              <Trans i18nKey="actions.notifications.manufacturerSaved" values={{ manufacturer: manufacturerData }}/>
            ), 
            actionTitle: <Trans i18nKey="actions.viewManufacturer"/>, 
            actionHandler: () => this.props.history.push(`/manufacturers/${response.id}`)
          });
        }
      } catch (error) {
        this.context({
          text: <Trans i18nKey="actions.notifications.manufacturerSaveFailed" values={{ error }}/>,
        });

        return false;
      }

      return true;
    }

    render() {
      const { data } = this.props;
      return (
        <Component
          { ...this.props }
          onSubmit={ this.onSubmit }
          manufacturer={ data ? data.manufacturer : undefined }
        />
      );
    }
  }

  return withManufacturerData(
    DataLoaderDecorator<ManufacturerEditorDataDecoratorProps & T, Queries.ManufacturerResponse>(Decorator)
  );
}

export { 
  ManufacturerEditorDataDecorator
};
