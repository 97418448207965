import * as React from 'react';

import { AttachmentType } from 'src/types';
import { MaterialProductInfoLinkListStyle, MaterialProductInfoLinkTextStyle } from 'src/components/material-layout';

import Attachment from '@material-ui/icons/Attachment';
import { Typography } from '@material-ui/core';


const MaterialAttachment: React.FC<{ attachment: AttachmentType }> = ({ attachment }) => {
  return (
    <a 
      href={ attachment.url }
      style={ MaterialProductInfoLinkListStyle }
    >
      <Attachment
        style={{
          color: 'black'
        }}
      />
      <Typography 
        style={{ 
          ...MaterialProductInfoLinkTextStyle,
          marginLeft: '3px',
        }}
      >
        { attachment.name }
      </Typography>
    </a>
  );
};

export { MaterialAttachment };