import * as React from 'react';

import ArrowBack from '@material-ui/icons/ArrowBack';
import { RouteButton } from 'src/components/RouteButton';


export interface ReturnInfo {
  name?: string;
  pathname: string;
  search?: string;
  state?: object;
}

export interface ReturnButtonProps {
  returnInfo: ReturnInfo;
}

export const ReturnButton: React.FC<ReturnButtonProps> = ({ children, returnInfo, ...other }) => (
  <RouteButton
    to={{
      pathname: returnInfo.pathname,
      state: returnInfo.state,
      search: returnInfo.search,
    }}
    { ...other }
  >
    <ArrowBack/>
    { returnInfo.name }
  </RouteButton>
);