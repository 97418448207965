import * as React from 'react';

import './App.css';

import { BrowserRouter as Router, Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { SnackbarProvider } from 'src/decorators/NotificationDecorator';

import { AuthenticatedApp } from 'src/container';
import { Login } from 'src/modules/login';

import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import { ApolloProvider } from '@apollo/client';
import { API } from 'src/service/API';
import Auth from 'src/service/Auth';


import { Provider as ReduxProvider } from 'react-redux';
import { createStore } from 'redux';
import reducers from './reducers';

import { I18nextProvider } from 'react-i18next';
import i18n from 'src/i18n';
import { AuthContext } from './decorators/AuthDecorator';


const theme = createMuiTheme({
  overrides: {
    MuiFormControl: {
      root: {
        marginTop: '10px',
      }
    },
    MuiFormLabel: {
      root: {
        marginBottom: '5px',
      }
    },
  }
});


const store = createStore(reducers);

class App extends React.Component {
  auth = new Auth();
  api = API(i18n);

  getRootRoute = (routeProps: RouteComponentProps<{}>) => {
    if (!this.auth.isAuthenticated()) {
      return (
        <Redirect 
          to={{
            pathname: '/login',
            state: {
              redirectTo: routeProps.location,
            }
          }}
        />
      );
    }

    return <AuthenticatedApp/>;
  }

  getLoginRoute = (routeProps: RouteComponentProps<{}, any, any>) => {
    if (this.auth.isAuthenticated()) {
      return (
        <Redirect 
          to={{
            pathname: '/',
          }}
        />
      );
    }

    return <Login { ...routeProps }/>;
  }

  render() {
    return (
      <Router>
        <ReduxProvider store={ store }>
          <ApolloProvider client={ this.api }>
            <MuiThemeProvider theme={ theme }>
              <I18nextProvider i18n={ i18n }>
                <SnackbarProvider>
                  <AuthContext.Provider value={ this.auth }>
                    <div className="App">
                      <CssBaseline/>
                      <Switch>
                        <Route 
                          path="/login" 
                          render={ this.getLoginRoute }
                        />
                        <Route 
                          path="/" 
                          render={ this.getRootRoute }
                        />
                      </Switch>
                    </div>
                  </AuthContext.Provider>
                </SnackbarProvider>
              </I18nextProvider>
            </MuiThemeProvider>
          </ApolloProvider>
        </ReduxProvider>
      </Router>
    );
  }
}

export default App;
