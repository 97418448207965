import * as React from 'react';

import { 
  MaterialAttachmentsDataDecorator, MaterialAttachmentsDataDecoratorChildProps, MaterialAttachmentsDataDecoratorProps
} from '../../decorators/MaterialAttachmentsDataDecorator';
import { MaterialAttachment, MaterialProductInfoLinkListStyle } from 'src/components/material-layout';
import { CircularProgress, Typography } from '@material-ui/core';
import { Trans } from 'react-i18next';

import { formatGraphqlError } from 'src/decorators/DataLoaderDecorator';
import Error from '@material-ui/icons/Error';


type AttachmentListProps = MaterialAttachmentsDataDecoratorProps;

const AttachmentList = MaterialAttachmentsDataDecorator<AttachmentListProps>(
  (props: MaterialAttachmentsDataDecoratorChildProps) => {
    if (!props.material.dataSourceItems) {
      return null;
    }

    const loading = props.attachments.done < props.attachments.total;
    return (
      <div className="attachments-list">
        { !loading && !props.attachments.data.length && !props.attachments.errors.length && (
          <Typography style={{ color: 'gray' }}>
            <Trans i18nKey="na"/>
          </Typography>
        ) }
        { props.attachments.data.map(attachment => (
          <MaterialAttachment
            key={ attachment.name }
            attachment={ attachment }
          />
        )) }
        { props.attachments.errors.map(error => (
          <div key={ error.message } style={ MaterialProductInfoLinkListStyle }>
            <Error color="error"/>
            <Typography 
              style={{ color: 'gray' }}
            >
              { formatGraphqlError(error) }
            </Typography>
          </div>
        )) }
        { loading && (
          <CircularProgress 
            size={ 25 } 
            style={{ marginTop: 'auto', marginBottom: 'auto' }}
          />
        ) }
      </div>
    );
  }
);

export { AttachmentList };
