import { Action, ActionHandlerProps, ActionList } from 'src/types';
import Auth from 'src/service/Auth';


/*export const filterActions: Function = <PropsT>(actions: Action<PropsT>[], auth: Auth, props: PropsT) => {
  return actions.filter(action => {
    const { access } = action;
    //return access instanceof Function ? access(props) : auth.hasGlobalAccess(access);
  });
};*/


const matchFilter = <PropsT>(filter: Action<PropsT>['filter'], props: ActionHandlerProps & PropsT) => {
  if (!filter) {
    return true;
  }

  if (Array.isArray(filter)) {
    return filter.every(f => f(props));
  }
  
  return filter(props);
};

export const showAction = <PropsT extends object>(
  action: Action<PropsT> | null, 
  auth: Auth,
  props: ActionHandlerProps & PropsT
) => {
  if (!action) {
    return true;
  }

  const { access, itemOrganizationGetter, filter } = action;
  if (!matchFilter(filter, props)) {
    return false;
  }

  if (typeof access === 'function') {
    return access(props);
  } else {
    if (auth.hasGlobalAccess(access)) {
      return true;
    }

    if (itemOrganizationGetter) {
      const organization = itemOrganizationGetter(props);
      if (organization && auth.hasOrganizationAccessByCode(access, organization.code)) {
        return true;
      }
    } else if (auth.hasOrganizationAccessAny(access)) {
      return true;
    }
  }

  return false;
};

export const filterActions = <PropsT extends object, ActionListT extends ActionList<PropsT>>(
  actions: ActionListT, 
  auth: Auth,
  props: ActionHandlerProps & PropsT,
  actionIds?: Array<keyof ActionListT>
) => {
  return Object.keys(actions)
    .filter(key => !actionIds || actionIds.indexOf(key) !== -1)
    .filter(key => showAction(actions[key], auth, props))
    .map(key => actions[key]);
};
