import * as React from 'react';

import { Location } from 'history';

import { ProviderType } from 'src/types';

import { Queries } from 'src/service/providers';

import { 
  ProvidersDataDecorator, ProvidersDataDecoratorChildProps 
} from 'src/modules/providers/decorators/ProvidersDataDecorator';

import { parseSearchVariables } from 'src/utils/SearchUtils';
import { ProviderFeatures, hasProviderFeature } from 'src/constants/ProviderFeatures';
import { ApolloClient, ApolloError, useApolloClient } from '@apollo/client';
import { MultiFetchReducer, getEmptyState, Action, State, MultiFetchReducerType } from 'src/reducers/MultiFetchReducer';


interface ProviderSearchCountsDecoratorProps {
  location: Location;
}

export interface SearchResultInfo {
  provider: ProviderType;
  total: number;
}

export interface ProviderSearchCountsDecoratorChildProps {
  providerCounts: State<SearchResultInfo>;
}


const fetchProviderResultCounts = async (query: string, provider: ProviderType, client: ApolloClient<any>) => {
  const res = await client.query<Queries.ProviderMaterialSearchTotalResponse>({
    query: Queries.ProviderMaterialSearchTotalQuery,
    variables: {
      query,
      providerId: provider.code,
    }
  });

  return res.data!.provider.search.total;
};

type Props = 
  ProviderSearchCountsDecoratorProps & ProvidersDataDecoratorChildProps;


export function ProviderSearchCountsDecorator<T>(
  Component: React.ComponentType<T & ProviderSearchCountsDecoratorChildProps>
) {
  const Decorator: React.FC<T & Props> = props => {
    const searchProviders = props.data.providers!
      .filter(provider => hasProviderFeature(provider, ProviderFeatures.SEARCH));

    const [ state, dispatch ] = React.useReducer<MultiFetchReducerType<SearchResultInfo>>(
      MultiFetchReducer, 
      getEmptyState(searchProviders.length)
    );

    const client = useApolloClient();
    const { query } = parseSearchVariables(props.location);

    React.useEffect(
      () => {
        dispatch({
          type: Action.RESET,
        });

        if (query) {
          searchProviders.forEach(
            async (provider) => {
              try {
                const total = await fetchProviderResultCounts(query, provider, client);
                dispatch({
                  type: Action.FETCHED,
                  data: total ? {
                    total,
                    provider,
                  } : undefined,
                });
              } catch (e) {
                console.error(`Failed to fetch total counts for provider ${provider.name}`, e);

                if (e instanceof ApolloError) {
                  dispatch({
                    type: Action.FETCH_FAILED,
                    error: e,
                  });
                }
              }
            }
          );
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [ props.data.providers, query, client ]
    );
    
    return (
      <Component
        providerCounts={ state }
        { ...props }
      />
    );
  };

  return ProvidersDataDecorator<T & ProviderSearchCountsDecoratorProps>(Decorator);
}
