import * as React from 'react';

import { Message } from 'src/components/Message';
import { Trans } from 'react-i18next';


const NotFoundView: React.FC<{}> = () => (
  <Message
    title={ <Trans i18nKey="pageNotFound"/> }
    description={ <Trans i18nKey="pageNotFoundDescription"/> }
  />
);

export { NotFoundView };
