
import { 
  LabelCountType, GBLabelCategoryType
} from 'src/types';


export interface GroupedLabelCountsType {
  category: GBLabelCategoryType;
  labelCounts: LabelCountType[];
  totalMaterialCount: number;
}

// Group a list of label counts by category
export const groupLabels = (reduced: GroupedLabelCountsType[], labelCounts: LabelCountType) => {
  const group = reduced.find(item => item.category.id === labelCounts.label.category.id);
  if (!group) {
    reduced.push({
      category: labelCounts.label.category,
      labelCounts: [ labelCounts ],
      totalMaterialCount: labelCounts.count
    });
  } else {
    group.labelCounts.push(labelCounts);
    group.totalMaterialCount += labelCounts.count;
  }

  return reduced;
};
