import * as React from 'react';

import { graphql } from '@apollo/client/react/hoc';

import { BrowserLayout, RootCategoryNum } from 'src/components/browser-layout';

import { DataLoaderDecorator } from 'src/decorators/DataLoaderDecorator';

import { Queries } from 'src/service/labelCategories';
import { LabelCategoryPagePropsType } from './LabelCategoryPageLayout';
import { normalizePaginatedResponse } from 'src/utils/PaginationUtils';
import { defaultSort } from 'src/utils/SortUtils';

import Folder from '@material-ui/icons/Folder';


export type LabelCategoryHomeLayoutProps = LabelCategoryPagePropsType;

const withCategories = graphql<LabelCategoryHomeLayoutProps, Queries.LabelCategoryListResponse>(
  Queries.LabelCategoryListQuery, 
  { }
);

const LabelCategoryHomeLayout = withCategories(DataLoaderDecorator(({ data, ...other }) => {
  return (
    <BrowserLayout
      category={ RootCategoryNum }
      childCategories={ 
        normalizePaginatedResponse(data.labelCategories!) 
          .sort((a, b) => defaultSort(a.name, b.name))
      }
      navigationItemIcon={ Folder }
      { ...other }
    />
  );
}));

export { LabelCategoryHomeLayout };