import { Permission } from 'src/constants';

import { ActionHandlerProps, GBMaterialCategoryType, ActionList } from 'src/types';

import { Actions } from 'src/service/materialCategories';

import Folder from '@material-ui/icons/Folder';
import Label from '@material-ui/icons/Label';

import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import { isRootCategory } from 'src/components/browser-layout';


interface MaterialCategoryEditActionProps {
  category: GBMaterialCategoryType;
}

interface MaterialCategoryCreateActionProps {

}

type CreateProps = ActionHandlerProps & MaterialCategoryCreateActionProps;
type EditProps = ActionHandlerProps & MaterialCategoryEditActionProps;



const handleCreateMaterial = (props: CreateProps) => {
  const { history, match } = props;
  const url = `${match.url}/material`;
  history.push(url);
};

const handleCreateCategory = (props: CreateProps) => {
  const { history, match } = props;
  const url = `${match.url}/category`;
  history.push(url);
};

const handleEdit = (props: EditProps) => {
  const { history, match } = props;

  const url = `${match.url}/category/edit`;
  history.push({
    pathname: url,
    search: history.location.search,
  });
};

const handleDelete = async (props: EditProps) => {
  const { category, client, history } = props;
  return Actions.sendDeleteMaterialCategory(category.id, client, result => {
    if (category.parent && !isRootCategory(category.parent)) { // parent should always exist
      history.replace(`/categories/${category.parent.id}`);
    } else {
      history.replace('/categories');
    }
  });
};

export const MaterialCategoryCreateActions: ActionList<MaterialCategoryCreateActionProps> = {
  CREATE_CATEGORY: {
    title: 'actions.newCategory',
    icon: Folder,
    handler: handleCreateCategory,
    access: Permission.MATERIAL_CATEGORIES_CREATE,
  }, 
  CREATE_MATERIAL: {
    title: 'actions.newMaterial',
    icon: Label,
    handler: handleCreateMaterial,
    access: Permission.MATERIALS_CREATE,
  }
};

export const MaterialCategoryEditActions: ActionList<MaterialCategoryEditActionProps> = {
  EDIT: {
    title: 'actions.edit',
    icon: Edit,
    handler: handleEdit,
    access: Permission.MATERIAL_CATEGORIES_EDIT,
  },
  DELETE: {
    title: 'actions.delete',
    icon: Delete,
    handler: handleDelete,
    onSuccess: 'actions.notifications.categoryDeleted',
    onFailed: 'actions.notifications.categoryDeleteFailed',
    access: Permission.MATERIAL_CATEGORIES_DELETE,
    confirmation: {
      title: 'actions.confirmations.deleteCategory',
      description: 'actions.confirmations.deleteCategoryMessage',
    },
  }
};
