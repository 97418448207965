import { Permission } from 'src/constants';

import { ActionHandlerProps, GBMaterialType, ActionList, MaterialStatus, ViewMaterialType } from 'src/types';

import { Actions } from 'src/service/materials';

import { stringifyQuery } from 'src/utils/QueryUtils';
import { SelectionActionProps } from 'src/components/table';
import { resetCache } from 'src/utils/MutationUtils';

import Delete from '@material-ui/icons/Delete';
import Forward from '@material-ui/icons/Forward';
// import Done from '@material-ui/icons/Done';
import Archive from '@material-ui/icons/Archive';


type SelectionProps = ActionHandlerProps & SelectionActionProps<GBMaterialType & ViewMaterialType>;


const handleDelete = async (props: SelectionProps) => {
  const { selection, client, snackbar } = props;
  for (let material of selection) {
    try {
      await Actions.sendDeleteMaterial(material.id, client);
    } catch (e) {
      snackbar({
        text: 'actions.notifications.materialDeleteFailed',
        values: { 
          error: e,
        },
      });
    }
  }
};

const handleMove = async (props: SelectionProps) => {
  const { selection, history, match } = props;
  history.push({
    pathname: `${match.url}/move`,
    search: stringifyQuery({
      materialIds: selection.map(sel => sel.id),
    })
  });
};

const setStatuses = async (props: SelectionProps, status: MaterialStatus) => {
  const { selection, client, snackbar } = props;
  for (let material of selection) {
    try {
      await Actions.sendUpdateMaterial(material.id, { status }, client);
    } catch (e) {
      snackbar({
        text: 'actions.notifications.materialUpdateFailed',
        values: { 
          error: e,
        },
      });
    }
  }

  resetCache(client);
};

/*const handleApprove = async (props: SelectionProps) => {
  return setStatuses(props, MaterialStatus.ACTIVE);
};*/

const handleArchive = async (props: SelectionProps) => {
  return setStatuses(props, MaterialStatus.ARCHIVED);
};


const MaterialSelectionActions: ActionList<SelectionProps> = {
  MOVE: {
    title: 'actions.move',
    icon: Forward,
    handler: handleMove,
    access: Permission.MATERIALS_EDIT,
  },
  DELETE: {
    title: 'actions.delete',
    icon: Delete,
    handler: handleDelete,
    onSuccess: 'actions.notifications.materialsDeleted',
    onFailed: 'actions.notifications.materialsDeleteFailed',
    access: Permission.MATERIALS_DELETE,
    confirmation: {
      title: 'actions.confirmations.deleteMaterials',
      description: 'actions.confirmations.deleteMaterialsMessage',
    },
  },
  /*APPROVE: {
    title: 'actions.approve',
    icon: Done,
    handler: handleApprove,
    access: Permission.MATERIALS_EDIT,
    filter: ({ selection }) => {
      return selection.some(sel => sel.status === MaterialStatus.NEW);
    }
  },*/
  ARCHIVE: {
    title: 'actions.archive',
    icon: Archive,
    handler: handleArchive,
    access: Permission.MATERIALS_EDIT,
    filter: ({ selection }) => {
      return selection.some(sel => sel.status === MaterialStatus.ACTIVE);
    }
  }
};

export { MaterialSelectionActions };
