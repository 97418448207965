import * as React from 'react';
import { UserInfo } from 'src/types';

import Person from '@material-ui/icons/Person';

import { LanguageSelector } from 'src/container/LanguageSelector';
import { OrganizationSelector } from '../OrganizationSelector';


const ImageStyle = {
  maxWidth: '50px',
  maxHeight: '50px',
  marginRight: '5px',
};

const UserInfoLayout: React.FC<{ userInfo?: UserInfo }> = ({ userInfo }) => {
  if (!userInfo) {
    return null;
  }

  return (
    <div 
      style={{
        margin: '15px 10px',
      }}
    >
      <div
        style={{
          display: 'flex',
        }}
      >
        { !!userInfo.picture ? ( 
          <img 
            alt={ userInfo.name }
            src={ userInfo.picture }
            style={ ImageStyle }
          /> 
        ) : (
          <Person
            style={{
              width: ImageStyle.maxWidth,
              height: ImageStyle.maxHeight,
            }}
          />
        ) }
        <div>
          <div
            style={{
              color: 'black',
            }}
          >
            { userInfo.name }
          </div>
          <div
            style={{
              color: 'gray',
            }}
          >
            { userInfo.email }
          </div>
        </div>
      </div>
      <LanguageSelector/>
      <OrganizationSelector/>
    </div>
  );
};

export { UserInfoLayout };
