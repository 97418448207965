import * as React from 'react';

import { MaterialFilterPanel } from 'src/components/browser-layout';
import { SearchResultsMaterialTable } from './SearchResultsTable';

import { Message } from 'src/components/Message';
import { parseSearchVariables } from 'src/utils/SearchUtils';
import { hasMaterialFilterVariables } from 'src/utils/MaterialFilterUtils';
import { LocalSearchCountsDecorator } from 'src/modules/search/decorators/LocalSearchCountsDecorator';
import { Trans } from 'react-i18next';
import { FilterableTableLayout } from 'src/components/table';


const SearchResultsLayout = LocalSearchCountsDecorator((
  { location, data }
) => {
  const { materials, materialLabelCounts, materialCategoryCounts, materialManufacturerCounts } = data.search!;
  if (materials.total === 0 && !hasMaterialFilterVariables(location)) {
    return (
      <div>
        <Message
          title={ <Trans i18nKey="noLocalResults" values={{ query: parseSearchVariables(location).query }}/> }
        />
      </div>
    );
  }

  return (
    <FilterableTableLayout
      table={
        <SearchResultsMaterialTable
          location={ location }
        />
      }
      filterPanel={
        <MaterialFilterPanel
          labelCounts={ materialLabelCounts }
          manufacturerCounts={ materialManufacturerCounts }
          materialCategoryCounts={ materialCategoryCounts }
        /> 
      }
    />
  );
});

export { SearchResultsLayout };