import React, { useContext } from 'react';

import { graphql } from '@apollo/client/react/hoc';

import { BrowserLayout, RootCategoryNum } from 'src/components/browser-layout';

import { DataLoaderDecorator } from 'src/decorators/DataLoaderDecorator';

import { Queries } from 'src/service/materialCategories';
import { MaterialCategoryPagePropsType } from './MaterialCategoryPageLayout';
import { normalizePaginatedResponse } from 'src/utils/PaginationUtils';
import { defaultSort } from 'src/utils/SortUtils';
import { Permission } from 'src/constants';
import { UnapprovedMaterialsNotification } from 'src/modules/materials';
import { AuthContext } from 'src/decorators/AuthDecorator';


type MaterialCategoryHomeLayoutPropsType = MaterialCategoryPagePropsType;

const withCategories = graphql<MaterialCategoryHomeLayoutPropsType, Queries.MaterialCategoryListResponse>(
  Queries.MaterialCategoryListQuery, 
  { }
);

const MaterialCategoryHomeLayout = withCategories(DataLoaderDecorator(({ data, location, ...other }) => {
  const auth = useContext(AuthContext)!;

  return (
    <BrowserLayout
      category={ RootCategoryNum }
      childCategories={ 
        normalizePaginatedResponse(data.materialCategories!)
          .sort((a, b) => defaultSort(a.name, b.name)) 
      }
      { ...other }
    >
      { auth.hasGlobalAccess(Permission.MATERIALS_EDIT) && (
        <UnapprovedMaterialsNotification
          location={ location }
        /> 
      ) }
    </BrowserLayout>
  );
}));

export { MaterialCategoryHomeLayout };