import * as React from 'react';

import { graphql } from '@apollo/client/react/hoc';
import { Location } from 'history';

import { 
  BrowserLayout, MaterialBrowserPropsType,
} from 'src/components/browser-layout';

import { DataLoaderDecorator } from 'src/decorators/DataLoaderDecorator';
import { Queries } from 'src/service/labels';

import Label from '@material-ui/icons/Label';
import { LabelMaterialTable } from './LabelMaterialTable';
import { Trans } from 'react-i18next';
import { GBLabelIdType } from 'src/types';


export interface LabelPagePropsType extends MaterialBrowserPropsType<GBLabelIdType> {
  labelId?: number;
  location: Location;
}


const withLabel = graphql<LabelPagePropsType, Queries.LabelCategoriesResponse>(
  Queries.LabelCategoriesQuery, {
    options: ({ labelId, location }) => {
      return {
        variables: { 
          labelId
        }
      };
    },
  }
);

const LabelPageLayout = withLabel(DataLoaderDecorator(({ data, location, ...other }) => {
  const { id, name, category, materials } = data.label!;
  return (
    <BrowserLayout<GBLabelIdType>
      category={{
        id,
        name,
        parent: {
          id: category.id,
          name: category.name,
        }
      }}
      childCategories={ [] }
      subTitle={ <Trans i18nKey="label"/> }
      navigationItemIcon={ Label }
      materialTable={ 
        <LabelMaterialTable
          label={ data.label! }
          location={ location }
        />
      }
      totalMaterialCount={ materials.total }
      { ...other }
    />
  );
}));

export { LabelPageLayout };