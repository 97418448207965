import * as React from 'react';

import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';


import Button from '@material-ui/core/Button';
import { Trans } from 'react-i18next';


interface LogoutCardProps {
  handleLogin: () => void;
}

const MessageCard: React.FC<LogoutCardProps> = ({ handleLogin, children }) => (
  <CardContent
    style={{
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    }}
  >
    <Typography variant="h5" component="h2">
      { children }
    </Typography>
    <Button
      variant="contained"
      onClick={ handleLogin }
      style={{ marginTop: '10px' }}
    >
      <Trans i18nKey="login"/>
    </Button>
  </CardContent>
);

export { MessageCard };