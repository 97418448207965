import gql from 'graphql-tag';


// Queries
export const CreateMaterialMutation = gql`
  mutation CreateMaterialMutation($input: CreateMaterialInput!) {
    createMaterial(input: $input) {
      id,
      category {
        id
      }
    }
  }
`;

export const UpdateMaterialMutation = gql`
  mutation UpdateMaterialMutation($id: Int!, $input: UpdateMaterialInput!) {
    updateMaterial(id: $id, input: $input) {
      id,
      category {
        id
      }
    }
  }
`;

export const DeleteMaterialMutation = gql`
  mutation DeleteMaterialMutation($id: Int!) {
    deleteMaterial(id: $id) {
      id,
      category {
        id
      }
    }
  }
`;

export const SyncMaterialMutation = gql`
  mutation SyncMaterialMutation($input: SyncMaterialInput!) {
    syncMaterial(input: $input) {
      id
    }
  }
`;



// Response types
export interface CreateMaterialResponse {
  createMaterial: {
    id: number;
    category: {
      id: number;
    }
  };
}

export interface UpdateMaterialResponse {
  updateMaterial: {
    id: number;
    category: {
      id: number;
    }
  };
}

export interface UploadMaterialImageResponse {
  uploadMaterialImage: {
    id: number;
    category: {
      id: number;
    }
  };
}

export interface SyncMaterialResponse {
  syncMaterial: {
    id: number;
  };
}

export interface DeleteMaterialResponse {
  deleteMaterial: {
    id: number;
    category: {
      id: number;
    }
  };
}
