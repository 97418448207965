import * as React from 'react';

import { Grid, Typography, Paper, makeStyles } from '@material-ui/core';

import { RouteButton } from 'src/components';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { Trans, useTranslation } from 'react-i18next';
import { MainLayout, ReturnButton } from 'src/components/main-layout';
import { Route, RouteComponentProps } from 'react-router';


const useStyles = makeStyles({
  root: {
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
});

interface AdminGridItemProps {
  id: string;
  icon: React.ComponentType<SvgIconProps>;
}

const AdminGridItem: React.FC<AdminGridItemProps> = ({ id, icon: Icon }) => {
  const classes = useStyles({});
  const url = `/admin/${id}`;
  return (
    <>
      <Grid item>
        <Paper style={{ width: '250px', height: '150px' }}>
          <RouteButton 
            to={ url }
            classes={ classes }
          >
            <Icon style={{ height: '75px', width: '75px' }}/>
            <Typography noWrap variant="h6">
              <Trans i18nKey={ id }/>
            </Typography>
          </RouteButton>
        </Paper>
      </Grid>
    </>
  );
};

interface AdminLayoutProps extends RouteComponentProps {
  id: string;
  component: React.ComponentType<RouteComponentProps>;
  actionBarRight?: React.ComponentType;
}

const AdminLayout: React.FC<AdminLayoutProps> = (
  { id, component: Component, actionBarRight: ActionBarRight, ...routeProps }
) => {
  const { t } = useTranslation();
  return (
    <MainLayout
      id="organizations-layout"
      title={ <Trans i18nKey={ id }/> }
      actionBarLeft={ (
        <ReturnButton
          returnInfo={{
            pathname: '/admin',
            name: t('admin'),
          }}
        />
      ) }
      actionBarRight={ !!ActionBarRight && <ActionBarRight/> }
    >
      <Component { ...routeProps }/>
    </MainLayout>
  );
};

const AdminSection = (
  id: string, 
  icon: React.ComponentType<SvgIconProps>, 
  component: React.ComponentType<RouteComponentProps>,
  actionBarRight?: React.ComponentType
) => ({
  component: (
    <AdminGridItem 
      key={ id } 
      id={ id } 
      icon={ icon }
    />
  ),
  route: (
    <Route 
      key={ id }
      path={ `/admin/${id}` } 
      render={ routeProps => (
        <AdminLayout
          id={ id }
          component={ component }
          actionBarRight={ actionBarRight }
          { ...routeProps }
        />
      ) }
    />
  )
});

export { AdminSection };
