import * as React from 'react';

import { WrappedFieldProps, Field, GenericField } from 'redux-form';
import { 
  CreatableSelectField, CreatableSelectFieldProps, 
  NewOptionCreator, ChangeHandler, Option,
} from './select-field';

import { Queries as LabelCategoryQueries } from 'src/service/labelCategories';
import { Actions as LabelActions } from 'src/service/labels';

import { DataLoaderDecorator, DataProps } from 'src/decorators/DataLoaderDecorator';
import { graphql } from '@apollo/client/react/hoc';
import { GBLabelType } from 'src/types';
import { Trans } from 'react-i18next';
import { parseCurrentLanguage } from 'src/utils/LanguageUtils';


type OptionType = Option<number>;

export const mapLabelOption = ({ id, name }: GBLabelType): OptionType => ({
  value: id,
  label: name,
});

export type LabelSelectFieldProps = { categoryId?: number; } & Partial<CreatableSelectFieldProps<number>>;
type LabelSelectFieldInputProps= 
  WrappedFieldProps & LabelSelectFieldProps &
  DataProps<LabelCategoryQueries.LabelCategoryLabelsResponse>;

class LabelSelectField extends React.Component<LabelSelectFieldInputProps> {
  options: Array<OptionType> = [];

  sendCreate = async (label: string) => {
    const { categoryId, client } = this.props;
    const response = await LabelActions.sendCreateLabel(
      {
        name: label,
        categoryId: categoryId!,
      },
      parseCurrentLanguage(),
      client,
      false
    );

    return response.id;
  }

  render() {
    const { input, data, ...other } = this.props;
    return (
      <CreatableSelectField 
        { ...other }
        input={ input }
        isMulti={ true as any as false }
        name="labels"
        placeholder={ <Trans i18nKey="prompts.select"/> as any }
        options={ !!data ? data.labelCategory!.labels.map(mapLabelOption) : [] }
        getNewOptionData={ NewOptionCreator }
        onSelectValueChange={ ChangeHandler(input.onChange, this.sendCreate) }
      />
    );
  }
}

const withLabelData = graphql<
  LabelSelectFieldProps & WrappedFieldProps, 
  LabelCategoryQueries.LabelCategoryLabelsResponse
>(
  LabelCategoryQueries.LabelCategoryLabelsQuery, {
    skip: ({ categoryId }) => !categoryId,
    options: ({ categoryId }) => ({ 
      variables: { 
        categoryId 
      } 
    }),
  }
);

const LabelSelectFieldWithData = withLabelData(
  DataLoaderDecorator<LabelSelectFieldProps & WrappedFieldProps, LabelCategoryQueries.LabelCategoryLabelsResponse>(
    LabelSelectField
  )
);

const FieldType = Field as new() => GenericField<LabelSelectFieldProps>;

export { 
  FieldType as LabelSelectField,
  LabelSelectFieldWithData as LabelSelectFieldComponent
};
