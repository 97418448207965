import * as React from 'react';

import { GBMaterialType, DataSourceItem } from 'src/types';
import { WithTranslation } from 'react-i18next';


export interface SyncStatusLayoutProps extends Pick<WithTranslation, 't'> {
  activeItem?: DataSourceItem;
  material: GBMaterialType;
}

const SyncStatusLayout: React.FC<SyncStatusLayoutProps> = (
  { material, activeItem, t }
) => {
  if (!activeItem) {
    return (
      <span>
        { t('disabled') }
      </span>
    );
  }

  return (
    <span style={{ color: 'green' }}>
      { t('enabled') }
    </span>
  );
};

export { SyncStatusLayout };