import * as React from 'react';

import { TFunction } from 'i18next';

import { Table as TableBase } from '@devexpress/dx-react-grid';
import { Table } from '@devexpress/dx-react-grid-material-ui';

import { Event, EventEntityType } from 'src/types';
import { 
  formatActionTitle, getActionIcon, formatUser, formatEntity 
} from './FormatUtils';
import { formatDateTime } from 'src/utils/DateUtils';
import { SelectedOrganizationContext } from 'src/decorators/SelectedOrganizationProvider';
import { useRouter } from 'src/effects';


export interface EventTableCellProps extends TableBase.DataCellProps {
  pageTitle: string;
  t: TFunction;
}

const EventFieldMapper = {
  categoryId: 'materialCategory',
  manufacturerId: 'manufacturer',
  imageName: 'image',
};

export const EntityTypeMappings: { [key in EventEntityType]: string; } = {
  [EventEntityType.MATERIALVARIANT_LOC]: 'materialLocalization',
  [EventEntityType.MATERIALVARIANT]: 'materialVariant',
  [EventEntityType.MATERIAL]: 'material',
  [EventEntityType.MANUFACTURER]: 'manufacturer',
  [EventEntityType.LABELCATEGORY]: 'labelCategory',
  [EventEntityType.LABEL]: 'label',
  [EventEntityType.MATERIALCATEGORY]: 'materialCategory',
  [EventEntityType.ORGANIZATION]: 'organization',
  [EventEntityType.DATASOURCE]: 'dataSource',
};

const EventTableCell = React.memo<EventTableCellProps>(props => {
  const { 
    t, pageTitle, ...cellProps
  } = props;

  const organizationContext = React.useContext(SelectedOrganizationContext)!;
  const { location } = useRouter();

  const { tableRow, column } = cellProps;
  const event: Event = tableRow.row;

  if (column.name === 'name') {
    return (
      <Table.Cell
        { ...cellProps }
        value={ formatEntity(t, event, EntityTypeMappings, location, pageTitle, organizationContext) }
      />
    );
  }

  if (column.name === 'event') {
    return (
      <Table.Cell
        { ...cellProps }
        value={ 
          <div 
            style={{ 
              display: 'flex' 
            }}
          >
            { getActionIcon(event.event.action) }
            <span style={{ marginLeft: '5px' }}>
              { formatActionTitle(t, event, EventFieldMapper) }
            </span>
          </div>
        }
      />
    );
  }


  if (column.name === 'user') {
    return (
      <Table.Cell
        { ...cellProps }
        value={ formatUser(event) }
      />
    );
  }

  if (column.name === 'date') {
    return (
      <Table.Cell
        { ...cellProps }
        value={ formatDateTime(event.time, t) }
      />
    );
  }

  return null;
});

export { EventTableCell };
