import * as React from 'react';

import { DataSourceItem } from 'src/types';

import { ProviderTitle } from 'src/modules/providers';
import { Typography } from '@material-ui/core';


interface DataSourceItemCaptionProps {
  dataSourceItem: DataSourceItem;
  style?: React.CSSProperties;
}

const DataSourceItemCaption: React.FC<DataSourceItemCaptionProps> = ({ dataSourceItem, style, children }) => (
  <Typography style={{ color: 'inherit' }}>
    <ProviderTitle 
      provider={ dataSourceItem.provider }
      style={ dataSourceItem.active ? {
        fontWeight: 'bold',
        ...style,
      } : style }
    />
    { children }
  </Typography>
);

export { DataSourceItemCaption };
