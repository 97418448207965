import { CreateHandler } from 'src/components/form';

const deleteUnchangedFields = <T extends { [key in string]: any }>(
  formData: Partial<T>, initialValue: Partial<T>
) => {
  return Object.keys(formData).reduce(
    (reduced, key) => {
      if (initialValue[key] !== formData[key]) {
        reduced[key as keyof T] = formData[key];
      }

      return reduced;
    }, 
    {} as Partial<T>
  );
};



type ValueBaseType = string | number | boolean | File | null;
type ValueType = ValueBaseType | ValueBaseType[];
type NormalizedValueTypeMap = { [key in string]: ValueType };
type NormalizedValueType = ValueType | NormalizedValueTypeMap;
type InitialValueType = ValueType | { [key in string]: ValueType | CreateHandler<any> } | CreateHandler<any>;

const parseBaseValue = async (
  value: ValueBaseType | CreateHandler<any> | undefined
): Promise<ValueBaseType | null> => {
  if (typeof value === 'function') {
    return await (value as any)();
  } else if (typeof value === 'string' && value.length === 0) {
    return null;
  } else {
    return value as ValueBaseType;
  }
};

const parseValue = async (
  value: ValueBaseType | ValueBaseType[] | CreateHandler<any> | undefined
): Promise<ValueBaseType | ValueBaseType[] | null> => {
  if (Array.isArray(value)) {
    const ret = Promise.all(value.map(parseBaseValue));
    return ret as Promise<ValueBaseType[]>;
  } 

  return parseBaseValue(value);
};


export type InitialFormValueType = { [key in string]: InitialValueType };
export type NormalizedFormValueType = { [key in string]: NormalizedValueType };

export const normalizeValues = async <T>(
  formData: InitialFormValueType
): Promise<NormalizedFormValueType> => {
  const ret = {} as NormalizedValueTypeMap;

  for (let key of Object.keys(formData)) {
    const value = formData[key];
    if (
      !!value && 
      typeof value === 'object' && !Array.isArray(value) && 
      !(value instanceof File) && !(value instanceof Function)
    ) {
      ret[key] = await normalizeValues<T>(value as InitialFormValueType) as any; // TODO
    } else {
      ret[key] = await parseValue(
        value as ValueBaseType | ValueBaseType[] | CreateHandler<any> | undefined
      );
    }
  }

  return ret;
};


export const normalizeUpdateFields = async <T>(
  formData: Partial<T>, 
  initialValue: Partial<T>
): Promise<Partial<T>> => {
  return deleteUnchangedFields<T>(await normalizeValues<T>(formData as any) as any, initialValue);
};

export const normalizeCreateFields = async <T>(
  formData: T
): Promise<T> => {
  return normalizeValues<T>(formData as any) as any;
};