import gql from 'graphql-tag';

//import { Fragments as LabelCountFragmentsRaw } from 'src/service/labelCounts/labelCountFragments';

//const LabelCountFragments = LabelCountFragmentsRaw();


export const Fragments = () => ({
  basic: gql`
    fragment ManufacturerBasic on Manufacturer {
      id
      name
    }
  `,
  /*labelCounts: gql`
    fragment ManufacturerLabelCounts on Manufacturer {
      labelCounts {
        ...LabelCount
      }
    }
    ${LabelCountFragments.labelCount}
  `,
  materialCategoryCounts: gql`
    fragment MaterialCategoryCounts on Manufacturer {
      materialCategoryCounts {
        ...LabelCount
      }
    }
    ${LabelCountFragments.labelCount}
  `,*/
});