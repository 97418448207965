import * as React from 'react';

import { MaterialEditorForm, mapFields } from './MaterialEditorForm';

import { 
  MaterialEditorDataDecorator,
  MaterialEditorDataDecoratorChildProps, MaterialActionTypes 
} from '../../decorators/MaterialEditorDataDecorator';

import { RouterModalProps, MaterialFieldTypes } from 'src/types';
import { createEditorDialog } from 'src/components/editor';
import { Trans } from 'react-i18next';


type MaterialEditorDialogProps = MaterialEditorDataDecoratorChildProps & RouterModalProps;

const EditorDialog = createEditorDialog<
  MaterialFieldTypes, 
  MaterialEditorDataDecoratorChildProps
>('materialEditor', mapFields);

const getTranslationKey = (type: MaterialActionTypes | undefined) => {
  switch (type) {
    case MaterialActionTypes.APPROVE: return 'actions.approveMaterial';
    case MaterialActionTypes.EDIT: return 'actions.editMaterial';
    default:
  }

  return 'actions.createMaterial';
};

class MaterialEditorDialog extends React.PureComponent<MaterialEditorDialogProps> {
  render() {
    const { match } = this.props;
    return (
      <EditorDialog
        title={ <Trans i18nKey={ getTranslationKey(match.params.actionType) }/> }
        fieldsComponent={ MaterialEditorForm }
        { ...this.props }
      />
    );
  }
}

const MaterialEditorDialogDecorated = MaterialEditorDataDecorator(MaterialEditorDialog);

export { MaterialEditorDialogDecorated as MaterialEditorDialog };
