import * as React from 'react';

import { NewOptionCreator } from './utils';
import { SuggestionChip } from './SuggestionChip';
import { Option } from './types';


export type SuggestionOption<ValueT> = Option<ValueT> & { rewardId?: string; };
export type SuggestionChangeHandler<ValueT> = (value: SuggestionOption<ValueT>) => void;

export interface SuggestionSectionProps<ValueT> {
  initialValue?: string;
  options: SuggestionOption<ValueT>[];
  onSuggestionSelected: SuggestionChangeHandler<ValueT>;
}


export const SuggestionSection = <ValueT extends any>(
  props: SuggestionSectionProps<ValueT>
) => {
  const { initialValue, options, onSuggestionSelected } = props;
  if (!options.length) {
    if (!initialValue) {
      return null;
    }

    // Add create option suggestion from the initial text value
    const title = (
      <span>
        <strong>Create new:</strong>
        { ' ' }
        { initialValue }
      </span>
    );

    const onClick = () => onSuggestionSelected(
      NewOptionCreator(initialValue, initialValue), 
      //{ action: 'create-option' }
    );

    return (
      <SuggestionChip
        formattedValue={ title }
        textValue={ initialValue }
        onClick={ onClick }
      />
    );
  }

  // Format the list of loaded suggestions
  return (
    <>
      { options.map(option => {
        return (
          <SuggestionChip
            key={ option.label }
            textValue={ option.label }
            onClick={ () => onSuggestionSelected(option) }
          />
        );
      }) }
    </>
  );
};
