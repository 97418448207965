import { format, formatDistance } from 'date-fns';
import { TFunction } from 'i18next';

import { enGB as en, fi } from 'date-fns/locale';

import { parseCurrentLanguage } from './LanguageUtils';


const locales: { [key in string]: any; } = { 
  en, 
  fi,
};

const getLocale = () => {
  const language = parseCurrentLanguage();
  return {
    locale: !!locales[language] ? locales[language] : en,
  };
};

export const formatDate = (str: string | null, t: TFunction, dateFormat: string = 'P') => {
  return !str ? t('na') : format(new Date(str), dateFormat, getLocale());
};

export const formatDuration = (str: string | null, t: TFunction) => {
  return !str ? t('na') : t('xAgo', {
    duration: formatDistance(new Date(str), new Date(), getLocale())
  });
};

export const formatDateTime = (str: string | null, t: TFunction) => {
  return formatDate(str, t, 'Pp');
};
