import React, { useState, useEffect } from "react";
import { Typography, Button, TypographyProps } from "@material-ui/core";
import { truncate } from "lodash";
import { useTranslation } from "react-i18next";

export interface TruncatedTextProps extends TypographyProps<"div"> {
  value: string | null | undefined;
  noContentCaption?: string;
  truncateLength?: number;
}

const TruncatedText: React.FC<TruncatedTextProps> = ({
  value,
  noContentCaption,
  truncateLength,
  ...other
}) => {
  const [forceShowAll, setForceShowAll] = useState(false);
  const { t: text } = useTranslation();

  useEffect(() => {
    setForceShowAll(false);
  }, [value]);

  if (!value) {
    if (!noContentCaption) {
      return null;
    }

    return (
      <Typography
        color="textSecondary"
        variant="body2"
        component="div"
        {...other}
      >
        {noContentCaption}
      </Typography>
    );
  }

  if (!!truncateLength && value.length > truncateLength) {
    return (
      <Typography variant="body2" component="div" {...other}>
        {!forceShowAll ? (
          <>
            {truncate(value, { length: truncateLength })}
            <div>
              <Button
                onClick={(evt) => {
                  evt.stopPropagation();
                  setForceShowAll(true);
                }}
              >
                {text("editableText.showAll")}
              </Button>
            </div>
          </>
        ) : (
          <>
            {value}
            <div>
              <Button
                onClick={(evt) => {
                  evt.stopPropagation();
                  setForceShowAll(false);
                }}
              >
                {text("editableText.showLess")}
              </Button>
            </div>
          </>
        )}
      </Typography>
    );
  } else {
    return (
      <Typography variant="body2" component="div" {...other}>
        {value}
      </Typography>
    );
  }
};

export default TruncatedText;
