import { parseQuery } from 'src/utils/QueryUtils';

import { Location } from 'history';
import { ParsedQuery } from 'query-string';


type GroupedLabelFilterType = {
  [key: string]: number[],
};

type FilterProperties = {
  grouped: GroupedLabelFilterType,
  flat: number[][],
};

const parseLabels = (labels: ParsedQuery[keyof ParsedQuery]): FilterProperties => {
  if (typeof labels !== 'string') {
    return {
      grouped: {},
      flat: [[]],
    };
  }

  const parsed = JSON.parse(labels);
  if (typeof parsed !== 'object') {
    return {
      grouped: {},
      flat: [[]],
    };
  }

  const ret = {
    grouped: parsed,
    flat: Object.keys(parsed).map(key => parsed[key]),
  };

  return ret;
};

export const stringifyLabels = (labels: GroupedLabelFilterType) => {
  const filtered = Object.keys(labels)
    .reduce(
      (reduced, key) => {
        if (!!labels[key].length) {
          reduced[key] = labels[key];
        }

        return reduced;
      }, 
      {} as GroupedLabelFilterType
    );

  if (Object.keys(filtered).length === 0) {
    return undefined;
  }

  return JSON.stringify(filtered);
};

const parse = (location: Location) => {
  const { 
    labels = null,
    materialCategories = {},
    manufacturers = [],
  } = parseQuery(location);
  
  return {
    labels: parseLabels(labels),
    materialCategories: Array.isArray(materialCategories) ? materialCategories.map(Number) : [],
    manufacturers: Array.isArray(manufacturers) ? manufacturers.map(Number) : [],
  };
};

export const parseMaterialFilterVariables = (location: Location) => {
  const { labels, materialCategories, manufacturers } = parse(location);

  return {
    query: {
      labels: labels.flat,
      materialCategories,
      manufacturers,
    },
    filters: {
      labels: labels.grouped,
      materialCategories,
      manufacturers,
    },
  };
};

export const hasMaterialFilterVariables = (location: Location) => {
  const { labels, manufacturers, materialCategories } = parse(location);
  return !!Object.keys(labels.grouped).length || !!manufacturers.length || !!materialCategories.length;
};
