import * as React from 'react';
import { SnackbarContext } from 'src/decorators/NotificationDecorator';
import { DataLoaderDecorator, DataProps } from 'src/decorators/DataLoaderDecorator';

import { graphql } from '@apollo/client/react/hoc';
import { RouteComponentProps } from 'react-router';

import { Actions, Queries } from 'src/service/organizations';
import { ApolloClient } from '@apollo/client';

import { normalizeUpdateFields, normalizeCreateFields } from 'src/utils/FormUtils';
import { FormSubmitHandler, OrganizationType } from 'src/types';
import { Trans } from 'react-i18next';
import { OrganizationFieldTypes } from 'src/types/input/organizationInput';
import { parseIntRouteParam } from 'src/utils/BrowserUtils';


type RouteProps = RouteComponentProps<{
  organizationId?: string;
}>;

type OrganizationEditorDataDecoratorProps = RouteProps;

export interface OrganizationEditorDataDecoratorChildProps extends RouteProps {
  onSubmit: FormSubmitHandler<OrganizationFieldTypes>;
  organization?: OrganizationType | null;
  client: ApolloClient<any>;
}


type Props = OrganizationEditorDataDecoratorProps & DataProps<Queries.OrganizationResponse>;

function OrganizationEditorDataDecorator<T>(
  Component: React.ComponentType<T & OrganizationEditorDataDecoratorChildProps>
) {

  const withOrganizationData = graphql<RouteProps & T, Queries.OrganizationResponse>(
    Queries.OrganizationQuery, 
    {
      skip: ({ match }) => {
        return !match.params.organizationId;
      },
      options: ({ match }) => ({ 
        variables: { 
          organizationId: parseIntRouteParam(match.params.organizationId!) 
        } 
      }),
    }
  );

  class Decorator extends React.Component<Props & T> {
    static contextType = SnackbarContext;
    context!: SnackbarContext;

    onSubmit: FormSubmitHandler<OrganizationFieldTypes> = async (organizationData, dispatch, formProps) => {
      const { data, client } = this.props;

      // Create/update organization
      try {
        const response = data && data.organization ? 
          await Actions.sendUpdateOrganization(
            data.organization.id, 
            await normalizeUpdateFields(organizationData, formProps.initialValues), 
            client
          ) :
          await Actions.sendCreateOrganization(
            await normalizeCreateFields(organizationData), 
            client
          );

        if (response) {
          this.context({
            text: (
              <Trans i18nKey="actions.notifications.organizationSaved" values={{ organization: organizationData }}/>
            ),
          });
        }
      } catch (error) {
        this.context({
          text: <Trans i18nKey="actions.notifications.organizationSaveFailed" values={{ error }}/>,
        });

        return false;
      }

      return true;
    }

    render() {
      const { data } = this.props;
      return (
        <Component
          { ...this.props }
          onSubmit={ this.onSubmit }
          organization={ data ? data.organization : undefined }
        />
      );
    }
  }

  return withOrganizationData(
    DataLoaderDecorator<OrganizationEditorDataDecoratorProps & T, Queries.OrganizationResponse>(Decorator)
  );
}

export { 
  OrganizationEditorDataDecorator
};
