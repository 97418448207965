import * as React from "react";

import Button from "@material-ui/core/Button";

import { DialogTitle, DialogActions, DialogContent } from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
import Measure from "react-measure";
import AssetViewer from "./AssetViewer/AssetViewer";

export interface AssetSelectDialogProps {
  onAssetSelected: (guid: string) => void;
  onClose: () => void;
  initialGuid: string | null;
}

const SELECT_LIST_WIDTH = 300;

export const AssetSelectDialog: React.FC<AssetSelectDialogProps> = ({
  onAssetSelected,
  onClose,
  initialGuid,
}) => {
  const [guid, setGuid] = React.useState<string | null>(initialGuid);

  const { t } = useTranslation();
  return (
    <>
      <DialogTitle id="simple-dialog-title">{t("selectAsset")}</DialogTitle>
      <DialogContent>
        <Measure bounds>
          {({ measureRef, contentRect }) => (
            <div
              style={{
                display: "flex",

                // Maintain the 16:9 aspect ration for the viewer element
                height: contentRect.bounds
                  ? `${Math.min(
                      ((contentRect.bounds.width - SELECT_LIST_WIDTH) * 9) / 16,
                      contentRect.bounds.height
                    )}px`
                  : "auto",
              }}
              ref={measureRef}
            >
              <AssetViewer
                initialGuid={initialGuid ?? undefined}
                selectedAsset={guid || ""}
                onSelectAsset={setGuid}
                dialog={true}
              />
            </div>
          )}
        </Measure>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="primary">
          <Trans i18nKey="cancel" />
        </Button>
        <Button
          disabled={!guid}
          color="primary"
          onClick={(evt) => {
            if (guid) {
              onAssetSelected(guid);
            }
          }}
        >
          {t("select")}
        </Button>
      </DialogActions>
    </>
  );
};
