import * as React from 'react';

import { MaterialCategoryBaseType } from 'src/types';
import { RouteButton, RouteButtonProps } from 'src/components/RouteButton';

import FolderIcon from '@material-ui/icons/Folder';
import Home from '@material-ui/icons/Home';

import { isRootCategory } from 'src/components/browser-layout';
//import { SvgIconProps } from '@material-ui/core/SvgIcon';


export type ThunkedCategoryUrl = string | ((category: MaterialCategoryBaseType) => string);

const unthunk = (url: ThunkedCategoryUrl, category: MaterialCategoryBaseType) => {
  return typeof url === 'string' ? `${url}/categories` : url(category);
};

export interface NavigationButtonProps extends Partial<RouteButtonProps> {
  browserBaseUrl: ThunkedCategoryUrl;
  category: MaterialCategoryBaseType;
}


export const NavigationButton: React.FC<NavigationButtonProps> = (
  { icon, browserBaseUrl, category, ...other }
) => (
  <RouteButton
    { ...other }
    to={ !isRootCategory(category) ? 
      `${unthunk(browserBaseUrl, category)}/${category.id}` : 
      unthunk(browserBaseUrl, category) }
    icon={ !isRootCategory(category) ? icon : Home }
  >
    { !isRootCategory(category) ? category.name : null }
  </RouteButton>
);

NavigationButton.defaultProps = {
  icon: FolderIcon,
};
