import * as React from 'react';

import { SearchResultsLayout } from './searchresults-browser';
import { ProviderSearchResultsLayout } from './provider-searchresults-browser';

import { RouteComponentProps, Route, Redirect } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';

import { parseSearchVariables } from 'src/utils/SearchUtils';
import { SearchTabBar } from 'src/modules/search/components/SearchTabBar';


type SearchViewProps = RouteComponentProps<{}>;

class SearchView extends React.PureComponent<SearchViewProps> {
  render() {
    const { match, location } = this.props;
    if (!parseSearchVariables(location).query) {
      return (
        <Redirect
          to="/"
        />
      );
    }

    return (
      <div id="search-page">
        <Paper>
          <SearchTabBar
            { ...this.props }
          />
          <Route
            component={ SearchResultsLayout }
            path={ `${match.url}/results` }
            exact={ true }
          />
          <Route
            component={ ProviderSearchResultsLayout }
            path={ `${match.url}/results/:providerId(\\w+)` }
          />
        </Paper>
      </div>
    );
  }
}

export { SearchView };
