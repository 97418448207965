import * as React from 'react';

import { graphql } from '@apollo/client/react/hoc';
import { Location } from 'history';

import {
  MaterialListColumnNames, MaterialTable, MaterialTableConfigProps, DefaultProviderMaterialListPagination
} from 'src/components/browser-layout';

import { DataLoaderDecorator } from 'src/decorators/DataLoaderDecorator';
import { Queries } from 'src/service/providers';

import { 
  parsePaginationVariables, normalizePaginatedResponse
} from 'src/utils/PaginationUtils';
import { ProviderMaterialCategoryType } from 'src/types';


interface ProviderMaterialCategoryMaterialTableProps extends MaterialTableConfigProps {
  category: ProviderMaterialCategoryType;
  location: Location;
  providerId: string;
}

const withMaterials = graphql<ProviderMaterialCategoryMaterialTableProps, Queries.MaterialCategoryMaterialsResponse>(
  Queries.ProviderMaterialCategoryMaterialsQuery, {
    options: ({ category, location, providerId }) => {
      return {
        variables: { 
          categoryId: category.id,
          providerId,
          ...parsePaginationVariables(location, DefaultProviderMaterialListPagination).query,
        }
      };
    },
  }
);

const Columns: MaterialListColumnNames = [ 'image', 'name', 'manufacturer' ];
//const SortableColumns: MaterialListColumnNames = [ 'name' ];

const ProviderMaterialCategoryMaterialTable = withMaterials(DataLoaderDecorator(({ data, ...other }) => {
  const { materials } = data.provider!.materialCategory;
  return (
    <MaterialTable
      { ...other }
      materials={ normalizePaginatedResponse(materials) }
      totalCount={ materials.total }
      columns={ Columns }
      manufacturerSectionSupported={ false }
      //sortableColumns={ SortableColumns }
    />
  );
}));

export { ProviderMaterialCategoryMaterialTable };
