import * as React from 'react';

import { graphql } from '@apollo/client/react/hoc';
import { Location } from 'history';

import { 
  MaterialListColumnNames, 
  MaterialTableConfigProps, MaterialTable, DefaultLocalMaterialListPagination
} from 'src/components/browser-layout';

import { DataLoaderDecorator, DataProps } from 'src/decorators/DataLoaderDecorator';
import { Queries } from 'src/service/materials';

import { parsePaginationVariables } from 'src/utils/PaginationUtils';
import { parseMaterialFilterVariables } from 'src/utils/MaterialFilterUtils';
import { SelectedOrganizationContext } from 'src/decorators/SelectedOrganizationProvider';
import { localMaterialsToViewMaterials } from 'src/utils/MaterialVariantUtils';
import { useTranslation } from 'react-i18next';
import { MaterialStatus } from 'src/types';
import { MaterialSelectionActions } from 'src/actions/MaterialSelectionActions';


export interface MaterialsTableProps extends MaterialTableConfigProps {
  location: Location;
}

const withUnapprovedMaterials = graphql<
  MaterialsTableProps, 
  Queries.MaterialsListResponse
> (
  Queries.MaterialsListQuery, {
    options: ({ location }) => {
      return {
        variables: {
          statuses: [ MaterialStatus.NEW ],
          ...parsePaginationVariables(location, DefaultLocalMaterialListPagination).query,
          ...parseMaterialFilterVariables(location).query,
        }
      };
    },
  }
);

type Props = MaterialsTableProps & 
  DataProps<Queries.MaterialsListResponse>;

const Columns: MaterialListColumnNames = [ 'image', 'name', 'category', 'manufacturer', 'createdAt' ];
const SortableColumns: MaterialListColumnNames = [ 'createdAt' ];

const MaterialsTable: React.FC<Props> = ({ data }) => {
  const organizationContext = React.useContext(SelectedOrganizationContext)!;
  const materials = data.materials!;
  const { t } = useTranslation();
  return (
    <MaterialTable
      materials={ localMaterialsToViewMaterials(materials, organizationContext, t) }
      totalCount={ materials.total }
      columns={ Columns }
      pageTitle={ t('unapprovedMaterials') }
      selectionActions={ MaterialSelectionActions }
      sortableColumns={ SortableColumns }
    />
  );
};

const MaterialsTableDecorated = withUnapprovedMaterials(
  DataLoaderDecorator<MaterialsTableProps, Queries.MaterialsListResponse>(MaterialsTable)
);

export { MaterialsTableDecorated as MaterialsTable };