import { useContext } from 'react';
import { RouteComponentProps, StaticContext } from 'react-router';
import * as H from 'history';

const R = require('react-router');

// FIXME:  use official API when https://github.com/ReactTraining/react-router/pull/6453 merged

export function useRouter<
  Params extends { [K in keyof Params]?: string } = {}, 
  C extends StaticContext = StaticContext, 
  S = H.LocationState
>() {
  return useContext(R.__RouterContext) as RouteComponentProps<Params, C, S>;
}
