import gql from 'graphql-tag';


// Queries
export const CreateLabelCategoryMutation = gql`
  mutation CreateLabelCategoryMutation($input: CreateLabelCategoryInput!, $languageCode: LanguageCode!) {
    createLabelCategory(input: $input, languageCode: $languageCode) {
      id,
    }
  }
`;

export const UpdateLabelCategoryMutation = gql`
  mutation UpdateLabelCategoryMutation($id: Int!, $input: UpdateLabelCategoryInput!, $languageCode: LanguageCode!) {
    updateLabelCategory(id: $id, input: $input, languageCode: $languageCode) {
      id,
    }
  }
`;

export const DeleteLabelCategoryMutation = gql`
  mutation DeleteLabelCategoryMutation($id: Int!) {
    deleteLabelCategory(id: $id) {
      id,
    }
  }
`;


// Response types
export interface CreateLabelCategoryResponse {
  createLabelCategory: {
    id: number;
  };
}

export interface UpdateLabelCategoryResponse {
  updateLabelCategory: {
    id: number;
  };
}

export interface DeleteLabelCategoryResponse {
  deleteLabelCategory: {
    id: number;
  };
}
