import * as React from 'react';

import { AccordionSummary, Typography, Accordion, AccordionDetails } from '@material-ui/core';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { defaultSort } from 'src/utils/SortUtils';


interface FilterableSectionProps<IdT extends number | string> {
  items: Array<{
    id: IdT;
    name: string;
    count?: number;
  }>;
  title: string; // must be string
  handleToggle: (id: IdT) => () => void;
  selectedIds: IdT[];
}

const FilterableSection = <IdT extends number | string>(
  { items, title, handleToggle, selectedIds }: FilterableSectionProps<IdT>
) => {
  if (!items.length) {
    return null;
  }

  return (
    <Accordion 
      style={{ 
        minWidth: '250px'
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>
          { selectedIds.length > 0 ? (
            <b>
              { `${title} (${selectedIds.length})` }
            </b>
          ) : title }
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ padding: '0px 24px 8px' }}>
        <List style={{ paddingTop: '0px', paddingBottom: '0px' }}>
          { items
            .sort((a, b) => defaultSort(a.name, b.name))
            .map(counts => (
              <ListItem
                key={ counts.id }
                role={ undefined }
                dense
                disableGutters
                button
                onClick={ handleToggle(counts.id) }
              >
                <Checkbox
                  checked={ selectedIds.indexOf(counts.id) !== -1 }
                  tabIndex={ -1 }
                  disableRipple
                />
                <ListItemText 
                  primary={ counts.count === undefined ? counts.name : `${counts.name} (${counts.count})` } 
                />
              </ListItem>
            ))
          }
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export { FilterableSection };