import * as React from 'react';

import { WrappedFieldProps, Field, GenericField } from 'redux-form';
import { CreatableSelectField, CreatableSelectFieldProps, Option } from './select-field';

import { Queries } from 'src/service/languages';

import { DataLoaderDecorator, DataProps } from 'src/decorators/DataLoaderDecorator';
import { graphql } from '@apollo/client/react/hoc';
import { Trans } from 'react-i18next';
import { LanguageType } from 'src/types';


type OptionType = Option<string>;

const mapOption = (language: LanguageType): OptionType => ({
  value: language.code,
  label: language.name,
});

export type LanguageSelectFieldProps = Partial<CreatableSelectFieldProps<string>> & { language?: LanguageType; };
type LanguageSelectFieldPropsWithData = 
  WrappedFieldProps & 
  LanguageSelectFieldProps & DataProps<Queries.LanguageListResponse>;

class LanguageSelectField extends React.Component<LanguageSelectFieldPropsWithData> {
  constructor(props: LanguageSelectFieldPropsWithData) {
    super(props);

    const { data } = this.props;
    this.options = data.languages!.map(mapOption);
  }

  options: OptionType[] = [];

  render() {
    const { input, required, ...other } = this.props;
    return (
      <CreatableSelectField 
        { ...other }
        input={ input }
        isMulti={ false }
        name="language"
        placeholder={ <Trans i18nKey="prompts.select"/> as any }
        options={ this.options }
        required={ required }
      />
    );
  }
}


const withLanguageData = graphql<
  WrappedFieldProps & LanguageSelectFieldProps, 
  Queries.LanguageListResponse
>(
  Queries.LanguageListQuery
);

const LanguageSelectFieldWithData = withLanguageData(
  DataLoaderDecorator<WrappedFieldProps & LanguageSelectFieldProps, Queries.LanguageListResponse>(LanguageSelectField)
);

const FieldType = Field as new() => GenericField<LanguageSelectFieldProps>;

export { 
  FieldType as LanguageSelectField,
  LanguageSelectFieldWithData as LanguageSelectFieldComponent
};
