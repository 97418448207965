import * as React from 'react';
import { SnackbarContext } from 'src/decorators/NotificationDecorator';
import { DataLoaderDecorator, DataProps } from 'src/decorators/DataLoaderDecorator';

import { graphql } from '@apollo/client/react/hoc';
import { RouteComponentProps } from 'react-router';

import { Actions, Queries } from 'src/service/labelCategories';

import { normalizeUpdateFields, normalizeCreateFields } from 'src/utils/FormUtils';
import { GBLabelCategoryType, FormSubmitHandler, LabelCategoryFieldTypes } from 'src/types';
import { Trans } from 'react-i18next';
import { parseIntRouteParam } from 'src/utils/BrowserUtils';


type RouteProps = RouteComponentProps<{
  labelCategoryId?: string;
}>;


export type LabelCategoryEditorDataDecoratorProps = RouteProps;

export interface LabelCategoryEditorDataDecoratorChildProps extends RouteProps {
  onSubmit: FormSubmitHandler<LabelCategoryFieldTypes>;
  labelCategory?: GBLabelCategoryType;
}


type Props = LabelCategoryEditorDataDecoratorProps & DataProps<Queries.LabelCategoryResponse>;

function LabelCategoryEditorDataDecorator<T>(
  Component: React.ComponentType<T & LabelCategoryEditorDataDecoratorChildProps>
) {
  const withLabelData = graphql<LabelCategoryEditorDataDecoratorProps & T, Queries.LabelCategoryResponse>(
    Queries.LabelCategoryQuery, 
    {
      skip: ({ match }) => {
        return !match.params.labelCategoryId;
      },
      options: ({ match }) => ({ 
        variables: { categoryId: parseIntRouteParam(match.params.labelCategoryId!) } 
      }),
    }
  );

  class Decorator extends React.Component<Props & T> {
    static contextType = SnackbarContext;
    context!: SnackbarContext;

    onSubmit: FormSubmitHandler<LabelCategoryFieldTypes> = async (formData, dispatch, formProps) => {
      const { data, client } = this.props;
      const { languageCode, ...categoryData } = formData;

      // Create/update label category
      try {
        const response = data && data.labelCategory ? 
          await Actions.sendUpdateLabelCategory(
            data.labelCategory.id, 
            await normalizeUpdateFields(categoryData, formProps.initialValues), 
            languageCode,
            client
          ) :
          await Actions.sendCreateLabelCategory(
            await normalizeCreateFields(categoryData), 
            languageCode,
            client
          );

        if (response) {
          this.context({
            text: (
              <Trans i18nKey="actions.notifications.labelCategorySaved" values={{ labelCategory: categoryData }}/>
            ), 
            actionTitle: <Trans i18nKey="actions.viewLabelCategory"/>, 
            actionHandler: () => this.props.history.push(`/label-categories/${response.id}`)
          });
        }
      } catch (error) {
        this.context({
          text: <Trans i18nKey="actions.notifications.labelCategorySaveFailed" values={{ error }}/>,
        });

        return false;
      }

      return true;
    }

    render() {
      const { data } = this.props;
      return (
        <Component
          { ...this.props }
          onSubmit={ this.onSubmit }
          labelCategory={ data ? data.labelCategory : undefined }
        />
      );
    }
  }

  return withLabelData(
    DataLoaderDecorator<LabelCategoryEditorDataDecoratorProps & T, Queries.LabelCategoryResponse>(Decorator)
  );
}

export { 
  LabelCategoryEditorDataDecorator
};
