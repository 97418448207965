import { Permission } from 'src/constants';

import { GBMaterialType, MaterialStatus } from 'src/types';
import Auth from 'src/service/Auth';


export const hasMaterialOrganizationVariant = (material: GBMaterialType, auth: Auth, variantAccess: Permission) => {
  return material.variants.every(v => {
    if (!v.organization) {
      return false;
    }
    
    return auth.hasOrganizationAccessByCode(variantAccess, v.organization.code);
  });
};

export const hasMaterialEditAccess = (
  material: GBMaterialType, 
  auth: Auth, 
  materialAccess: Permission, 
  variantAccess: Permission
) => {
  if (material.status === MaterialStatus.NEW && hasMaterialOrganizationVariant(material, auth, variantAccess)) {
    return true;
  }

  return auth.hasGlobalAccess(materialAccess);
};
