import * as React from 'react';

import { SnackbarContext } from 'src/decorators/NotificationDecorator';

import { ConfirmPrompt } from 'src/components/ConfirmPrompt';

import { useApolloClient } from '@apollo/client';
import { Trans } from 'react-i18next';
import { Action, ActionHandlerProps } from 'src/types';
import { AuthContext } from 'src/decorators/AuthDecorator';
import { useRouter } from 'src/effects';
import { ApolloClient } from '@apollo/client';



export interface ActionDecoratorProps<PropsT> {
  onClickAction: (action: Action<PropsT>) => void;
}

export type ActionDecoratorChildProps<PropsT> = ActionHandlerProps & ActionDecoratorProps<PropsT>;


function ActionDecorator<PropsT>(
  Component: React.ComponentType<PropsT & ActionDecoratorChildProps<PropsT>>
) {
  type Props = PropsT & ActionDecoratorProps<PropsT>;

  const ActionHandler: React.FC<Props & { client: ApolloClient<any> }> = props => {
    const [ confirmAction, setConfirmAction ] = React.useState<Action<PropsT> | null>(null);
    const router = useRouter();
    const snackbar = React.useContext(SnackbarContext)!;
    const auth = React.useContext(AuthContext)!;
    const client = useApolloClient();

    const getHandlerProps = () => {
      const ret: ActionHandlerProps & PropsT = {
        ...router,
        ...props,
        client,
        snackbar,
        auth,
      };

      return ret;
    };
  
    const handleAction = async (action: Action<PropsT>) => {
      try {
        await action.handler(getHandlerProps());
        
        if (action.onSuccess) {
          snackbar({
            text: action.onSuccess,
            values: {
              ...props as object,
            }
          });
        }
      } catch (error) {
        if (action.onFailed) {
          snackbar({
            text: action.onFailed,
            values: { error },
          });
        }
      }
    };

    const closeConfirmation = () => {
      setConfirmAction(null);
    };

    const handleConfirm = async () => {
      if (!confirmAction) {
        return;
      }

      await handleAction(confirmAction);
      closeConfirmation();
    };
  
    const handleClickAction = async (action: Action<PropsT>) => {
      if (action.confirmation) {
        setConfirmAction(action);
      } else {
        await handleAction(action);
      }
    };

    return (
      <>
        <Component
          { ...getHandlerProps() }
          onClickAction={ handleClickAction }
        />
        { !!confirmAction && (
          <ConfirmPrompt
            open={ true }
            title={ <Trans i18nKey={ confirmAction.confirmation!.title }/> }
            description={ 
              <Trans 
                i18nKey={ confirmAction.confirmation!.description } 
                values={ !confirmAction.messageValueGetter ? getHandlerProps() :
                  confirmAction.messageValueGetter(getHandlerProps()) 
                }
              />
            }
            onConfirm={ handleConfirm }
            onCancel={ closeConfirmation }
          />
        ) }
      </>
    );
  };

  return ActionHandler;
}

export { ActionDecorator };