import * as React from 'react';

import { MaterialVariant, LanguageType } from 'src/types';
// import styled from 'styled-components';

//@ts-ignore
import ReactCountryFlag from 'react-country-flag'; 
import { mergeQuery } from 'src/utils/QueryUtils';
import { LanguageListDecorator, LanguageListDecoratorChildProps } from '../../decorators/LanguageListDecorator';
import { parseVariantLanguage } from 'src/utils/MaterialVariantUtils';
import { useRouter } from 'src/effects';
import { languageToCountry } from 'src/i18n';


type RouteProps = { 
  materialId: string;
};

interface MaterialVariantLanguageSelectorProps {
  variant?: MaterialVariant;
  variants?: MaterialVariant[];
}

const parseVariantLanguages = (variant: MaterialVariant): LanguageType[] => {
  return variant.localizations.map(v => v.language);
};


const parseLanguages = ({ variant, variants }: MaterialVariantLanguageSelectorProps): LanguageType[] => {
  if (variant) {
    return parseVariantLanguages(variant);
  }

  if (variants) {
    return variants.reduce(
      (reduced, v) => {
        reduced.push(...parseVariantLanguages(v));
        return reduced;
      }, 
      [] as LanguageType[]
    );
  }

  return [];
};


type Props = MaterialVariantLanguageSelectorProps & LanguageListDecoratorChildProps;

// const LanguageButton = styled.button

const MaterialVariantLanguageSelector = React.memo<Props>(props => {
  const { location, history } = useRouter<RouteProps>();

  const handleChangeLanguage = (language: LanguageType) => {
    history.push({
      pathname: location.pathname,
      search: mergeQuery(location, {
        variantLanguage: language.code,
      }),
    });
  };

  const { data } = props;
  const variantLanguages = parseLanguages(props);
  const selectedLanguage = parseVariantLanguage(location);
  return (
    <span className="country-flags">
      { data.languages!.map(language => (
        <button
          key={ language.code }
          onClick={ () => handleChangeLanguage(language) }
          style={{
            margin: '2px',
            opacity: !!variantLanguages.find(l => l.code === language.code) ? 1 : 0.3,
            // border: selectedLanguage === language.code ? '2px solid #2196f3' : 'none',
            border: 'none',
            padding: selectedLanguage === language.code ? '5px' : undefined,
          }}
        >
          <ReactCountryFlag 
            key={ language.code }
            countryCode={ languageToCountry(language) }
            onClick={ () => handleChangeLanguage(language) }
            style={{
              border: selectedLanguage === language.code ? '1.7px dotted #2196f3' : undefined,
              width: '1.75em',
              height: '1.5em',
              //padding: selectedLanguage === language ? '5px' : undefined,
            }}
            svg
          />
        </button>
      )) }
    </span>
  );
});

const Decorated = LanguageListDecorator(MaterialVariantLanguageSelector);

export { Decorated as MaterialVariantLanguageSelector };
