import * as React from 'react';

import { ProviderType, ProviderMaterialType, AttachmentType } from 'src/types';
import { 
  MaterialProductInfoLink, MaterialProductInfoListItem, MaterialProductInfoList
 } from 'src/components/material-layout';
import { MaterialAttachment } from 'src/components/material-layout';
import { MaterialLinkLayout } from 'src/modules/providers/modules/materials/components/material-link-layout';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ProviderFeatures, hasProviderFeature } from 'src/constants/ProviderFeatures';


interface MaterialProductInfoListProps {
  material: ProviderMaterialType;
  provider: ProviderType;
}

const formatAttachments = (attachments?: AttachmentType[]) => {
  if (!!attachments && !!attachments.length) {
    return attachments.map(item => (
      <MaterialAttachment
        key={ item.name }
        attachment={ item }
      />
    ));
  }

  return null;
};

const ProviderMaterialProperties: React.FC<WithTranslation & MaterialProductInfoListProps> = (
  { material, provider, t }
) => (
  <MaterialProductInfoList title={ t('properties') }>
    <MaterialProductInfoListItem
      title={ t('manufacturer') }
    >
      { material.manufacturer.name }
    </MaterialProductInfoListItem>
    <MaterialProductInfoListItem
      title={ t('category') }
    >
      { hasProviderFeature(provider, ProviderFeatures.BROWSER) ? (
        <MaterialProductInfoLink
          to={ `/providers/${provider.code}/categories/${material.category.id}` }
        >
          { material.category.name }
        </MaterialProductInfoLink>
      ) : material.category.name }
    </MaterialProductInfoListItem>
    <MaterialProductInfoListItem
      title={ t('gtin') }
      showEmpty={ true }
    >
      { material.gtin }
    </MaterialProductInfoListItem>
    <MaterialProductInfoListItem
      title={ t('sku') }
      showEmpty={ true }
    >
      { material.sku }
    </MaterialProductInfoListItem>
    <MaterialProductInfoListItem
      title={ t('localMaterials') }
      disableTypography={ true }
    >
      <MaterialLinkLayout
        provider={ provider }
        providerMaterial={ material }
        dataSourceItems={ material.dataSourceItems }
        t={ t }
      />
    </MaterialProductInfoListItem>
    <MaterialProductInfoListItem
      title={ t('attachments') }
      disableTypography={ true }
    >
      { formatAttachments(material.attachments) }
    </MaterialProductInfoListItem>
    {/*<ListItem>
      <ListItemText 
        primary="Created at" 
        secondary={ formatDate(material.createdAt) }
      />
    </ListItem>*/}
  </MaterialProductInfoList>
);

const ProviderMaterialPropertiesDecorated = withTranslation()(ProviderMaterialProperties);

export { ProviderMaterialPropertiesDecorated as ProviderMaterialProperties };
