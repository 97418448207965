import * as React from 'react';

import { ConfigProps, FormSection } from 'redux-form';

import { GBMaterialType, MaterialFieldTypes } from 'src/types';
import { 
  MaterialCategorySelectField, MaterialCategorySelectFieldComponent,
  ManufacturerSelectField, ManufacturerSelectFieldComponent,
  TextField, TextFieldComponent 
} from 'src/components/form';

import { 
  MaterialEditorDataDecoratorChildProps, MaterialActionTypes 
} from '../../decorators/MaterialEditorDataDecorator';
import { InjectedEditorFormProps } from 'src/components/editor/EditorForm';
import { useTranslation } from 'react-i18next';
import { gtinValidator, legacy3dIdValidator /*, max250Validator*/ } from 'src/utils/FieldValidators';

import { gtinUniquenessValidator, asyncValidatorComposer } from 'src/utils/AsyncFieldValidators';
import { MaterialVariantEditorForm, mapFields as mapVariantFields } from '../variant-editor/MaterialVariantEditorForm';
import { Typography } from '@material-ui/core';
import { Location } from 'history';
import { SelectedOrganizationContext } from 'src/decorators/SelectedOrganizationProvider';
import { MaterialVariantActionTypes } from '../../decorators/MaterialVariantEditorDataDecorator';
import { AssetSelectFieldComponent } from 'src/components/form/asset-select-field';
import { Permission } from 'src/constants';
import { parseOptionalIntRouteParam } from 'src/utils/BrowserUtils';


type MaterialEditorFormProps = InjectedEditorFormProps<MaterialFieldTypes, MaterialEditorDataDecoratorChildProps> & {
  material?: GBMaterialType;
};

const MaterialEditorForm: React.FC<MaterialEditorFormProps> = props => {
  const { material, match, initialValues } = props;
  const { t } = useTranslation();
  return (
    <>
      <TextField 
        name="gtin" 
        component={ TextFieldComponent } 
        label={ t('gtin') }
        validate={ gtinValidator }
      />
      <TextField 
        name="sku" 
        component={ TextFieldComponent } 
        label={ t('sku') }
      />
      <MaterialCategorySelectField 
        required 
        name="categoryId" 
        component={ MaterialCategorySelectFieldComponent } 
        label={ t('category') }
      />
      <ManufacturerSelectField 
        required 
        name="manufacturerId" 
        component={ ManufacturerSelectFieldComponent } 
        label={ t('manufacturer') }
      />
      { (!material || match.params.actionType === MaterialActionTypes.APPROVE) && (
        <FormSection name="variant">
          <Typography
            variant="h5"
            style={{ marginTop: '30px' }}
          >
            { /*t('variant')*/ 'Variant' }
          </Typography>
          <MaterialVariantEditorForm
            { ...props as any }
            //{ ...other }
            //material={ material }
            //match={ match as any }
            //handleSubmit={ this.props.handleSubmit as any }
            //fieldsComponent={ this.props.fieldsComponent as any }
            initialValues={ initialValues.variant! }
          />
        </FormSection>
      ) }
      <Typography
        variant="h5"
        style={{ marginTop: '30px' }}
      >
        { /*t('variant')*/ '3D'  }
      </Typography>
      <TextField 
        name="assetGUID" 
        component={ AssetSelectFieldComponent } 
        label={ t('assetGUID') }
      />
      <TextField 
        name="legacy3dId" 
        component={ TextFieldComponent } 
        label={ t('legacy3dId') }
        validate={ legacy3dIdValidator }
      />
    </>
  );
};

type ReduxFormConfigProps = RecursivePartial<ConfigProps<MaterialFieldTypes>>;


const parseBaselineVariant = (material: GBMaterialType | undefined) => {
  if (!material || !material.variants.length) {
    return undefined;
  }

  return material.variants[0];
};

const parseVariantConfig = (
  material: GBMaterialType | undefined, 
  location: Location, 
  organizationContext: SelectedOrganizationContext,
  approving?: boolean
) => {
  return mapVariantFields(null, {
    location,
    organizationContext,
    materialVariant: parseBaselineVariant(material),
    actionType: MaterialVariantActionTypes.CREATE,
    approving
  }).initialValues;
};

const mapFields = (
  state: any,
  { material, match, client, location, organizationContext, auth }: MaterialEditorDataDecoratorChildProps
): ReduxFormConfigProps => {
  const commonMappers = asyncValidatorComposer(
    [ gtinUniquenessValidator ], 
    client
  );

  const approving = match.params.actionType === MaterialActionTypes.APPROVE || 
    (!material && auth.hasGlobalAccess(Permission.ADMIN));

  if (!material) {
    const { categoryId, manufacturerId } = match.params;
    return {
      initialValues: {
        categoryId: parseOptionalIntRouteParam(categoryId),
        manufacturerId: parseOptionalIntRouteParam(manufacturerId),
        variant: parseVariantConfig(material, location, organizationContext, approving),
      },
      ...commonMappers,
    };
  }

  const { assetGUID, category, gtin, manufacturer, legacy3dId, sku } = material;
  return {
    initialValues: {
      gtin,
      assetGUID,
      legacy3dId,
      categoryId: category.id,
      manufacturerId: manufacturer.id,
      sku,
      // Variant editor is not available for existing materials
      // (unless we are reviewing an unapproved material)
      variant: !approving ? undefined : {
        ...parseVariantConfig(material, location, organizationContext, approving),
        description: undefined,
        organizationId: undefined,
      }
    },
    ...commonMappers,
  };
};

export { MaterialEditorForm, mapFields };
