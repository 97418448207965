import { Permission } from 'src/constants';

import { ActionHandlerProps, ActionList, OrganizationType } from 'src/types';

import { Actions } from 'src/service/organizations';

import Domain from '@material-ui/icons/Domain';

import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';


interface OrganizationEditActionProps {
  organization: OrganizationType;
}

interface OrganizationCreateActionProps {
  organization?: OrganizationType | null;
} 


type CreateProps = ActionHandlerProps & OrganizationCreateActionProps;
type EditProps = ActionHandlerProps & OrganizationEditActionProps;


const handleCreate = (props: CreateProps) => {
  const { history, match } = props;
  const url = `${match.url}/create`;
  history.push(url);
};

const handleEdit = (props: EditProps) => {
  const { history, match } = props;
  history.push(`${match.url}/${props.organization.id}/edit`);
};

const handleDelete = async (props: EditProps) => {
  const { organization, client } = props;
  await Actions.sendDeleteOrganization(organization.id, client);
};

export const OrganizationCreateActions: ActionList<OrganizationCreateActionProps> = {
  CREATE_ORGANIZATION: {
    title: 'actions.newOrganization',
    icon: Domain,
    handler: handleCreate,
    access: Permission.ADMIN,
  }
};

export const OrganizationEditActions: ActionList<OrganizationEditActionProps> = {
  EDIT: {
    title: 'actions.edit',
    icon: Edit,
    handler: handleEdit,
    access: Permission.ADMIN,
  },
  DELETE: {
    title: 'actions.delete',
    icon: Delete,
    handler: handleDelete,
    onSuccess: 'actions.notifications.organizationDeleted',
    onFailed: 'actions.notifications.organizationDeleteFailed',
    access: Permission.ADMIN,
    confirmation: {
      title: 'actions.confirmations.deleteOrganization',
      description: 'actions.confirmations.deleteOrganizationMessage',
    },
  }
};
