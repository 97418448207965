import * as React from 'react';

import { RouteComponentProps } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import CircularProgress from '@material-ui/core/CircularProgress';

import { 
  LocalSearchCountsDecoratorChildProps, LocalSearchCountsDecorator
} from 'src/modules/search/decorators/LocalSearchCountsDecorator';
import { 
  ProviderSearchCountsDecoratorChildProps, ProviderSearchCountsDecorator
} from 'src/modules/search/decorators/ProviderSearchCountsDecorator';

import { getApolloErrorMessage } from 'src/decorators/DataLoaderDecorator';

import { RouteTab } from 'src/components/RouteTab';
import { Trans } from 'react-i18next';
import { parseSearchVariables } from 'src/utils/SearchUtils';
import { stringifyQuery } from 'src/utils/QueryUtils';
import { Tab } from '@material-ui/core';



interface ResultInfoLabelProps {
  name: React.ReactNode;
  count: number | null;
}

const ResultInfoLabel: React.FC<ResultInfoLabelProps> = ({ name, count }) => (
  <span>
    { name }
    <span style={{ fontWeight: !!count ? 'bold' : 'normal' }}>
      { ` (${count})` }
    </span>
  </span>
);


type SearchTabBarProps = RouteComponentProps<{}>;

class SearchTabBar extends React.PureComponent<
  LocalSearchCountsDecoratorChildProps & ProviderSearchCountsDecoratorChildProps & SearchTabBarProps
> {
  getSearchQuery = () => {
    const search = parseSearchVariables(this.props.location);
    return stringifyQuery(search);
  }

  render() {
    const { match, providerCounts, data, location } = this.props;
    return (
      <Paper>
        <Tabs
          value={ location.pathname }
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <RouteTab 
            label={ 
              <ResultInfoLabel 
                name={ <Trans i18nKey="localDatabase"/> } 
                count={ data.search!.materials.total }
              /> 
            }
            to={ `${match.url}/results` }
            value={ `${match.url}/results` }
            search={ this.getSearchQuery() }
          />
          { providerCounts.data.map(countInfo => (
            <RouteTab
              key={ countInfo.provider.id }
              label={ <ResultInfoLabel name={ countInfo.provider.name } count={ countInfo.total }/> }
              to={ `${match.url}/results/${countInfo.provider.code}` }
              value={ `${match.url}/results/${countInfo.provider.code}` }
              search={ this.getSearchQuery() }
            />
          )) }
          { providerCounts.done < providerCounts.total && (
            <Tab
              component={ () => (
                <CircularProgress 
                  size={ 25 } 
                  style={{ marginTop: 'auto', marginBottom: 'auto' }}
                />
              ) }
            />
          ) }
        </Tabs>
        { providerCounts.errors.map(error => getApolloErrorMessage(error)) }
      </Paper>
    );
  }
}

const SearchTabBarDecorated = ProviderSearchCountsDecorator<SearchTabBarProps>(
  LocalSearchCountsDecorator<ProviderSearchCountsDecoratorChildProps & SearchTabBarProps>(
    SearchTabBar
  )
);

export { SearchTabBarDecorated as SearchTabBar };
