import * as React from 'react';

import Typography, { TypographyProps } from '@material-ui/core/Typography';
import { SvgIconProps } from '@material-ui/core/SvgIcon';


interface LayoutTitleProps extends TypographyProps {
  subTitle?: React.ReactNode;
  titleIcon?: React.ComponentType<SvgIconProps>;
  rightContent?: React.ReactNode;
}

const LayoutTitle: React.FC<LayoutTitleProps> = ({ titleIcon: Icon, children, subTitle, rightContent, ...other }) => (
  <div
    className="layout-title"
    style={{ margin: '10px' }}
  >
    <div 
      className="sub"
      style={{ color: 'gray' }} 
    >
      { subTitle }
    </div>
    <div 
      className="header"
      style={{ 
        display: 'flex',
        alignItems: 'center'
      }}
    >
      { Icon && (
        <Icon
          style={{ width: '35px', height: '35px', marginRight: '5px' }}
        />
      ) }
      <Typography 
        variant="h4" 
        color="inherit"
        { ...other }
      >
        { children }
      </Typography>
      { !!rightContent && (
        <div style={{ marginLeft: '5px', }}>
          { rightContent }
        </div>
      ) }
    </div>
  </div>
);

export { LayoutTitle };