import * as React from 'react';

import { 
  LabelCategoryEditorDataDecorator, LabelCategoryEditorDataDecoratorChildProps
} from '../../decorators/LabelCategoryEditorDataDecorator';

import { LabelEditorForm, mapFields } from './LabelCategoryEditorForm';

import { RouterModalProps, LabelCategoryFieldTypes } from 'src/types';
import { createEditorDialog } from 'src/components/editor';
import { Trans } from 'react-i18next';


type LabelCategoryEditorDialogProps = 
  LabelCategoryEditorDataDecoratorChildProps & RouterModalProps;

const EditorDialog = createEditorDialog<
  LabelCategoryFieldTypes, 
  LabelCategoryEditorDataDecoratorChildProps
>('labelEditor', mapFields);

class LabelCategoryEditorDialog extends React.PureComponent<LabelCategoryEditorDialogProps> {
  render() {
    const { labelCategory, ...other } = this.props;
    return (
      <EditorDialog
        title={ <Trans i18nKey={ !!labelCategory ? 'actions.editLabelCategory' : 'actions.createLabelCategory' }/> }
        labelCategory={ labelCategory }
        fieldsComponent={ LabelEditorForm }
        { ...other }
      />
    );
  }
}

const LabelCategoryEditorDialogDecorated = (
  LabelCategoryEditorDataDecorator(LabelCategoryEditorDialog)
);

export { LabelCategoryEditorDialogDecorated as LabelCategoryEditorDialog };
