import * as React from 'react';

import { RouteComponentProps, Route, Redirect } from 'react-router-dom';

import { ProviderCategoryView } from '../modules/categories';
import { ProviderMaterialView } from '../modules/materials';

import { ProvidersDataDecorator, ProvidersDataDecoratorChildProps } from '../decorators/ProvidersDataDecorator';
import { BrowserSettings } from 'src/constants';


interface RouteProps {
  providerId?: string;
}

class ProviderView extends React.PureComponent<RouteComponentProps<RouteProps> & ProvidersDataDecoratorChildProps> {
  render() {
    const providers = this.props.data.providers!;
    const { providerId } = this.props.match.params;
    if (!providerId && providers.length) {
      let code = localStorage.getItem(BrowserSettings.LAST_PROVIDER_ID);
      if (!providers.find(provider => provider.code === code)) {
        code = providers[0].code;
      }

      return (
        <Redirect 
          to={ `/providers/${code}/categories` }
        />
      );
    }

    return (
      <div id="provider-page">
        <Route path="/providers/:providerId(\w+)/materials/:materialId([\w-]+)" component={ ProviderMaterialView }/>
        <Route path="/providers/:providerId(\w+)/categories/:categoryId([\w-]+)?" component={ ProviderCategoryView }/>
      </div>
    );
  }
}

const ProviderViewWithData = ProvidersDataDecorator(ProviderView);

export { ProviderViewWithData as ProviderView };
