import gql from 'graphql-tag';

export const Fragments = () => ({
  basic: gql`
    fragment OrganizationBasic on Organization {
      id
      code
      name
    }
  `,
});