import * as React from 'react';

import { WrappedFieldProps, Field, GenericField } from 'redux-form';
import { CreatableSelectField, CreatableSelectFieldProps, Option } from './select-field';
//import { Option } from 'react-select';

import { Queries } from 'src/service/organizations';

import { DataLoaderDecorator, DataProps } from 'src/decorators/DataLoaderDecorator';
import { graphql } from '@apollo/client/react/hoc';
import { OrganizationType } from 'src/types';
import { normalizePaginatedResponse } from 'src/utils/PaginationUtils';
import { Trans } from 'react-i18next';


type OptionType = Option<number | string>;

const mapOption = ({ id, name }: OrganizationType): OptionType => ({
  value: id,
  label: name,
});

export type OrganizationSelectFieldProps = Partial<CreatableSelectFieldProps<OptionType>> & { 
  organization?: OrganizationType | null;
  nullOptionLabel?: string;
};

type OrganizationSelectFieldPropsWithData = 
  WrappedFieldProps & 
  OrganizationSelectFieldProps & DataProps<Queries.OrganizationListResponse>;

class OrganizationSelectField extends React.Component<OrganizationSelectFieldPropsWithData> {
  constructor(props: OrganizationSelectFieldPropsWithData) {
    super(props);

    const { data, required, nullOptionLabel } = this.props;
    const items = normalizePaginatedResponse(data.organizations!);
    this.options = items.map(mapOption);
    if (!required && !!nullOptionLabel) {
      this.options = [ 
        {
          value: '',
          label: nullOptionLabel,
        },
        ...this.options,
      ];
    }
  }

  options: Array<OptionType> = [];

  render() {
    const { input, required, ...other } = this.props;
    return (
      <CreatableSelectField 
        { ...other }
        input={ input }
        isMulti={ false }
        name="organizations"
        placeholder={ <Trans i18nKey="prompts.select"/> as any }
        options={ this.options }
        required={ required }
      />
    );
  }
}


const withOrganizationData = graphql<
  WrappedFieldProps & OrganizationSelectFieldProps, 
  Queries.OrganizationListResponse
>(
  Queries.OrganizationListQuery
);

const OrganizationSelectFieldWithData = withOrganizationData(
  DataLoaderDecorator<WrappedFieldProps & OrganizationSelectFieldProps, Queries.OrganizationListResponse>(
    OrganizationSelectField
  )
);

const FieldType = Field as new() => GenericField<OrganizationSelectFieldProps>;

export { 
  FieldType as OrganizationSelectField,
  OrganizationSelectFieldWithData as OrganizationSelectFieldComponent
};
