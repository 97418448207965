import * as React from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import { DropdownMenu } from 'src/components/DropdownMenu';

import { default as i18n, Languages } from 'src/i18n';
import { parseCurrentLanguage } from 'src/utils/LanguageUtils';


interface LanguageSelectorProps {

}

const parseLanguageName = (code: string) => {
  let language = Languages.find(lang => lang.code === code);
  if (!!language) {
    return language.title;
  }

  return `Unknown language (${code})`;
};

const LanguageSelector: React.FC<LanguageSelectorProps> = (props) => {
  return (
    <DropdownMenu
      caption={ parseLanguageName(parseCurrentLanguage()) }
      buttonProps={{
        fullWidth: true,
        style: { marginTop: '5px' },
      }}
    >
      { Languages.map(lang => (
        <MenuItem 
          key={ lang.code }
          onClick={ () => {
            i18n.changeLanguage(lang.code);
            window.location.reload();
          }}
        >
          { lang.title }
        </MenuItem>
      )) }
    </DropdownMenu>
  );
};

export { LanguageSelector };
