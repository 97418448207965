import * as React from 'react';

import { WrappedFieldProps, Field, GenericField } from 'redux-form';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

//@ts-ignore
import TreeSelect from 'rc-tree-select';
import 'rc-tree-select/assets/index.css';
import './TreeSelectField.css';

import { Queries } from 'src/service/materialCategories';

import { DataLoaderDecorator, DataProps } from 'src/decorators/DataLoaderDecorator';
import { graphql } from '@apollo/client/react/hoc';
import { normalizePaginatedResponse } from 'src/utils/PaginationUtils';
import { RecursiveMaterialCategoryItem } from 'src/service/materialCategories/materialCategoryQueries';

import { FormLabelProps } from './types';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ChevronRight from '@material-ui/icons/ChevronRight';


const mapCategoryOption = ({ id, name, categories }: RecursiveMaterialCategoryItem): any => ({
  key: id, 
  value: id.toString(), 
  title: name, 
  children: normalizePaginatedResponse(categories).map(mapCategoryOption)
});


const IconStyle: React.CSSProperties = {
  marginTop: '-4px',
};

const SwitcherIcon = ({ expanded, children }: any) => {
  if (!children.length) {
    return null;
  }

  return !expanded ? <ChevronRight style={ IconStyle }/> : <ExpandMore style={ IconStyle }/>; 
};

export type MaterialCategorySelectFieldProps = FormLabelProps & { disabled?: boolean };
type MaterialCategorySelectFieldInputProps = 
  MaterialCategorySelectFieldProps & WrappedFieldProps & DataProps<Queries.MaterialCategoryListRecursiveResponse>;

class MaterialCategorySelectField extends React.Component<MaterialCategorySelectFieldInputProps> {
  onChange = (value: string) => {
    this.props.input.onChange(parseInt(value));
  }

  render() {
    const { input, data, required, label, error, focused, disabled } = this.props;
    return (
      <FormControl
        fullWidth={ true }
      >
        { !!label && (
          <FormLabel 
            //component="legend"
            error={ error }
            required={ required }
            focused={ focused } 
          >
            { label }
          </FormLabel>
        ) }
        <TreeSelect
          className={ input.name }
          dropdownStyle={{ maxHeight: 200, overflow: 'auto', zIndex: 100000 }}
          transitionName="rc-tree-select-dropdown-slide-up"
          choiceTransitionName="rc-tree-select-selection__choice-zoom"
          showSearch={ false }
          allowClear={ !required }
          treeLine
          disabled={ disabled }
          dropdownMatchSelectWidth={ false }
          treeNodeFilterProp="label"
          value={ input.value ? input.value.toString() : undefined }
          treeData={ normalizePaginatedResponse(data.materialCategories!).map(mapCategoryOption) }
          onChange={ this.onChange }
          switcherIcon={ SwitcherIcon }
        />
      </FormControl>
    );
  }
}


const withMaterialData = graphql<
  MaterialCategorySelectFieldProps & WrappedFieldProps, 
  Queries.MaterialCategoryListRecursiveResponse
> (
  Queries.MaterialCategoryListQueryRecursive
);

const MaterialCategorySelectFieldWithData = withMaterialData(
  DataLoaderDecorator<
    MaterialCategorySelectFieldProps & WrappedFieldProps, 
    Queries.MaterialCategoryListRecursiveResponse
  >(
    MaterialCategorySelectField
  )
);

const FieldType = Field as new() => GenericField<MaterialCategorySelectFieldProps>;

export { 
  FieldType as MaterialCategorySelectField, 
  MaterialCategorySelectFieldWithData as MaterialCategorySelectFieldComponent 
};
