import * as React from 'react';

import { graphql } from '@apollo/client/react/hoc';
import { Location } from 'history';

import { Queries } from 'src/service/materials';

import { DataLoaderDecorator, DataProps } from 'src/decorators/DataLoaderDecorator';
import { parseSearchVariables } from 'src/utils/SearchUtils';
import { SearchType } from 'src/types';


interface LocalSearchCountsDecoratorProps {
  location: Location;
}

export type LocalSearchCountsDecoratorChildProps = 
  LocalSearchCountsDecoratorProps & DataProps<Queries.MaterialSearchCountsResponse>;


export const LocalSearchCountsDecorator = function <T>(
  Component: React.ComponentType<T & LocalSearchCountsDecoratorChildProps>
) {
  const withResults = graphql<T & LocalSearchCountsDecoratorProps, Queries.MaterialSearchCountsResponse>(
    Queries.MaterialSearchCountsQuery, {
      options: ({ location }) => {
        return {
          variables: {
            types: [ SearchType.MATERIAL ],
            ...parseSearchVariables(location),
          }
        };
      },
    }
  );

  return withResults(
    DataLoaderDecorator<LocalSearchCountsDecoratorProps & T, Queries.MaterialSearchCountsResponse>(Component)
  );
};
