import { 
  CreateLabelCategoryResponse, UpdateLabelCategoryResponse, DeleteLabelCategoryResponse, 
  UpdateLabelCategoryMutation, DeleteLabelCategoryMutation, CreateLabelCategoryMutation
} from './labelCategoryMutations';
import { ApolloClient } from '@apollo/client';
import { deleteMutation, updateMutation, createMutation, resetCache } from 'src/utils/MutationUtils';
import { GBLabelCategoryIdType, LabelCategoryInput, LabelCategoryVariables } from 'src/types';


// Create a new label category
export const sendCreateLabelCategory = async (
  input: LabelCategoryInput, 
  languageCode: string,
  client: ApolloClient<any>
) => {
  const response = await createMutation<CreateLabelCategoryResponse, LabelCategoryVariables> (
    { input, languageCode }, CreateLabelCategoryMutation, client
  );

  resetCache(client);
  return response.createLabelCategory;
};

// Update an existing label category
export const sendUpdateLabelCategory = async (
  id: GBLabelCategoryIdType, 
  input: RecursivePartial<LabelCategoryInput>, 
  languageCode: string,
  client: ApolloClient<any>
) => {
  const response = await updateMutation<UpdateLabelCategoryResponse, LabelCategoryVariables> (
    id, { input, languageCode }, UpdateLabelCategoryMutation, client
  );

  resetCache(client);
  return response.updateLabelCategory;
};

// Delete label category
export const sendDeleteLabelCategory = async (
  id: GBLabelCategoryIdType,  
  client: ApolloClient<any>,
  onDeletedCallback: (response: DeleteLabelCategoryResponse) => void
) => {
  const response = await deleteMutation<DeleteLabelCategoryResponse>(
    id, DeleteLabelCategoryMutation, client, onDeletedCallback
  );

  resetCache(client);
  return response.deleteLabelCategory;
};