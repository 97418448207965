import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Trans } from 'react-i18next';


interface ConfirmPromptProps {
  onConfirm: () => void;
  onCancel: () => void;
  title: React.ReactNode;
  description: React.ReactNode;
  open: boolean;
}

class ConfirmPrompt extends React.PureComponent<ConfirmPromptProps> {
  render() {
    const { title, description, open, onCancel, onConfirm } = this.props;
    return (
      <Dialog
        open={ open }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{ title }</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            { description }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={ onCancel } color="primary">
            <Trans i18nKey="cancel"/>
          </Button>
          <Button onClick={ onConfirm } color="primary" autoFocus>
            <Trans i18nKey="delete"/>
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export { ConfirmPrompt };
