

import { WebAuth, Auth0DecodedHash } from 'auth0-js';

import { UserInfo } from 'src/types';
import { ApolloClient, ApolloError } from '@apollo/client';
import { FindUserQuery, UserResponse } from 'src/service/users/userQueries';
import { Permission } from 'src/constants';


const hasAccess = (access: Permission, permissions: Permission[]) => {
  return permissions.indexOf(access) !== -1;
};

const hasOrganizationAccessByCode = (access: Permission, organizationCode: string, userInfo: UserInfo) => {
  const organizations = userInfo.organizationPermissions;
  return !!organizations.find(org => org.organization.code === organizationCode && hasAccess(access, org.permissions));
};

const hasOrganizationAccessAny = (access: Permission, userInfo: UserInfo) => {
  const organizations = userInfo.organizationPermissions;
  return !!organizations.find(org => hasAccess(access, org.permissions));
};

const fetchUserInfo = async (client: ApolloClient<any>) => {
  const res = await client.query({
    query: FindUserQuery,
  });

  const { user } = res.data as UserResponse;
  return user;
};

const setSession = (authResult: Auth0DecodedHash) => {
  if (authResult && authResult.accessToken && authResult.expiresIn) {
    const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('expires_at', expiresAt);
  }
};

const setUserInfo = (userInfo: UserInfo) => {
  localStorage.setItem('user_info', JSON.stringify(userInfo));
};

const clearAuthData = () => {
  // Clear access token and ID token from local storage
  localStorage.removeItem('access_token');
  localStorage.removeItem('expires_at');
  localStorage.removeItem('user_info');
};

const formatApolloError = (apolloError: ApolloError) => {
  const { networkError } = apolloError;
  if (networkError && (networkError as any).result) {
    return (networkError as any).result.message;
  }

  return apolloError.toString();
};

const getUserInfo = (): UserInfo | undefined => {
  const userInfo = localStorage.getItem('user_info');
  if (userInfo) {
    return JSON.parse(userInfo);
  }

  return undefined;
};

type UserInfoGetter = () => UserInfo | undefined;

export default class Auth {
  userInfoGetter: UserInfoGetter;

  constructor(userInfoGetter?: UserInfoGetter) {
    this.userInfoGetter = userInfoGetter || getUserInfo;
  }

  auth0 = new WebAuth({
    domain:       process.env.REACT_APP_AUTH0_DOMAIN!,
    clientID:     process.env.REACT_APP_AUTH0_CLIENT_ID!,
    responseType: 'token',
    scope:        'openid email profile picture app_metadata',
    audience:     process.env.REACT_APP_AUTH0_AUDIENCE,
    redirectUri:  `${window.location.origin}/login/callback`,
  });

  login = () => {
    this.auth0.authorize({
      connection: process.env.REACT_APP_AUTH0_DATABASE,
      prompt: 'login',
    });
  }

  handleAuthentication = (client: ApolloClient<any>) => {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.expiresIn) {
          setSession(authResult);
          fetchUserInfo(client)
            .then(user => {
              setUserInfo(user);
              resolve(authResult);
            })
            .catch((apolloError: ApolloError) => {
              clearAuthData();
              console.warn('Failed to get user info', apolloError);
              reject(new Error(`Authentication failed (${formatApolloError(apolloError)})`));
            });
        } else if (err) {
          console.error('Authentication failed', JSON.stringify(err, null, 4));
          reject(new Error('Authentication failed'));
        } else {
          reject(new Error('Unknown authentication response'));
        }
      });
    });
  }

  getUserInfo = (): UserInfo | undefined => {
    return this.userInfoGetter();
  }

  logout = () => {
    clearAuthData();
  }

  isAuthenticated = () => {
    // Check whether the current time is past the 
    // access token's expiry time
    const expiresAt = localStorage.getItem('expires_at');
    if (expiresAt) {
      return new Date().getTime() < JSON.parse(expiresAt);
    }

    return false;
  }

  hasOrganizationAccessByCode = (access: Permission, organizationCode: string) => {
    const userInfo = this.getUserInfo();
    if (!userInfo) {
      return false;
    }

    return hasAccess(access, userInfo.globalPermissions) || 
      hasOrganizationAccessByCode(access, organizationCode, userInfo);
  }

  hasOrganizationAccessAny = (access: Permission) => {
    const userInfo = this.getUserInfo();
    if (!userInfo) {
      return false;
    }

    return hasAccess(access, userInfo.globalPermissions) || 
      hasOrganizationAccessAny(access, userInfo);
  }

  hasGlobalAccess = (access: Permission) => {
    const userInfo = this.getUserInfo();
    if (!userInfo) {
      return false;
    }

    return hasAccess(access, userInfo.globalPermissions);
  }
}
