import * as React from 'react';

import { ConfigProps, EventWithDataHandler, formValueSelector } from 'redux-form';

import { GBLabelCategoryIdType, GBLabelType, GBLabelIdType } from 'src/types';
import { 
  LabelCategorySelectField,
  LabelSelectField,
  LabelCategorySelectFieldComponent,
  LabelSelectFieldComponent
} from 'src/components/form';

import { 
  MaterialLabelFieldTypes, MaterialLabelEditorDataDecoratorChildProps 
} from '../../decorators/MaterialLabelEditorDataDecorator';
import { InjectedEditorFormProps } from 'src/components/editor/EditorForm';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { LabelSuggestions } from './LabelSuggestions';
import { SuggestionChangeHandler, SuggestionOption } from 'src/components/form/select-field/SuggestionSection';
import { parseOptionalIntRouteParam } from 'src/utils/BrowserUtils';


export type MaterialLabelEditorFormProps = 
  InjectedEditorFormProps<MaterialLabelFieldTypes, MaterialLabelEditorDataDecoratorChildProps>;

interface DataProps { 
  labelIds?: GBLabelIdType[];
  categoryId?: GBLabelCategoryIdType;
  suggestions?: SuggestionOption<GBLabelType>[];
}

class MaterialLabelEditorForm extends React.PureComponent<MaterialLabelEditorFormProps & DataProps /*, State*/> {
  onLabelCategoryChanged: EventWithDataHandler<React.ChangeEvent<any>> = (event, value, previousValue) => {
    const { change } = this.props;
    change('labelIds', null);
  }

  onSuggestionSelected: SuggestionChangeHandler<GBLabelType> = option => {
    const { change, labelIds, suggestions } = this.props;
    const newCategoryId = option.value.category.id;

    if (newCategoryId !== this.props.categoryId) {
      change('categoryId', option.value.category.id);
      change('labelIds', [ option.value.id ]);
      change('suggestions', [ option ]);
    } else {
      change('labelIds', [ ...(!!labelIds ? labelIds : []), option.value.id ]);
      change('suggestions', [ ...(!!suggestions ? suggestions : []), option ]);
    }
  }

  render() {
    const { match, material, labelIds, categoryId } = this.props;
    return (
      <>
        <LabelCategorySelectField 
          required 
          disabled={ !!match.params.labelCategoryId }
          name="categoryId" 
          component={ LabelCategorySelectFieldComponent } 
          label={ <Trans i18nKey="labelCategory"/> }
          onChange={ this.onLabelCategoryChanged }
          material={ material }
        />
        <LabelSelectField 
          required 
          name="labelIds" 
          component={ LabelSelectFieldComponent } 
          label={ <Trans i18nKey="labels"/> }
          categoryId={ categoryId }
          disabled={ !categoryId }
        />
        <LabelSuggestions
          materialId={ material.id }
          labelIds={ labelIds }
          categoryId={ categoryId }
          onSuggestionSelected={ this.onSuggestionSelected }
          material={ material }
        />
      </>
    );
  }
}

type ReduxFormConfigProps = Partial<ConfigProps<MaterialLabelFieldTypes>>;

const mapFields = (
  state: any,
  { material, match }: MaterialLabelEditorDataDecoratorChildProps
): ReduxFormConfigProps => {
  const labelCategoryId = parseOptionalIntRouteParam(match.params.labelCategoryId);
  if (!labelCategoryId) {
    return {
      initialValues: {}
    };
  }

  return {
    initialValues: {
      labelIds: material.labels
        .filter(label => label.category.id === labelCategoryId)
        .map(label => label.id),
      categoryId: labelCategoryId,
    }
  };
};


const FORM_ID = 'materialLabelEditor';

const selector = formValueSelector(FORM_ID);
const MaterialLabelEditorFormDecorated = connect(state => {
  const labelIds = selector(state, 'labelIds');
  const suggestions = selector(state, 'suggestions');
  const categoryId = selector(state, 'categoryId');
  return {
    labelIds,
    categoryId,
    suggestions,
  };
})(MaterialLabelEditorForm);


export { 
  MaterialLabelEditorFormDecorated as MaterialLabelEditorForm, 
  mapFields, FORM_ID 
};
