import * as React from 'react';

import { WrappedFieldProps, Field, GenericField } from 'redux-form';
import { 
  AsyncCreatableSelectField, AsyncCreatableSelectFieldProps, 
  NewOptionCreator, ChangeHandler, Option, SkipFetchCreatable
} from './select-field';

import { Queries, Actions } from 'src/service/manufacturers';

import { DataLoaderDecorator, DataProps } from 'src/decorators/DataLoaderDecorator';
import { graphql } from '@apollo/client/react/hoc';
import { GBManufacturerType, SearchType } from 'src/types';

import { normalizePaginatedResponse } from 'src/utils/PaginationUtils';
import { Trans } from 'react-i18next';
import { ApolloClient } from '@apollo/client';


type OptionType = Option<number>;

const mapOption = ({ id, name }: GBManufacturerType): OptionType => ({
  value: id,
  label: name,
});

export type ManufacturerSelectFieldProps = Partial<AsyncCreatableSelectFieldProps<number>>;
type ManufacturerSelectFieldInputProps = 
  ManufacturerSelectFieldProps & WrappedFieldProps & DataProps<Queries.ManufacturerResponse>;


const sendSearch = async (text: string, client: ApolloClient<any>) => {
  const res = await client.query<Queries.ManufacturerSearchResponse>({
    query: Queries.ManufacturerSearchQuery,
    variables: {
      first: 5,
      query: text,
      types: [ SearchType.MANUFACTURER ],
    }
  });

  return normalizePaginatedResponse(res.data!.search.manufacturers);
};

class ManufacturerSelectField extends React.Component<ManufacturerSelectFieldInputProps> {
  constructor(props: ManufacturerSelectFieldInputProps) {
    super(props);

    if (props.data && props.data.manufacturer) {
      this.options = [ mapOption(props.data.manufacturer) ];
    }
  }

  // Cache the previously fetched options
  // as the list must always contain the currently selected one
  options: Array<OptionType> = [];

  loadOptions = async (text: string) => {
    const { client } = this.props;
    if (text.length > 0) {
      const results = await sendSearch(text, client);
      this.options = results.map(mapOption);
    }

    return this.options;
  }

  sendCreate = async (name?: string) => {
    if (!name) {
      return;
    }

    const { client, input } = this.props;
    const response = await Actions.sendCreateManufacturer(
      {
        name,
      }, 
      client,
      false
    );

    // Update selection
    this.options = [ 
      mapOption({
        id: response.id,
        name,
      }) 
    ];

    input.onChange(response.id);
    return response.id;
  }

  render() {
    const { input, ...other } = this.props;
    return (
      <>
        <AsyncCreatableSelectField 
          { ...other }
          input={ input }
          isMulti={ false }
          name="manufacturers"
          placeholder={ <Trans i18nKey="prompts.search"/> as any }
          loadOptions={ this.loadOptions }
          defaultOptions={ false }
          cacheOptions={ false }
          options={ this.options }
          getNewOptionData={ NewOptionCreator }
          onSelectValueChange={ ChangeHandler(input.onChange, this.sendCreate) }
        />
      </>
    );
  }
}

const withManufacturerData = graphql<WrappedFieldProps & ManufacturerSelectFieldProps, Queries.ManufacturerResponse>(
  Queries.ManufacturerQuery, {
    skip: ({ input }) => SkipFetchCreatable(input),
    options: ({ input }) => ({
      variables: { 
        manufacturerId: input.value 
      }
    }),
  }
);

const FieldType = Field as new() => GenericField<ManufacturerSelectFieldProps>;
const ManufacturerSelectFieldWithData = withManufacturerData(
  DataLoaderDecorator<WrappedFieldProps & ManufacturerSelectFieldProps, Queries.ManufacturerResponse>(
    ManufacturerSelectField
  )
);

export { 
  FieldType as ManufacturerSelectField,
  ManufacturerSelectFieldWithData as ManufacturerSelectFieldComponent
};
