import * as React from 'react';

import { List, Paper, ListSubheader, Typography } from '@material-ui/core';
import { PaperProps } from '@material-ui/core/Paper';


export interface MaterialProductInfoListProps extends Omit<PaperProps, 'title'> {
  title?: React.ReactNode;
}

export const MaterialProductInfoList: React.FC<MaterialProductInfoListProps> = (
  { title, children, ...other }
) => {
  return (
    <Paper { ...other }>
      <List 
        subheader={ !title ? undefined : (
          <ListSubheader style={{ paddingTop: '10px' }}>
            <Typography
              variant="h5"
            >
              { title }
            </Typography>
          </ListSubheader>
        ) }
      >
        { children }
      </List>
    </Paper>
  );
};
