import * as React from 'react';

import { useApolloClient, ApolloClient, ApolloError } from '@apollo/client';

import { DataSourceItemId, AttachmentType, GBMaterialType } from 'src/types';

import { Queries } from 'src/service/dataSourceItems';
import { MultiFetchReducer, MultiFetchReducerType, Action, getEmptyState, State } from 'src/reducers/MultiFetchReducer';


export interface MaterialAttachmentsDataDecoratorProps {
  material: GBMaterialType;
}

export interface MaterialAttachmentsDataDecoratorChildProps extends MaterialAttachmentsDataDecoratorProps {
  attachments: State<AttachmentType>;
}


const fetchDataSourceItemAttachments = async (dataSourceItemId: DataSourceItemId, client: ApolloClient<any>) => {
  const res = await client.query<Queries.DataSourceItemAttachmentsResponse>({
    query: Queries.DataSourceItemAttachmentsQuery,
    variables: {
      dataSourceItemId,
    }
  });

  const { providerMaterial } = res.data!.dataSourceItem;
  return !!providerMaterial && !!providerMaterial.attachments ? providerMaterial.attachments : [];
};

type Props = 
  MaterialAttachmentsDataDecoratorProps;

function MaterialAttachmentsDataDecorator<T>(
  Component: React.ComponentType<T & MaterialAttachmentsDataDecoratorChildProps>
) {
  const Decorator: React.FC<T & Props> = props => {
    const [ state, dispatch ] = React.useReducer<MultiFetchReducerType<AttachmentType>>(
      MultiFetchReducer, 
      getEmptyState(props.material.dataSourceItems!.length)
    );
    const client = useApolloClient();

    React.useEffect(
      () => {
        dispatch({
          type: Action.RESET,
        });

        props.material.dataSourceItems!.forEach(
          async ({ id, provider }) => {
            try {
              const res = await fetchDataSourceItemAttachments(id, client);
              dispatch({
                type: Action.FETCHED,
                data: res,
              });
            } catch (e) {
              console.error(`Failed to fetch attachments for provider ${provider.name}`, e);

              if (e instanceof ApolloError) {
                dispatch({
                  type: Action.FETCH_FAILED,
                  error: e,
                });
              }
            }
          }
        );
      },
      [ props.material.dataSourceItems, client ]
    );
    
    return (
      <Component
        attachments={ state }
        { ...props }
      />
    );
  };

  return Decorator;
}

export { 
  MaterialAttachmentsDataDecorator
};
