import * as React from 'react';

import { NavLink, NavLinkProps } from 'react-router-dom';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

type ExternalProps = ButtonProps & NavLinkProps;

export interface RouteButtonProps extends ExternalProps {
  icon?: React.ComponentType<SvgIconProps> | null;
}

const iconStyle = {
  marginRight: '3px',
};


// https://material-ui.com/components/buttons/#ButtonRouter.js
const AdapterLink = React.forwardRef<any, Omit<RouteButtonProps, 'ref'>>((props, ref) => (
  <NavLink 
    innerRef={ ref }
    activeClassName="active" 
    { ...props }
  />
));

export const RouteButton: React.FC<RouteButtonProps> = ({ icon: Icon, to, children, ...other }) => (
  <Button 
    component={ AdapterLink }
    to={ to }
    classes={{
      containedPrimary: 'active',
    }}
    { ...other }
  >
    { !!Icon && <Icon style={ iconStyle }/>  }
    { children }
  </Button>
);