import * as React from 'react';

import { MaterialBaseType, ViewMaterialType } from 'src/types';
import { MainLayout, ReturnButton } from 'src/components/main-layout';
import { MaterialImage } from 'src/components/MaterialImage';

import './material-layout.css';
import { useRouter } from 'src/effects';
import { parseReturnInfo, ReturnInfoState } from 'src/utils/BrowserUtils';


type MaterialPropertiesRendererType = 
  (material: MaterialBaseType) => React.ReactNode;

//type ActionPanelType = React.ComponentType<{ material: MaterialBaseType }>;
type ActionPanelType = React.ReactNode;


interface MaterialLayoutProps {
  material: ViewMaterialType;
  materialPropertiesRenderer?: MaterialPropertiesRendererType;
  materialLabelsRenderer?: MaterialPropertiesRendererType;
  actionPanel?: ActionPanelType;
  browserBaseUrl?: string;
  subTitle?: React.ReactNode;
  showDefaultReturn?: boolean;
  children?: React.ReactNode;
}




const MaterialLayout = React.memo<MaterialLayoutProps>(props => {
  const {
    material, materialPropertiesRenderer, materialLabelsRenderer, actionPanel: ActionPanel,
    subTitle, browserBaseUrl = '', showDefaultReturn = true, children
  } = props;

  const { location } = useRouter<{}, any, ReturnInfoState>();

  const returnInfo = parseReturnInfo(location, showDefaultReturn ? {
    name: material.category.name,
    pathname: `${browserBaseUrl}/categories/${material.category.id}`,
  } : undefined);

  return (
    <MainLayout
      id="material-layout"
      actionBarLeft={ !!returnInfo && (
        <ReturnButton
          returnInfo={{
            ...returnInfo,
            state: {
              ...returnInfo.state,
              rowId: material.id,
            },
            name: returnInfo.name || material.category.name
          }}
        />
      ) }
      //actionBarRight={ !!ActionPanel && <ActionPanel material={ material }/> }
      actionBarRight={ ActionPanel }
      title={ material.name }
      subTitle={ subTitle }
    >
      { children }
      <div 
        className="material"
        style={{
          display: 'flex',
        }}
      >
        <MaterialImage
          className="image"
          image={ material.image }
          maxSize={ 400 }
          margin={ 20 }
          style={{
            flex: '0 50 100%',
          }}
        />
        { !!material.description && (
          <div 
            className="description"
            style={{
              flex: '0 50 100%',
            }}
          >
            { material.description }
          </div>
        ) }
        <div className="info">
          { !!materialPropertiesRenderer && materialPropertiesRenderer(material) }
          { !!materialLabelsRenderer && materialLabelsRenderer(material) }
        </div>
      </div>
    </MainLayout>
  );
});

export { MaterialLayout };
