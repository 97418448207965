import * as React from 'react';

import { createContext } from 'react';
import Auth from 'src/service/Auth';


export const AuthContext = createContext<Auth | undefined>(undefined);

export type AuthContextType = Auth;
export interface AuthProps {
  auth: Auth;
}

export function withAuth<PropsT>(Component: React.ComponentType<PropsT & AuthProps>) {
  return function (props: PropsT) {
    return (
      <AuthContext.Consumer>
        { context => {
          if (!context) {
            console.error('Auth context value missing ');
            return null;
          }

          return (
            <Component {...props} auth={ context } />
          ); 
        } }
      </AuthContext.Consumer>
    );
  };
}