import { Validator } from 'redux-form';
import i18n from 'src/i18n';


export const gtinValidator: Validator = (value: string, allValues?: any, props?: any, name?: any) => {
  if (value && !value.match(/\d{12,14}|\d{8}/)) {
    return i18n.t('validation.invalidValue');
  }

  return null;
};

export const legacy3dIdValidator: Validator = (value: string, allValues?: any, props?: any, name?: any) => {
  if (value && !value.match(/^[\w#;&]+$/)) {
    return i18n.t('validation.invalidValue');
  }

  return null;
};

export const max250Validator: Validator = (value: string, allValues?: any, props?: any, name?: any) => {
  if (value && value.length > 250) {
    return i18n.t('validation.maxLengthChars', { max: 250 });
  }

  return null;
};