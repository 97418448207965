import gql from 'graphql-tag';
import { GBLabelType, PaginatedResponse, GBMaterialType, TotalResponse } from 'src/types';

import { Fragments as FragmentsRaw } from './labelFragments';
import { Fragments as MaterialFragmentsRaw } from 'src/service/materials/materialFragments';


import { Fragments as MaterialVariantFragmentsRaw } from 'src/service/materialVariants/materialVariantFragments';
import { 
  Fragments as MaterialVariantLocalizationFragmentsRaw 
} from 'src/service/materialVariantLocalizations/materialVariantLocalizationFragments';

const MaterialVariantFragments = MaterialVariantFragmentsRaw();
const MaterialLocalizationFragments = MaterialVariantLocalizationFragmentsRaw();

const MaterialFragments = MaterialFragmentsRaw();
const Fragments = FragmentsRaw();


// Queries
export const LabelQuery = gql`
  query LabelQuery(
    $labelId: Int!
  ) {
    label(id: $labelId) {
      ...LabelBasic
      ...LabelCategory
    }
  }
  ${Fragments.basic}
  ${Fragments.category}
`;

export const LabelCategoriesQuery = gql`
  query LabelCategoriesQuery(
    $labelId: Int!
  ) {
    label(id: $labelId) {
      ...LabelBasic
      ...LabelCategory
      materials {
        total
      }
    }
  }
  ${Fragments.basic}
  ${Fragments.category}
`;

export const LabelMaterialsQuery = gql`
  query LabelMaterialsQuery(
    $labelId: Int!,
    $first: Int, 
    $after: String, 
    $orderBy: MaterialsOrder, 
    $languageCode: LanguageCode
  ) {
    label(id: $labelId) {
      id
      materials(first: $first, after: $after, orderBy: $orderBy) {
        total
        edges {
          node {
            ...MaterialBasic
            ...MaterialCategory
            ...MaterialManufacturer
            variants {
              ...MaterialVariantBasic
              ...MaterialVariantOrganization
              ...MaterialVariantProvider
              localization(languageCode: $languageCode) {
                ...MaterialVariantLocalizationBasic
              }
            }
          }
        }
      }
    }
  }
  ${MaterialFragments.basic}
  ${MaterialFragments.materialCategory}
  ${MaterialFragments.manufacturer}
  ${MaterialVariantFragments.basic}
  ${MaterialVariantFragments.organization}
  ${MaterialVariantFragments.provider}
  ${MaterialLocalizationFragments.basic}
`;

// Response types
export interface LabelResponse {
  label: GBLabelType;
}

export interface LabelCategoriesResponse {
  label: GBLabelType & {
    materials: TotalResponse;
  };
}

export interface LabelMaterialsResponse {
  label: {
    materials: PaginatedResponse<GBMaterialType>;
  };
}