import { ApolloClient, ApolloError } from '@apollo/client';

import { 
  CreateMaterialVariantResponse, UpdateMaterialVariantResponse, 
  UploadMaterialVariantImageResponse, DeleteMaterialVariantResponse,
  CreateMaterialVariantMutation, UpdateMaterialVariantMutation, 
  UploadMaterialVariantImageMutation, DeleteMaterialVariantMutation
} from './materialVariantMutations';
import { MaterialVariantIdType, MaterialVariantFieldTypes, MaterialVariantVariables } from 'src/types';
import { createMutation, updateMutation, deleteMutation, resetCache } from 'src/utils/MutationUtils';


const parseDate = (response: Response) => {
  const dateStr = response.headers.get('Date');
  if (!!dateStr) {
    try {
      const date = new Date(dateStr);
      return date;
    } catch (e) {
      // ...
    }
  }

  return new Date();
};

const urlToFile = async (url: string, fileName: string) => {
  const res = await fetch(url);
  const blob: any = await res.blob();
  blob.lastModifiedDate = parseDate(res);
  blob.name = fileName;
  return blob as File;
};

// Upload image for a material
const sendImage = async (
  id: number, 
  file: File, 
  client: ApolloClient<any>
) => {
  try {
    const response = (await client.mutate({
      mutation: UploadMaterialVariantImageMutation,
      variables: { 
        id, 
        file,
      }
    })).data as UploadMaterialVariantImageResponse;

    return response && response.uploadMaterialVariantImage;
  } catch (err) {
    if (err instanceof ApolloError) {
      console.log('Failed to create material', JSON.stringify(err));
    }
    
    throw err;
  }
};

// Create a new material
export const sendCreateMaterialVariant = async (
  formFields: MaterialVariantFieldTypes, 
  client: ApolloClient<any>,
  resetStore: boolean,
) => {
  const { image, localization, ...input } = formFields;
  const response = await createMutation<CreateMaterialVariantResponse, MaterialVariantVariables>(
    { input }, CreateMaterialVariantMutation, client
  );

  const variant = response.createMaterialVariant;
  if (!!variant && !!image) {
    try {
      if (image instanceof File) {
        // Uploaded file
        await sendImage(variant.id, image, client);
      } else {
        // Cloning, convert the existing MaterialImage to file
        const file = await urlToFile(image.urlLarge, image.name);
        await sendImage(variant.id, file, client);
      }
    } catch (e) {
      // Image upload failed, don't leave incomplete variants
      await sendDeleteMaterialVariant(variant.id, client, undefined, false);
      throw e;
    }
  }

  if (resetStore) {
    resetCache(client);
  }

  return variant;
};

// Update an existing material
export const sendUpdateMaterialVariant = async (
  id: MaterialVariantIdType, 
  formFields: RecursivePartial<MaterialVariantFieldTypes>, 
  client: ApolloClient<any>,
  resetStore: boolean,
) => {
  const { image, localization, ...input } = formFields;
  const response = await updateMutation<UpdateMaterialVariantResponse, MaterialVariantVariables>(
    id, { input }, UpdateMaterialVariantMutation, client
  );

  if (!!response && !!image && image instanceof File) {
    await sendImage(id, image, client);
  }
  
  if (resetStore) {
    resetCache(client);
  }

  return response.updateMaterialVariant;
};

// Delete material category
export const sendDeleteMaterialVariant = async (
  id: MaterialVariantIdType,
  client: ApolloClient<any>,
  onDeletedCallback?: (response: DeleteMaterialVariantResponse) => void,
  doReset = true
) => {
  const response = await deleteMutation<DeleteMaterialVariantResponse>(
    id, DeleteMaterialVariantMutation, client, onDeletedCallback
  );
  
  if (doReset) {
    resetCache(client);
  }

  return response.deleteMaterialVariant;
};
