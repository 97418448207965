import * as React from 'react';

import { 
  MaterialFilterPanel, //MaterialFilterPanel
} from 'src/components/browser-layout';

import { MaterialsTable } from './MaterialsTable';

import { Message } from 'src/components/Message';
import { hasMaterialFilterVariables } from 'src/utils/MaterialFilterUtils';
import { Trans } from 'react-i18next';
import { MainLayout } from 'src/components/main-layout';
import { 
  UnapprovedMaterialsDecoratorChildProps, UnapprovedMaterialsDecorator 
} from '../../decorators/UnapprovedMaterialCountsDecorator';
import { FilterableTableLayout } from 'src/components/table';


const MaterialsLayout: React.FC<UnapprovedMaterialsDecoratorChildProps> = ((
  { location, data }
) => {
  const { 
    total, //materialLabelCounts, materialCategoryCounts, materialManufacturerCounts
  } = data.materials!;
  if (total === 0 && !hasMaterialFilterVariables(location)) {
    return (
      <div>
        <Message
          title={ <Trans i18nKey="noUnapprovedMaterials"/> }
        />
      </div>
    );
  }

  return (
    <MainLayout
      id="materials-layout"
      title={ <Trans i18nKey="unapprovedMaterials"/> }
    >
      <FilterableTableLayout
        table={
          <MaterialsTable
            location={ location }
          />
        }
        filterPanel={
          <MaterialFilterPanel/> 
        }
      />
    </MainLayout>
  );
});

const MaterialsLayoutDecorated = UnapprovedMaterialsDecorator(MaterialsLayout);

export { MaterialsLayoutDecorated as MaterialsLayout };