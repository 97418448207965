import * as React from 'react';

import { GBMaterialType, DataSourceItem } from 'src/types';

import { formatDuration } from 'src/utils/DateUtils';
import Typography from '@material-ui/core/Typography';
import { WithTranslation } from 'react-i18next';
import { ActionButton } from 'src/components';
import { MaterialEditActions } from 'src/actions/MaterialActions';


export interface LastSyncedAtLayoutProps extends Pick<WithTranslation, 't'> {
  activeItem: DataSourceItem;
  material: GBMaterialType;
}

export const LastSyncedAtLayout: React.FC<LastSyncedAtLayoutProps> = (
  { activeItem, material, t }
) => {
  return (
    <>
      <Typography style={{ color: 'gray' }}>
        { formatDuration(activeItem.syncedAt, t) }
      </Typography>
      <ActionButton
        variant="outlined"
        size="small"
        style={{
          marginTop: '5px',
        }}
        action={ MaterialEditActions.SYNC }
        material={ material }
      />
    </>
  );
};
