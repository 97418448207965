import * as React from 'react';

import { ProvidersDataDecorator, ProvidersDataDecoratorChildProps } from '../decorators/ProvidersDataDecorator';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { BrowserSettings } from 'src/constants';
import { useRouter } from 'src/effects';


type RouteProps = { providerId: string; };

const ProviderSelector = React.memo<ProvidersDataDecoratorChildProps>(props => {
  const { match, history } = useRouter<RouteProps>();

  const handleChange = (event: any) => {
    localStorage.setItem(BrowserSettings.LAST_PROVIDER_ID, event.target.value);
    history.push(`/providers/${event.target.value}/categories`);
  };

  const { providers } = props.data;
  const { providerId } = match.params;

  return (
    <Select
      value={ providerId }
      onChange={ handleChange }
      displayEmpty
      name="provider"
      style={{ margin: '5px 10px' }}
    >
      { providers!
          .map(provider => (
            <MenuItem 
              key={ provider.id }
              value={ provider.code }
            >
              { provider.name }
            </MenuItem>
          ))
      }
    </Select>
  );
});

const ProviderSelectorWithData = ProvidersDataDecorator<{}>(ProviderSelector);

export { ProviderSelectorWithData as ProviderSelector };
