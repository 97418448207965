import gql from 'graphql-tag';

export const ProviderFragments = () => ({
  basic: gql`
    fragment ProviderBasic on Provider {
      id
      code
      name
      iconUrl
      features
    }
  `,
});

export const ProviderMaterialCategoryFragments = () => ({
  basic: gql`
    fragment ProviderMaterialCategoryBasic on ProviderMaterialCategory {
      id
      name
    }
  `,
  parent: gql`
    fragment ProviderMaterialCategoryParent on ProviderMaterialCategory {
      parent {
        id
        name
      }
    }
  `,
});

export const ProviderMaterialFragments = () => ({
  basic: gql`
    fragment ProviderMaterialBasic on ProviderMaterial {
      id
      name
      image {
        name
        urlSmall
        urlLarge
      }
    }
  `,
  manufacturer: gql`
    fragment ProviderMaterialManufacturer on ProviderMaterial {
      manufacturer {
        id
        name
      }
    }
  `,
  attachments: gql`
    fragment ProviderMaterialAttachments on ProviderMaterial {
      attachments {
        name
        url
      }
    }
  `,
  extra: gql`
    fragment ProviderMaterialExtra on ProviderMaterial {
      description
      gtin
      sku
    }
  `,
  materialCategory: gql`
    fragment ProviderMaterialCategory on ProviderMaterial {
      category {
        ...ProviderMaterialCategoryBasic
      },
    }
    ${ProviderMaterialCategoryFragments().basic}
  `,
});
