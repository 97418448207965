import * as React from 'react';

import { RouterModalProps } from 'src/types';
import { createEditorDialog } from 'src/components/editor';
import { Trans } from 'react-i18next';
import { MaterialMoveEditorForm, MaterialMoveFieldTypes } from './MaterialMoveEditorForm';
import { parseQuery } from 'src/utils/QueryUtils';
import { Actions } from 'src/service/materials';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import { SnackbarContext } from 'src/decorators/NotificationDecorator';
import { resetCache } from 'src/utils/MutationUtils';


type MaterialMoveDialogProps = RouterModalProps;

const EditorDialog = createEditorDialog<
  MaterialMoveFieldTypes, 
  {}
>('materialMoveEditor', () => { return {}; });

class MaterialMoveDialog extends React.PureComponent<WithApolloClient<MaterialMoveDialogProps>> {
  static contextType = SnackbarContext;
  context!: SnackbarContext;

  handleSubmit = async (data: MaterialMoveFieldTypes) => {
    const { location, client } = this.props;
    const { materialIds } = parseQuery(location);

    if (!!materialIds && Array.isArray(materialIds)) {
      for (let id of materialIds) {
        try {
          await Actions.sendUpdateMaterial(
            parseInt(id),
            { 
              categoryId: data.categoryId,
            },
            client!
          );
        } catch (error) {
          this.context({
            text: <Trans i18nKey="actions.notifications.materialSaveFailed" values={{ error }}/>,
          });
        }
      }
    }

    resetCache(client!);
    return true;
  }

  render() {
    return (
      <EditorDialog
        title={ <Trans i18nKey={ 'actions.moveMaterials' }/> }
        fieldsComponent={ MaterialMoveEditorForm }
        onSubmit={ this.handleSubmit }
        saveCaption="move"
        { ...this.props }
      />
    );
  }
}

const MaterialMoveDialogDecorated = withApollo<MaterialMoveDialogProps>(MaterialMoveDialog);

export { MaterialMoveDialogDecorated as MaterialMoveDialog };
