import { CreateHandler, Option } from './types';
import { EventOrValueHandler, WrappedFieldProps } from 'redux-form';

// UTILS START
const NEW_OPTION_PREFIX = 'new_option_id_';


// newOptionCreator prop for cratable select fields
export const NewOptionCreator: any = (inputValue: string, formattedValue: React.ReactNode) => {
  return {
    value: NEW_OPTION_PREFIX + Math.random(),
    label: formattedValue,
  };
};

export const isNewValue = <ValueT extends Function>(value?: ValueT | CreateHandler<ValueT>) => {
  return !!value && value.toString().indexOf(NEW_OPTION_PREFIX) === 0;
};

// Normalize creatable values for Redux form
export const ChangeHandler = <ValueT extends Function>(
  func: EventOrValueHandler<React.ChangeEvent<any>>, 
  sendCreate: (label: string) => Promise<any>
) => {
  const toFormValue = (value: Option<ValueT>): any => {
    if (value && isNewValue(value.value)) {
      const ret = ((...args: any[]) => {
        // Redux form will call this function to get the value
        if (!!args.length) {
          // Return the original function as it is
          return ret;
        }

        return sendCreate(value.label);
      }) as CreateHandler<ValueT>;
      ret.toString = () => `${value.value}`;
      ret.getValue = () => value;
      return ret;
    } else if (Array.isArray(value)) {
      return value.map(toFormValue);
    } else if (value) {
      return value.value;
    } else {
      return value;
    }
  };

  return (value: Option<ValueT>) => {
    const parsed = toFormValue(value);
    //console.log('[CHANGE] FORM CUSTOM CHANGE', parsed, value);
    setTimeout(() => func(parsed));
  };
};

export const SkipFetchCreatable = (input: WrappedFieldProps['input']) => {
  return !input.value || typeof input.value === 'function';
};
