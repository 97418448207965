import gql from 'graphql-tag';

import { 
  Fragments as OrganizationFragmentsRaw 
} from 'src/service/organizations/organizationFragments';

const OrganizationFragments = OrganizationFragmentsRaw();

export const Fragments = () => ({
  basic: gql`
    fragment UserBasic on User {
      name
      email
    }
  `,
  permissions: gql`
    fragment UserPermissions on User {
      globalPermissions
      organizationPermissions {
        organization {
          ...OrganizationBasic
        }
        permissions
      }
    }
    ${OrganizationFragments.basic}
  `,
});