import * as React from 'react';

import { graphql } from '@apollo/client/react/hoc';
import { Queries } from 'src/service/languages';

import { DataLoaderDecorator, DataProps } from 'src/decorators/DataLoaderDecorator';


export type LanguageListDecoratorChildProps = 
  DataProps<Queries.LanguageListResponse>;


export const LanguageListDecorator = function <T>(
  Component: React.ComponentType<T & LanguageListDecoratorChildProps>
) {
  const withResults = graphql<T, Queries.LanguageListResponse>(
    Queries.LanguageListQuery
  );

  return withResults(
    DataLoaderDecorator<T, Queries.LanguageListResponse>(Component)
  );
};
