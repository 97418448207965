/* eslint-disable no-fallthrough */
import React from 'react';

import { Typography } from '@material-ui/core';
import { TFunction } from 'i18next';
import { Event, EventActionType, EventEntityType, EventEntityBase } from 'src/types';
import { MaterialVariantTitle } from 'src/components/MaterialVariantTitle';



import CloudDownload from '@material-ui/icons/CloudDownload';
import Autorenew from '@material-ui/icons/Autorenew';

import Add from '@material-ui/icons/Add';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';

import LinkIcon from '@material-ui/icons/Link';
import LinkOff from '@material-ui/icons/LinkOff';

import { stringifyQuery } from 'src/utils/QueryUtils';
import { History, Location } from 'history';
import { Link } from 'react-router-dom';
import { SelectedOrganizationContext } from 'src/decorators/SelectedOrganizationProvider';
import { TableLinkStyle } from 'src/components/table';
import { toReturnInfo } from 'src/utils/BrowserUtils';

export const getActionIcon = (actionType: EventActionType) => {
  switch (actionType) {
    case EventActionType.CREATE: return <Add/>;
    case EventActionType.EDIT: return <Edit/>;
    case EventActionType.DELETE: return <Delete/>;
    case EventActionType.IMPORT: return <CloudDownload/>;
    case EventActionType.SYNC: return <Autorenew/>;
    case EventActionType.LINK: return <LinkIcon/>;
    case EventActionType.UNLINK: return <LinkOff/>;
    default: return <span/>;
  }
};


// tslint:disable:no-switch-case-fall-through
const parseLink = (entity: EventEntityBase): History.LocationDescriptor | null => {
  const { type, id } = entity;
  switch (type) {
    // @ts-ignore: Fallthrough case in switch
    case EventEntityType.MATERIALVARIANT_LOC: {
      const { materialVariantLocalization } = entity;
      if (!!materialVariantLocalization) {
        return {
          pathname: `/materials/${materialVariantLocalization.materialVariant.material.id}`,
          search: stringifyQuery({
            variant: materialVariantLocalization.materialVariant.id,
            variantLanguage: materialVariantLocalization.language.code,
          })
        };
      }
    }
    // @ts-ignore: Fallthrough case in switch
    case EventEntityType.MATERIALVARIANT: {
      const { materialVariant } = entity;
      if (!!materialVariant) {
        return {
          pathname: `/materials/${materialVariant.material.id}`,
          search: stringifyQuery({
            variant: materialVariant.id
          })
        };
      }
    }
    // @ts-ignore: Fallthrough case in switch
    case EventEntityType.MATERIAL: {
      const { material } = entity;
      if (!!material) {
        return `/materials/${id}`;
      }
    }
    // @ts-ignore: Fallthrough case in switch
    case EventEntityType.MANUFACTURER: {
      const { manufacturer } = entity;
      if (!!manufacturer) {
        return `/manufacturers/${id}`;
      }
    }
    // @ts-ignore: Fallthrough case in switch
    case EventEntityType.LABEL: {
      const { label } = entity;
      if (!!label) {
        return `/labels/${id}`;
      }
    }
    // @ts-ignore: Fallthrough case in switch
    case EventEntityType.LABELCATEGORY: {
      const { labelCategory } = entity;
      if (!!labelCategory) {
        return `/label-categories/${id}`;
      }
    }
    // @ts-ignore: Fallthrough case in switch
    case EventEntityType.MATERIALCATEGORY: {
      const { materialCategory } = entity;
      if (!!materialCategory) {
        return `/categories/${id}`;
      }
    }
    default: {
      return null;
    }
  }
};

const formatEntityName = (
  t: TFunction, entity: EventEntityBase, location: Location, organizationContext: SelectedOrganizationContext
) => {
  const materialVariant = entity.materialVariantLocalization ? 
    entity.materialVariantLocalization.materialVariant : entity.materialVariant;

  if (!!materialVariant) {
    return (
      <MaterialVariantTitle 
        variant={ materialVariant }
        t={ t }
      />
    );
  }

  return entity.name;
};

type EntityTypeMapper = { [key in EventEntityType]: string; };

export const formatEntityType = (
  t: TFunction, entity: EventEntityBase, entityTypeMappings: EntityTypeMapper
) => {
  let ret = t(entityTypeMappings[entity.type as EventEntityType]) || entity.type;
  if (entity.materialVariantLocalization) {
    ret += ` (${entity.materialVariantLocalization.language.name})`;
  }

  return ret;
};

export const toLink = (
  entity: EventEntityBase, content: React.ReactNode, currentLocation: Location, pageTitle: string
) => {
  const newLocation = parseLink(entity);
  if (!!newLocation) {
    const state = {
      returnInfo: toReturnInfo(currentLocation, pageTitle),
    };

    return (
      <div
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        <Link
          to={{
            ...(typeof newLocation === 'string' ? { pathname: newLocation } : newLocation),
            state
          }}
          style={{ 
            ...TableLinkStyle,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          { content }
        </Link>
      </div>
    );
  }

  // No entity (or it has been deleted)
  // Add line wrapping
  return (
    <div>
      { content }
    </div>
  );
};

export const formatEntity = (
  t: TFunction, 
  event: Event, 
  entityTypeMappings: EntityTypeMapper,
  location: Location,
  pageTitle: string,
  organizationContext: SelectedOrganizationContext
) => {
  const name = formatEntityName(t, event.entity, location, organizationContext);
  const type = formatEntityType(t, event.entity, entityTypeMappings);
  if (!name) {
    return toLink(event.entity, type, location, pageTitle);
  }

  return (
    <>
      { toLink(event.entity, name, location, pageTitle) }
      { !!event.entity.groups && (
        <>
          { event.entity.groups.map(group => {
            if (group.type === EventEntityType.DATASOURCE) {
              return null;
            }

            const groupName = formatEntityName(t, group, location, organizationContext);
            return (
              <React.Fragment
                key={ group.id }
              >
                { toLink(group, groupName, location, pageTitle) }
              </React.Fragment>
            );
          }) }
        </>
      ) }
      <Typography color="textSecondary">
        { type }
      </Typography>
    </>
  );
};

export const formatUser = (event: Event) => {
  return (
    <div>
      { event.user.name }
      <Typography color="textSecondary">
        {event.user.email}
      </Typography>
    </div>
  );
};

export const formatActionTitle = (t: TFunction, event: Event, fieldMappings?: { [key in string]: string }) => {
  const style: React.CSSProperties | undefined = event.event.action === EventActionType.SYNC ? undefined : {
    fontWeight: 'bold',
  };

  let ret = t(`eventTypes.${event.event.action}`);
  if (event.entity.updatedFields) {
    ret += ' (';
    ret += event.entity.updatedFields.reduce(
      (reduced, field) => {
        if (!!reduced.length) {
          reduced += ', ';
        }

        const translationKey = fieldMappings && fieldMappings[field] ? fieldMappings[field] : field;
        reduced += t(translationKey);
        return reduced;
      },
      ''
    );

    ret += ')';
  }

  return (
    <span style={ style }>
      { ret }
    </span>
  );
};
