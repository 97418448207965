import * as React from 'react';

import { ProviderMaterialImportForm, mapFields } from './ProviderMaterialImportForm';

import { 
  ProviderImportDataDecorator, ProviderImportDataDecoratorChildProps, ProviderImportFieldTypes,
} from '../../decorators/ProviderImportDataDecorator';

import { RouterModalProps } from 'src/types';
import { createEditorDialog } from 'src/components/editor';
import { Trans } from 'react-i18next';


type MaterialEditorDialogProps = 
  ProviderImportDataDecoratorChildProps & 
  RouterModalProps;

const EditorDialog = createEditorDialog<
  ProviderImportFieldTypes, 
  ProviderImportDataDecoratorChildProps
>('providerImportDialog', mapFields);

class ProviderMaterialImportDialog extends React.PureComponent<MaterialEditorDialogProps> {
  render() {
    return (
      <EditorDialog
        title={ <Trans i18nKey="importMaterial"/> }
        fieldsComponent={ ProviderMaterialImportForm }
        { ...this.props }
      />
    );
  }
}

const ProviderMaterialImportDialogWithData = ProviderImportDataDecorator(ProviderMaterialImportDialog);

export { ProviderMaterialImportDialogWithData as ProviderMaterialImportDialog };
