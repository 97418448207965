import * as React from 'react';

import { ListItemIcon, MenuItem } from '@material-ui/core';
import { Trans } from 'react-i18next';
import { Permission } from 'src/constants';
import { MenuItemProps } from '@material-ui/core/MenuItem';
import Auth from 'src/service/Auth';


export interface DropdownMenuItemProps extends MenuItemProps {
  access?: Permission | ((auth: Auth) => boolean);
  icon: React.ReactElement<any>;
  captionKey: string;
  //title: React.ReactNode;
  //description?: React.ReactNode;
}

export const DropdownMenuItem: React.FC<DropdownMenuItemProps> = (
  { access, button, icon, captionKey, ...other }
) => (
  <MenuItem { ...other }>
    <ListItemIcon>
      { icon }
    </ListItemIcon>
    <Trans i18nKey={ captionKey }/>
  </MenuItem>
);
