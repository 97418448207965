import { 
  CreateLabelResponse, UpdateLabelResponse, DeleteLabelResponse, 
  UpdateLabelMutation, DeleteLabelMutation, CreateLabelMutation
} from './labelMutations';
import { ApolloClient } from '@apollo/client';
import { createMutation, updateMutation, deleteMutation, resetCache } from 'src/utils/MutationUtils';
import { LabelInput, GBLabelIdType, LabelVariables } from 'src/types';


// Create a new label
export const sendCreateLabel = async (
  input: LabelInput, 
  languageCode: string,
  client: ApolloClient<any>,
  resetStore: boolean = true
) => {
  const response = await createMutation<CreateLabelResponse, LabelVariables> (
    { input, languageCode }, CreateLabelMutation, client
  );

  if (resetStore) {
    resetCache(client);
  }

  return response.createLabel;
};

// Update an existing label
export const sendUpdateLabel = async (
  id: GBLabelIdType, 
  input: RecursivePartial<LabelInput>, 
  languageCode: string,
  client: ApolloClient<any>
) => {
  const response = await updateMutation<UpdateLabelResponse, LabelVariables> (
    id, { input, languageCode }, UpdateLabelMutation, client
  );

  resetCache(client);
  return response.updateLabel;
};

// Delete label
export const sendDeleteLabel = async (
  id: number,  
  client: ApolloClient<any>,
  onDeletedCallback: (response: DeleteLabelResponse) => void
) => {
  const response = await deleteMutation<DeleteLabelResponse>(
    id, DeleteLabelMutation, client, onDeletedCallback
  );

  resetCache(client);
  return response.deleteLabel;
};