import * as React from 'react';

import { 
  OperationVariables, useApolloClient, ApolloClient, ApolloError
} from '@apollo/client';
import { 
  DataProps as ApolloDataProps, ChildProps
} from '@apollo/client/react/hoc';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Message } from 'src/components/Message';
import { Trans } from 'react-i18next';


export interface DataProps<TData = any, TVariables = OperationVariables> 
  extends ApolloDataProps<TData, TVariables> /*, MutateProps<TData, TVariables>*/ {

  client: ApolloClient<any>;
}

export type ErrorFormatter = (error: ApolloError) => React.ReactElement;

export interface DataLoaderDecoratorProps {
  formatError?: ErrorFormatter;
}

export enum ErrorPolicy {
  NONE = 'none',
  IGNORE = 'ignore',
  ALL = 'all',
}

export const formatGraphqlError = (error: Error) => {
  return error.message.replace('GraphQL error:', '').trim();
};

export const getApolloErrorMessage = (error: ApolloError, formatError?: ErrorFormatter): React.ReactElement => {
  if (!!formatError) {
    // Custom error component
    const formatted = formatError(error);
    if (!!formatted) {
      return formatted;
    }
  }

  return (
    <Message
      title={ <Trans i18nKey="failedToFetch"/> }
      // https://github.com/apollographql/apollo-client/issues/1812
      description={ formatGraphqlError(error) }
    />
  );
};

export function DataLoaderDecorator<TProps, TData>(
  Component: React.ComponentType<TProps & DataProps<TData>>,
  errorPolicy: ErrorPolicy = ErrorPolicy.NONE
) {
  const Decorated: React.FC<
    DataLoaderDecoratorProps & ChildProps<TProps, TData>
  > = (
    props
  ) => {
    const client = useApolloClient();
    const { data, formatError } = props;

    if (!!data) { // Data does not exist if 'skip' option was used for all queries
      const { loading, error } = data;

      if (loading) {
        return <CircularProgress/>;
      }

      if (!!error && (
        errorPolicy === ErrorPolicy.NONE || !!error.networkError) // always skip render in case of network errors
      ) {
        return getApolloErrorMessage(error, formatError);
      }
    }

    return (
      <>
        { (errorPolicy !== ErrorPolicy.IGNORE && !!data && !!data.error) && 
          getApolloErrorMessage(data.error, formatError) 
        }
        <Component
          { ...props }
          data={ data! }
          client={ client }
        />
      </>
    );
  };

  return Decorated;
}
