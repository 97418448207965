import React, { useState } from 'react';

import { ActionDecorator, ActionDecoratorChildProps } from 'src/decorators/ActionDecorator';
import { Button, CircularProgress } from '@material-ui/core';
import { Trans } from 'react-i18next';
import { ButtonProps } from '@material-ui/core/Button';
import { Action } from 'src/types';
import { showAction } from 'src/utils/ActionUtils';


export interface ActionButtonProps<PropsT> extends Omit<ButtonProps, 'action'> {
  action: Action<PropsT>;
}


const ActionButton = <PropsT extends object> (
  props: Omit<PropsT, keyof ActionButtonProps<PropsT> | keyof ActionDecoratorChildProps<PropsT>> & 
          ActionButtonProps<PropsT> & ActionDecoratorChildProps<PropsT>
) => {
  const [ loading, setLoading ] = useState(false);

  const { 
    action, onClickAction, auth, 
    snackbar, staticContext, ...other 
  } = props as ActionButtonProps<PropsT> & ActionDecoratorChildProps<PropsT>;

  if (!showAction(action, auth, props as any)) {
    return null;
  }

  if (loading) {
    return <CircularProgress/>;
  }

  return (
    <Button
      onClick={ async () => {
        setLoading(true);
        await onClickAction(action);
        setLoading(false);
      } }
      { ...other }
    >
      <action.icon/>
      <Trans i18nKey={ action.title }/>
    </Button>
  );
};

const Decorated = ActionDecorator<any>(ActionButton as any);

export { Decorated as ActionButton };
