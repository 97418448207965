import * as React from 'react';

import { ActionPanelProps } from 'src/components/browser-layout';

import { ProviderSelector } from '../../../components/ProviderSelector';
import { ProviderMaterialCategoryIdType } from 'src/types';

const ProviderActionPanel: React.FC<ActionPanelProps<ProviderMaterialCategoryIdType>> = (props) => (
  <ProviderSelector/>
);

export { ProviderActionPanel };