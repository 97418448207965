import gql from 'graphql-tag';

export const Fragments = () => ({
  basic: gql`
    fragment LabelCategoryBasic on LabelCategory {
      id
      systemCategory
      name
      localization {
        id
        name
        language {
          code
        }
      }
    }
  `,
});