import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { EventTable } from './event-table';
import { RouteComponentProps } from 'react-router';
import { EventFilterPanel } from './event-table/EventFilterPanel';
import { FilterableTableLayout } from 'src/components/table';


export interface EventsViewProps {

}

const EventsView: React.FC<EventsViewProps & RouteComponentProps> = ((
  { location }
) => {
  const { t } = useTranslation();
  return (
    <FilterableTableLayout
      table={ 
        <EventTable
          location={ location }
          pageTitle={ t('events') }
          t={ t }
        />
      }
      filterPanel={
        <EventFilterPanel/> 
      }
    />
  );
});

export { EventsView };