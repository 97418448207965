import { ApolloClient, from, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';

import { createUploadLink } from 'apollo-upload-client';
import { i18n } from 'i18next';

const dataIdFromObject = (result: any): string | undefined => {
  if (result.__typename) {
    if (result.id !== undefined) {
      if (result.__typename === 'EventEntity') {
        return undefined;
      }

      return `${result.__typename}:${result.id}`;
    }
  }
  return undefined;
};

export const errorLink = onError(({ graphQLErrors, networkError, response }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      );
    });
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError} (response: ${JSON.stringify(networkError, null, 2)}`);
  }
});

export const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_MATERIAL_API_URL,
});

export const API = (intl: i18n) => {  
  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('access_token');
    if (!token) {
      console.warn('Trying to access API without a token');
    }
  
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
        'Accept-Language': intl.language,
      }
    };
  });

  return new ApolloClient({
    // By default, this client will send queries to the
    //  `/graphql` endpoint on the same host
    // Pass the configuration option { uri: YOUR_GRAPHQL_API_URL } to the `HttpLink` to connect
    // to a different host
    link: from([
      errorLink,
      authLink, 
      uploadLink as any, // TODO: remove when newer type definitions are available
    ]),
    cache: new InMemoryCache({
      dataIdFromObject
    }),
    //connectToDevTools: process.env.NODE_ENV === 'develop',
    connectToDevTools: true,
    /*defaultOptions: { // not working, https://github.com/apollographql/apollo-client/issues/2555
      query: {
        errorPolicy: 'all',
      }
    }*/
  });
};
