import React, { useState } from 'react';

import { 
  AccordionSummary, 
  Typography, TextField, IconButton, Accordion, AccordionDetails 
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import { TFunction } from 'i18next';


interface FilterableTextSectionProps {
  title: string; // must be string
  handleChange: (text: string) => void;
  currentValue: string;
  t: TFunction;
}

const FilterableTextSection = (
  { title, handleChange, currentValue, t }: FilterableTextSectionProps
) => {
  const [ inputValue, setValue ] = useState(currentValue);
  return (
    <Accordion 
      style={{ 
        minWidth: '250px'
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>
          { !!currentValue ? (
            <b>
              { title }
            </b>
          ) : title }
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ padding: '0px 24px 8px' }}>
          <TextField 
            onChange={ evt => {
              setValue(evt.target.value);
            } }
            onKeyDown={ evt => {
              if (evt.keyCode === 13) {
                handleChange(inputValue);
              }
            } }
            value={ inputValue }
            placeholder={ t('prompts.filter') }
          />
          <IconButton
            onClick={ () => {
              handleChange(inputValue);
            }}
          >
            <SearchIcon/>
          </IconButton>
      </AccordionDetails>
    </Accordion>
  );
};

export { FilterableTextSection };