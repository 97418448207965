import * as React from 'react';

import { AccordionSummary, Typography, TextField, Accordion, AccordionDetails } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TFunction } from 'i18next';


interface FilterableDateRangeSectionProps {
  title: string; // must be string
  handleChange: (text: string, field: 'start' | 'end') => void;
  currentValue: {
    start: string;
    end: string;
  };
  t: TFunction;
}

const FilterableDateRangeSection = (
  { title, handleChange, currentValue, t }: FilterableDateRangeSectionProps
) => {
  return (
    <Accordion 
      style={{ 
        minWidth: '250px'
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>
          { !!currentValue.start || !!currentValue.end ? (
            <b>
              { title }
            </b>
          ) : title }
        </Typography>
      </AccordionSummary>
      <AccordionDetails 
        style={{ 
          padding: '0px 24px 8px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <TextField 
          label={ t('startDate') }
          type="date"
          onChange={ evt => {
            handleChange(evt.target.value, 'start');
          } }
          InputLabelProps={{ shrink: true, }}
          inputProps={ !!currentValue.end ? {
            max: currentValue.end,
          } : undefined }
          value={ currentValue.start }
        />
        <TextField 
          label={ t('endDate') }
          type="date"
          InputLabelProps={{ shrink: true, }}
          inputProps={ !!currentValue.start ? {
            min: currentValue.start,
          } : undefined }
          onChange={ evt => {
            handleChange(evt.target.value, 'end');
          } }
          value={ currentValue.end }
        />
      </AccordionDetails>
    </Accordion>
  );
};

export { FilterableDateRangeSection };