import * as React from 'react';

import { 
  MaterialVariantEditorForm, mapFields
} from './MaterialVariantEditorForm';

import { 
  MaterialVariantEditorDataDecorator,
  MaterialVariantEditorDataDecoratorChildProps, MaterialVariantActionTypes 
} from '../../decorators/MaterialVariantEditorDataDecorator';

import { RouterModalProps, MaterialVariantFieldTypes } from 'src/types';
import { createEditorDialog } from 'src/components/editor';
import { Trans } from 'react-i18next';


type MaterialEditorDialogProps = MaterialVariantEditorDataDecoratorChildProps & RouterModalProps;

const EditorDialog = createEditorDialog<
  MaterialVariantFieldTypes,
  MaterialVariantEditorDataDecoratorChildProps
>('materialEditor', mapFields);

const getTranslationKey = (type: MaterialVariantActionTypes) => {
  switch (type) {
    case MaterialVariantActionTypes.COPY: 
    case MaterialVariantActionTypes.CREATE: return 'actions.createMaterialVariant';
    case MaterialVariantActionTypes.EDIT: return 'actions.editMaterialVariant';
    default:
  }

  return '';
};

class MaterialVariantEditorDialog extends React.PureComponent<MaterialEditorDialogProps> {
  render() {
    const { materialVariant, actionType, ...other } = this.props;
    return (
      <EditorDialog
        title={ 
          <Trans i18nKey={ getTranslationKey(actionType) }/> 
        }
        materialVariant={ materialVariant }
        fieldsComponent={ MaterialVariantEditorForm as any } // TODO
        actionType={ actionType }
        { ...other }
      />
    );
  }
}

const MaterialVariantEditorDialogDecorated = MaterialVariantEditorDataDecorator(MaterialVariantEditorDialog);

export { MaterialVariantEditorDialogDecorated as MaterialVariantEditorDialog };
