import * as React from 'react';

import Edit from '@material-ui/icons/Edit';

import { Trans } from 'react-i18next';
import { ActionDropdownMenu } from 'src/components/ActionDropdownMenu';
import { GBMaterialType, MaterialStatus } from 'src/types';
import { Typography } from '@material-ui/core';
import { MaterialStatusActions } from 'src/actions/MaterialStatusActions';


interface MaterialStatusLayoutProps {
  material: GBMaterialType;
}



const getMaterialStatusStyle = (material: GBMaterialType): React.CSSProperties => {
  switch (material.status) {
    case MaterialStatus.NEW: return { fontWeight: 'bold'};
    case MaterialStatus.ARCHIVED: return { color: 'gray'};
    case MaterialStatus.ACTIVE:
    default: return { color: 'gray'};
  }
};

const MaterialStatusLayout: React.FC<MaterialStatusLayoutProps> = (
  { material }
) => (
  <div 
    style={{
      display: 'flex',
      alignItems: 'center',
    }}
  >
    <Typography style={ getMaterialStatusStyle(material) }>
      <Trans i18nKey={ `materialStatus.${material.status.toLowerCase()}` }/>
    </Typography>
    <ActionDropdownMenu
      caption={ <Edit/> }
      actions={ MaterialStatusActions }
      material={ material }
      buttonProps={{
        size: 'small',
        style: {
          padding: '0px',
          marginLeft: '5px',
          minHeight: '24px',
        }
      }}
    />
  </div>
);

export { MaterialStatusLayout };