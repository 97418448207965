import * as React from 'react';

import { Chip, Avatar } from '@material-ui/core';
import Done from '@material-ui/icons/Done';


interface SuggestionChipProps {
  formattedValue?: React.ReactNode;
  textValue: string;
  onClick: () => void;
}

export const SuggestionChip: React.FC<SuggestionChipProps> = (
  { formattedValue, textValue, onClick }
) => {
  return (
    <Chip
      label={ !!formattedValue ? formattedValue : textValue }
      onClick={ onClick }
      avatar={ !textValue.length ? undefined : <Avatar>{ textValue[0].toUpperCase() }</Avatar> }
      onDelete={ onClick }
      deleteIcon={ <Done/> }
      component={ 'a' as 'div' }
      style={{
        //marginTop: '5px',
        margin: '5px',
        width: 'min-content',
      }}
      clickable
    />
  );
};
