import * as React from 'react';

import { graphql } from '@apollo/client/react/hoc';
import { Location } from 'history';

import { 
  BrowserLayout, MaterialBrowserPropsType
} from 'src/components/browser-layout';

import { DataLoaderDecorator } from 'src/decorators/DataLoaderDecorator';
import { Queries } from 'src/service/manufacturers';
import { ManufacturerMaterialTable } from './ManufacturerMaterialTable';
import { MaterialFilterPanel } from 'src/components/browser-layout';
import { Trans } from 'react-i18next';
import { GBManufacturerIdType } from 'src/types';


export interface ManufacturerPagePropsType extends MaterialBrowserPropsType<GBManufacturerIdType> {
  manufacturerId?: number;
  location: Location;
}


const withManufacturer = graphql<ManufacturerPagePropsType, Queries.ManufacturerCategoriesResponse>(
  Queries.ManufacturerCategoriesQuery, {
    options: ({ manufacturerId }) => {
      return {
        variables: { 
          manufacturerId,
        }
      };
    },
  }
);

const ManufacturerPageLayout = withManufacturer(DataLoaderDecorator(({ data, location, ...other }) => {
  const { materials, ...manufacturer } = data.manufacturer!;
  return (
    <BrowserLayout<GBManufacturerIdType>
      { ...other }
      category={ manufacturer }
      childCategories={ [] }
      subTitle={ <Trans i18nKey="manufacturer"/> }
      materialTable={ 
        <ManufacturerMaterialTable
          manufacturer={ manufacturer }
          location={ location }
        /> 
      }
      filterPanel={ 
        <MaterialFilterPanel
          labelCounts={ manufacturer.labelCounts }
          materialCategoryCounts={ manufacturer.materialCategoryCounts }
        /> 
      }
      totalMaterialCount={ materials.total }
    />
  );
}));

export { ManufacturerPageLayout };