import * as React from 'react';

import { ViewMaterialType } from 'src/types';


import {
  Table as TableBase,
} from '@devexpress/dx-react-grid';

import {
  Table,
} from '@devexpress/dx-react-grid-material-ui';

import { Link, RouteComponentProps } from 'react-router-dom';
import { MaterialImage } from 'src/components/MaterialImage';
import { useRouter } from 'src/effects';
import { toReturnInfo } from 'src/utils/BrowserUtils';
import { formatDateTime } from 'src/utils/DateUtils';
import { useTranslation } from 'react-i18next';


export interface MaterialTableCellProps extends TableBase.DataCellProps {
  categorySectionUrl: string;
  materialSectionUrl: string;
  manufacturerSectionUrl?: string;
  pageTitle?: string;
}


const TextStyle = {
  color: 'inherit',
  textDecoration: 'none',
};

const parseMaterialTarget = (
  { materialSectionUrl, pageTitle, tableRow }: MaterialTableCellProps, 
  { location }: RouteComponentProps
) => ({ 
  pathname: `${materialSectionUrl}/${tableRow.row.id}`,
  state: {
    returnInfo: toReturnInfo(location, pageTitle)
  }
});

const MaterialTableCell = React.memo<MaterialTableCellProps>(props => {
  const { 
    categorySectionUrl, manufacturerSectionUrl, pageTitle, materialSectionUrl, ...cellProps
  } = props;

  const router = useRouter();
  const { t } = useTranslation();
  const { tableRow, column } = cellProps;
  const material: ViewMaterialType = tableRow.row;

  if (column.name === 'image') {
    return (
      <Table.Cell
        { ...cellProps }
        value={ (
          <Link to={ parseMaterialTarget(props, router) }>
            <MaterialImage
              image={ material.image }
              maxSize={ 100 }
            />
          </Link>
        ) }
      />
    );
  }

  if (column.name === 'createdAt') {
    return (
      <Table.Cell 
        { ...cellProps } 
        value={ (
          <Link 
            to={ parseMaterialTarget(props, router) }
            style={ TextStyle }
          >
            { formatDateTime(cellProps.value, t) }
          </Link>
        ) }
      />
    );
  }

  if (column.name === 'category') {
    return (
      <Table.Cell
        { ...cellProps }
        value={ (
          <Link 
            to={ `${categorySectionUrl}/${material.category.id}` }
            style={ TextStyle }
          >
            { material.category.name }
          </Link>
        ) }
      />
    );
  }

  if (column.name === 'manufacturer') {
    return (
      <Table.Cell
        { ...cellProps }
        value={ !!manufacturerSectionUrl ? (
          <Link 
            to={ `${manufacturerSectionUrl}/${material.manufacturer.id}` }
            style={ TextStyle }
          >
            { material.manufacturer.name }
          </Link>
        ) : material.manufacturer.name }
      />
    );
  }

  return (
    <Table.Cell 
      { ...cellProps } 
      value={ (
        <Link 
          to={ parseMaterialTarget(props, router) }
          style={ TextStyle }
        >
          { cellProps.value }
        </Link>
      ) }
    />
  );
});

export { MaterialTableCell };
