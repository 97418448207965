import * as React from 'react';

import { GBMaterialType, GBLabelType, GBLabelCategoryType } from 'src/types';

import { MaterialLabelListItem } from 'src/modules/materials/components/label-layout/MaterialLabelListItem';

import { MaterialProductInfoList } from 'src/components/material-layout';
import { Trans } from 'react-i18next';
import { MaterialLabelCreateAction } from 'src/actions/MaterialLabelActions';
import { ActionButton } from 'src/components';
import { AuthProps, withAuth } from 'src/decorators/AuthDecorator';
import { Permission } from 'src/constants';
import { hasMaterialEditAccess } from 'src/utils/AccessUtils';


interface MaterialLabelListProps {
  material: GBMaterialType;
}

interface GroupedLabelsType {
  category: GBLabelCategoryType;
  labels: GBLabelType[];
}

const groupLabels = (reduced: GroupedLabelsType[], label: GBLabelType) => {
  const group = reduced.find(item => item.category.id === label.category.id);
  if (!group) {
    reduced.push({
      category: label.category,
      labels: [ label ],
    });
  } else {
    group.labels.push(label);
  }

  return reduced;
};

const MaterialLabelList: React.FC<MaterialLabelListProps & AuthProps> = ({ material, auth }) => {
  if (!material.labels.length && 
    !hasMaterialEditAccess(material, auth, Permission.MATERIALS_EDIT, Permission.MATERIAL_VARIANTS_EDIT)) {
    return null;
  }

  return (
    <MaterialProductInfoList title={ <Trans i18nKey="labels"/> }>
      { !!material.labels.length && material.labels
          .reduce(groupLabels, [])
          .map(group => (
            <MaterialLabelListItem
              key={ group.category.id }
              labels={ group.labels }
              category={ group.category }
              material={ material }
            />
          )) 
      }
      <ActionButton
        action={ MaterialLabelCreateAction }
        material={ material }
      />
    </MaterialProductInfoList>
  );
};

const Decorated = withAuth(MaterialLabelList);

export { Decorated as MaterialLabelList };
