import React from "react";
import { Tooltip, TypographyProps } from "@material-ui/core";
import TruncatedText from "./TruncatedText";

const limitStringLength = (
  str: string,
  maxLength: number,
  cutIn: number
): string => {
  return str.length > maxLength ? str.substring(0, cutIn) + ".." : str;
};

interface LimitedStringProps extends TypographyProps {
  text: string;
  maxLength: number;
  cutIn: number;
  showAll: boolean;
}

const LimitedString = ({
  text,
  maxLength,
  cutIn,
  showAll,
  ...other
}: LimitedStringProps) => {
  return (
    <>
      {showAll ? (
        <TruncatedText
          value={text}
          truncateLength={maxLength}
          style={{
            flex: "1 1 100%",
            maxHeight: "100%",
            overflow: "auto",
            paddingRight: "3px",
            lineBreak: "anywhere",
          }}
        />
      ) : (
        <Tooltip disableHoverListener={!(text.length > maxLength)} title={text}>
          <span {...other}>{limitStringLength(text, maxLength, cutIn)}</span>
        </Tooltip>
      )}
    </>
  );
};

export default LimitedString;
