import * as React from 'react';

import Edit from '@material-ui/icons/Edit';


import { MaterialVariant, GBMaterialType } from 'src/types';
import { MaterialImage, ActionDropdownMenu } from 'src/components';
import { Paper } from '@material-ui/core';
import { LayoutTitle } from 'src/components/main-layout/LayoutTitle';
import { parseLocalizableVariantProperties } from 'src/utils/MaterialVariantUtils';
import { MaterialVariantLanguageSelector } from './MaterialVariantLanguageSelector';
import { MaterialVariantEditActions } from 'src/actions/MaterialVariantActions';
import { MaterialVariantTitle } from 'src/components/MaterialVariantTitle';
import { WithTranslation } from 'react-i18next';


type MaterialVariantLayoutProps = {
  variant: MaterialVariant;
  material: GBMaterialType;
};
class MaterialVariantLayout extends React.Component<MaterialVariantLayoutProps & Pick<WithTranslation, 't'>> {
  render() {
    const { variant, material, t } = this.props;
    const localization = parseLocalizableVariantProperties(variant, t);
    return (
      <Paper 
        id="material-variant"
        style={{
          margin: '15px 10px',
          padding: '5px',
        }}
        elevation={ 5 }
      >
          <LayoutTitle 
            subTitle={ (
              <MaterialVariantTitle
                variant={ variant }
                t={ t }
              />
            ) }
            variant="h6"
            rightContent={(
              <>
                <ActionDropdownMenu
                  caption={ <Edit/> }
                  variant={ variant }
                  material={ material }
                  actions={ MaterialVariantEditActions }
                />
                <MaterialVariantLanguageSelector
                  variant={ variant }
                />
              </>
            )}
          >
            { localization.name }
            {/* !!variant.localization ? variant.localization.language : undefined */}
          </LayoutTitle>
          <div 
            style={{ 
              margin: '5px', 
              display: 'flex',
            }}
          >
            <MaterialImage
              className="image"
              image={ variant.image }
              maxSize={ 200 }
              style={{
                flex: '0 50 100%',
              }}
            />
            { !!localization.description && (
              <div 
                className="description"
                style={{
                  marginLeft: '10px',
                  flex: '0 50 100%',
                }}
              >
                { localization.description }
              </div>
            ) }
          </div>
      </Paper>
    );
  }
}

export { MaterialVariantLayout };
