import { Permission } from 'src/constants';

import { ActionHandlerProps, GBMaterialType, ActionList, MaterialStatus } from 'src/types';
import { hasBaselineVariant } from 'src/utils/MaterialVariantUtils';

import { Actions } from 'src/service/materials';

import Done from '@material-ui/icons/Done';
import Review from '@material-ui/icons/RateReview';
import Archive from '@material-ui/icons/Archive';
import { resetCache } from 'src/utils/MutationUtils';


interface MaterialEditActionProps {
  material: GBMaterialType;
}

type EditProps = ActionHandlerProps & MaterialEditActionProps;


const handleReview = (props: EditProps) => {
  const { material, history } = props;
  history.push({
    pathname: `/materials/${material.id}/approve`,
    search: history.location.search,
  });
};

const setStatus = async (props: EditProps, status: MaterialStatus) => {
  const { material, client } = props;
  await Actions.sendUpdateMaterial(material.id, { status }, client);
  resetCache(client);
};

const handleApprove = async (props: EditProps) => {
  return setStatus(props, MaterialStatus.ACTIVE);
};

const handleArchive = async (props: EditProps) => {
  return setStatus(props, MaterialStatus.ARCHIVED);
};

const handleUnarchive = async (props: EditProps) => {
  return setStatus(props, MaterialStatus.ACTIVE);
};


const isNew = (props: EditProps) => {
  return props.material.status === MaterialStatus.NEW;
};

const isActive = (props: EditProps) => {
  return props.material.status === MaterialStatus.ACTIVE;
};

const isArchived = (props: EditProps) => {
  return props.material.status === MaterialStatus.ARCHIVED;
};

const hasBaseline = (props: EditProps) => {
  return hasBaselineVariant(props.material);
};


const MaterialStatusActions: ActionList<MaterialEditActionProps> = {
  APPROVE: {
    title: 'actions.approve',
    icon: Done,
    filter: [ hasBaseline, isNew ],
    handler: handleApprove,
    access: Permission.MATERIALS_EDIT,
  },
  REVIEW: {
    title: 'actions.review',
    icon: Review,
    filter: isNew,
    handler: handleReview,
    access: Permission.MATERIALS_EDIT,
  },
  ARCHIVE: {
    title: 'actions.archive',
    icon: Archive,
    filter: isActive,
    handler: handleArchive,
    access: Permission.MATERIALS_EDIT,
  },
  UNARCHIVE: {
    title: 'actions.unarchive',
    icon: Archive,
    filter: isArchived,
    handler: handleUnarchive,
    access: Permission.MATERIALS_EDIT,
  }
};

export { MaterialStatusActions };
