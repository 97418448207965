import * as React from 'react';

import { isRootCategory } from 'src/components/browser-layout';

import { ActionPanelProps as BrowserLayoutActionPanelProps } from 'src/components/browser-layout';
import { GBLabelCategoryIdType, GBLabelCategoryType } from 'src/types';
import { ActionDropdownMenu } from 'src/components';
import { LabelCategoryEditActions, LabelCategoryCreateActions } from 'src/actions/LabelCategoryActions';

import Add from '@material-ui/icons/Add';
import Edit from '@material-ui/icons/Edit';
import { Trans } from 'react-i18next';


interface ActionPanelProps {
  labelCategory: GBLabelCategoryType;
}

const ActionPanel: React.FC<ActionPanelProps> = ({ labelCategory }) => (
  <>
    <ActionDropdownMenu
      actions={ LabelCategoryCreateActions }
      labelCategory={ labelCategory }
      caption={ (
        <>
          <Add/>
          <Trans i18nKey="actions.create"/>
        </>
      ) }
    />
    { !isRootCategory(labelCategory)  && (
      <ActionDropdownMenu
        actions={ LabelCategoryEditActions }
        labelCategory={ labelCategory }
        caption={ (
          <>
            <Edit/>
            <Trans i18nKey="actions.modify"/>
          </>
        ) }
      />
    )}
  </>
);


// Helper component that will convert the 'category' prop used by BrowserLayout to 'manufacturer'
type LabelCategoryActionPanelProps = BrowserLayoutActionPanelProps<GBLabelCategoryIdType>;

const LabelCategoryActionPanel: React.FC<LabelCategoryActionPanelProps> = ({ category, ...other }) => {
  const { id, name } = category;
  return (
    <ActionPanel
      labelCategory={{
        id,
        name,
      }}
    />
  );
};

export { LabelCategoryActionPanel };