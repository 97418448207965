import gql from 'graphql-tag';


import { Fragments as MaterialFragmentsRaw } from 'src/service/materials/materialFragments';

import { 
  ProviderFragments as ProviderFragmentsRaw, 
  ProviderMaterialFragments as ProviderMaterialFragmentsRaw
} from 'src/service/providers/providerFragments';

const MaterialFragments = MaterialFragmentsRaw();
const ProviderFragments = ProviderFragmentsRaw();
const ProviderMaterialFragments = ProviderMaterialFragmentsRaw();

export const Fragments = () => ({
  basic: gql`
    fragment DataSourceItemBasic on DataSourceItem {
      id
      active
      syncedAt
    }
  `,
  provider: gql`
    fragment DataSourceItemProvider on DataSourceItem {
      provider {
        ...ProviderBasic
      }
    }
    ${ProviderFragments.basic}
  `,
  providerMaterial: gql`
    fragment DataSourceItemProviderMaterial on DataSourceItem {
      providerMaterial {
        ...ProviderMaterialBasic
      }
    }
    ${ProviderMaterialFragments.basic}
  `,
  material: gql`
    fragment DataSourceItemMaterial on DataSourceItem {
      material {
        ...MaterialBasic
      }
    }
    ${MaterialFragments.basic}
  `,
});