import * as React from 'react';

import { MaterialVariant, GBMaterialType, DataSourceItem } from 'src/types';

import { formatDateTime } from 'src/utils/DateUtils';
import { 
  MaterialProductInfoLink, MaterialProductInfoListItem, MaterialProductInfoList 
} from 'src/components/material-layout';
import { MaterialDataSourceItem } from 'src/modules/materials/components/data-source-item';
import { SyncLayout } from './sync-layout';
import { withTranslation, WithTranslation } from 'react-i18next';
import { MaterialStatusLayout } from './MaterialStatusLayout';
import { AttachmentList } from './data-source-item';
import { AssetInfoArea } from 'src/components/form/asset-select-field';

interface MaterialProductInfoListProps {
  material: GBMaterialType;
  variant: MaterialVariant;
}

const formatDataSourceItems = (items: DataSourceItem[], material: GBMaterialType) => {
  return (
    <>
      { items.map(item => (
        <MaterialDataSourceItem
          key={ item.id }
          dataSourceItem={ item }
          material={ material }
        />
      )) }
    </>
  );
};

const MaterialProperties: React.FC<MaterialProductInfoListProps & WithTranslation> = ({ material, variant, t }) => (
  <MaterialProductInfoList>
    <MaterialProductInfoListItem
      title="Status"
      disableTypography={ true }
    >
      <MaterialStatusLayout
        material={ material }
      />
    </MaterialProductInfoListItem>
    <MaterialProductInfoListItem
      title={ t('manufacturer') }
      //disableTypography={ true }
    >
      <MaterialProductInfoLink
        to={ `/manufacturers/${material.manufacturer.id}` }
      >
        { material.manufacturer.name }
      </MaterialProductInfoLink>
    </MaterialProductInfoListItem>
    <MaterialProductInfoListItem
      title={ t('category') }
      //disableTypography={ true }
    >
      <MaterialProductInfoLink
        to={ `/categories/${material.category.id}` }
      >
        { material.category.name }
      </MaterialProductInfoLink>
    </MaterialProductInfoListItem>
    <MaterialProductInfoListItem
      title={ t('assetGUID') }
      showEmpty={ true }
      disableTypography={ true }
    >
      { !!material.assetGUID && (
        <AssetInfoArea
          assetGuid={ material.assetGUID }
          style={{ color: 'gray' }}
          showPreview={ true }
        /> 
      ) }
    </MaterialProductInfoListItem>
    <MaterialProductInfoListItem
      title={ t('gtin') }
      showEmpty={ true }
    >
      { material.gtin  }
    </MaterialProductInfoListItem>
    <MaterialProductInfoListItem
      title={ t('sku') }
      showEmpty={ true }
    >
      { material.sku  }
    </MaterialProductInfoListItem>
    <MaterialProductInfoListItem
      title={ t('net') }
      showEmpty={ true }
    >
      { "€" + variant.netPrice }
    </MaterialProductInfoListItem>
    <MaterialProductInfoListItem
      title={ t('createdAt') } 
    >
      { formatDateTime(material.createdAt, t) }
    </MaterialProductInfoListItem>
    <MaterialProductInfoListItem
      title={ t('updatedAt') } 
    >
      { formatDateTime(variant.updatedAt, t) }
    </MaterialProductInfoListItem>
    { !!material.dataSourceItems && !!material.dataSourceItems.length && (
      <>
        <MaterialProductInfoListItem
          title={ t('attachments') } 
          disableTypography={ true }
        >
          <AttachmentList material={ material }/>
        </MaterialProductInfoListItem>
        <SyncLayout
          t={ t }
          dataSourceItems={ material.dataSourceItems }
          material={ material }
        />
        <MaterialProductInfoListItem
          title={ t('dataSources') } 
          disableTypography={ true }
        >
          { formatDataSourceItems(material.dataSourceItems, material) }
        </MaterialProductInfoListItem>
      </>
    ) }
  </MaterialProductInfoList>
);

const MaterialPropertiesDecorated = withTranslation()(MaterialProperties);

export { MaterialPropertiesDecorated as MaterialProperties };
