import * as React from 'react';

import { 
  OrganizationEditorDataDecorator, OrganizationEditorDataDecoratorChildProps
} from '../../decorators/OrganizationEditorDataDecorator';

import { OrganizationEditorForm, mapFields  } from './OrganizationEditorForm';
import { createEditorDialog } from 'src/components/editor';

import { RouterModalProps } from 'src/types';
import { Trans } from 'react-i18next';
import { OrganizationFieldTypes } from 'src/types/input/organizationInput';


type OrganizationEditorDialogProps = 
  OrganizationEditorDataDecoratorChildProps & RouterModalProps;


const EditorDialog = createEditorDialog<
  OrganizationFieldTypes, 
  OrganizationEditorDataDecoratorChildProps
>('organizationEditor', mapFields);

class OrganizationEditorDialog extends React.PureComponent<OrganizationEditorDialogProps> {
  render() {
    const { organization, ...other } = this.props;
    return (
      <EditorDialog
        title={ <Trans i18nKey={ !!organization ? 'actions.editOrganization' : 'actions.createOrganization' }/> }
        organization={ organization }
        fieldsComponent={ OrganizationEditorForm }
        { ...other }
      />
    );
  }
}

const OrganizationEditorDialogDecorated = (
  OrganizationEditorDataDecorator(OrganizationEditorDialog)
);

export { OrganizationEditorDialogDecorated as OrganizationEditorDialog };
