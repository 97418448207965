import * as React from 'react';

import { Actions } from 'src/service/dataSourceItems';
import { ApolloClient } from '@apollo/client';

import { FormSubmitHandler, ProviderMaterialType, MaterialImportInput } from 'src/types';


import { 
  ProviderMaterialDataDecorator, ProviderMaterialDataDecoratorChildProps, ProviderMaterialRouteProps 
} from './ProviderMaterialDataDecorator';
import { normalizeCreateFields } from 'src/utils/FormUtils';
import { Trans } from 'react-i18next';
import { SnackbarContext } from 'src/decorators/NotificationDecorator';


export type ProviderImportFieldTypes = Pick<MaterialImportInput, 'categoryId'> & { manufacturerId: number; };
type ProviderImportDataDecoratorProps = ProviderMaterialDataDecoratorChildProps;

export interface ProviderImportDataDecoratorChildProps extends ProviderMaterialRouteProps {
  onSubmit: FormSubmitHandler<ProviderImportFieldTypes>;
  material?: ProviderMaterialType;
}

type DataProps = { client: ApolloClient<any> };

export function ProviderImportDataDecorator<T>(
  Component: React.ComponentType<T & ProviderImportDataDecoratorChildProps>
) {
  class Decorator extends React.PureComponent<ProviderImportDataDecoratorProps & DataProps & T> {
    static contextType = SnackbarContext;
    context!: SnackbarContext;

    handleSubmit: FormSubmitHandler<ProviderImportFieldTypes> = async (materialData, dispatch, formProps) => {
      try {
        const { client, match, data } = this.props;
        const { materialId } = match.params;

        const input = await normalizeCreateFields({
          ...materialData,
          providerMaterialId: materialId,
          providerId: data.provider!.id,
        });
    
        const dataSourceResponse = await Actions.sendImportMaterial(input, client);
        if (dataSourceResponse) {
          this.context({
            text: (
              <Trans 
                i18nKey="actions.notifications.materialSaved" 
                values={{ material: this.props.data.provider!.material }}
              />
            ), 
            actionTitle: <Trans i18nKey="actions.viewMaterial"/>, 
            actionHandler: () => this.props.history.push(`/materials/${dataSourceResponse.material.id}`)
          });
        }
      } catch (error) {
        this.context({
          text: <Trans i18nKey="actions.notifications.materialSaveFailed" values={{ error }}/>,
        });

        return false;
      }

      return true;
    }

    render() {
      const { data } = this.props;
      return (
        <Component
          { ...this.props }
          onSubmit={ this.handleSubmit }
          material={ !!data ? data.provider!.material : undefined }
        />
      );
    }
  }

  return ProviderMaterialDataDecorator<ProviderImportDataDecoratorProps & T>(Decorator);
}
