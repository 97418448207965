import * as React from 'react';

import { isRootCategory } from 'src/components/browser-layout';

import { RouteButton } from 'src/components/RouteButton';

import CloudDownload from '@material-ui/icons/CloudDownload';
import { Trans } from 'react-i18next';
import { ActionDropdownMenu } from 'src/components/ActionDropdownMenu';

import Add from '@material-ui/icons/Add';
import Edit from '@material-ui/icons/Edit';
import { MaterialCategoryEditActions, MaterialCategoryCreateActions } from 'src/actions/MaterialCategoryActions';
import { GBMaterialCategoryType } from 'src/types';


interface MaterialCategoryActionPanelProps {
  category: GBMaterialCategoryType;
}

const MaterialCategoryActionPanel: React.FC<MaterialCategoryActionPanelProps> = ({ category }) => (
  <>
    <RouteButton
      to="/providers"
      icon={ CloudDownload }
    >
      <Trans i18nKey="actions.import"/>
    </RouteButton>
    <ActionDropdownMenu
      caption={ (
        <>
          <Add/>
          <Trans i18nKey="actions.create"/>
        </>
      ) }
      actions={ MaterialCategoryCreateActions }
      category={ category }
    />
    { !isRootCategory(category) && (
      <ActionDropdownMenu
        caption={ (
          <>
            <Edit/>
            <Trans i18nKey="actions.modify"/>
          </>
        ) }
        actions={ MaterialCategoryEditActions }
        category={ category }
      />
    ) }
  </>
);


export { MaterialCategoryActionPanel };