import * as React from 'react';

import { TextField, TextFieldComponent } from 'src/components/form';
import { FieldMapperConfigProps, InjectedEditorFormProps } from 'src/components/editor';
//import { 
//  ManufacturerFieldTypes, ManufacturerEditorDataDecoratorChildProps 
//} from 'src/modules/manufacturers/decorators/ManufacturerEditorDataDecorator';
import { Trans } from 'react-i18next';
import { max250Validator } from 'src/utils/FieldValidators';
//import { asyncValidatorComposer, nameUniquenessValidator } from 'src/utils/AsyncFieldValidators';
//import { Queries } from 'src/service/organizations';
//import { SearchType } from 'src/types';
import { OrganizationFieldTypes } from 'src/types/input/organizationInput';
import { OrganizationEditorDataDecoratorChildProps } from '../../decorators/OrganizationEditorDataDecorator';


export type OrganizationEditorFormProps = 
  InjectedEditorFormProps<OrganizationFieldTypes, OrganizationEditorDataDecoratorChildProps>;

export const mapFields = (
  state: any,
  { organization, client }: OrganizationEditorDataDecoratorChildProps
): FieldMapperConfigProps<OrganizationFieldTypes> => {
  /*const commonMappers = asyncValidatorComposer(
    [ nameUniquenessValidator(Queries.ManufacturerSearchQuery, SearchType.MANUFACTURER) ], 
    client
  );*/

  if (!organization) {
    // Creating new, set initial parent
    //return commonMappers;
    return {};
  }

  // Editing existing, convert the data
  const { name, code } = organization;
  return {
    initialValues: {
      name,
      code,
    },
    //...commonMappers,
  };
};

class OrganizationEditorForm extends React.Component<OrganizationEditorFormProps> {
  render() {
    return (
      <>
        <TextField
          required
          name="name" 
          component={ TextFieldComponent } 
          label={ <Trans i18nKey="name"/> }
          validate={ max250Validator }
        />
        <TextField
          required
          name="code" 
          component={ TextFieldComponent } 
          label={ <Trans i18nKey="code"/> }
          validate={ max250Validator }
        />
      </>
    );
  }
}

export { OrganizationEditorForm };
