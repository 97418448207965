import { MaterialCategoryBaseType, MaterialCategoryBaseIdType } from 'src/types';


export const ROOT_ID_STR: MaterialCategoryBaseIdType = 'ROOT';
export const ROOT_ID_NUM: MaterialCategoryBaseIdType = Number.MIN_SAFE_INTEGER;


export const RootCategoryStr: MaterialCategoryBaseType<string> = {
  id: ROOT_ID_STR,
  name: 'Root',
};

export const RootCategoryNum: MaterialCategoryBaseType<number> = {
  id: ROOT_ID_NUM,
  name: 'Root',
};

export const ensureParentCategory = <IdType extends MaterialCategoryBaseIdType>(
  category: MaterialCategoryBaseType<IdType>, 
  root: MaterialCategoryBaseType<IdType>
): MaterialCategoryBaseType<IdType> => {
  if (category.parent) {
    return category;
  }

  return {
    ...category,
    parent: root,
  };
};

export const isRootCategory = (category: MaterialCategoryBaseType) => {
  return category.id === ROOT_ID_NUM || category.id === ROOT_ID_STR;
};
