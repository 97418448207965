
import * as React from 'react';

import BrokenImage from '@material-ui/icons/BrokenImage';
import { MaterialImageType } from 'src/types';


interface MaterialImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  image?: MaterialImageType | null;
  maxSize: number;
  margin?: number;
}

export const MaterialImage: React.FC<MaterialImageProps> = (
  { image, maxSize, margin = 0, style, ...other }
) => (
  <>
    { !!image ? (
      <img
        alt={ image.name }
        src={ maxSize <= 300 ? image.urlSmall : image.urlLarge }
        style={{
          maxWidth: maxSize + 'px',
          maxHeight: maxSize + 'px',
          margin: margin + 'px',
          objectFit: 'contain',
          alignSelf: 'baseline',
          ...style,
        }}
        { ...other }
      />
    ) : (
      <BrokenImage 
        color="disabled" 
        style={{ 
          width: maxSize + 'px',
          height: maxSize + 'px',
        }}
      />
    ) }
  </>
);
