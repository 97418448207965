import { 
  CreateMaterialCategoryResponse, UpdateMaterialCategoryResponse, DeleteMaterialCategoryResponse, 
  UpdateMaterialCategoryMutation, DeleteMaterialCategoryMutation, CreateMaterialCategoryMutation
} from './materialCategoryMutations';
import { ApolloClient } from '@apollo/client';
import { deleteMutation, updateMutation, createMutation, resetCache } from 'src/utils/MutationUtils';
import { 
  GBMaterialCategoryIdType, MaterialCategoryInput, MaterialCategoryFieldTypes, MaterialCategoryVariables 
} from 'src/types';


// Create a new material category
export const sendCreateMaterialCategory = async (
  input: MaterialCategoryInput, 
  languageCode: string,
  client: ApolloClient<any>
) => {
  const response = await createMutation<
    CreateMaterialCategoryResponse,
    MaterialCategoryVariables
  >(
    { input, languageCode }, CreateMaterialCategoryMutation, client
  );

  resetCache(client);
  return response.createMaterialCategory;
};

// Update an existing material category
export const sendUpdateMaterialCategory = async (
  id: number, 
  input: RecursivePartial<MaterialCategoryFieldTypes>, 
  languageCode: string,
  client: ApolloClient<any>
) => {
  const response = await updateMutation<
    UpdateMaterialCategoryResponse,
    MaterialCategoryVariables
  > (
    id, { input, languageCode }, UpdateMaterialCategoryMutation, client
  );

  resetCache(client);
  return response.updateMaterialCategory;
};

// Delete material category
export const sendDeleteMaterialCategory = async (
  id: GBMaterialCategoryIdType,  
  client: ApolloClient<any>,
  onDeletedCallback?: (response: DeleteMaterialCategoryResponse) => void
) => {
  const response = await deleteMutation<DeleteMaterialCategoryResponse>(
    id, DeleteMaterialCategoryMutation, client, onDeletedCallback
  );

  resetCache(client);
  return response.deleteMaterialCategory;
};