import React, { Suspense } from 'react';
import './style.css';

import { Route, Switch, Redirect } from 'react-router-dom';

import { MainNavigation } from './MainNavigation';
import { NotFoundView } from './NotFoundView';

import MaterialCategoryView from 'src/modules/categories';
import ManufacturerView from 'src/modules/manufacturers';
import MaterialView from 'src/modules/materials';
import ProviderView from 'src/modules/providers';
import LabelView from 'src/modules/labels';
import LabelCategoryView from 'src/modules/label-categories';
import SearchView from 'src/modules/search';
import { AdminView } from 'src/modules/admin';

import { SelectedOrganizationProvider } from 'src/decorators/SelectedOrganizationProvider';

import { CircularProgress } from '@material-ui/core';


//const MaterialCategoryView = 
//  lazy(() => import(/* webpackChunkName: "material-categories" */ 'src/modules/categories'));
//const ManufacturerView = 
//  lazy(() => import(/* webpackChunkName: "manufacturers" */ 'src/modules/manufacturers'));
//const MaterialView = 
//  lazy(() => import(/* webpackChunkName: "materials" */ 'src/modules/materials'));
//const ProviderView = 
//  lazy(() => import(/* webpackChunkName: "providers" */ 'src/modules/providers'));
//const LabelView = 
//  lazy(() => import(/* webpackChunkName: "labels" */ 'src/modules/labels'));
//const LabelCategoryView = 
//  lazy(() => import(/* webpackChunkName: "label-categories" */ 'src/modules/label-categories'));
//const SearchView = 
//  lazy(() => import(/* webpackChunkName: "search" */ 'src/modules/search'));


class AuthenticatedApp extends React.Component<{}> {
  render() {
    return (
      <SelectedOrganizationProvider>
        <div className="main-layout">
          <MainNavigation/>
          <Suspense fallback={ <CircularProgress/> }>
            <Switch>
              <Route 
                exact 
                path="/" 
                render={ () => (
                  <Redirect
                    to="/categories"
                  />
                ) }
              />
              <Route path="/categories/:categoryId(\d+)?" component={ MaterialCategoryView }/>
              <Route path="/materials/:materialId(\d+)?" component={ MaterialView }/>
              <Route path="/manufacturers/:manufacturerId(\d+)?" component={ ManufacturerView }/>
              <Route path="/providers/:providerId(\w+)?" component={ ProviderView }/>
              <Route path="/label-categories/:labelCategoryId(\d+)?" component={ LabelCategoryView }/>
              <Route path="/labels/:labelId(\d+)" component={ LabelView }/>
              <Route path="/search" component={ SearchView }/>
              <Route path="/admin" component={ AdminView }/>
              <Route path="*" component={ NotFoundView }/>
            </Switch>
          </Suspense>
        </div>
      </SelectedOrganizationProvider>
    );
  }
}

export { AuthenticatedApp };
