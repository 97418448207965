import * as React from 'react';

import Button, { ButtonProps } from '@material-ui/core/Button';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import MenuList from '@material-ui/core/MenuList';
import Grow, { GrowProps } from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';

import Popper, { PopperPlacementType } from '@material-ui/core/Popper';

import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

import { DropdownMenuItemProps } from './DropdownMenuItem';
import { AuthContext } from 'src/decorators/AuthDecorator';
//import Auth from 'src/service/Auth';


interface State {
  open: boolean;
}

interface ClickProps extends DropdownMenuItemProps {
  onClick: (evt: any) => void;
}

export interface DropdownMenuProps {
  caption: React.ReactNode;
  iconTrigger?: boolean;
  menuProps?: GrowProps;
  menuPlacement?: PopperPlacementType;
  buttonProps?: ButtonProps;
  children: React.ReactElement | React.ReactElement[];
}

class DropdownMenu extends React.PureComponent<DropdownMenuProps, State> {
  state = {
    open: false,
  };

  anchorEl: any = null;

  static defaultProps = {
    placement: 'bottom-start',
  };

  handleOpen = (evt: React.SyntheticEvent) => {
    evt.stopPropagation(); // Don't let ClickAwayListener to receive this
    this.setState({ open: true });
  }

  handleClose = () => {
    this.setState({ open: false });
  }

  render() {
    const { open } = this.state;
    const { caption, iconTrigger, menuProps, menuPlacement, buttonProps } = this.props;
    //let { children } = this.props;

    const commonButtonProps = {
      buttonRef: (node: any) => {
        this.anchorEl = node;
      },
      'aria-owns': open ? 'menu-list-grow' : undefined,
      'aria-haspopup': true,
      onClick: this.handleOpen,
      ...buttonProps,
    };

    return (
      <AuthContext.Consumer>
        { auth => {
          const children = React.Children.toArray(this.props.children).filter(child => {
            const menuItem = child as React.ReactElement<any>;
            const { access }: DropdownMenuItemProps = menuItem.props;
            if (!access) {
              return true;
            }

            return access instanceof Function ? access(auth!) : auth!.hasGlobalAccess(menuItem.props.access);
          });

          if (React.Children.count(children) === 0) {
            return null;
          }

          return (
            <>
              { iconTrigger ? (
                <IconButton
                  { ...commonButtonProps as IconButtonProps } // TODO: remove cast
                >
                  { caption }
                </IconButton>
              ) : (
                <Button
                  { ...commonButtonProps }
                >
                  { caption }
                  <ArrowDropDown/>
                </Button>
              ) }
              <Popper
                placement={ menuPlacement }
                anchorEl={ this.anchorEl }
                open={ open }
                style={{ zIndex: open ? 999 : -1 }}
                transition={ true }
              >
                { ({ TransitionProps, placement }) => (
                  <Grow
                    { ...TransitionProps }
                    //id="menu-list-grow"
                    { ...menuProps }
                    style={{ transformOrigin: 'center top' }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={ this.handleClose }>
                        <MenuList>
                          { React.Children.map(children as React.ReactElement<ClickProps>[], (
                            menuItem: React.ReactElement<ClickProps>
                          ) => {
                            return !!menuItem && React.cloneElement(menuItem, {
                              onClick: (evt: React.SyntheticEvent) => {
                                if (menuItem.props.onClick) {
                                  this.handleClose();
                                  menuItem.props.onClick(evt);
                                }
                              }
                            });
                          }) }
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                ) }
              </Popper>
            </>
          ); 
        } }
      </AuthContext.Consumer>
    );
  }
}

export { DropdownMenu };
