import * as React from 'react';

import { graphql } from '@apollo/client/react/hoc';
import { Queries } from 'src/service/providers';

import { RouteComponentProps } from 'react-router';

import { DataLoaderDecorator, DataProps } from 'src/decorators/DataLoaderDecorator';
import { parseSearchVariables } from 'src/utils/SearchUtils';



type ProviderSearchTotalDecoratorProps = RouteComponentProps<{
  providerId: string;
}>;

export type ProviderSearchTotalDecoratorChildProps = 
  ProviderSearchTotalDecoratorProps & DataProps<Queries.ProviderMaterialSearchTotalResponse>;


export const ProviderSearchTotalDecorator = function <T>(
  Component: React.ComponentType<T & ProviderSearchTotalDecoratorChildProps>
) {
  const withResults = graphql<T & ProviderSearchTotalDecoratorProps, Queries.ProviderMaterialSearchTotalResponse>(
    Queries.ProviderMaterialSearchTotalQuery, {
      options: ({ match, location }) => {
        return {
          variables: {
            ...parseSearchVariables(location),
            providerId: match.params.providerId,
          }
        };
      },
    }
  );

  return withResults(
    DataLoaderDecorator<T & ProviderSearchTotalDecoratorProps, Queries.ProviderMaterialSearchTotalResponse>(Component)
  );
};
