import { Permission } from 'src/constants';

import { ActionHandlerProps, ActionList, GBManufacturerType } from 'src/types';

import { Actions } from 'src/service/manufacturers';

import Domain from '@material-ui/icons/DomainSharp';
import Label from '@material-ui/icons/Label';

import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import { isRootCategory } from 'src/components/browser-layout';


interface ManufacturerEditActionProps {
  manufacturer: GBManufacturerType;
}

interface ManufacturerCreateActionProps {
  manufacturer?: GBManufacturerType;
}

type CreateProps = ActionHandlerProps & ManufacturerCreateActionProps;
type EditProps = ActionHandlerProps & ManufacturerEditActionProps;



const handleCreateMaterial = (props: CreateProps) => {
  const { history, match } = props;
  const url = `${match.url}/material`;
  history.push(url);
};

const handleCreateManufacturer = (props: CreateProps) => {
  const { history, match } = props;
  const url = `${match.url}/create`;
  history.push(url);
};

const handleEdit = (props: EditProps) => {
  const { history, match } = props;

  const url = `${match.url}/edit`;
  history.push({
    pathname: url,
    search: history.location.search,
  });
};

const handleDelete = async (props: EditProps) => {
  const { manufacturer, client, history } = props;
  await Actions.sendDeleteManufacturer(manufacturer.id, client, res => {
    history.replace('/manufacturers');
  });
};


const isRoot = ({ manufacturer }: CreateProps) => !!manufacturer && isRootCategory(manufacturer);

export const ManufacturerCreateActions: ActionList<ManufacturerCreateActionProps> = {
  CREATE_MANUFACTURER: {
    title: 'actions.newManufacturer',
    icon: Domain,
    handler: handleCreateManufacturer,
    access: Permission.MANUFACTURERS_CREATE,
    filter: isRoot,
  }, 
  CREATE_MATERIAL: {
    title: 'actions.newMaterial',
    icon: Label,
    handler: handleCreateMaterial,
    access: Permission.MATERIALS_CREATE,
    filter: props => !isRoot(props)
  }
};

export const ManufacturerEditActions: ActionList<ManufacturerEditActionProps> = {
  EDIT: {
    title: 'actions.edit',
    icon: Edit,
    handler: handleEdit,
    access: Permission.MANUFACTURERS_EDIT,
  },
  DELETE: {
    title: 'actions.delete',
    icon: Delete,
    handler: handleDelete,
    onSuccess: 'actions.notifications.manufacturerDeleted',
    onFailed: 'actions.notifications.manufacturerDeleteFailed',
    access: Permission.MANUFACTURERS_DELETE,
    confirmation: {
      title: 'actions.confirmations.deleteManufacturer',
      description: 'actions.confirmations.deleteManufacturerMessage',
    },
  }
};
