import gql from 'graphql-tag';
import { 
  GBMaterialType, GBMaterialCategoryType, 
  PaginatedResponse, LabelCountType, TotalResponse, ManufacturerCountType 
} from 'src/types';

import { Fragments as FragmentsRaw } from './materialCategoryFragments';
import { Fragments as MaterialFragmentsRaw } from 'src/service/materials/materialFragments';
import { Fragments as MaterialCountFragmentsRaw } from 'src/service/materialCounts/materialCountFragments';


import { Fragments as MaterialVariantFragmentsRaw } from 'src/service/materialVariants/materialVariantFragments';
import { 
  Fragments as MaterialVariantLocalizationFragmentsRaw 
} from 'src/service/materialVariantLocalizations/materialVariantLocalizationFragments';

const MaterialVariantFragments = MaterialVariantFragmentsRaw();
const MaterialLocalizationFragments = MaterialVariantLocalizationFragmentsRaw();

const MaterialCountFragments = MaterialCountFragmentsRaw();

const MaterialFragments = MaterialFragmentsRaw();
const Fragments = FragmentsRaw();

// Queries
export const MaterialCategoryQuery = gql`
  query MaterialCategoryQuery($categoryId: Int!) {
    materialCategory(id: $categoryId) {
      ...MaterialCategoryBasic
      ...MaterialCategoryParent
    }
  }
  ${Fragments.basic}
  ${Fragments.parent}
`;

export const MaterialCategoryCategoriesQuery = gql`
  query MaterialCategoryCategoriesQuery(
    $categoryId: Int!, 
  ) {
    materialCategory(id: $categoryId) {
      ...MaterialCategoryBasic
      parent {
        ...MaterialCategoryBasic
        parent {
          ...MaterialCategoryBasic
          parent {
            ...MaterialCategoryBasic
            parent {
              ...MaterialCategoryBasic
            }
          }
        }
      }
      labelCounts {
        ...LabelCount
      }
      manufacturerCounts {
        ...ManufacturerCount
      }
      categories(orderBy: { field: ID, direction: ASC }) {
        edges {
          node {
            ...MaterialCategoryBasic,
          }
        }
      }
      materials {
        total
      }
    }
  }
  ${Fragments.basic}
  ${MaterialCountFragments.labelCount}
  ${MaterialCountFragments.manufacturerCount}
`;

export const MaterialCategoryMaterialsQuery = gql`
  query MaterialCategoryMaterialsQuery(
    $categoryId: Int!, 
    $first: Int, 
    $after: String, 
    $orderBy: MaterialsOrder, 
    $labels: [[Int]],
    $manufacturers: [Int],
    $languageCode: LanguageCode
  ) {
    materialCategory(id: $categoryId) {
      id
      materials(
        first: $first, 
        after: $after, 
        orderBy: $orderBy, 
        labels: $labels,
        manufacturers: $manufacturers
      ) {
        total
        edges {
          node {
            ...MaterialBasic
            ...MaterialManufacturer
            variants {
              ...MaterialVariantBasic
              ...MaterialVariantOrganization
              ...MaterialVariantProvider
              localization(languageCode: $languageCode) {
                ...MaterialVariantLocalizationBasic
              }
            }
          }
        }
      }
    }
  }
  ${MaterialFragments.basic}
  ${MaterialFragments.manufacturer}
  ${MaterialVariantFragments.basic}
  ${MaterialVariantFragments.organization}
  ${MaterialVariantFragments.provider}
  ${MaterialLocalizationFragments.basic}
`;

export const MaterialCategorySearchQuery = gql`
  query MaterialCategorySearchQuery(
    $types: [SearchTypes]!, 
    $query: String!
    $first: Int, 
    $after: String, 
    $orderBy: MaterialCategoriesOrder
  ) {
    search(types: $types, query: $query) {
      materialCategories(first: $first, after: $after, orderBy: $orderBy) {
        total,
        edges {
          node {
            ...MaterialCategoryBasic
          }
        }
      }
    }
  }
  ${Fragments.basic}
`;

export const MaterialCategoryListQuery = gql`
  query MaterialCategoryListQuery {
    materialCategories(rootsOnly: true) {
      edges {
        node {
          ...MaterialCategoryBasic
        }
      }
    }
  }
  ${Fragments.basic}
`;

export const MaterialCategoryListQueryRecursive = gql`
  query MaterialCategoryListQueryRecursive {
    materialCategories(rootsOnly: true) {
      edges {
        node {
          ...MaterialCategoryBasic,
          categories {
            edges {
              node {
                ...MaterialCategoryBasic,
                categories {
                  edges {
                    node {
                      ...MaterialCategoryBasic,
                      categories {
                        edges {
                          node {
                            ...MaterialCategoryBasic,
                          }
                        }
                        total
                      }
                    }
                  }
                  total
                }
              }
            }
            total
          }
        }
      }
      total
    }
  }
  ${Fragments.basic}
`;



// Response types
export interface MaterialCategoryResponse {
  materialCategory: GBMaterialCategoryType;
}

export interface MaterialCategoryCategoriesResponse {
  materialCategory: GBMaterialCategoryType & {
    categories: PaginatedResponse<GBMaterialCategoryType>;
    labelCounts: LabelCountType[];
    manufacturerCounts: ManufacturerCountType[];
    materials: TotalResponse;
  };
}

export interface MaterialCategoryMaterialsResponse {
  materialCategory: {
    materials: PaginatedResponse<GBMaterialType>;
  };
}

export interface MaterialCategorySearchResponse {
  search: {
    materialCategories: PaginatedResponse<GBMaterialCategoryType>;
  };
}

export interface MaterialCategoryListResponse {
  materialCategories: PaginatedResponse<GBMaterialCategoryType>;
}

export interface RecursiveMaterialCategoryItem extends GBMaterialCategoryType {
  categories: PaginatedResponse<RecursiveMaterialCategoryItem>;
}

export interface MaterialCategoryListRecursiveResponse {
  materialCategories: PaginatedResponse<RecursiveMaterialCategoryItem>;
}
