import { 
  CreateManufacturerResponse, UpdateManufacturerResponse, DeleteManufacturerResponse, 
  UpdateManufacturerMutation, DeleteManufacturerMutation, CreateManufacturerMutation
} from './manufacturerMutations';
import { ApolloClient } from '@apollo/client';
import { updateMutation, createMutation, deleteMutation, resetCache } from 'src/utils/MutationUtils';
import { GBManufacturerIdType, ManufacturerInput } from 'src/types';


// Create a new manufacturer
export const sendCreateManufacturer = async (
  input: ManufacturerInput, 
  client: ApolloClient<any>,
  resetStore: boolean = true
) => {
  const response = await createMutation<CreateManufacturerResponse> (
    { input }, CreateManufacturerMutation, client
  );

  if (resetStore) {
    resetCache(client);
  }

  return response.createManufacturer;
};

// Update an existing manufacturer
export const sendUpdateManufacturer = async (
  id: GBManufacturerIdType, 
  input: RecursivePartial<ManufacturerInput>, 
  client: ApolloClient<any>
) => {
  const response = await updateMutation<UpdateManufacturerResponse> (
    id, { input }, UpdateManufacturerMutation, client
  );

  resetCache(client);
  return response.updateManufacturer;
};

// Delete manufacturer
export const sendDeleteManufacturer = async (
  id: GBManufacturerIdType,  
  client: ApolloClient<any>,
  onDeletedCallback: (response: DeleteManufacturerResponse) => void
) => {
  const response = await deleteMutation<DeleteManufacturerResponse>(
    id, DeleteManufacturerMutation, client, onDeletedCallback
  );

  resetCache(client);
  return response.deleteManufacturer;
};