import * as React from 'react';
import Button from '@material-ui/core/Button';
import {
  DialogActions,
  DialogContent,
} from '@material-ui/core';

import { ConfigProps, InjectedFormProps } from 'redux-form';
import { Trans } from 'react-i18next';


export type FieldMapperConfigProps<FieldTypesT> = Partial<ConfigProps<Partial<FieldTypesT>>>;

export type FieldsComponentType<FieldTypesT, PropsT> = 
  React.ComponentType<InjectedEditorFormProps<FieldTypesT, PropsT>>;

export type EditorFormProps<FieldTypesT, PropsT> = PropsT & FieldMapperConfigProps<FieldTypesT> & {
  onCancel: () => void;
  saveCaption?: string;
};

export type InjectedEditorFormProps<FieldTypesT, PropsT, ErrorT = string> = 
  InjectedFormProps<FieldTypesT, EditorFormProps<FieldTypesT, PropsT>, ErrorT> & 
  EditorFormProps<FieldTypesT, PropsT> &
  {
    fieldsComponent: FieldsComponentType<FieldTypesT, PropsT>;
  };

export const EditorForm = <FieldTypesT extends object, PropsT extends object>(
  props: InjectedEditorFormProps<FieldTypesT, PropsT>
) => {
  const [ saving, setSaving ] = React.useState(false);
  const mountedRef = React.useRef(null);
  const { handleSubmit, onCancel, fieldsComponent: FormFields, saveCaption = 'save', ...other } = props as any;
  return (
    // role=form might be needed for the tests...
    // eslint-disable-next-line jsx-a11y/no-redundant-roles
    <form 
      role="form"
      ref={ mountedRef }
      onSubmit={ async (data) => {
        setSaving(true);
        await handleSubmit(data);

        if (mountedRef.current) {
          setSaving(false);
        }
      } }
    >
      <DialogContent>
        <FormFields
          { ...other }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={ onCancel } color="primary">
          <Trans i18nKey="cancel"/>
        </Button>
        <Button disabled={ saving } type="submit" color="primary">
          <Trans i18nKey={ saveCaption }/>
        </Button>
      </DialogActions>
    </form>
  );
};
