import * as React from 'react';

import { ActionDropdownMenu } from 'src/components';
import Add from '@material-ui/icons/Add';
import { Trans } from 'react-i18next';
import { OrganizationCreateActions } from 'src/actions/OrganizationActions';


interface ActionPanelProps {

}

export const OrganizationsActionPanel: React.FC<ActionPanelProps> = () => (
  <>
    <ActionDropdownMenu
      actions={ OrganizationCreateActions }
      caption={ (
        <>
          <Add/>
          <Trans i18nKey="actions.create"/>
        </>
      ) }
    />
  </>
);
