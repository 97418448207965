import * as React from 'react';

import { graphql } from '@apollo/client/react/hoc';
import { RouteComponentProps } from 'react-router';

import { Actions, Queries } from 'src/service/materials';
import { ApolloClient } from '@apollo/client';
import { Trans } from 'react-i18next';

import { DataLoaderDecorator, DataProps } from 'src/decorators/DataLoaderDecorator';
import { FormSubmitHandler, GBMaterialType, MaterialFieldTypes, MaterialStatus } from 'src/types';
import { normalizeUpdateFields, normalizeCreateFields } from 'src/utils/FormUtils';
import { withSelectedOrganization, SelectedOrganizationProps } from 'src/decorators/SelectedOrganizationProvider';
import { saveVariant, MaterialVariantActionTypes } from './MaterialVariantEditorDataDecorator';
import { SnackbarContext } from 'src/decorators/NotificationDecorator';
import { resetCache } from 'src/utils/MutationUtils';
import { AuthContext } from 'src/decorators/AuthDecorator';
import { Permission } from 'src/constants';
import Auth from 'src/service/Auth';
import { parseIntRouteParam } from 'src/utils/BrowserUtils';


export enum MaterialActionTypes {
  APPROVE = 'approve',
  EDIT = 'edit',
}

type RouteProps = RouteComponentProps<{
  manufacturerId?: string;
  categoryId?: string;
  materialId?: string;
  actionType?: MaterialActionTypes;
}>;

type MaterialEditorDataDecoratorProps =
  SelectedOrganizationProps &
  RouteProps;

export interface MaterialEditorDataDecoratorChildProps extends RouteProps, SelectedOrganizationProps {
  onSubmit: FormSubmitHandler<MaterialFieldTypes>;
  material?: GBMaterialType;
  client: ApolloClient<any>;
  auth: Auth;
}

type Props = MaterialEditorDataDecoratorProps & DataProps<Queries.MaterialResponse>;

function MaterialEditorDataDecorator<T>(Component: React.ComponentType<T & MaterialEditorDataDecoratorChildProps>) {
  const withMaterialData = graphql<RouteProps & T, Queries.MaterialResponse>(Queries.MaterialWithVariantsQuery, {
    skip: ({ match }) => {
      return !match.params.materialId;
    },
    options: ({ match }) => ({ 
      variables: { 
        materialId: parseIntRouteParam(match.params.materialId!) 
      } 
    }),
  });

  const Decorator: React.FC<Props & T> = props => {
    const snackbar = React.useContext(SnackbarContext)!;
    const auth = React.useContext(AuthContext)!;
    const { data } = props;

    const handleSubmit: FormSubmitHandler<MaterialFieldTypes> = async (formData, dispatch, formProps) => {
      try {
        const { client, match } = props;
        const updating = !!data && !!data.material;
        const { variant, ...materialData } = formData;
    
        // Create/update material
        const materialResponse = updating ? 
          await Actions.sendUpdateMaterial(
            data.material!.id, 
            await normalizeUpdateFields(materialData, formProps.initialValues),
            client
          ) :
          await Actions.sendCreateMaterial(
            await normalizeCreateFields(materialData), 
            client
          );

        if (materialResponse) {
          const approving = match.params.actionType === MaterialActionTypes.APPROVE || 
            (!updating && auth.hasGlobalAccess(Permission.ADMIN));

          if (!updating || approving) {
            await saveVariant(
              materialResponse.id,
              variant,
              approving ? MaterialVariantActionTypes.COPY : MaterialVariantActionTypes.CREATE,
              client,
              formProps.initialValues.variant!,
            );
          }

          if (approving) {
            await Actions.sendUpdateMaterial(
              materialResponse.id,
              { status: MaterialStatus.ACTIVE },
              client
            );
          }

          snackbar({
            text: <Trans i18nKey="actions.notifications.materialSaved" values={{ material: materialData }}/>, 
            actionTitle: <Trans i18nKey="actions.viewMaterial"/>, 
            actionHandler: () => props.history.push(`/materials/${materialResponse.id}`)
          });
        }
        
        resetCache(client);
      } catch (error) {
        snackbar({
          text: <Trans i18nKey="actions.notifications.materialSaveFailed" values={{ error }}/>,
        });

        return false;
      }

      return true;
    };

    return (
      <Component
        { ...props }
        onSubmit={ handleSubmit }
        material={ data ? data.material : undefined }
        auth={ auth }
      />
    );
  };

  return withMaterialData(
    DataLoaderDecorator<RouteProps & T, Queries.MaterialResponse>(
      withSelectedOrganization<RouteProps & DataProps<Queries.MaterialResponse> & T>(Decorator)
    )
  );
}

export { MaterialEditorDataDecorator };
