import * as React from 'react';

import { MaterialVariant } from 'src/types';
import { ProviderTitle } from 'src/modules/providers';
import { parseVariantName } from 'src/utils/MaterialVariantUtils';
import { TFunction } from 'i18next';

interface ProviderTitleProps {
  variant: MaterialVariant;
  t: TFunction;
}

export const MaterialVariantTitle: React.FC<ProviderTitleProps> = ({ variant, t }) => {
  if (variant.provider) {
    return (
      <ProviderTitle
        provider={ variant.provider }
      />
    );
  }

  return (
    <div>
      { parseVariantName(variant, t('baseline')) }
    </div>
  );
};