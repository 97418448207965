import * as React from 'react';

import { graphql } from '@apollo/client/react/hoc';
import { Location } from 'history';

import {
  MaterialTableConfigProps, MaterialTable, DefaultLocalMaterialListPagination
} from 'src/components/browser-layout';

import { DataLoaderDecorator, DataProps } from 'src/decorators/DataLoaderDecorator';
import { Queries } from 'src/service/materials';

import { parsePaginationVariables } from 'src/utils/PaginationUtils';
import { parseMaterialFilterVariables } from 'src/utils/MaterialFilterUtils';
import { parseSearchVariables } from 'src/utils/SearchUtils';

import i18n from 'src/i18n';
import { localMaterialsToViewMaterials } from 'src/utils/MaterialVariantUtils';
import { SelectedOrganizationContext } from 'src/decorators/SelectedOrganizationProvider';
import { useTranslation } from 'react-i18next';
import { SearchType } from 'src/types';
import { MaterialSelectionActions } from 'src/actions/MaterialSelectionActions';


export interface SearchResultsMaterialTableProps extends MaterialTableConfigProps {
  location: Location;
}

const withResults = graphql<
  SearchResultsMaterialTableProps, 
  Queries.MaterialSearchListResponse
>(
  Queries.MaterialSearchListQuery, {
    options: ({ location }) => {
      return {
        variables: {
          types: [ SearchType.MATERIAL ],
          ...parseSearchVariables(location),
          ...parsePaginationVariables(location, DefaultLocalMaterialListPagination).query,
          ...parseMaterialFilterVariables(location).query,
        }
      };
    },
  }
);


type Props = SearchResultsMaterialTableProps & DataProps<Queries.MaterialSearchListResponse>;

const SearchResultsMaterialTable: React.FC<Props> = ({ data }) => {
  const organizationContext = React.useContext(SelectedOrganizationContext)!;
  const { t } = useTranslation();
  const { materials } = data.search!;
  return (
    <MaterialTable
      materials={ localMaterialsToViewMaterials(materials, organizationContext, t) }
      totalCount={ materials.total }
      pageTitle={ i18n.t('searchResults') }
      selectionActions={ MaterialSelectionActions }
    />
  );
};

const SearchResultsMaterialTableDecorated = withResults(
  DataLoaderDecorator<SearchResultsMaterialTableProps, Queries.MaterialSearchListResponse>(
    SearchResultsMaterialTable
  )
);

export { SearchResultsMaterialTableDecorated as SearchResultsMaterialTable };