import * as React from 'react';

import { graphql } from '@apollo/client/react/hoc';
import { Location } from 'history';

import { 
  BrowserLayout, MaterialBrowserPropsType
} from 'src/components/browser-layout';

import { DataLoaderDecorator } from 'src/decorators/DataLoaderDecorator';
import { Queries } from 'src/service/labelCategories';

import Label from '@material-ui/icons/Label';
import { Trans } from 'react-i18next';
import { GBLabelCategoryIdType } from 'src/types';
import { LabelCategoryActionPanel } from './labelcategory-action-panel';


export interface LabelCategoryPagePropsType extends MaterialBrowserPropsType<GBLabelCategoryIdType> {
  labelCategoryId?: number;
  location: Location;
}


const withLabel = graphql<LabelCategoryPagePropsType, Queries.LabelCategoryLabelsResponse>(
  Queries.LabelCategoryLabelsQuery, {
    options: ({ labelCategoryId, location }) => {
      return {
        variables: { 
          categoryId: labelCategoryId,
        }
      };
    },
  }
);

const LabelCategoryPageLayout = withLabel(DataLoaderDecorator(({ data, ...other }) => {
  return (
    <BrowserLayout<GBLabelCategoryIdType>
      category={ data.labelCategory! }
      actionPanel={ LabelCategoryActionPanel }
      childCategories={ data.labelCategory!.labels }
      subTitle={ <Trans i18nKey="labelCategory"/> }
      navigationItemIcon={ Label }
      { ...other }
    />
  );
}));

export { LabelCategoryPageLayout };