import * as React from 'react';

import { GBMaterialType, DataSourceItem } from 'src/types';

import { MaterialProductInfoListItem } from 'src/components/material-layout';
import { SyncStatusLayout } from './SyncStatusLayout';
import { LastSyncedAtLayout } from './LastSyncedAtLayout';
import { WithTranslation } from 'react-i18next';


export interface SyncLayoutProps extends Pick<WithTranslation, 't'> {
  dataSourceItems: DataSourceItem[];
  material: GBMaterialType;
}

const SyncLayout: React.FC<SyncLayoutProps> = (
  { dataSourceItems, material, t }
) => {
  if (!dataSourceItems || !dataSourceItems.length) {
    return null;
  }
  
  const activeItem = dataSourceItems.find(i => i.active);

  return (
    <>
      <MaterialProductInfoListItem
        title={ t('syncStatus') } 
      >
        <SyncStatusLayout
          t={ t }
          activeItem={ activeItem }
          material={ material }
        />
      </MaterialProductInfoListItem>
      { !!activeItem && (
        <MaterialProductInfoListItem
          title={ t('lastSyncedAt') } 
          disableTypography={ true }
        >
          <LastSyncedAtLayout
            t={ t }
            material={ material }
            activeItem={ activeItem }
          />
        </MaterialProductInfoListItem>
      )}
    </>
  );
};

export { SyncLayout };