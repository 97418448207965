import * as React from 'react';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

import { MaterialCategoryBaseType } from 'src/types';
import { NavigationButton, ThunkedCategoryUrl } from './NavigationButton';
import { SvgIconProps } from '@material-ui/core/SvgIcon';


export interface CategoryListProps {
  category: MaterialCategoryBaseType;
  childCategories?: Array<MaterialCategoryBaseType>;
  browserBaseUrl: ThunkedCategoryUrl;
  navigationItemIcon?: React.ComponentType<SvgIconProps>;
}


const CategoryList = ({ 
  category, childCategories, navigationItemIcon, browserBaseUrl 
}: CategoryListProps) => (
  <GridList 
    cols={ window.innerWidth / 400 }
    style={{ margin: '0px' }}
  >
    { !!childCategories && childCategories
      .map((item, index) => (
        <GridListTile 
          key={ index } 
          style={{ height: '60px' }}
        >
          <NavigationButton
            category={ item }
            browserBaseUrl={ browserBaseUrl }
            icon={ navigationItemIcon }
          />
        </GridListTile>
      ))
    }
  </GridList>
);

export { CategoryList };