import * as React from 'react';

import { MaterialCategoryHomeLayout, MaterialCategoryPageLayout } from './materialcategory-browser';

import { RouteComponentProps } from 'react-router';
import { MaterialCategoryActionPanel } from './materialcategory-action-panel';

import { MaterialEditorDialog } from 'src/modules/materials';
import { MaterialCategoryEditorDialog } from './materialcategory-editor';

import { ModalRoute } from 'src/components/ModalRoute';
import { Trans } from 'react-i18next';
import { parseOptionalIntRouteParam } from 'src/utils/BrowserUtils';


interface RouteProps {
  categoryId?: string;
}

class MaterialCategoryView extends React.PureComponent<RouteComponentProps<RouteProps>> {
  render() {
    const { match, location } = this.props;

    const categoryId = parseOptionalIntRouteParam(match.params.categoryId);
    const MainLayout = categoryId ? MaterialCategoryPageLayout : MaterialCategoryHomeLayout;
    return (
      <div id="material-category-page">
        <MainLayout
          categoryId={ categoryId }
          browserBaseUrl=""
          actionPanel={ MaterialCategoryActionPanel }
          indexTitle={ <Trans i18nKey="materialCategories"/> }
          location={ location }
        />
        <ModalRoute
          component={ MaterialEditorDialog }
          path={ `/categories/:categoryId(\\d+)?/material` }
        />
        <ModalRoute
          component={ MaterialCategoryEditorDialog }
          path={ `/categories/:parentId(\\d+)?/category` }
          exact={ true }
        />
        <ModalRoute
          component={ MaterialCategoryEditorDialog }
          path={ `/categories/:categoryId(\\d+)/category/edit` }
        />
      </div>
    );
  }
}

export { MaterialCategoryView };
