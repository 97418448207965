import gql from 'graphql-tag';

import { Fragments as FragmentsRaw } from './userFragments';
import { UserInfo } from 'src/types';

const Fragments = FragmentsRaw();


// Queries
export const FindUserQuery = gql`
  query FindUserQuery {
    user {
      ...UserBasic
      ...UserPermissions
    }
  }
  ${Fragments.basic}
  ${Fragments.permissions}
`;


// Response types
export interface UserResponse {
  user: UserInfo;
}
