import { parseQuery } from 'src/utils/QueryUtils';

import { Location } from 'history';


const parse = (location: Location) => {
  const { 
    actionTypes,
    textFilter,
    startDate,
    endDate,
    entityTypes,
    //materialCategories = {},
    //manufacturers = [],
  } = parseQuery(location);
  
  return {
    //labels: parseLabels(labels),
    //materialCategories: Array.isArray(materialCategories) ? materialCategories.map(Number) : [],
    actionTypes: Array.isArray(actionTypes) ? actionTypes : [],
    textFilter: !!textFilter ? textFilter.toString() : '',
    startDate: !!startDate ? startDate.toString() : '',
    endDate: !!endDate ? endDate.toString() : '',
    entityTypes: Array.isArray(entityTypes) ? entityTypes : [],
  };
};

const parseStartDate = (dateStr: string) => {
  if (!dateStr) {
    return undefined;
  }

  const date = new Date(dateStr);
  date.setHours(0);
  date.setMinutes(0);
  return date.toISOString();
};

const parseEndDate = (dateStr: string) => {
  if (!dateStr) {
    return undefined;
  }

  const date = new Date(dateStr);
  date.setHours(23);
  date.setMinutes(59);
  date.setMilliseconds(999);
  return date.toISOString();
};

export const parseEventFilterVariables = (location: Location) => {
  const { actionTypes, entityTypes, textFilter, startDate, endDate } = parse(location);

  return {
    query: {
      entityFilter: !!entityTypes && !!entityTypes.length ? {
        types: entityTypes,
      } : undefined,
      actionTypes: !!actionTypes && !!actionTypes.length ? actionTypes : undefined,
      query: textFilter || undefined,
      dateRange: {
        start: parseStartDate(startDate),
        end: parseEndDate(endDate)
      },
    },
    filters: {
      entityTypes,
      actionTypes,
      textFilter,
      startDate,
      endDate,
    },
  };
};

export const hasEventFilterVariables = (location: Location) => {
  const { actionTypes, textFilter, startDate, endDate, entityTypes } = parse(location);
  return !!actionTypes.length || !!entityTypes.length || textFilter || startDate || endDate;
};
