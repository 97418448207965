import { 
  CreateMaterialVariantLocalizationResponse, UpdateMaterialVariantLocalizationResponse, 
  DeleteMaterialVariantLocalizationResponse,
  CreateMaterialVariantLocalizationMutation, UpdateMaterialVariantLocalizationMutation, 
  DeleteMaterialVariantLocalizationMutation
} from './materialVariantLocalizationMutations';
import { ApolloClient } from '@apollo/client';
import { 
  GBMaterialIdType, MaterialVariantLocalizationIdType, 
  MaterialVariantLocalizationFieldTypes, MaterialVariantLocalizationVariables 
} from 'src/types';
import { updateMutation, deleteMutation, createMutation, resetCache } from 'src/utils/MutationUtils';


// Create a new material
export const sendCreateMaterialVariantLocalization = async (
  input: MaterialVariantLocalizationFieldTypes, 
  client: ApolloClient<any>
) => {
  const response = await createMutation<
    CreateMaterialVariantLocalizationResponse,
    MaterialVariantLocalizationVariables
  >(
    { input }, CreateMaterialVariantLocalizationMutation, client
  );

  //await client.resetStore();
  //setTimeout(() => client.resetStore());
  return response.createMaterialVariantLocalization;
};

// Update an existing material
export const sendUpdateMaterialVariantLocalization = async (
  id: MaterialVariantLocalizationIdType, 
  input: RecursivePartial<MaterialVariantLocalizationFieldTypes>, 
  client: ApolloClient<any>
) => {
  const response = await updateMutation<
    UpdateMaterialVariantLocalizationResponse, 
    MaterialVariantLocalizationVariables
  >(
    id, { input }, UpdateMaterialVariantLocalizationMutation, client
  );

  //setTimeout(() => client.resetStore());
  return response.updateMaterialVariantLocalization;
};

// Delete material category
export const sendDeleteMaterialVariantLocalization = async (
  id: GBMaterialIdType,
  client: ApolloClient<any>,
  onDeletedCallback: (response: DeleteMaterialVariantLocalizationResponse) => void
) => {
  const response = await deleteMutation< 
    DeleteMaterialVariantLocalizationResponse
  >(
    id, DeleteMaterialVariantLocalizationMutation, client, onDeletedCallback
  );

  resetCache(client);
  return response.deleteMaterialVariantLocalization;
};
