import gql from 'graphql-tag';
import { 
  PaginatedResponse, Event
} from 'src/types';

import { Fragments as MaterialFragmentsRaw } from 'src/service/materials/materialFragments';

import { Fragments as MaterialVariantFragmentsRaw } from 'src/service/materialVariants/materialVariantFragments';
import { Fragments as EventFragmentsRaw } from 'src/service/events/eventFragments';
import { 
  Fragments as MaterialVariantLocalizationFragmentsRaw 
} from 'src/service/materialVariantLocalizations/materialVariantLocalizationFragments';


const MaterialFragments = MaterialFragmentsRaw();
const MaterialVariantFragments = MaterialVariantFragmentsRaw();
const MaterialLocalizationFragments = MaterialVariantLocalizationFragmentsRaw();
const EventFragments = EventFragmentsRaw();


export const EventsQuery = gql`
  query EventsQuery(
    $entityFilter: EventEntityFilter,
    $actionTypes: [EventActionTypes],
    $dateRange: DateRange,
    $query: String,
    $first: Int, 
    $after: String, 
    $orderBy: EventOrder,
    $languageCode: LanguageCode
  ) {
    events(
      entityFilter: $entityFilter,
      actionTypes: $actionTypes,
      dateRange: $dateRange,
      query: $query,
      first: $first, 
      after: $after, 
      orderBy: $orderBy
    ) {
      total,
      edges {
        node {
          ...EventBasic
          ...EventUser
          ...EventDetails
          entity {
            ...EventEntityBasic
            groups {
              ...EventEntityGroupBasic
              material {
                ...MaterialBasic
                variants {
                  ...MaterialVariantBasic
                  ...MaterialVariantOrganization
                  ...MaterialVariantProvider
                  localization(languageCode: $languageCode) {
                    ...MaterialVariantLocalizationBasic
                  }
                }
              }
            }
            materialVariant {
              ...MaterialVariantBasic
              ...MaterialVariantOrganization
              ...MaterialVariantProvider
              material {
                id
              }
            }
            materialVariantLocalization {
              ...MaterialVariantLocalizationBasic
              materialVariant {
                ...MaterialVariantBasic
                ...MaterialVariantOrganization
                ...MaterialVariantProvider
                material {
                  id
                }
              }
            }
            material {
              ...MaterialBasic
              variants {
                ...MaterialVariantBasic
                ...MaterialVariantOrganization
                ...MaterialVariantProvider
                localization(languageCode: $languageCode) {
                  ...MaterialVariantLocalizationBasic
                }
              }
            }
            materialCategory {
              id
            }
            manufacturer {
              id
            }
            labelCategory {
              id
            }
            label {
              id
            }
          }
        }
      }
    }
  }
  ${EventFragments.basic}
  ${EventFragments.user}
  ${EventFragments.details}
  ${EventFragments.entityBasic}
  ${EventFragments.entityGroupBasic}
  ${MaterialVariantFragments.basic}
  ${MaterialVariantFragments.organization}
  ${MaterialVariantFragments.provider}
  ${MaterialLocalizationFragments.basic}
  ${MaterialFragments.basic}
`;


export interface EventsResponse {
  events: PaginatedResponse<Event>;
}
