// Toggle filtering for the selected ID
export const constructFilterArray = <IdType extends string | number>(
  currentSelections: IdType[], id: IdType
) => {
  const newSelectedLabels = [...currentSelections];

  {
    const currentIndex = currentSelections.indexOf(id);
    if (currentIndex === -1) {
      newSelectedLabels.push(id);
    } else {
      newSelectedLabels.splice(currentIndex, 1);
    }
  }

  return newSelectedLabels;
};

export const hasItems = <T>(items?: T[]): boolean => !!items && !!items.length;
export const ensureItems = <T>(items?: T[]): T[] => hasItems(items) ? items as T[] : [];
