import { 
  CreateOrganizationResponse, UpdateOrganizationResponse, DeleteOrganizationResponse, 
  UpdateOrganizationMutation, DeleteOrganizationMutation, CreateOrganizationMutation
} from './organizationMutations';
import { ApolloClient } from '@apollo/client';
import { createMutation, updateMutation, deleteMutation, resetCache } from 'src/utils/MutationUtils';
import { OrganizationIdType } from 'src/types';
import { OrganizationInput, OrganizationVariables } from 'src/types/input/organizationInput';


// Create a new label
export const sendCreateOrganization = async (
  input: OrganizationInput, 
  client: ApolloClient<any>,
  resetStore: boolean = true
) => {
  const response = await createMutation<CreateOrganizationResponse, OrganizationVariables> (
    { input }, CreateOrganizationMutation, client
  );

  if (resetStore) {
    resetCache(client);
  }

  return response.createOrganization;
};

// Update an existing label
export const sendUpdateOrganization = async (
  id: OrganizationIdType, 
  input: RecursivePartial<OrganizationInput>, 
  client: ApolloClient<any>
) => {
  const response = await updateMutation<UpdateOrganizationResponse, OrganizationVariables> (
    id, { input }, UpdateOrganizationMutation, client
  );

  resetCache(client);
  return response.updateOrganization;
};

// Delete label
export const sendDeleteOrganization = async (
  id: number,  
  client: ApolloClient<any>,
  //onDeletedCallback: (response: DeleteOrganizationResponse) => void
) => {
  const response = await deleteMutation<DeleteOrganizationResponse>(
    id, DeleteOrganizationMutation, client, //onDeletedCallback
  );

  resetCache(client);
  return response.deleteOrganization;
};