import gql from 'graphql-tag';
import { DataSourceItem, GBMaterialType, ProviderType } from 'src/types';



// Queries
export const ImportProviderMaterialMutation = gql`
  mutation ImportProviderMaterialMutation($input: ImportMaterialInput!) {
    importProviderMaterial(input: $input) {
      id
      material {
        id
      }
    }
  }
`;

// Response types



export const LinkProviderMaterialMutation = gql`
  mutation LinkProviderMaterialMutation($input: AddMaterialDataSourceItemInput!) {
    linkProviderMaterial(input: $input) {
      id
      provider {
        id
        name
      }
    }
  }
`;

export const SetActiveMaterialDataSourceItemMutation = gql`
  mutation SetActiveMaterialDataSourceItemMutation($input: SetActiveMaterialDataSourceItemInput!) {
    setActiveMaterialDataSourceItem(input: $input) {
      id
    }
  }
`;

export const RemoveDataSourceItemMutation = gql`
  mutation RemoveDataSourceItemMutation($id: Int!) {
    removeDataSourceItem(id: $id) {
      id
      provider {
        id
        name
      }
    }
  }
`;


// Response types

export interface ImportProviderMaterialResponse {
  importProviderMaterial: {
    id: number;
    material: Pick<GBMaterialType, 'id'>
  };
}
export interface LinkProviderMaterialResponse {
  linkProviderMaterial: {
    provider: Pick<ProviderType, 'id' | 'name'>;
  };
}

export interface SetActiveMaterialDataSourceItemResponse {
  setActiveMaterialDataSourceItem: Pick<GBMaterialType, 'id'>;
}

export interface RemoveDataSourceItemResponse {
  removeDataSourceItem: Pick<DataSourceItem, 'id' | 'provider'>;
}
