import * as React from 'react';

import { LabelPageLayout } from './label-browser';

import { RouteComponentProps } from 'react-router';
import { LabelActionPanel } from './label-action-panel';

import { LabelEditorDialog } from './label-dialog';

import { ModalRoute } from 'src/components/ModalRoute';
import { Trans } from 'react-i18next';
import { parseOptionalIntRouteParam } from 'src/utils/BrowserUtils';


interface RouteProps {
  labelId?: string;
}

class LabelView extends React.PureComponent<RouteComponentProps<RouteProps>, {}> {
  render() {
    const { match, location } = this.props;
    const { labelId } = match.params;

    return (
      <div id="label-page">
        <LabelPageLayout
          labelId={ parseOptionalIntRouteParam(labelId) }
          browserBaseUrl={ category => {
            return !!category.parent ? '/labels' : '/label-categories';
          } }
          actionPanel={ LabelActionPanel }
          location={ location }
          indexTitle={ <Trans i18nKey="labels"/> }
        />
        <ModalRoute
          component={ LabelEditorDialog }
          path={ `/labels/:labelId(\\d+)/edit` }
        />
      </div>
    );
  }
}

export { LabelView };
