import { Permission } from 'src/constants';

import {
  Action, ActionHandlerProps,
  GBMaterialType, GBLabelType, GBLabelCategoryType, ActionList
} from 'src/types';

import { Actions } from 'src/service/materialsLabels';

import Add from '@material-ui/icons/Add';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import { hasMaterialEditAccess } from 'src/utils/AccessUtils';


interface MaterialLabelActionEditProps {
  material: GBMaterialType;
  labels: GBLabelType[];
  category: GBLabelCategoryType;
}

type EditProps = ActionHandlerProps & MaterialLabelActionEditProps;

const handleCreate = (props: EditProps) => {
  const { material, history } = props;
  const url = `/materials/${material.id}/labels`;
  history.push(url);
};

const handleEdit = (props: EditProps) => {
  const { material, category, history } = props;

  const url = `/materials/${material.id}/labels/${category.id}`;
  history.push(url);
};

const handleDelete = async (props: EditProps) => {
  const { labels, material, client } = props;
  return Actions.sendRemoveMaterialLabels(
    labels.map(label => label.id),
    material.id,
    client
  );
};



export const hasEditAccess = ({ auth, material }: EditProps) => {
  return hasMaterialEditAccess(material, auth, Permission.MATERIALS_EDIT, Permission.MATERIAL_VARIANTS_EDIT);
};

export const MaterialLabelCreateAction: Action<MaterialLabelActionEditProps> = {
  title: 'actions.addLabelCategory',
  icon: Add,
  handler: handleCreate,
  access: hasEditAccess,
};

export const MaterialLabelEditActions: ActionList<MaterialLabelActionEditProps> = {
  EDIT: {
    title: 'actions.edit',
    icon: Edit,
    handler: handleEdit,
    access: hasEditAccess,
  }, 
  DELETE: {
    title: 'actions.delete',
    icon: Delete,
    handler: handleDelete,
    onSuccess: 'actions.notifications.materialLabelCategoryRemoved',
    onFailed: 'actions.notifications.materialLabelCategoryRemoveFailed',
    access: hasEditAccess,
    confirmation: {
      title: 'actions.confirmations.removeMaterialLabelCategory',
      description: 'actions.confirmations.removeMaterialLabelCategoryMessage',
    },
  }
};
