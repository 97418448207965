import * as React from 'react';

import { ThunkedCategoryUrl } from '../NavigationButton';

import { MaterialCategoryBaseType } from 'src/types';

import { isRootCategory, RootCategoryNum } from '../RootCategory';
import { NavigationBarSection } from './NavigationBarSection';


interface NavigationBarProps {
  category: MaterialCategoryBaseType;
  browserBaseUrl: ThunkedCategoryUrl;
}

const NavigationBar: React.FC<NavigationBarProps> = ({ category, browserBaseUrl }) => {
  const items = [];

  {
    let cur: MaterialCategoryBaseType | undefined = category;
    for (;;) {
      if (!cur) {
        cur = RootCategoryNum;
      }

      items.unshift(
        <NavigationBarSection
          key={ cur.id }
          category={ cur }
          browserBaseUrl={ browserBaseUrl }
          hasItems={ !!items.length }
          isSelected={ cur.id === category.id }
        />
      );

      if (isRootCategory(cur)) {
        break;
      }

      cur = cur.parent;
    }
  }

  return (
    <div style={{ display: 'flex' }}>
      { items }
    </div>
  );
};

export { NavigationBar };
