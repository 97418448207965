import gql from 'graphql-tag';

import { Fragments as LabelCategoryFragmentsRaw } from 'src/service/labelCategories/labelCategoryFragments';

const LabelCategoryFragments = LabelCategoryFragmentsRaw();

export const Fragments = () => ({
  basic: gql`
    fragment LabelBasic on Label {
      id
      name
      localization {
        id
        name
        language {
          code
        }
      }
    }
  `,
  category: gql`
    fragment LabelCategory on Label {
      category {
        ...LabelCategoryBasic
      }
    }
    ${LabelCategoryFragments.basic}
  `,
});