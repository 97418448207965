import React from "react";
import { Manifest } from "../Models/Manifest";
import useStyles from "./SelectionView.styles";
import LimitedString from "src/components/LimitedString";

interface ResultPanelProps {
  options: Manifest[];
  selectedAsset: string;
  onSelectAsset: (guid: string) => void;
}
const ResultPanel = ({
  options,
  selectedAsset,
  onSelectAsset,
}: ResultPanelProps) => {
  const classes = useStyles();
  if (!options.length) return null;
  return (
    <div className={classes.resultsPanel}>
      {options.map((option) => {
        return (
          <div
            key={option.guid}
            onClick={() => onSelectAsset(option.guid)}
            className={`${classes.assetOption} ${
              selectedAsset === option.guid && classes.selectedAsset
            }`}
          >
            <LimitedString
              style={{ lineBreak: "anywhere" }}
              text={option.name}
              maxLength={100}
              cutIn={98}
              showAll={true}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ResultPanel;
