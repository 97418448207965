import { Permission } from 'src/constants';

import {
  Action, ActionHandlerProps,
  GBMaterialType, MaterialVariant, ActionList, MaterialVariantType 
} from 'src/types';

import { Actions } from 'src/service/materialVariants';

import Add from '@material-ui/icons/Add';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import Copy from '@material-ui/icons/FileCopy';


interface VariantEditActionProps {
  material: GBMaterialType;
  variant: MaterialVariant;
}

interface VariantCreateActionProps {
  material: GBMaterialType;
}

type CreateProps = ActionHandlerProps & VariantCreateActionProps;
type EditProps = ActionHandlerProps & VariantEditActionProps;

const handleCreate = (props: CreateProps) => {
  const { material, history } = props;
  const url = `/materials/${material.id}/variants/create`;
  history.push({
    pathname: url,
    search: history.location.search,
  });
};

const handleCreateCopy = (props: EditProps) => {
  const { material, variant, history } = props;

  const url = `/materials/${material.id}/variants/${variant.id}/copy`;
  history.push({
    pathname: url,
    search: history.location.search,
  });
};

const handleEdit = (props: EditProps) => {
  const { material, variant, history } = props;

  const url = `/materials/${material.id}/variants/${variant.id}/edit`;
  history.push({
    pathname: url,
    search: history.location.search,
  });
};

const handleDelete = async (props: EditProps) => {
  const { variant, client } = props;
  return Actions.sendDeleteMaterialVariant(variant.id, client);
};

const getVariantOrganization = (props: EditProps) => {
  return props.variant.organization;
};

const MaterialVariantCreateAction: Action<VariantCreateActionProps> = {
  title: 'actions.create',
  icon: Add,
  handler: handleCreate,
  access: Permission.MATERIAL_VARIANTS_CREATE,
};


const filterEdit = (props: EditProps) => {
  return props.variant.type === MaterialVariantType.BASELINE || props.variant.type === MaterialVariantType.ORGANIZATION;
};

const filterDelete = (props: EditProps) => {
  return props.variant.type === MaterialVariantType.ORGANIZATION && props.material.variants.length > 1;
};

const MaterialVariantEditActions: ActionList<VariantEditActionProps> = {
  COPY: {
    title: 'actions.createCopy',
    icon: Copy,
    handler: handleCreateCopy,
    access: Permission.MATERIAL_VARIANTS_CREATE,
  }, 
  EDIT: {
    title: 'actions.edit',
    icon: Edit,
    handler: handleEdit,
    access: Permission.MATERIAL_VARIANTS_EDIT,
    itemOrganizationGetter: getVariantOrganization,
    filter: filterEdit,
  }, 
  DELETE: {
    title: 'actions.delete',
    icon: Delete,
    handler: handleDelete,
    onSuccess: 'actions.notifications.materialVariantDeleted',
    onFailed: 'actions.notifications.materialVariantDeleteFailed',
    access: Permission.MATERIAL_VARIANTS_DELETE,
    filter: filterDelete,
    confirmation: {
      title: 'actions.confirmations.deleteMaterialVariant',
      description: 'actions.confirmations.deleteMaterialVariantMessage',
    },
    itemOrganizationGetter: getVariantOrganization,
  }
};

export { MaterialVariantCreateAction, MaterialVariantEditActions };
