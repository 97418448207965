import { Location } from 'history';
import { PaginatedResponse } from 'src/types';

import { parseQuery, ensureString, ensureStringMaybe } from './QueryUtils';
import { Sorting } from '@devexpress/dx-react-grid';
import { loadLocalProperty } from './BrowserUtils';
import { snakeCase } from 'lodash';


const offsetToCursor = (offset: number): string => {
  return btoa(`arrayconnection:${(Math.max(offset - 1, 0)).toString()}`);
};

interface OrderByType {
  field: string;
  direction: string;
}

export type PaginationQueryType = { 
  pagesize: string; 
  page: string;
  orderby?: string;
  direction?: string;
  pageSizeStorageKey?: string;
};

export interface PaginationVariablesType {
  first: number;
  after?: string;
  orderBy: OrderByType;
}

export enum SortDirectionEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

const parsePageSize = (paginationInfo: PaginationQueryType) => {
  if (!!paginationInfo.pageSizeStorageKey) {
    const value = loadLocalProperty(paginationInfo.pageSizeStorageKey);
    if (!!value) {
      return value;
    }
  }

  return paginationInfo.pagesize;
};

export const parsePaginationVariables = (
  location: Location, 
  defaultVariables: PaginationQueryType
) => {
  const query = parseQuery(location);
  
  const pageNum = Math.max(parseInt(ensureString(query.page, defaultVariables.page)), 1) - 1;
  const pageSizeNum = Math.max(parseInt(ensureString(query.pagesize, parsePageSize(defaultVariables))), 1);

  const orderby = ensureStringMaybe(query.orderby, defaultVariables.orderby);
  const direction = ensureStringMaybe(query.direction, defaultVariables.direction);
  
  return {
    query: {
      first: pageSizeNum,
      after: pageNum ? offsetToCursor(pageNum * pageSizeNum) : undefined,
      orderBy: {
        field: orderby ? snakeCase(orderby).toUpperCase() : undefined,
        direction: direction === 'asc' ? SortDirectionEnum.ASC : SortDirectionEnum.DESC,
      }
    } as PaginationVariablesType, 
    table: {
      pageSize: pageSizeNum,
      currentPage: pageNum,
      sorting: [
        {
          columnName: orderby,
          direction,
        }
      ] as Sorting[],
    }
  };
};

export const normalizePaginatedResponse = <T>(response: PaginatedResponse<T>) => {
  return response.edges.map(edge => edge.node);
};
