import gql from 'graphql-tag';
import { GBLabelCategoryType, PaginatedResponse, GBLabelType } from 'src/types';

import { Fragments as FragmentsRaw } from './labelCategoryFragments';
import { Fragments as LabelFragmentsRaw } from 'src/service/labels/labelFragments';

const Fragments = FragmentsRaw();
const LabelFragments = LabelFragmentsRaw();


// Queries
export const LabelCategoryQuery = gql`
  query LabelCategoryQuery($categoryId: Int!) {
    labelCategory(id: $categoryId) {
      ...LabelCategoryBasic
    }
  }
  ${Fragments.basic}
`;

export const LabelCategoryLabelsQuery = gql`
  query LabelCategoryLabelsQuery(
    $categoryId: Int!
  ) {
    labelCategory(id: $categoryId) {
      ...LabelCategoryBasic
      labels {
        ...LabelBasic
      }
    }
  }
  ${Fragments.basic}
  ${LabelFragments.basic}
`;

export const LabelCategoryListQuery = gql`
  query LabelCategoryListQuery {
    labelCategories {
      edges {
        node {
          ...LabelCategoryBasic
        }
      }
    }
  }
  ${Fragments.basic}
`;


// Response types
export interface LabelCategoryResponse {
  labelCategory: GBLabelCategoryType;
}

interface LabelCategoryWithLabels extends GBLabelCategoryType {
  labels: GBLabelType[];
}

export interface LabelCategoryLabelsResponse {
  labelCategory: LabelCategoryWithLabels;
}

export interface LabelCategoryListResponse {
  labelCategories: PaginatedResponse<GBLabelCategoryType>;
}
