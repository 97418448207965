import * as React from 'react';

import { 
  ManufacturerEditorDataDecorator, ManufacturerEditorDataDecoratorChildProps
} from '../../decorators/ManufacturerEditorDataDecorator';

import { ManufacturerEditorForm, mapFields  } from './ManufacturerEditorForm';
import { createEditorDialog } from 'src/components/editor';

import { RouterModalProps, ManufacturerFieldTypes } from 'src/types';
import { Trans } from 'react-i18next';


type ManufacturerEditorDialogProps = 
  ManufacturerEditorDataDecoratorChildProps & RouterModalProps;


const EditorDialog = createEditorDialog<
  ManufacturerFieldTypes, 
  ManufacturerEditorDataDecoratorChildProps
>('manufacturerEditor', mapFields);

class ManufacturerEditorDialog extends React.PureComponent<ManufacturerEditorDialogProps> {
  render() {
    const { manufacturer, ...other } = this.props;
    return (
      <EditorDialog
        title={ <Trans i18nKey={ !!manufacturer ? 'actions.editManufacturer' : 'actions.createManufacturer' }/> }
        manufacturer={ manufacturer }
        fieldsComponent={ ManufacturerEditorForm }
        { ...other }
      />
    );
  }
}

const ManufacturerEditorDialogDecorated = (
  ManufacturerEditorDataDecorator(ManufacturerEditorDialog)
);

export { ManufacturerEditorDialogDecorated as ManufacturerEditorDialog };
