import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { Typography } from '@material-ui/core';


export interface MaterialProductInfoLinkProps extends LinkProps {
  icon?: React.ReactNode;
  textStyle?: React.CSSProperties;
}

export const MaterialProductInfoLinkStyle: React.CSSProperties = {
  width: 'fit-content',
  margin: '3px 0px',
  alignItems: 'center',
  color: 'gray',
};

export const MaterialProductInfoLinkListStyle: React.CSSProperties = {
  ...MaterialProductInfoLinkStyle,
  display: 'flex',
};

export const MaterialProductInfoLinkTextStyle: React.CSSProperties = {
  color: 'gray', 
  textDecoration: 'underline',
};


export const MaterialProductInfoLink: React.FC<MaterialProductInfoLinkProps> = (
  { children, icon: Icon, style, textStyle, ...other }
) => (
  <Link
    { ...other }
    style={{ 
      ...(!!Icon ? MaterialProductInfoLinkListStyle : MaterialProductInfoLinkStyle),
      ...style,
    }}
  >
    { Icon }
    { !!Icon ? (
      <Typography 
        style={{ 
          ...MaterialProductInfoLinkTextStyle,
          ...textStyle,
          marginLeft: !!Icon ? '3px' : '0px',
        }}
      >
        { children }
      </Typography>
    ) : children }
  </Link>
);
