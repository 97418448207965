import * as React from 'react';

import { 
  TextField, TextFieldComponent,
  MaterialCategorySelectField, MaterialCategorySelectFieldComponent 
} from 'src/components/form';

import { 
  MaterialCategoryEditorDataDecoratorChildProps
 } from '../../decorators/MaterialCategoryEditorDataDecorator';
import { InjectedEditorFormProps, FieldMapperConfigProps } from 'src/components/editor';
import { Trans } from 'react-i18next';
import { max250Validator } from 'src/utils/FieldValidators';
import { LanguageSelectField, LanguageSelectFieldComponent } from 'src/components/form/LanguageSelectField';
import { parseLocalizationLanguage } from 'src/utils/LanguageUtils';
import { MaterialCategoryFieldTypes } from 'src/types';
import { parseOptionalIntRouteParam } from 'src/utils/BrowserUtils';


type MaterialCategoryEditorFormProps = 
  InjectedEditorFormProps<MaterialCategoryFieldTypes, MaterialCategoryEditorDataDecoratorChildProps>;

const MaterialCategoryEditorForm: React.FC<MaterialCategoryEditorFormProps> = ({ category }) => {
  return (
    <>
      <TextField 
        required 
        name="name" 
        component={ TextFieldComponent } 
        label={ <Trans i18nKey="name"/> }
        validate={ max250Validator }
      />
      <MaterialCategorySelectField 
        disabled={ !!category } 
        name="parentId" 
        component={ MaterialCategorySelectFieldComponent } 
        label={ <Trans i18nKey="parentCategory"/> }
      />
      <LanguageSelectField
        required
        name="languageCode"
        component={ LanguageSelectFieldComponent }
        label={ <Trans i18nKey="language"/> }
        disabled={ !!category }
      />
    </>
  );
};

const mapFields = (
  state: any,
  { category, match }: MaterialCategoryEditorDataDecoratorChildProps
): FieldMapperConfigProps<MaterialCategoryFieldTypes> => {
  const languageCode = parseLocalizationLanguage(category);
  if (!category) {
    const { parentId } = match.params;
    // Creating new, set initial parent
    return {
      initialValues: {
        parentId: parseOptionalIntRouteParam(parentId),
        languageCode,
      }
    };
  }

  // Editing existing category, convert the data
  const { name, parent } = category;
  return {
    initialValues: {
      name,
      parentId: !!parent ? parent.id : undefined,
      languageCode,
    }
  };
};

export { MaterialCategoryEditorForm, mapFields };
