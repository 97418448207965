import * as React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';


const ProgressCard: React.FC = ({ children }) => (
  <CardContent
    style={{
      display: 'flex',
      alignItems: 'center',
    }}
  >
    <CircularProgress 
      style={{
        marginRight: '10px',
      }}
    />
    <Typography variant="h5" component="h2">
      { children }
    </Typography>
  </CardContent>
);

export { ProgressCard };