import gql from 'graphql-tag';


// Queries
export const AddMaterialLabelMutation = gql`
  mutation AddMaterialLabelMutation($input: AddMaterialLabelInput!, $rewardId: String) {
    addMaterialLabel(input: $input, rewardId: $rewardId) {
      label {
        id,
        name,
      },
    }
  }
`;

export const RemoveMaterialLabelMutation = gql`
  mutation RemoveMaterialLabelMutation($input: RemoveMaterialLabelInput!) {
    removeMaterialLabel(input: $input) {
      label {
        id,
        name,
      },
    }
  }
`;


// Response types
export interface AddMaterialLabelResponse {
  addMaterialLabel: {
    material: number;
    label: number;
  };
}

export interface RemoveMaterialLabelResponse {
  removeMaterialLabel: {
    material: number;
    label: number;
  };
}
