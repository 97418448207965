import * as React from 'react';

import { graphql } from '@apollo/client/react/hoc';
import { Location } from 'history';

import {
  MaterialListColumnNames, MaterialTable, MaterialTableConfigProps, DefaultLocalMaterialListPagination
} from 'src/components/browser-layout';

import { DataLoaderDecorator, DataProps } from 'src/decorators/DataLoaderDecorator';
import { Queries } from 'src/service/materialCategories';

import { parsePaginationVariables } from 'src/utils/PaginationUtils';

import { parseMaterialFilterVariables } from 'src/utils/MaterialFilterUtils';
import { GBMaterialCategoryType, GBMaterialType, ViewMaterialType } from 'src/types';
import { SelectedOrganizationContext } from 'src/decorators/SelectedOrganizationProvider';
import { localMaterialsToViewMaterials } from 'src/utils/MaterialVariantUtils';
import { useTranslation } from 'react-i18next';
import { MaterialSelectionActions } from 'src/actions/MaterialSelectionActions';


interface MaterialCategoryMaterialTableProps extends MaterialTableConfigProps {
  category: GBMaterialCategoryType;
  location: Location;
}

const withMaterials = graphql<MaterialCategoryMaterialTableProps, Queries.MaterialCategoryMaterialsResponse>(
  Queries.MaterialCategoryMaterialsQuery, {
    options: ({ category, location }) => {
      return {
        variables: { 
          categoryId: category.id,
          ...parsePaginationVariables(location, DefaultLocalMaterialListPagination).query,
          ...parseMaterialFilterVariables(location).query,
        }
      };
    },
  }
);

const Columns: MaterialListColumnNames = [ 'image', 'name', 'manufacturer' ];


type Props = MaterialCategoryMaterialTableProps & 
  DataProps<Queries.MaterialCategoryMaterialsResponse>;

const MaterialCategoryMaterialTable: React.FC<Props> = ({ data, category }) => {
  const { t } = useTranslation();
  const { materials } = data.materialCategory!;
  return (
    <SelectedOrganizationContext.Consumer>
      { orgContext => (
        <MaterialTable<GBMaterialType & ViewMaterialType>
          materials={ localMaterialsToViewMaterials(materials, orgContext!, t) }
          totalCount={ materials.total }
          columns={ Columns }
          selectionActions={ MaterialSelectionActions }
        />
      ) }
    </SelectedOrganizationContext.Consumer>
  );
};

const MaterialCategoryMaterialTableDecorated = withMaterials(
  DataLoaderDecorator<MaterialCategoryMaterialTableProps, Queries.MaterialCategoryMaterialsResponse>(
    MaterialCategoryMaterialTable
  )
);

export { MaterialCategoryMaterialTableDecorated as MaterialCategoryMaterialTable };
