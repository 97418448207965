import { 
  CreateMaterialResponse, UpdateMaterialResponse, DeleteMaterialResponse,
  CreateMaterialMutation, UpdateMaterialMutation, DeleteMaterialMutation, 
  SyncMaterialMutation, SyncMaterialResponse
} from './materialMutations';
import { ApolloClient } from '@apollo/client';
import { GBMaterialIdType, MaterialSyncInput, MaterialVariables, MaterialFieldTypes } from 'src/types';
import { updateMutation, createMutation, deleteMutation, inputMutation, resetCache } from 'src/utils/MutationUtils';


// Create a new material
export const sendCreateMaterial = async (
  formFields: any, 
  client: ApolloClient<any>
) => {
  const input = formFields;
  const response = await createMutation<
    CreateMaterialResponse,
    MaterialVariables
  >(
    { input }, CreateMaterialMutation, client
  );

  //await client.resetStore();
  return response.createMaterial;
};

// Update an existing material
export const sendUpdateMaterial = async (
  id: GBMaterialIdType, 
  input: RecursivePartial<MaterialFieldTypes>, 
  client: ApolloClient<any>
) => {
  const response = await updateMutation<
    UpdateMaterialResponse,
    MaterialVariables
  >(
    id, { input }, UpdateMaterialMutation, client
  );

  //setTimeout(() => client.resetStore());
  return response.updateMaterial;
};

// Delete material category
export const sendDeleteMaterial = async (
  id: GBMaterialIdType,
  client: ApolloClient<any>,
  onDeletedCallback?: (response: DeleteMaterialResponse) => void
) => {
  const response = await deleteMutation<
    DeleteMaterialResponse
  >(
    id, DeleteMaterialMutation, client, onDeletedCallback
  );

  resetCache(client);
  return response.deleteMaterial;
};

// Sync material from a provider
export const sendSyncMaterial = async (
  input: MaterialSyncInput, 
  client: ApolloClient<any>
) => {
  const response = await inputMutation<
    SyncMaterialResponse
  >(
    { input }, SyncMaterialMutation, client
  );

  resetCache(client);
  return response.syncMaterial;
};
