import { createStyles } from "@material-ui/core/styles";
import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    interface: {
      width: "300px",
      background: "white",
      position: "absolute",
    },
    frameViewer: {
      height: "600px",
      width: "100%",
      minWidth: "700px",
      border: "none",
    },
    resultsPanel: {
      display: "flex",
      minHeight: "50px",
      borderRadius: "0px 0px 4px 0px",
      flexDirection: "column",
      gap: "0.2rem",
      maxHeight: "500px",
      overflow: "scroll",
      overflowX: "hidden",
    },
    assetOption: {
      cursor: "pointer",
      borderBottom: "1px solid lightgray",
      padding: "0.5rem",
    },
    selectedAsset: {
      background: "lightgray",
    },
  })
);

export default useStyles;
