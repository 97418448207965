import * as React from 'react';

import { graphql } from '@apollo/client/react/hoc';

import { BrowserLayout, RootCategoryStr } from 'src/components/browser-layout';
import { ProviderMaterialCategoryIdType } from 'src/types';

import { DataLoaderDecorator } from 'src/decorators/DataLoaderDecorator';

import { Queries } from 'src/service/providers';
import { ProviderCategoryPagePropsType } from './ProviderMaterialCategoryPageLayout';

import { NoBrowserPanel } from './NoBrowserPanel';
import { ProviderTitle } from 'src/modules/providers';
import { Trans } from 'react-i18next';
import { ProviderFeatures, hasProviderFeature } from 'src/constants/ProviderFeatures';


type ProviderHomeLayoutPropsType = ProviderCategoryPagePropsType;

const withProviderCategories = graphql<ProviderHomeLayoutPropsType, Queries.ProviderMaterialCategoryListResponse>(
  Queries.ProviderMaterialCategoryListQuery, { 
    options: ({ providerId }) => {
      return {
        variables: { 
          providerId,
        }
      };
    },
  }
);

const ProviderHomeLayout = withProviderCategories(DataLoaderDecorator(({ data, ...other }) => {
  const provider = data.provider!;
  return (
    <BrowserLayout<ProviderMaterialCategoryIdType>
      category={ RootCategoryStr }
      childCategories={ provider.materialCategories }
      indexTitle={ <Trans i18nKey="materialCategories"/> }
      subTitle={ <ProviderTitle provider={ provider }/> }
      { ...other }
    >
      { !hasProviderFeature(provider, ProviderFeatures.BROWSER) && ( 
        <NoBrowserPanel
          provider={ provider } 
        />
      ) }
    </BrowserLayout>
  );
}));

export { ProviderHomeLayout };