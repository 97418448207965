import * as React from 'react';

import { 
  LabelCategorySelectField, LabelCategorySelectFieldComponent,
  TextField, TextFieldComponent } from 'src/components/form';

import { LabelEditorDataDecoratorChildProps } from '../../decorators/LabelEditorDataDecorator';
import { FieldMapperConfigProps, InjectedEditorFormProps } from 'src/components/editor';
import { Trans } from 'react-i18next';
import { max250Validator } from 'src/utils/FieldValidators';
import { parseLocalizationLanguage } from 'src/utils/LanguageUtils';
import { LanguageSelectField, LanguageSelectFieldComponent } from 'src/components/form/LanguageSelectField';
import { LabelFieldTypes } from 'src/types';
import { parseIntRouteParam } from 'src/utils/BrowserUtils';


type LabelEditorFormProps = 
  InjectedEditorFormProps<LabelFieldTypes, LabelEditorDataDecoratorChildProps>;

class LabelEditorForm extends React.Component<LabelEditorFormProps> {
  render() {
    const { label } = this.props;
    return (
      <>
        <TextField
          required
          name="name" 
          component={ TextFieldComponent } 
          label={ <Trans i18nKey="name"/> }
          validate={ max250Validator }
        />
        <LabelCategorySelectField 
          required 
          name="categoryId" 
          component={ LabelCategorySelectFieldComponent } 
          label={ <Trans i18nKey="labelCategory"/> }
        />
        <LanguageSelectField
          required
          name="languageCode"
          component={ LanguageSelectFieldComponent }
          label={ <Trans i18nKey="language"/> }
          disabled={ !!label }
        />
      </>
    );
  }
}

export const mapFields = (
  state: any,
  { label, match }: LabelEditorDataDecoratorChildProps
): FieldMapperConfigProps<LabelFieldTypes> => {
  const languageCode = parseLocalizationLanguage(label);
  if (!label) {
    // Creating new, set initial category
    return {
      initialValues: {
        categoryId: parseIntRouteParam(match.params.labelCategoryId),
        languageCode,
      }
    };
  }

  // Editing existing, convert the data
  const { name, category } = label;
  return {
    initialValues: {
      name,
      categoryId: !!category ? category.id : undefined,
      languageCode,
    }
  };
};

export { LabelEditorForm };
