import React, { ChangeEvent, useState } from "react";
import { TextField } from "@material-ui/core";
import styled from "styled-components";
import Search from "@material-ui/icons/Search";

export const StyledSearch = styled(TextField)`
  && {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-grow: 1;

    input {
      width: 100%;
      padding: 12px 6px;
    }
  }
`;

interface SearchBarProps {
  onSubmit: (val: string) => void;
}

const SearchBar = ({ onSubmit }: SearchBarProps) => {
  const [searchText, setSearchText] = useState<string>("");

  const readInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" || e.type === "click") {
      onSubmit(searchText);
    }
  };

  return (
    <StyledSearch
      focused
      variant={"outlined"}
      margin="dense"
      value={searchText}
      onChange={({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
        setSearchText(currentTarget.value);
      }}
      onKeyDown={readInput}
      placeholder={"Search assets"}
      InputProps={{
        endAdornment: (
          <Search
            style={{ cursor: "pointer" }}
            onClick={(e: any) => readInput(e)}
          />
        ),
      }}
      style={{ width: "100%" }}
    />
  );
};

export default SearchBar;
