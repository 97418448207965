import * as React from 'react';

import { Queries } from 'src/service/events';

import { DataProps, DataLoaderDecorator } from 'src/decorators/DataLoaderDecorator';
import { Message } from 'src/components';

import { normalizePaginatedResponse, parsePaginationVariables, PaginationQueryType } from 'src/utils/PaginationUtils';
import { PaginatedTable, PaginatedTableColumnWidthInfo } from 'src/components/table';
import { graphql } from '@apollo/client/react/hoc';
import { Location } from 'history';
import { EventTableCell } from './EventTableCell';
import { parseEventFilterVariables } from 'src/utils/EventFilterUtils';
import { TFunction } from 'i18next';


export interface EventTableProps extends EventTableQueryProps {
  pageTitle: string;
  t: TFunction;
}


interface EntityFilter {
  id: number;
  types: string[];
  matchGroups: boolean;
}

export interface EventTableQueryProps {
  entityFilter?: EntityFilter;
  location: Location;
}


export const DefaultEventListPagination: PaginationQueryType = {
  orderby: 'TIMESTAMP',
  page: '1',
  pagesize: '25',
  direction: 'desc',
};

const withEventData = graphql<EventTableProps, Queries.EventsResponse>(
  Queries.EventsQuery, {
    options: ({ entityFilter, location }) => { 
      const filterVariables = parseEventFilterVariables(location).query;
      return {
        variables: { 
          ...parsePaginationVariables(location, DefaultEventListPagination).query,
          ...filterVariables,
          entityFilter: entityFilter || filterVariables.entityFilter,
        },
        fetchPolicy: 'network-only',
      };
    }
  }
);


type EventColumn = 'event' | 'name' | 'user' | 'date';
export type EventListColumnNames = EventColumn[];

const Columns: EventListColumnNames = [
  'event',
  'name',
  'user',
  'date',
];


const DefaultColumnWidths: PaginatedTableColumnWidthInfo[] = [
  {
    columnName: 'event',
    width: '20%',
    minWidth: '100px',
  }, {
    columnName: 'name',
    width: '40%',
    minWidth: '150px',
  }, {
    columnName: 'user',
    width: '20%',
    minWidth: '100px',
  }, {
    columnName: 'date',
    width: '150px',
  }
];

const EventTable: React.FC<EventTableProps & DataProps<Queries.EventsResponse>> = (
  { t, data, pageTitle, ...other }
) => {
  const events = normalizePaginatedResponse(data.events!);
  if (!events.length) {
    return (
      <Message
        title={ t('noEvents') }
      />
    );
  }

  return (
    <PaginatedTable
      columns={ Columns }
      rows={ events }
      totalCount={ data.events!.total }
      defaultPagination={ DefaultEventListPagination }
      defaultColumnWidths={ DefaultColumnWidths }
      cellComponent={ cellProps => (
        <EventTableCell
          t={ t }
          pageTitle={ pageTitle }
          { ...cellProps }
        />
      ) }
      { ...other }
    />
  );
};

const EventsListDecorated = withEventData(DataLoaderDecorator<EventTableProps, Queries.EventsResponse>(EventTable));

export { EventsListDecorated as EventTable };
