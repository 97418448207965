import React, { useMemo, useState } from 'react';

import SearchBar from 'material-ui-search-bar';
import { parseSearchVariables, SearchQueryType } from 'src/utils/SearchUtils';
import { stringifyQuery } from 'src/utils/QueryUtils';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'src/effects';


interface SearchInputProps {
  style?: object;
}

const SearchInput = React.memo<SearchInputProps>(props => {
  const { t } = useTranslation();
  const { history, location } = useRouter();

  const urlQuery = useMemo(
    () => {
      return parseSearchVariables(history.location).query;
    },
    [ history.location ]
  );

  const [ value, setValue ] = useState(urlQuery);

  const handleSubmit = (query: SearchQueryType) => {
    if (!query || query.length < 2) {
      return;
    }

    const search = stringifyQuery({
      query,
    });

    const { pathname } = location;

    // Keep the possible provider selected if we are already viewing results for an earlier search 
    const newPath = pathname.indexOf('/search/results') === 0 ? pathname : '/search/results';
    history.push({
      pathname: newPath,
      search,
    });
  };

  const onChange = (newValue: string) => {
    setValue(newValue);
  };

  const { style } = props;
  return (
    <SearchBar
      onRequestSearch={ handleSubmit as any }
      placeholder={ t('prompts.search') }
      style={ style }
      value={ value }
      onChange={ onChange }
      onCancelSearch={ () => setValue('') }
    />
  );
});

export { SearchInput };
