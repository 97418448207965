import gql from 'graphql-tag';

//import { Fragments as LabelCountFragmentsRaw } from 'src/service/labelCounts/labelCountFragments';

//const LabelCountFragments = LabelCountFragmentsRaw();

export const Fragments = () => ({
  basic: gql`
    fragment MaterialCategoryBasic on MaterialCategory {
      id
      name
      localization {
        id
        name
        language {
          code
        }
      }
    }
  `,
  parent: gql`
    fragment MaterialCategoryParent on MaterialCategory {
      parent {
        id
        name
      }
    }
  `,
  /*labelCounts: gql`
    fragment MaterialCategoryLabelCounts on MaterialCategory {
      labelCounts {
        ...LabelCount
      }
    }
    ${LabelCountFragments.labelCount}
  `,*/
});