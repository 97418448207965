import * as React from 'react';

import { NavLink, NavLinkProps } from 'react-router-dom';
import { default as Tab, TabProps } from '@material-ui/core/Tab';
import { Search } from 'history';

type ExternalProps = TabProps & NavLinkProps;

export interface RouteTabProps extends ExternalProps {
  to: string;
  search?: Search;
}

export const RouteTab: React.FC<RouteTabProps> = (
  { to, location, search, ...other }
) => (
  <Tab 
    { ...other }
    component={ ({ innerRef, ...props }) => (
      <NavLink 
        { ...props }
        to={{
          search,
          pathname: to, 
        }}
      />
    ) }
  />
);
