import gql from 'graphql-tag';

import { Fragments as LabelFragmentsRaw } from 'src/service/labels/labelFragments';
import { Fragments as MaterialCategoryFragmentsRaw } from 'src/service/materialCategories/materialCategoryFragments';
import { Fragments as ManufacturerFragmentsRaw } from 'src/service/manufacturers/manufacturerFragments';

const LabelFragments = LabelFragmentsRaw();
const MaterialCategoryFragments = MaterialCategoryFragmentsRaw();
const ManufacturerFragments = ManufacturerFragmentsRaw();

export const Fragments = () => ({
  labelCount: gql`
    fragment LabelCount on MaterialCount {
      label {
        ...LabelBasic
        ...LabelCategory
      }
      count
    }
    ${LabelFragments.basic}
    ${LabelFragments.category}
  `,
  manufacturerCount: gql`
    fragment ManufacturerCount on MaterialCount {
      manufacturer {
        ...ManufacturerBasic
      }
      count
    }
    ${ManufacturerFragments.basic}
  `,
  materialCategoryCount: gql`
    fragment MaterialCategoryCount on MaterialCount {
      materialCategory {
        ...MaterialCategoryBasic
      }
      count
    }
    ${MaterialCategoryFragments.basic}
  `,
});