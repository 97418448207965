import * as React from 'react';

import { ProviderSearchResultsMaterialTable } from './ProviderSearchResultsTable';

import { Message } from 'src/components/Message';
import { parseSearchVariables } from 'src/utils/SearchUtils';
import { ProviderSearchTotalDecorator } from 'src/modules/search/decorators/ProviderSearchTotalDecorator';
import { Trans } from 'react-i18next';
import { FilterableTableLayout } from 'src/components/table';


const ProviderSearchResultsLayout = ProviderSearchTotalDecorator((
  { location, data }
) => {
  const provider = data.provider!;
  if (provider.search.total === 0) {
    return (
      <div>
        <Message
          title={ 
            <Trans 
              i18nKey="noProviderResults" 
              values={{ 
                query: parseSearchVariables(location).query,
                provider,
              }}
            />
          }
        />
      </div>
    );
  }

  return (
    <FilterableTableLayout
      table={
        <ProviderSearchResultsMaterialTable
          location={ location }
          provider={ data.provider! }
        />
      }
    />
  );
});

export { ProviderSearchResultsLayout };