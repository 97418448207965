import * as React from 'react';

import { Queries } from 'src/service/organizations';

import { DataLoaderDecorator, DataProps } from 'src/decorators/DataLoaderDecorator';
import { graphql } from '@apollo/client/react/hoc';
import { normalizePaginatedResponse } from 'src/utils/PaginationUtils';
import { OrganizationType } from 'src/types';
import { BrowserSettings } from 'src/constants';
import { loadLocalProperty, saveLocalProperty } from 'src/utils/BrowserUtils';



const parseSelectedOrganization = (organizations: OrganizationType[]) => {
  const lastId = loadLocalProperty(BrowserSettings.LAST_ORGANIZATION_ID);
  const selectedOrganization = organizations.find(org => org.id === lastId);
  if (selectedOrganization) {
    return selectedOrganization;
  }

  if (!organizations.length) {
    throw new Error('Organizations list should not be empty');
  }

  return organizations[0];
};

interface SelectedOrganizationProviderProps {

}

type SelectedOrganizationProviderDataProps = DataProps<Queries.OrganizationListResponse>;

export interface SelectedOrganizationContext { 
  selectedOrganization: OrganizationType;
  setOrganization: (newOrg: OrganizationType) => void;
}

export const SelectedOrganizationContext = React.createContext<SelectedOrganizationContext | undefined>(
  undefined
);

export interface SelectedOrganizationProps { 
  organizationContext: SelectedOrganizationContext;
}

export function withSelectedOrganization<PropsT>(Component: React.ComponentType<PropsT & SelectedOrganizationProps>) {
  return function (props: PropsT) {
    return (
      <SelectedOrganizationContext.Consumer>
        { context => {
          if (!context) {
            console.error('Organization context value missing ');
            return null;
          }

          return (
            <Component {...props} organizationContext={ context } />
          ); 
        } }
      </SelectedOrganizationContext.Consumer>
    );
  };
}


class SelectedOrganizationProvider extends React.Component<
  SelectedOrganizationProviderProps & SelectedOrganizationProviderDataProps, 
  SelectedOrganizationContext
> {
  constructor(props: SelectedOrganizationProviderProps & SelectedOrganizationProviderDataProps) {
    super(props);

    this.state = {
      selectedOrganization: parseSelectedOrganization(normalizePaginatedResponse(props.data.organizations!)),
      setOrganization: this.setOrganization,
    };
  }

  setOrganization = (newOrganization: OrganizationType) => {
    saveLocalProperty(BrowserSettings.LAST_ORGANIZATION_ID, newOrganization.id);
    this.setState({
      selectedOrganization: newOrganization,
    });
  }

  render() {
    return (
      <SelectedOrganizationContext.Provider value={ this.state }>
        { this.props.children }
      </SelectedOrganizationContext.Provider>
    );
  }
}

const withOrganizationData = graphql<
  SelectedOrganizationProviderProps, 
  Queries.OrganizationListResponse
>(
  Queries.OrganizationListQuery
);

const SelectedOrganizationProviderWithData = withOrganizationData(
  DataLoaderDecorator<SelectedOrganizationProviderProps, Queries.OrganizationListResponse>(SelectedOrganizationProvider)
);

export { SelectedOrganizationProviderWithData as SelectedOrganizationProvider };
