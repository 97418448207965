import * as React from 'react';

import {
  TextField as MUITextField,
} from '@material-ui/core';
import { GenericField, Field, WrappedFieldProps } from 'redux-form';
import { TextFieldProps as MUITextFieldProps } from '@material-ui/core/TextField';


type FieldProps = { required?: boolean, disabled?: boolean, label: React.ReactNode; } & MUITextFieldProps;

export const TextAreaFieldComponent: React.FC<WrappedFieldProps & FieldProps> = (props) => (
  <TextFieldComponent
    multiline={ true }
    rows={ 5 }
    { ...props }
  />
);

export const TextFieldComponent: React.FC<WrappedFieldProps & FieldProps> = (
  { label, input, meta: { touched, invalid, error }, ...other }
) => (
  <MUITextField
    fullWidth={ true }
    autoComplete="off" 

    label={ label }
    //placeholder={ label as any }
    error={ touched && invalid }
    helperText={ touched && error }
    { ...input }
    { ...other }
  />
);

const FieldType = Field as new() => GenericField<FieldProps>;

export { FieldType as TextField };
