import * as React from 'react';

import { graphql } from '@apollo/client/react/hoc';
import { Location } from 'history';

import { 
  MaterialTable, MaterialTableConfigProps,
  MaterialListColumnNames,
  DefaultLocalMaterialListPagination
} from 'src/components/browser-layout';

import { DataLoaderDecorator, DataProps } from 'src/decorators/DataLoaderDecorator';
import { Queries } from 'src/service/labels';

import { parsePaginationVariables } from 'src/utils/PaginationUtils';
import { GBLabelType } from 'src/types';
import { SelectedOrganizationContext } from 'src/decorators/SelectedOrganizationProvider';
import { localMaterialsToViewMaterials } from 'src/utils/MaterialVariantUtils';
import { useTranslation } from 'react-i18next';
import { MaterialSelectionActions } from 'src/actions/MaterialSelectionActions';


export interface LabelMaterialTableProps extends MaterialTableConfigProps {
  label: GBLabelType;
  location: Location;
}


const withMaterials = graphql<LabelMaterialTableProps, Queries.LabelMaterialsResponse>(
  Queries.LabelMaterialsQuery, {
    options: ({ label, location }) => {
      return {
        variables: { 
          labelId: label.id,
          ...parsePaginationVariables(location, DefaultLocalMaterialListPagination).query,
        }
      };
    },
  }
);

type Props = LabelMaterialTableProps & DataProps<Queries.LabelMaterialsResponse>;

const Columns: MaterialListColumnNames = [ 'image', 'name', 'category' ];

const LabelMaterialTable: React.FC<Props> = (
  { data, label }
) => {
  const { t } = useTranslation();
  const { materials } = data.label!;
  const organizationContext = React.useContext(SelectedOrganizationContext)!;
  return (
    <MaterialTable
      materials={ localMaterialsToViewMaterials(materials, organizationContext, t) }
      totalCount={ materials.total }
      columns={ Columns }
      pageTitle={ label.name }
      selectionActions={ MaterialSelectionActions }
    />
  );
};

const LabelMaterialTableDecorated = withMaterials(
  DataLoaderDecorator<LabelMaterialTableProps, Queries.LabelMaterialsResponse>(
    LabelMaterialTable
  )
);

export { LabelMaterialTableDecorated as LabelMaterialTable };