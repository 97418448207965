export const resources = {
  en: require('./en/main.json'),
  fi: require('./fi/main.json'),
  nb: require('./nb/main.json'),
  ru: require('./ru/main.json'),
  cs: require('./cs/main.json'),
  sk: require('./sk/main.json'),
  es: require('./es/main.json'),
  et: require('./et/main.json'),
  de: require('./de/main.json'),
  nl: require('./nl/main.json'),
  fr: require('./fr/main.json'),
  sv: require('./sv/main.json')
};
