
import { parseQuery, ensureStringMaybe } from 'src/utils/QueryUtils';
import { MaterialVariant, ViewMaterialType, GBMaterialType, PaginatedResponse, MaterialVariantType } from 'src/types';
import { SelectedOrganizationContext } from 'src/decorators/SelectedOrganizationProvider';
import { defaultSort } from './SortUtils';
import { normalizePaginatedResponse } from './PaginationUtils';
import { parseCurrentLanguage } from './LanguageUtils';

import { Location } from 'history';
import { TFunction } from 'i18next';



export const variantSort = (organizationContext: SelectedOrganizationContext) => {
  return (a: MaterialVariant, b: MaterialVariant): number => {
    // Selected organization will go first
    if (!!a.organization || !!b.organization) {
      if (!!a.organization && a.organization.id === organizationContext.selectedOrganization.id) {
        return -1;
      }

      if (!!b.organization && b.organization.id === organizationContext.selectedOrganization.id) {
        return 1;
      }
    }

    // Baseline with a localization
    if (a.type === MaterialVariantType.BASELINE && !!a.localization) {
      return -1;
    }
    if (b.type === MaterialVariantType.BASELINE && !!b.localization) {
      return 1;
    }

    // Other accessible organization variants
    if (!!a.organization !== !!b.organization) {
      return !!a.organization ? -1 : 1;
    }

    // Provider variants
    if (!!a.provider || !!b.provider) {
      if (!!a.provider !== !!b.provider) {
        return !!a.provider ? -1 : 1;
      }

      return defaultSort(a.provider!.name, b.provider!.name);
    }

    // Baseline variants should never come here...
    return defaultSort(parseVariantName(a, ''), parseVariantName(b, ''));
  };
};


export const parseVariantLanguage = (location: Location): string => {
  const query = parseQuery(location);
  if (!!query && typeof query.variantLanguage === 'string') {
    return query.variantLanguage;
  }

  return parseCurrentLanguage();
};

export const parseVariantName = (variant: MaterialVariant, baselineCaption: string) => {
  const { organization, provider, description } = variant;
  if (organization) {
    return !!description ? `${organization.name} (${description})` : organization.name;
  }

  if (provider) {
    return provider.name;
  }

  return baselineCaption;
};

export const pickDefaultVariant = (
  variants: MaterialVariant[], 
  organizationContext: SelectedOrganizationContext
): MaterialVariant | undefined => {
  const variantsSorted = [ ...variants ]
    .sort(variantSort(organizationContext));
  
  return variantsSorted.length ? variantsSorted[0] : undefined;
};

export const localMaterialToViewMaterial = (
  material: GBMaterialType,
  organizationContext: SelectedOrganizationContext,
  t: TFunction,
  location: Location | undefined
): ViewMaterialType & GBMaterialType => {
  const variant = parseCurrentVariant(material.variants, location, organizationContext);
  const { 
    name, description
  } = parseLocalizableVariantProperties(variant, t);

  return {
    ...material,
    name,
    description,
    image: !!variant ? variant.image : undefined,
  };
};


export const localMaterialsToViewMaterials = (
  materials: PaginatedResponse<GBMaterialType>,
  organizationContext: SelectedOrganizationContext,
  t: TFunction
): Array<ViewMaterialType & GBMaterialType> => {
  return normalizePaginatedResponse(materials)
    .map(m => localMaterialToViewMaterial(m, organizationContext, t, undefined));
};

export const parseCurrentVariant = (
  variants: MaterialVariant[], 
  location: Location | undefined,
  organizationContext: SelectedOrganizationContext
): MaterialVariant | undefined => {
  if (!!location) {
    const query = parseQuery(location);
    if (!!query) {
      const variant = ensureStringMaybe(query.variant);
      if (!!variant) {
        const ret = variants.find(v => v.id === parseInt(variant));
        if (!!ret) {
          return ret;
        }
      }
    }
  }

  return pickDefaultVariant(variants, organizationContext)!;
};

type LocalizableVariantProperties = Pick<ViewMaterialType, 'name' | 'description'>;

const getEmptyLocalizableProperties = (t: TFunction): LocalizableVariantProperties => ({
  name: t('noName'),
  description: undefined,
});

export const parseLocalizableVariantProperties = (
  variant: MaterialVariant | undefined,
  t: TFunction
): LocalizableVariantProperties => {
  if (!variant) {
    return getEmptyLocalizableProperties(t);
  }

  if (variant.localization) {
    const { name, description } = variant.localization;
    return {
      name,
      description,
    };
  }

  return getEmptyLocalizableProperties(t);
};

export const hasBaselineVariant = (material: GBMaterialType) => {
  return !!material.variants.find(variant => variant.type === MaterialVariantType.BASELINE);
};
