import * as React from 'react';

import { TextField, TextFieldComponent } from 'src/components/form';

import { 
  LabelCategoryEditorDataDecoratorChildProps 
} from '../../decorators/LabelCategoryEditorDataDecorator';
import { FieldMapperConfigProps, InjectedEditorFormProps } from 'src/components/editor';
import { Trans } from 'react-i18next';
import { max250Validator } from 'src/utils/FieldValidators';
import { parseLocalizationLanguage } from 'src/utils/LanguageUtils';
import { LanguageSelectField, LanguageSelectFieldComponent } from 'src/components/form/LanguageSelectField';
import { LabelCategoryFieldTypes } from 'src/types';


type LabelEditorFormProps = 
  InjectedEditorFormProps<LabelCategoryFieldTypes, LabelCategoryEditorDataDecoratorChildProps>;

class LabelEditorForm extends React.Component<LabelEditorFormProps> {
  render() {
    const { labelCategory } = this.props;
    return (
      <>
        <TextField
          required
          name="name" 
          component={ TextFieldComponent } 
          label={ <Trans i18nKey="name"/> }
          validate={ max250Validator }
        />
        <LanguageSelectField
          required
          name="languageCode"
          component={ LanguageSelectFieldComponent }
          label={ <Trans i18nKey="language"/> }
          disabled={ !!labelCategory }
        />
      </>
    );
  }
}

export const mapFields = (
  state: any,
  { labelCategory }: LabelCategoryEditorDataDecoratorChildProps
): FieldMapperConfigProps<LabelCategoryFieldTypes> => {
  const languageCode = parseLocalizationLanguage(labelCategory);
  if (!labelCategory) {
    // Creating new, set initial parent
    return {
      initialValues: {
        languageCode,
      }
    };
  }

  // Editing existing, convert the data
  const { name } = labelCategory;
  return {
    initialValues: {
      name,
      languageCode,
    }
  };
};

export { LabelEditorForm };
