import gql from 'graphql-tag';
import { 
  GBManufacturerType, GBMaterialType, 
  PaginatedResponse, TotalResponse, LabelCountType, MaterialCategoryCountType 
} from 'src/types';

import { Fragments as FragmentsRaw } from './manufacturerFragments';
import { Fragments as MaterialFragmentsRaw } from 'src/service/materials/materialFragments';
import { Fragments as MaterialCountFragmentsRaw } from 'src/service/materialCounts/materialCountFragments';


import { Fragments as MaterialVariantFragmentsRaw } from 'src/service/materialVariants/materialVariantFragments';
import { 
  Fragments as MaterialVariantLocalizationFragmentsRaw 
} from 'src/service/materialVariantLocalizations/materialVariantLocalizationFragments';

const MaterialVariantFragments = MaterialVariantFragmentsRaw();
const MaterialLocalizationFragments = MaterialVariantLocalizationFragmentsRaw();

const MaterialCountFragments = MaterialCountFragmentsRaw();


const Fragments = FragmentsRaw();
const MaterialFragments = MaterialFragmentsRaw();

// Queries
export const ManufacturerQuery = gql`
  query ManufacturerQuery($manufacturerId: Int!) {
    manufacturer(id: $manufacturerId) {
      ...ManufacturerBasic,
    }
  }
  ${Fragments.basic}
`;

export const ManufacturerCategoriesQuery = gql`
  query ManufacturerCategoriesQuery($manufacturerId: Int!) {
    manufacturer(id: $manufacturerId) {
      ...ManufacturerBasic
      materialCategoryCounts {
        ...MaterialCategoryCount
      }
      labelCounts {
        ...LabelCount
      }
      materials {
        total
      }
    }
  }
  ${Fragments.basic}
  ${MaterialCountFragments.materialCategoryCount}
  ${MaterialCountFragments.labelCount}
`;

export const ManufacturerMaterialsQuery = gql`
  query ManufacturerMaterialsQuery(
    $manufacturerId: Int!,
    $first: Int, 
    $after: String, 
    $orderBy: MaterialsOrder, 
    $labels: [[Int]],
    $materialCategories: [Int],
    $languageCode: LanguageCode
  ) {
    manufacturer(id: $manufacturerId) {
      id
      materials(
        first: $first, 
        after: $after, 
        orderBy: $orderBy, 
        labels: $labels, 
        materialCategories: $materialCategories
      ) {
        total
        edges {
          node {
            ...MaterialBasic
            ...MaterialCategory
            variants {
              ...MaterialVariantBasic
              ...MaterialVariantOrganization
              ...MaterialVariantProvider
              localization(languageCode: $languageCode) {
                ...MaterialVariantLocalizationBasic
              }
            }
          }
        }
      }
    }
  }
  ${MaterialFragments.basic}
  ${MaterialFragments.materialCategory}
  ${MaterialVariantFragments.basic}
  ${MaterialVariantFragments.organization}
  ${MaterialVariantFragments.provider}
  ${MaterialLocalizationFragments.basic}
`;

export const ManufacturerSearchQuery = gql`
  query ManufacturerSearchQuery(
    $types: [SearchTypes]!, 
    $query: String!
    $first: Int, 
    $after: String, 
    $orderBy: ManufacturersOrder
  ) {
    search(types: $types, query: $query) {
      manufacturers(first: $first, after: $after, orderBy: $orderBy) {
        total
        edges {
          node {
            ...ManufacturerBasic
          }
        }
      }
    }
  }
  ${Fragments.basic}
`;

export const ManufacturerListQuery = gql`
  query ManufacturerListQuery {
    manufacturers {
      edges {
        node {
          ...ManufacturerBasic
        }
      }
    }
  }
  ${Fragments.basic}
`;


// Response types
export interface ManufacturerResponse {
  manufacturer: GBManufacturerType;
}

export interface ManufacturerCategoriesResponse {
  manufacturer: GBManufacturerType & {
    materials: TotalResponse;
    labelCounts: LabelCountType[];
    materialCategoryCounts: MaterialCategoryCountType[];
  };
}

export interface ManufacturerMaterialsResponse {
  manufacturer: {
    materials: PaginatedResponse<GBMaterialType>;
  };
}

export interface ManufacturerSearchResponse {
  search: {
    manufacturers: PaginatedResponse<GBManufacturerType>;
  };
}

export interface ManufacturerListResponse {
  manufacturers: PaginatedResponse<GBManufacturerType>;
}
