import { Location } from 'history';
import { StringifiableRecord } from 'query-string';
import { parseQuery, ensureStringMaybe } from 'src/utils/QueryUtils';


export type SearchQueryType = string | undefined;

export interface SearchVariables extends StringifiableRecord {
  query: SearchQueryType;
}

export const parseSearchVariables = (
  location: Location
): SearchVariables => {
  const {
    query,
  } = parseQuery(location);
  
  return {
    query: ensureStringMaybe(query),
  };
};
