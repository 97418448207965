import * as React from 'react';

import { graphql } from '@apollo/client/react/hoc';
import { Queries } from 'src/service/materials';

import { 
  DataLoaderDecorator, DataProps, 
  DataLoaderDecoratorProps, ErrorPolicy 
} from 'src/decorators/DataLoaderDecorator';

import { ProviderMaterialType, DataSourceItem } from 'src/types';


export interface MaterialLinkLayoutDataDecoratorProps extends DataLoaderDecoratorProps {
  //provider: ProviderType;
  providerMaterial: ProviderMaterialType;
  dataSourceItems?: DataSourceItem[];
}

export type MaterialLinkLayoutDataDecoratorChildProps = 
  MaterialLinkLayoutDataDecoratorProps & DataProps<Queries.MaterialGTINResponse>;

export const MaterialLinkLayoutDataDecorator = function <PropsT>(
  Component: React.ComponentType<PropsT & MaterialLinkLayoutDataDecoratorChildProps>
) {
  const withProvidersData = graphql<PropsT & MaterialLinkLayoutDataDecoratorProps, Queries.MaterialGTINResponse>(
    Queries.MaterialGTINQuery, 
    {
      skip: ({ providerMaterial, dataSourceItems }: MaterialLinkLayoutDataDecoratorProps) => {
        return (!providerMaterial.gtin /*&& !providerMaterial.sku*/) || (!!dataSourceItems && !!dataSourceItems.length);
      },
      options: ({ providerMaterial }) => ({
        variables: {
          gtin: providerMaterial.gtin,
        }
      })
    }
  );

  return withProvidersData(
    DataLoaderDecorator<PropsT & MaterialLinkLayoutDataDecoratorProps, Queries.MaterialGTINResponse>(
      Component, 
      ErrorPolicy.IGNORE
    )
  );
};
