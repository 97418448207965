import * as React from 'react';

import { ProviderType } from 'src/types';

interface ProviderTitleProps {
  provider: ProviderType;
  iconSize?: number;
  style?: React.CSSProperties;
}

export const ProviderTitle: React.FC<ProviderTitleProps> = ({ provider, style, iconSize = 12 }) => (
  <>
    <img 
      src={ provider.iconUrl }
      style={{ maxWidth: `${iconSize}px`, maxHeight: `${iconSize}px`, marginRight: '5px' }}
      alt=""
    />
    <span style={ style }>
      { provider.name }
    </span>
  </>
);