import * as React from 'react';

import { WrappedFieldProps, Field, GenericField } from 'redux-form';
import { CreatableSelectField, CreatableSelectFieldProps, Option } from './select-field';

import { Queries } from 'src/service/labelCategories';

import { DataLoaderDecorator, DataProps } from 'src/decorators/DataLoaderDecorator';
import { graphql } from '@apollo/client/react/hoc';
import { GBLabelCategoryType, GBMaterialType } from 'src/types';
import { normalizePaginatedResponse } from 'src/utils/PaginationUtils';
import { Trans } from 'react-i18next';


type OptionType = Option<number>;

const mapCategoryOption = ({ id, name }: GBLabelCategoryType): OptionType => ({
  value: id,
  label: name,
});

export type LabelCategorySelectFieldProps = Partial<CreatableSelectFieldProps<number>> & { material?: GBMaterialType; };
type LabelCategorySelectFieldPropsWithData = 
  WrappedFieldProps & 
  LabelCategorySelectFieldProps & DataProps<Queries.LabelCategoryListResponse>;

const LabelCategorySelectField: React.FC<LabelCategorySelectFieldPropsWithData> = props => {
  const { input, required, data, material, ...other } = props;
  const options = React.useMemo(
    () => {
      let categories = normalizePaginatedResponse(data.labelCategories!);
      if (!!material) {
        // Remove categories that exist already for this material
        categories = categories.filter(category => {
          return category.id === input.value || material.labels
            .map(label => label.category.id)
            .indexOf(category.id) === -1;
        });
      }

      return categories.map(mapCategoryOption);
    },
    [ input.value, material, data ]
  );

  return (
    <CreatableSelectField 
      { ...other }
      input={ input }
      isMulti={ false }
      name="label-categories"
      placeholder={ <Trans i18nKey="prompts.select"/> as any }
      options={ options }
      required={ required }
    />
  );
};


const withCategoryData = graphql<WrappedFieldProps & LabelCategorySelectFieldProps, Queries.LabelCategoryListResponse>(
  Queries.LabelCategoryListQuery
);

const LabelCategorySelectFieldWithData = withCategoryData(
  DataLoaderDecorator<WrappedFieldProps & LabelCategorySelectFieldProps, Queries.LabelCategoryListResponse>(
    LabelCategorySelectField
  )
);

const FieldType = Field as new() => GenericField<LabelCategorySelectFieldProps>;

export { 
  FieldType as LabelCategorySelectField,
  LabelCategorySelectFieldWithData as LabelCategorySelectFieldComponent
};
