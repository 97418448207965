import * as React from 'react';

import Paper from '@material-ui/core/Paper';

import { 
  LabelCountType, MaterialCategoryCountType, ManufacturerCountType,
  GBLabelIdType, GBMaterialCategoryIdType, GBManufacturerIdType, 
  GBLabelCategoryIdType
} from 'src/types';

import { withRouter, RouteComponentProps } from 'react-router-dom';
import { updateLocationParams } from 'src/utils/QueryUtils';
import { parseMaterialFilterVariables, stringifyLabels } from 'src/utils/MaterialFilterUtils';

import { groupLabels } from './filter-utils';
import { withTranslation, WithTranslation } from 'react-i18next';
import { constructFilterArray, hasItems, ensureItems } from 'src/utils/FilterUtils';
import { FilterableSection } from 'src/components/table';


export interface MaterialFilterPanelProps {
  labelCounts?: Array<LabelCountType>;
  materialCategoryCounts?: Array<MaterialCategoryCountType>;
  manufacturerCounts?: Array<ManufacturerCountType>;
}

class MaterialFilterPanel extends React.Component<RouteComponentProps & WithTranslation & MaterialFilterPanelProps> {  
  parseLocationFilters = () => {
    const { location } = this.props;
    return parseMaterialFilterVariables(location).filters;
  }

  handleToggleLabel = (value: GBLabelIdType, labelCategory: GBLabelCategoryIdType) => () => {
    const { history } = this.props;

    const filters = this.parseLocationFilters();
    const labels = constructFilterArray(filters.labels[labelCategory] || [], value);
    
    updateLocationParams(
      history, 
      {
        labels: stringifyLabels({
          ...filters.labels,
          [labelCategory]: labels,
        }),
      }
    );
  }

  handleToggleMaterialCategory = (value: GBMaterialCategoryIdType) => () => {
    const { history } = this.props;
    updateLocationParams(
      history, 
      {
        materialCategories: constructFilterArray(this.parseLocationFilters().materialCategories, value),
      }
    );
  }

  handleToggleManufacturer = (value: GBManufacturerIdType) => () => {
    const { history } = this.props;
    updateLocationParams(
      history, 
      {
        manufacturers: constructFilterArray(this.parseLocationFilters().manufacturers, value),
      }
    );
  }

  render() {
    const { labelCounts, materialCategoryCounts, manufacturerCounts, t } = this.props;
    if (!hasItems(labelCounts) && !hasItems(materialCategoryCounts) && !hasItems(manufacturerCounts)) {
      return null;
    }

    const filters = this.parseLocationFilters();
    return (
      <Paper
        style={{ marginRight: '10px', padding: '10px' }}
      >
        <FilterableSection
          title={ t('materialCategory') }
          selectedIds={ filters.materialCategories }
          handleToggle={ this.handleToggleMaterialCategory }
          items={ ensureItems(materialCategoryCounts).map(c => (
            { 
              id: c.materialCategory.id,
              name: c.materialCategory.name,
              count: c.count,
            }
          ))}
        />
        <FilterableSection
          title={ t('manufacturer') }
          selectedIds={ filters.manufacturers }
          handleToggle={ this.handleToggleManufacturer }
          items={ ensureItems(manufacturerCounts).map(c => (
            { 
              id: c.manufacturer.id,
              name: c.manufacturer.name,
              count: c.count,
            }
          ))}
        />
        { ensureItems(labelCounts)
            .reduce(groupLabels, [])
            .sort((a, b) => a.totalMaterialCount - b.totalMaterialCount)
            .map(groupedCounts => (
              <FilterableSection
                key={ groupedCounts.category.id }
                title={ groupedCounts.category.name }
                selectedIds={ filters.labels[groupedCounts.category.id] || [] }
                handleToggle={ value => this.handleToggleLabel(value, groupedCounts.category.id) }
                items={ groupedCounts.labelCounts.map(c => (
                  { 
                    id: c.label.id,
                    name: c.label.name,
                    count: c.count,
                  }
                ))}
              />
            ))
        }
      </Paper>
    );
  }
}

const MaterialFilterPanelDecorated = withTranslation()(withRouter(MaterialFilterPanel));

export { MaterialFilterPanelDecorated as MaterialFilterPanel };