import * as React from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';

import { DropdownMenu } from 'src/components/DropdownMenu';
import { UserInfoLayout } from './UserInfoLayout';

import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToApp from '@material-ui/icons/ExitToApp';
import { Trans, useTranslation } from 'react-i18next';
import { AuthContext } from 'src/decorators/AuthDecorator';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import { formatDateTime } from 'src/utils/DateUtils';
import { useRouter } from 'src/effects';
import { resetCache } from 'src/utils/MutationUtils';


type UserDropdownProps = WithApolloClient<{}>;

const UserDropdown: React.FC<UserDropdownProps> = ({ client }) => {
  const { history } = useRouter();
  const { t } = useTranslation();
  const auth = React.useContext(AuthContext)!;

  const versionStr = `Version: ${GIT_COMMIT_HASH} (${process.env.NODE_ENV})`; // eslint-disable-line
  const buildDateStr = `Build date: ${formatDateTime(UI_BUILD_DATE!, t)}`; // eslint-disable-line
  return (
    <DropdownMenu
      caption={ (
        <AccountCircle 
          style={{
            color: 'lightgray',
          }}
        />
      ) }
      menuPlacement="left"
      iconTrigger={ true }
    >
      <li>
        <UserInfoLayout
          userInfo={ auth.getUserInfo() }
        />
      </li>
      <Divider/>
      <MenuItem 
        onClick={ () => {
          auth.logout();
          resetCache(client!);
          history.push('/login');
        }}
      >
        <ListItemIcon>
          <ExitToApp/>
        </ListItemIcon>
        <Trans i18nKey="actions.logout"/>
      </MenuItem>
      { /*NODE_ENV !== 'production' &&*/ (
        <div 
          style={{
            marginLeft: '5px',
            color: '#e6e6e6',
          }}
        >
          <div>
            { versionStr }
          </div>
          <div>
            { buildDateStr }
          </div>
        </div>
      ) }
    </DropdownMenu>
  );
};

const Decorated = withApollo<{}>(UserDropdown);

export { Decorated as UserDropdown };
