import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import './index.css';

import { init as initSentry, ErrorBoundary } from '@sentry/react';
import { parseCurrentLanguage } from './utils/LanguageUtils';


initSentry({ 
  dsn: 'https://8cbc7d0de99a4d09a8cd575479a31858@sentry.io/1798745',
  enabled: process.env.NODE_ENV === 'production' || !!process.env.REACT_APP_USE_SENTRY
});

function ErrorFallback() {
  return (
    <div>The application has crashed, try reloading the page</div>
  );
}

ReactDOM.render(
  (
    <ErrorBoundary 
      fallback={ErrorFallback} 
      showDialog
      dialogOptions={{
        lang: parseCurrentLanguage()
      }}
    >
      <App />
    </ErrorBoundary>
  ),
  document.getElementById('root') as HTMLElement
);
registerServiceWorker();
