import * as React from 'react';

import Paper from '@material-ui/core/Paper';

import {
  EventActionType, EventEntityType
} from 'src/types';

import { updateLocationParams } from 'src/utils/QueryUtils';
import { parseEventFilterVariables } from 'src/utils/EventFilterUtils';

import { constructFilterArray } from 'src/utils/FilterUtils';
import { useTranslation } from 'react-i18next';
import { EntityTypeMappings } from './EventTableCell';
import { FilterableDateRangeSection, FilterableSection, FilterableTextSection } from 'src/components/table';
import { useRouter } from 'src/effects';


export interface EventFilterPanelProps {

}

const EventFilterPanel: React.FC<EventFilterPanelProps> = (props) => {
  const { t } = useTranslation();
  const { location, history } = useRouter();

  const parseLocationFilters = () => {
    return parseEventFilterVariables(location).filters;
  };

  const handleToggleEntityType = (value: EventEntityType) => () => {
    updateLocationParams(
      history, 
      {
        entityTypes: constructFilterArray(parseLocationFilters().entityTypes, value),
      }
    );
  };

  const handleToggleActionType = (value: EventActionType) => () => {
    updateLocationParams(
      history, 
      {
        actionTypes: constructFilterArray(parseLocationFilters().actionTypes, value),
      }
    );
  };

  const handleChangeText = (value: string) => {
    updateLocationParams(
      history, 
      {
        textFilter: !!value ? value : undefined,
      }
    );
  };

  const handleChangeDateRange = (value: string, type: 'start' | 'end') => {
    updateLocationParams(
      history, 
      type === 'start' ? {
        startDate: !!value ? value : undefined
      } : {
        endDate: !!value ? value : undefined
      }
    );
  };

  const filters = parseLocationFilters();
  return (
    <Paper
      style={{ marginRight: '10px', padding: '10px', width: '270px' }}
    >
      <FilterableTextSection
        title={ t('textFilter') }
        currentValue={ filters.textFilter }
        handleChange={ handleChangeText }
        t={ t }
      />
      <FilterableDateRangeSection
        title={ t('dateRange') }
        currentValue={{
          start: filters.startDate,
          end: filters.endDate,
        }}
        handleChange={ handleChangeDateRange }
        t={ t }
      />
      <FilterableSection
        title={ t('event') }
        selectedIds={ filters.actionTypes as EventActionType[]  }
        handleToggle={ handleToggleActionType }
        items={ Object.keys(EventActionType).map(actionKey => ({
          id: actionKey as EventActionType,
          name: t(`eventTypes.${EventActionType[actionKey as keyof typeof EventActionType]}`),
        })) }
      />
      <FilterableSection
        title={ t('type') }
        selectedIds={ filters.entityTypes as EventEntityType[] }
        handleToggle={ handleToggleEntityType }
        items={ Object.keys(EventEntityType).map(entityKey => {
          const entityTypeValue = EventEntityType[entityKey as keyof typeof EventEntityType];
          return {
            id: entityTypeValue.toUpperCase() as EventEntityType,
            name: t(EntityTypeMappings[entityTypeValue as EventEntityType]),
          };
        }) }
      />
    </Paper>
  );
};

export { EventFilterPanel };