import gql from 'graphql-tag';


// Queries
export const CreateMaterialVariantMutation = gql`
  mutation CreateMaterialVariantMutation($input: CreateMaterialVariantInput!) {
    createMaterialVariant(input: $input) {
      id
    }
  }
`;

export const UpdateMaterialVariantMutation = gql`
  mutation UpdateMaterialVariantMutation($id: Int!, $input: UpdateMaterialVariantInput!) {
    updateMaterialVariant(id: $id, input: $input) {
      id
    }
  }
`;

export const UploadMaterialVariantImageMutation = gql`
  mutation UploadMaterialVariantImageMutation($id: Int!, $file: Upload!) {
    uploadMaterialVariantImage(id: $id, file: $file) {
      id
    }
  }
`;

export const DeleteMaterialVariantMutation = gql`
  mutation DeleteMaterialVariantMutation($id: Int!) {
    deleteMaterialVariant(id: $id) {
      id
    }
  }
`;

/*export const SyncMaterialMutation = gql`
  mutation SyncMaterialMutation($input: SyncMaterialInput!) {
    syncMaterial(input: $input) {
      id
    }
  }
`;*/



// Response types
export interface CreateMaterialVariantResponse {
  createMaterialVariant: {
    id: number;
  };
}

export interface UpdateMaterialVariantResponse {
  updateMaterialVariant: {
    id: number;
  };
}

export interface UploadMaterialVariantImageResponse {
  uploadMaterialVariantImage: {
    id: number;
  };
}

/*export interface SyncMaterialResponse {
  syncMaterial: {
    id: number;
  };
}*/

export interface DeleteMaterialVariantResponse {
  deleteMaterialVariant: {
    id: number;
  };
}
