import { Permission } from 'src/constants';

import { Action, ActionHandlerProps, GBMaterialType, ActionList } from 'src/types';

import { Actions } from 'src/service/materials';

import Add from '@material-ui/icons/Add';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import Autorenew from '@material-ui/icons/Autorenew';
import History from '@material-ui/icons/History';
import { hasMaterialEditAccess } from 'src/utils/AccessUtils';


interface MaterialEditActionProps {
  material: GBMaterialType;
}

interface MaterialCreateActionProps {

} 


type CreateProps = ActionHandlerProps & MaterialCreateActionProps;
type EditProps = ActionHandlerProps & MaterialEditActionProps;


const handleCreate = (props: CreateProps) => {
  const { history, match } = props;
  history.push(`${match.url}/material`);
};

const handleEdit = (props: EditProps) => {
  const { material, history } = props;
  history.push({
    pathname: `/materials/${material.id}/edit`,
    search: history.location.search,
  });
};

const handleViewEvents = (props: EditProps) => {
  const { material, history } = props;
  history.push({
    pathname: `/materials/${material.id}/events`,
    search: history.location.search,
  });
};

const handleDelete = async (props: EditProps) => {
  const { material, client, history } = props;
  return Actions.sendDeleteMaterial(material.id, client, result => {
    const url = `/categories/${result.deleteMaterial.category.id}`;
    history.replace(url);
  });
};


const handleSync = async (props: EditProps) => {
  const { material, client } = props;
  return Actions.sendSyncMaterial({ materialId: material.id }, client);
};


const hasDataSourceItems = (props: EditProps) => {
  const { material } = props;
  return !!material.dataSourceItems && !!material.dataSourceItems.length;
};


export const hasEditAccess = (materialAccess: Permission, variantAccess: Permission) => {
  return ({ auth, material }: EditProps) => {
    return hasMaterialEditAccess(material, auth, materialAccess, variantAccess);
  };
};

const MaterialCreateAction: Action<MaterialCreateActionProps> = {
  title: 'actions.create',
  icon: Add,
  handler: handleCreate,
  access: Permission.MATERIALS_CREATE,
};

const MaterialEditActions: ActionList<MaterialEditActionProps> = {
  EVENTS: {
    title: 'actions.eventHistory',
    icon: History,
    handler: handleViewEvents,
    access: Permission.MATERIALS_EDIT,
  },
  DIVIDER: null,
  SYNC: {
    title: 'syncNow',
    icon: Autorenew,
    handler: handleSync,
    filter: hasDataSourceItems,
    onSuccess: 'actions.notifications.materialSynced',
    onFailed: 'actions.notifications.materialSyncFailed',
    access: Permission.MATERIALS_EDIT,
  },
  EDIT: {
    title: 'actions.edit',
    icon: Edit,
    handler: handleEdit,
    access: hasEditAccess(Permission.MATERIALS_EDIT, Permission.MATERIAL_VARIANTS_EDIT),
  },
  DELETE: {
    title: 'actions.delete',
    icon: Delete,
    handler: handleDelete,
    onSuccess: 'actions.notifications.materialDeleted',
    onFailed: 'actions.notifications.materialDeleteFailed',
    access: hasEditAccess(Permission.MATERIALS_DELETE, Permission.MATERIAL_VARIANTS_DELETE),
    confirmation: {
      title: 'actions.confirmations.deleteMaterial',
      description: 'actions.confirmations.deleteMaterialMessage',
    },
  }
};

export { MaterialCreateAction, MaterialEditActions };
