import * as React from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import { Trans } from 'react-i18next';


export type SnackbarContext = (message: SnackbarMessage) => void;

export interface SnackbarProps {
  snackbar: SnackbarContext;
}

export const SnackbarContext = React.createContext<SnackbarContext | undefined>(
  undefined
);

export function withSnackbar<PropsT>(Component: React.ComponentType<PropsT & SnackbarProps>) {
  return function (props: PropsT) {
    return (
      <SnackbarContext.Consumer>
        { context => {
          if (!context) {
            console.error('Snackbar context value missing ');
            return null;
          }

          return (
            <Component {...props} snackbar={ context } />
          ); 
        } }
      </SnackbarContext.Consumer>
    );
  };
}

type ActionHandlerType = () => void;

export interface SnackbarMessage {
  text: React.ReactElement<any> | string;
  values?: object;
  actionHandler?: ActionHandlerType;
  actionTitle?: React.ReactNode;
}

interface State { 
  open: boolean; 
  message?: SnackbarMessage;
}

const toSnackbarContent = (message: SnackbarMessage) => {
  if (typeof message.text === 'string') {
    return (
      <Trans i18nKey={ message.text } values={ message.values }/>
    );
  }

  return message.text;
};

class SnackbarProvider extends React.Component<{}, State> {
  state: State = {
    message: undefined,
    open: false,
  };

  showMessage = (message: SnackbarMessage) => {
    this.setState({ 
      open: true,
      message,
    });
  }

  handleActionClick = (event: React.SyntheticEvent<any>) => {
    this.handleClose(event, 'actionclick');

    const { message } = this.state;
    if (message && message.actionHandler) {
      message.actionHandler();
    }
  }

  handleClose = (event: React.SyntheticEvent<any>, reason: string) => {
    setTimeout(() => this.setState({ 
      open: false, message: undefined 
    }));
  }

  render () {
    const {
      message,
      open
    } = this.state;

    const {
      children,
    } = this.props;

    return (
      <>
        <SnackbarContext.Provider value={ this.showMessage }>
          { children }
        </SnackbarContext.Provider>
        { message && (
          <Snackbar
            autoHideDuration={ 4000 }
            open={ open }
            message={ toSnackbarContent(message) }
            action={ (!!message.actionTitle && !!message.actionHandler) ? (
              <Button color="secondary" onClick={ this.handleActionClick }>
                { message.actionTitle }
              </Button>
            ) : undefined }
            onClose={ this.handleClose }
          />
        ) }
      </>
    );
  }
}

export { SnackbarProvider };
