import { Permission } from 'src/constants';

import { ActionHandlerProps, ActionList, GBLabelType } from 'src/types';

import { Actions } from 'src/service/labels';

import Label from '@material-ui/icons/Label';

import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import { isRootCategory } from 'src/components/browser-layout';


interface LabelEditActionProps {
  label: GBLabelType;
}

interface LabelCreateActionProps {
  label?: GBLabelType;
} 


type CreateProps = ActionHandlerProps & LabelCreateActionProps;
type EditProps = ActionHandlerProps & LabelEditActionProps;


const handleCreateMaterial = (props: CreateProps) => {
  const { history, match } = props;
  const url = `${match.url}/material`;
  history.push(url);
};

const handleEdit = (props: EditProps) => {
  const { history, match } = props;
  history.push(`${match.url}/edit`);
};

const handleDelete = async (props: EditProps) => {
  const { label, client, history } = props;
  await Actions.sendDeleteLabel(label.id, client, res => {
    history.replace(`/label-categories/${label.category.id}`);
  });
};


const isRoot = ({ label }: CreateProps) => !!label && isRootCategory(label);

export const LabelCreateActions: ActionList<LabelCreateActionProps> = {
  CREATE_MATERIAL: {
    title: 'actions.newMaterial',
    icon: Label,
    handler: handleCreateMaterial,
    access: Permission.MATERIALS_CREATE,
    filter: props => !isRoot(props)
  }
};

export const LabelEditActions: ActionList<LabelEditActionProps> = {
  EDIT: {
    title: 'actions.edit',
    icon: Edit,
    handler: handleEdit,
    access: Permission.LABELS_EDIT,
  },
  DELETE: {
    title: 'actions.delete',
    icon: Delete,
    handler: handleDelete,
    onSuccess: 'actions.notifications.labelDeleted',
    onFailed: 'actions.notifications.labelDeleteFailed',
    access: Permission.LABELS_DELETE,
    confirmation: {
      title: 'actions.confirmations.deleteLabel',
      description: 'actions.confirmations.deleteLabelMessage',
    },
  }
};
