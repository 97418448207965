import * as React from 'react';

import { WrappedFieldProps, Field, GenericField } from 'redux-form';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';

import { Dialog } from '@material-ui/core';
import { FormLabelProps } from '../types';
import { AssetSelectDialog } from './AssetSelectDialog';
import { AssetInfoArea } from './AssetInfoArea';
import { useTranslation } from 'react-i18next';


export type AssetSelectFieldProps = FormLabelProps & { disabled?: boolean; };
type AssetSelectFieldInputProps = WrappedFieldProps & AssetSelectFieldProps;

const AssetSelectField: React.FC<AssetSelectFieldInputProps> = props => {
  const [ open, setOpen ] = React.useState(false);
  const { t } = useTranslation();

  const { label, error, focused, required, input, disabled } = props;
  const { value, name, onChange } = input;
  return (
    <>
      <FormControl>
        { label && (
          <FormLabel 
            error={ error }
            required={ required }
            focused={ focused } 
            aria-label={ name }
          >
            { label }
          </FormLabel>
        ) }
        <AssetInfoArea
          assetGuid={ value }
        />
        <Button
          size="small"
          variant="contained"
          onClick={ evt => {
            setOpen(true);
          }}
          disabled={ disabled }
          style={{
            marginTop: '5px'
          }}
        >
          { t(value ? 'prompts.changeAsset' : 'prompts.selectAsset') }
        </Button>
        { !!value && (
          <Button
            size="small"
            variant="contained"
            onClick={ evt => {
              onChange(null);
            }}
            disabled={ disabled }
            style={{
              marginTop: '5px'
            }}
          >
            { t('removeAsset') }
          </Button>
        ) }
        <input
          tabIndex={ -1 }
          name={ name }
          value={ value }
          required={ required }
          onChange={ onChange }
          aria-labelledby={ name }
          style={{
            opacity: 0,
            width: 0,
            height: 0,
            position: 'absolute',
          }}
        />
      </FormControl>

      <Dialog 
        open={ open } 
        onClose={ () => setOpen(false) } 
        aria-labelledby="asset-select-dialog"
        fullWidth={ true }
        maxWidth="lg"
      >
        <AssetSelectDialog
          onAssetSelected={ guid => {
            setOpen(false);

            onChange(guid);
          } }
          onClose={ () => setOpen(false) }
          initialGuid={ value }
        />
      </Dialog>
    </>
  );
};

const FieldType = Field as new() => GenericField<AssetSelectFieldProps>;

export { 
  FieldType as ImageUploadField,
  AssetSelectField as AssetSelectFieldComponent
};


