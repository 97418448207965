import * as React from 'react';

import { RouterModalProps, EventEntityType } from 'src/types';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Dialog, DialogTitle } from '@material-ui/core';
import { RouteComponentProps } from 'react-router';
import { EventTable } from 'src/modules/admin/events';
import { parseIntRouteParam } from 'src/utils/BrowserUtils';


type MaterialEventsDialogProps =  RouterModalProps;

type RouteProps = RouteComponentProps<{
  materialId: string;
}>;

class MaterialEventsDialog extends React.PureComponent<MaterialEventsDialogProps & WithTranslation & RouteProps> {
  render() {
    const { t, location, match } = this.props;
    return (
      <Dialog
        //aria-labelledby={ `${formId}-title` }
        //aria-describedby={ `${formId}-description` }
        open={ true }
        onClose={ this.props.closeModal }
        maxWidth="md"
      >
        <DialogTitle id="dialog-title">
          { t('materialEditHistory') }
        </DialogTitle>
        <EventTable
          location={ location }
          pageTitle={ t('materialEditHistory') }
          t={ t }
          entityFilter={{
            id: parseIntRouteParam(match.params.materialId),
            types: [ EventEntityType.MATERIAL.toUpperCase() ],
            matchGroups: true,
          }}
        />
      </Dialog>
    );
  }
}

const MaterialEventsDialogDecorated = withTranslation()(MaterialEventsDialog);

export { MaterialEventsDialogDecorated as MaterialEventsDialog };
