import * as React from 'react';

import { Paper, Typography } from '@material-ui/core';
import { PaperProps } from '@material-ui/core/Paper';


export interface MessageProps extends Omit<PaperProps, 'title'> {
  title: React.ReactNode;
  description?: React.ReactNode;
  icon?: React.ReactNode; 
}

export const Message: React.FC<MessageProps> = ({ title, description, icon, style, ...other }) => (
  <Paper style={{ display: 'flex', alignItems: 'center', padding: '10px', ...style }} elevation={2} { ...other }>
    { icon }
    <div style={ !!icon ? { paddingLeft: '5px' } : undefined }>
      <Typography 
        variant={ !!description ? 'h5' : 'subtitle1' } 
        component={ !!description ? 'h3' : 'h3' }
      >
        { title }
      </Typography>
      <Typography component="p">
        { description }
      </Typography>
    </div>
  </Paper>
);
