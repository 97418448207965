import React, { useEffect, useState } from "react";
import SearchBar from "./SelectionView/SearchBar";
import { Manifest, Result } from "./Models/Manifest";
import ResultPanel from "./SelectionView/ResultPanel";
import useStyles from "./SelectionView/SelectionView.styles";

interface AssetViewerProps {
  initialGuid?: string;
  selectedAsset: string;
  onSelectAsset:
    | React.Dispatch<React.SetStateAction<string | null>>
    | ((value: string) => void);
  dialog?: boolean;
}
const AssetViewer = ({
  initialGuid,
  selectedAsset,
  onSelectAsset,
  dialog = false,
}: AssetViewerProps) => {
  const classes = useStyles();
  const queryUrl = process.env.REACT_APP_3D_API_URL + '/manifest';
  const [options, setOptions] = useState<Manifest[]>([]);

  const queryAsset = (searchWord: string) => {
    fetch(queryUrl + `?name=${searchWord}`)
      .then(async (response) => {
        const result: Result = await response.json();
        if (!response.ok || !result) {
          return Promise.reject(response.statusText);
        }

        setOptions(result.data);
        return Promise.resolve();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  function relaySearch(searchTerm: string) {
    queryAsset(searchTerm);
  }

  function iframeMessage(guid: string) {
    const iframes = document.querySelectorAll("iframe");
    const lastIframe = iframes[iframes.length - 1];
    if (lastIframe) {
      lastIframe?.contentWindow?.postMessage(guid, "*");
    }
  }

  return (
    <div
      style={{
        border: "1px solid lightgray",
        width: dialog ? "100%" : "1000px",
      }}
    >
      {dialog && (
        <div className={`${classes.interface}`}>
          <SearchBar onSubmit={relaySearch} />
          <ResultPanel
            selectedAsset={selectedAsset}
            onSelectAsset={(guid: string) => {
              iframeMessage(guid);
              onSelectAsset(guid);
            }}
            options={options}
          />
        </div>
      )}

      <iframe
        className={classes.frameViewer}
        title="asset-selector"
        src={`${process.env.REACT_APP_ASSETDB_VIEWER_URL}?guid=${initialGuid}`}
      />
    </div>
  );
};

export default AssetViewer;
