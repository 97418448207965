import * as qs from 'query-string';
import { History, Location } from 'history';


// Utilities for URL query strings

export const parseQuery = (location: Location): qs.ParsedQuery => {
  return qs.parse(
    location.search, 
    { arrayFormat: 'index' }
  );
};

export const ensureStringMaybe = (
  param: qs.ParsedQuery[keyof qs.ParsedQuery], 
  defaultValue?: string
): string | undefined => {
  return typeof param === 'string' ? param : defaultValue;
};

export const ensureString = (
  param: qs.ParsedQuery[keyof qs.ParsedQuery], 
  defaultValue: string
): string => {
  return typeof param === 'string' ? param : defaultValue;
};

export const stringifyQuery = (variables: qs.StringifiableRecord) => {
  return qs.stringify(
    variables,
    { arrayFormat: 'index' }
  );
};

export const mergeQuery = <ParamT extends object>(location: Location, variables: ParamT) => {
  const old = parseQuery(location);

  return stringifyQuery(
    {
      ...old,
      ...variables as any,
    }
  );
};

export const updateLocationParams = <ParamT extends object>(history: History, params: ParamT) => {
  history.push({
    pathname: history.location.pathname,
    search: mergeQuery(history.location, params),
  });
};
