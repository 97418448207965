import * as React from 'react';

import { ActionPanelProps as BrowserLayoutActionPanelProps } from 'src/components/browser-layout';
import { GBLabelCategoryIdType, GBLabelType } from 'src/types';
import { ActionDropdownMenu } from 'src/components';
import { LabelEditActions } from 'src/actions/LabelActions';
import Edit from '@material-ui/icons/Edit';
import { Trans } from 'react-i18next';


interface ActionPanelProps {
  label: GBLabelType;
}

const ActionPanel: React.FC<ActionPanelProps> = ({ label }) => (
  <>
    <ActionDropdownMenu
      actions={ LabelEditActions }
      label={ label }
      caption={ (
        <>
          <Edit/>
          <Trans i18nKey="actions.modify"/>
        </>
      ) }
    />
  </>
);


// Helper component that will convert the 'category' prop used by BrowserLayout to 'manufacturer'
type LabelActionPanelProps = BrowserLayoutActionPanelProps<GBLabelCategoryIdType>;

const LabelActionPanel: React.FC<LabelActionPanelProps> = ({ category }) => {
  const { id, name, parent } = category;
  if (!parent) {
    return null;
  }

  return (
    <ActionPanel
      label={{ 
        id,
        name,
        category: {
          id: parent.id,
          name: parent.name,
        }
      }}
    />
  );
};

export { LabelActionPanel };