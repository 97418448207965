import * as React from 'react';

import { TextField, TextFieldComponent } from 'src/components/form';
import { FieldMapperConfigProps, InjectedEditorFormProps } from 'src/components/editor';
import { 
  ManufacturerEditorDataDecoratorChildProps 
} from 'src/modules/manufacturers/decorators/ManufacturerEditorDataDecorator';
import { Trans } from 'react-i18next';
import { max250Validator } from 'src/utils/FieldValidators';
import { asyncValidatorComposer, nameUniquenessValidator } from 'src/utils/AsyncFieldValidators';
import { Queries } from 'src/service/manufacturers';
import { SearchType, ManufacturerFieldTypes } from 'src/types';


export type ManufacturerEditorFormProps = 
  InjectedEditorFormProps<ManufacturerFieldTypes, ManufacturerEditorDataDecoratorChildProps>;

export const mapFields = (
  state: any,
  { manufacturer, client }: ManufacturerEditorDataDecoratorChildProps
): FieldMapperConfigProps<ManufacturerFieldTypes> => {
  const commonMappers = asyncValidatorComposer(
    [ nameUniquenessValidator(
      Queries.ManufacturerSearchQuery, 
      SearchType.MANUFACTURER, 
      !!manufacturer ? manufacturer.id : undefined
    ) ], 
    client
  );

  if (!manufacturer) {
    // Creating new, set initial parent
    return commonMappers;
  }

  // Editing existing, convert the data
  const { name } = manufacturer;
  return {
    initialValues: {
      name,
    },
    ...commonMappers,
  };
};

class ManufacturerEditorForm extends React.Component<ManufacturerEditorFormProps> {
  render() {
    return (
      <TextField
        required
        name="name" 
        component={ TextFieldComponent } 
        label={ <Trans i18nKey="name"/> }
        validate={ max250Validator }
      />
    );
  }
}

export { ManufacturerEditorForm };
