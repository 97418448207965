import * as React from 'react';

import { graphql } from '@apollo/client/react/hoc';
import { Queries } from 'src/service/providers';

import { DataLoaderDecorator, DataProps } from 'src/decorators/DataLoaderDecorator';


export type ProvidersDataDecoratorChildProps = DataProps<Queries.GetProvidersResponse>;

export const ProvidersDataDecorator = function <T>(
  Component: React.ComponentType<T & ProvidersDataDecoratorChildProps>
) {
  const withProvidersData = graphql<T, Queries.GetProvidersResponse>(Queries.ProvidersGetQuery);

  return withProvidersData(
    DataLoaderDecorator<T, Queries.GetProvidersResponse>(Component)
  );
};
