import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { resources } from '../locales/';

export const DEFAULT_LANGUAGE = 'en';

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // if not using I18nextProvider
  .init(
    {
      resources,
      fallbackLng: DEFAULT_LANGUAGE,
      debug: process.env.NODE_ENV === 'development',
      load: 'languageOnly',
      defaultNS: 'main',
      interpolation: {
        escapeValue: false, // not needed for react!!
      },
      react: {
        wait: false,
        nsMode: 'default'
      }
    }, 
    undefined
  );

export default i18n;
