import gql from 'graphql-tag';

import { 
  Fragments as MaterialVariantLocalizationFragmentsRaw 
} from 'src/service/materialVariantLocalizations/materialVariantLocalizationFragments';
import { 
  Fragments as OrganizationFragmentsRaw 
} from 'src/service/organizations/organizationFragments';
import { 
  ProviderFragments as ProviderFragmentsRaw 
} from 'src/service/providers/providerFragments';

const LocalizationFragments = MaterialVariantLocalizationFragmentsRaw();
const OrganizationFragments = OrganizationFragmentsRaw();
const ProviderFragments = ProviderFragmentsRaw();


export const Fragments = () => ({
  basic: gql`
    fragment MaterialVariantBasic on MaterialVariant {
      id
      description
      type
      image {
        name
        urlSmall
        urlLarge
      }
      netPrice
      provision
      vat
      updatedAt
    }
  `,
  localizations: gql`
    fragment MaterialVariantLocalizations on MaterialVariant {
      localizations {
        ...MaterialVariantLocalizationBasic
      }
    }
    ${LocalizationFragments.basic}
  `,
  organization: gql`
    fragment MaterialVariantOrganization on MaterialVariant {
      organization {
        ...OrganizationBasic
      }
    }
    ${OrganizationFragments.basic}
  `,
  provider: gql`
    fragment MaterialVariantProvider on MaterialVariant {
      provider {
        ...ProviderBasic
      }
    }
    ${ProviderFragments.basic}
  `,
});