import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { ProviderHomeLayout, ProviderCategoryPageLayout } from './materialcategory-browser';

import { ProviderActionPanel } from './ProviderActionPanel';


interface RouteProps {
  categoryId?: string;
  providerId?: string;
}

class ProviderCategoryView extends React.PureComponent<RouteComponentProps<RouteProps>, {}> {
  render() {
    const { match, location } = this.props;
    const { providerId, categoryId } = match.params;
    if (!providerId) {
      return null;
    }

    const MainLayout = categoryId ? ProviderCategoryPageLayout : ProviderHomeLayout;
    return (
      <div id="provider-category-page">
        <MainLayout
          providerId={ providerId }
          categoryId={ categoryId }
          browserBaseUrl={ `/providers/${providerId}` }
          actionPanel={ ProviderActionPanel }
          location={ location }
        />
      </div>
    );
  }
}

export { ProviderCategoryView };
