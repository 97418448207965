import * as React from 'react';

import { ManufacturerHomeLayout, ManufacturerPageLayout } from './manufacturer-browser';

import { RouteComponentProps } from 'react-router';
import { ManufacturerActionPanel } from './manufacturer-action-panel';

import { ManufacturerEditorDialog } from './manufacturer-editor';
import Domain from '@material-ui/icons/DomainSharp';

import { ModalRoute } from 'src/components/ModalRoute';
import { MaterialEditorDialog } from 'src/modules/materials';
import { Trans } from 'react-i18next';
import { parseOptionalIntRouteParam } from 'src/utils/BrowserUtils';


interface RouteProps {
  manufacturerId?: string;
}

class ManufacturerView extends React.PureComponent<RouteComponentProps<RouteProps>, {}> {
  render() {
    const { match, location } = this.props;
    const { manufacturerId } = match.params;

    const MainLayout = match.params.manufacturerId ? ManufacturerPageLayout : ManufacturerHomeLayout;
    return (
      <div id="manufacturer-page">
        <MainLayout
          manufacturerId={ parseOptionalIntRouteParam(manufacturerId) }
          browserBaseUrl={ () => '/manufacturers' }
          actionPanel={ ManufacturerActionPanel }
          location={ location }
          navigationItemIcon={ Domain }
          indexTitle={ <Trans i18nKey="manufacturers"/> }
        />
        <ModalRoute
          component={ ManufacturerEditorDialog }
          path={ `/manufacturers/:actionType(create)` }
          exact={ true }
        />
        <ModalRoute
          component={ ManufacturerEditorDialog }
          path={ `/manufacturers/:manufacturerId(\\d+)/:actionType(edit)` }
        />
        <ModalRoute
          component={ MaterialEditorDialog }
          path={ `/manufacturers/:manufacturerId(\\d+)/material` }
        />
      </div>
    );
  }
}

export { ManufacturerView };
