import * as React from 'react';

import { RouteButton, Message } from 'src/components';
import { 
  UnapprovedMaterialsDecorator, UnapprovedMaterialsDecoratorChildProps
} from '../decorators/UnapprovedMaterialCountsDecorator';
import { Trans } from 'react-i18next';


const UnapprovedMaterialsNotification: React.FC<UnapprovedMaterialsDecoratorChildProps> = ((
  { data }
) => {
  const count = data.materials!.total;
  if (count === 0) {
    return null;
  }

  return (
    <Message
      style={{ marginBottom: '10px' }}
      title=""
      description={
        <>
          <Trans i18nKey="unapprovedMaterialsNotification" count={ count || undefined }>
            There are <strong>{{ count }}</strong> materials waiting for approval
          </Trans>
          <br/>
          <RouteButton
            variant="contained"
            to="/materials/unapproved"
            style={{
              margin: '5px 0px'
            }}
          >
            <Trans i18nKey="viewMaterials"/>
          </RouteButton>
        </>
      }
    />
  );
});

const UnapprovedMaterialsNotificationDecorated = UnapprovedMaterialsDecorator(UnapprovedMaterialsNotification);

export { UnapprovedMaterialsNotificationDecorated as UnapprovedMaterialsNotification };