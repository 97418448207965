import * as React from 'react';

import { RouteComponentProps, Route } from 'react-router';

import { MainLayout } from 'src/components/main-layout';
import { AdminSection } from './AdminSection';

import Domain from '@material-ui/icons/Domain';
import History from '@material-ui/icons/History';
import { Grid } from '@material-ui/core';

import { OrganizationsView, OrganizationsActionPanel } from '../organizations';
import { EventsView } from '../events';

import { useTranslation } from 'react-i18next';


const Sections = [
  AdminSection('events', History, EventsView),
  AdminSection('organizations', Domain, OrganizationsView, OrganizationsActionPanel),
];

const AdminView: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation();
  return (
    <>
      <Route 
        path="/admin" 
        exact={ true } 
        render={ () => (
          <MainLayout title={ t('admin') } id="admin">
            <div id="admin-page">
              <Grid 
                container 
                spacing={ 2 }
                style={{
                  padding: '0px 10px 5px 10px'
                }}
              >
                { Sections.map(sec => sec.component) }
              </Grid>
            </div>
          </MainLayout>
        )}
      />
      { Sections.map(sec => sec.route) }
    </>
  );
};

export { AdminView };
