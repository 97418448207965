import { Permission } from 'src/constants';

export type MaterialCategoryBaseIdType = number | string;

export interface GraphqlEntity {
  __typename?: string;
}

export interface MaterialCategoryBaseType<IdType extends MaterialCategoryBaseIdType = MaterialCategoryBaseIdType> {
  id: IdType;
  name: string;
  parent?: MaterialCategoryBaseType<IdType>;
}

export type GBManufacturerIdType = number;
export type ProviderManufacturerIdType = number;
export type GBMaterialIdType = number;
export type MaterialVariantIdType = number;
export type MaterialVariantLocalizationIdType = number;
export type OrganizationIdType = number;
export type ProviderIdType = number;

export type ManufacturerBaseIdType = number | string;

export interface ManufacturerBaseType {
  id: MaterialCategoryBaseIdType;
  name: string;
}

export type GBLabelCategoryIdType = number;

export interface GBLabelCategoryType extends GraphqlEntity {
  id: GBLabelCategoryIdType;
  name: string;
  systemCategory?: SystemCategoryType | null;
  localization?: NameLocalization;
}

export interface LabelCountType {
  label: GBLabelType;
  count: number;
}

export interface MaterialCategoryCountType {
  materialCategory: GBMaterialCategoryType;
  count: number;
}

export interface ManufacturerCountType {
  manufacturer: GBManufacturerType;
  count: number;
}

export type GBLabelIdType = number;

export interface GBLabelType extends GraphqlEntity {
  id: GBLabelIdType;
  name: string;
  category: GBLabelCategoryType;
  localization?: NameLocalization;
}

export interface GBManufacturerType extends ManufacturerBaseType, GraphqlEntity {
  id: GBManufacturerIdType;
}

export interface ProviderManufacturerType extends ManufacturerBaseType, GraphqlEntity {
  id: ProviderManufacturerIdType;
}

export type GBMaterialCategoryIdType = number;

export interface NameLocalization {
  id: number;
  name: string;
  language: LanguageType;
}

export interface GBMaterialCategoryType extends MaterialCategoryBaseType, GraphqlEntity {
  id: GBMaterialCategoryIdType;
  parent?: GBMaterialCategoryType;
  localization?: NameLocalization;
}

export interface ProviderMaterialCategoryType extends MaterialCategoryBaseType, GraphqlEntity {
  id: ProviderMaterialCategoryIdType;
  parent?: ProviderMaterialCategoryType;
}

export type ProviderMaterialCategoryIdType = string;

export type MaterialBaseIdType = number | string;

export interface MaterialImageType extends GraphqlEntity {
  name: string;
  urlSmall: string;
  urlLarge: string;
}

export interface MaterialBaseType {
  id: MaterialBaseIdType;
  //name: string;
  //description?: string;
  //image?: MaterialImageType;
  category: MaterialCategoryBaseType;
  manufacturer: ManufacturerBaseType;
  gtin?: string;
  sku?: string;
}

export enum MaterialStatus {
  NEW = 'NEW',
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export type DataSourceItemId = number;

export interface DataSourceItem extends GraphqlEntity {
  id: DataSourceItemId;
  material: GBMaterialType;
  provider: ProviderType;
  providerMaterial?: ProviderMaterialType;
  active: boolean;
  syncedAt: string | null;
}

//export type LanguageType = string;

export interface OrganizationType extends GraphqlEntity {
  id: OrganizationIdType;
  code: string;
  name: string;
}

export interface LanguageType extends GraphqlEntity {
  code: string;
  name: string;
}

export enum SystemCategoryType {
  DECO_TOOL_CATEGORY = 'DECO_TOOL_CATEGORY'
}

export enum MaterialVariantType {
  BASELINE = 'BASELINE',
  ORGANIZATION = 'ORGANIZATION',
  PROVIDER = 'PROVIDER',
}

export interface MaterialVariantLocalizationType extends GraphqlEntity {
  id: MaterialVariantLocalizationIdType;
  name: string;
  description: string;
  language: LanguageType;
  materialVariant: MaterialVariant;
}

export interface MaterialVariant extends GraphqlEntity {
  id: MaterialVariantIdType;
  description: string;
  image?: MaterialImageType | null;
  organization?: OrganizationType | null;
  provider?: ProviderType | null;
  localization?: MaterialVariantLocalizationType;
  localizations: MaterialVariantLocalizationType[];
  material: GBMaterialType;
  type: MaterialVariantType;
  netPrice: number;
  provision: number;
  vat: number;
  updatedAt: string;
}

export interface GBMaterialType extends MaterialBaseType, GraphqlEntity {
  id: GBMaterialIdType;
  assetGUID?: string | null;
  legacy3dId?: string | null;
  createdAt: string;
  category: GBMaterialCategoryType;
  manufacturer: GBManufacturerType;
  dataSourceItems?: DataSourceItem[] | null;
  labels: GBLabelType[];
  variants: MaterialVariant[];
  status: MaterialStatus;
}

export interface AttachmentType extends GraphqlEntity {
  name: string;
  url: string;
}

export type ProviderMaterialIdType = string;

export interface ProviderMaterialType extends MaterialBaseType, GraphqlEntity {
  id: ProviderMaterialIdType;
  name: string;
  description?: string;
  category: ProviderMaterialCategoryType;
  attachments?: Array<AttachmentType>;
  dataSourceItems?: DataSourceItem[];
  manufacturer: ProviderManufacturerType;
  image?: MaterialImageType | null;
}

export interface ProviderType extends GraphqlEntity {
  id: ProviderIdType;
  code: string;
  name: string;
  iconUrl: string;
  features: string[];
}

export interface OrganizationPermissionInfo {
  organization: OrganizationType;
  level: number;
  permissions: Permission[];
}

export interface UserInfo {
  name: string;
  email: string;
  picture: string;
  globalPermissions: Permission[];
  organizationPermissions: OrganizationPermissionInfo[];
}

export enum SearchType {
  MATERIAL = 'MATERIAL',
  MANUFACTURER = 'MANUFACTURER',
}


export interface EventEntityBase {
  id: number;
  name: string | null;
  type: string;
  materialVariant?: MaterialVariant;
  materialVariantLocalization?: MaterialVariantLocalizationType;
  material?: GBMaterialType;
  materialCategory?: GBMaterialCategoryType;
  manufacturer?: GBManufacturerType;
  label?: GBLabelType;
  labelCategory?: GBLabelCategoryType;
}

export interface Event extends GraphqlEntity {
  id: string;
  time: string;
  entity: EventEntityBase & {
    updatedFields?: string[];
    groups?: EventEntityBase[];
  };
  event: {
    id: string;
    action: EventActionType;
  };
  user: {
    id: string | null;
    name: string;
    email: string;
  };
  /*organization: {
    id: number;
    name: string;
    code: string;
  };*/
}

export enum EventActionType {
  EDIT = 'edit',
  CREATE = 'create',
  DELETE = 'delete',
  IMPORT = 'import',
  SYNC = 'sync',
  LINK = 'link',
  UNLINK = 'unlink',
}

export enum EventEntityType {
  MATERIAL = 'materials',
  MATERIALVARIANT = 'materialvariants',
  MATERIALVARIANT_LOC = 'materiallocalizations',
  MATERIALCATEGORY = 'materialcategories',
  MANUFACTURER = 'manufacturers',
  LABEL = 'labels',
  LABELCATEGORY = 'labelcategories',
  ORGANIZATION = 'organizations',
  DATASOURCE = 'datasources',
}

export interface MaterialLabelSuggestion extends GraphqlEntity {
  id: string;
  label: GBLabelType;
  score: number;
  rewardId: string;
}
