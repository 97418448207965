import * as React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import { Link } from 'react-router-dom';

import { SearchInput } from 'src/modules/search';

import { RouteButton } from 'src/components/RouteButton';
import { UserDropdown } from './user-dropdown';

import { Permission } from 'src/constants';
import { useTranslation } from 'react-i18next';
import { AuthContext } from 'src/decorators/AuthDecorator';

import Logo from '../logo.png';


export const MainNavigation = () => {
  const { t } = useTranslation();
  const auth = React.useContext(AuthContext)!;
  return (
    <AppBar position="static" className="main-navigation">
      <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className="left" style={{ float: 'left', display: 'flex' }}>
          <Link to="/">
            <img src={ Logo } className="logo" alt="logo" />
          </Link>
          <SearchInput style={{ marginLeft: 30, height: 40 }}/>
        </div>
        <div className="right" style={{ float: 'right', display: 'flex' }}>
          <RouteButton to="/categories" color="inherit">
            { t('materials') }
          </RouteButton>
          <RouteButton to="/manufacturers" color="inherit">
            { t('manufacturers') }
          </RouteButton>
          <RouteButton to="/label-categories" color="inherit">
            { t('labels') }
          </RouteButton>
          { auth.hasGlobalAccess(Permission.ADMIN) && (
            <RouteButton to="/admin" color="inherit">
              { t('admin') }
            </RouteButton>
          ) }
          <UserDropdown/>
        </div>
      </Toolbar>
    </AppBar>
  );
};
