import * as React from 'react';

import Edit from '@material-ui/icons/Edit';

import { Trans } from 'react-i18next';
import { ActionDropdownMenu } from 'src/components/ActionDropdownMenu';
import { MaterialEditActions } from 'src/actions/MaterialActions';
import { GBMaterialType } from 'src/types';


interface MaterialActionPanelProps {
  material: GBMaterialType;
}

const MaterialActionPanel: React.FC<MaterialActionPanelProps> = (
  { material }
) => (
  <ActionDropdownMenu
    caption={ (      
      <>
        <Edit/>
        <Trans i18nKey="actions.actions"/>
      </>
    ) }
    actions={ MaterialEditActions }
    material={ material }
  />
);

export { MaterialActionPanel };