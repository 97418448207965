import gql from 'graphql-tag';

import { Fragments as FragmentsRaw } from './dataSourceItemFragments';
import { ProviderMaterialFragments as ProviderMaterialFragmentsRaw } from 'src/service/providers/providerFragments';
import { DataSourceItem } from 'src/types';


const Fragments = FragmentsRaw();
const ProviderMaterialFragments = ProviderMaterialFragmentsRaw();


// Queries
export const DataSourceItemAttachmentsQuery = gql`
  query DataSourceItemAttachmentsQuery(
    $dataSourceItemId: Int!
  ) {
    dataSourceItem(id: $dataSourceItemId) {
      ...DataSourceItemBasic
      providerMaterial {
        ...ProviderMaterialBasic
        ...ProviderMaterialAttachments
      }
    }
  }
  ${Fragments.basic}
  ${ProviderMaterialFragments.basic}
  ${ProviderMaterialFragments.attachments}
`;

export interface DataSourceItemAttachmentsResponse {
  dataSourceItem: Pick<DataSourceItem, 'providerMaterial'>;
}
