import * as React from 'react';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { MaterialInputField } from './MaterialInputField';
import { ProviderType } from 'src/types';
import { useTranslation } from 'react-i18next';


interface ProviderHomePanelProps {
  provider: ProviderType;
}

const NoBrowserPanel: React.FC<ProviderHomePanelProps> = ({ provider }) => {
  const { t } = useTranslation();
  return (
    <Paper elevation={ 4 } style={{ padding: '10px' }}>
      <Typography component="h3">
        { t('providerNoBrowsing') }
      </Typography>
      <MaterialInputField
        t={ t }
        provider={ provider }
        materialSectionUrl={ `/providers/${provider.code}/materials` }
      />
    </Paper>
  );
};

export { NoBrowserPanel };