import gql from 'graphql-tag';
import { OrganizationIdType } from 'src/types';


// Queries
export const CreateOrganizationMutation = gql`
  mutation CreateOrganizationMutation($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      id,
    }
  }
`;

export const UpdateOrganizationMutation = gql`
  mutation UpdateOrganizationMutation(
    $id: Int!, 
    $input: UpdateOrganizationInput!
  ) {
    updateOrganization(id: $id, input: $input) {
      id,
    }
  }
`;

export const DeleteOrganizationMutation = gql`
  mutation DeleteOrganizationMutation($id: Int!) {
    deleteOrganization(id: $id) {
      id,
    }
  }
`;


// Response types
export interface CreateOrganizationResponse {
  createOrganization: {
    id: OrganizationIdType;
  };
}

export interface UpdateOrganizationResponse {
  updateOrganization: {
    id: OrganizationIdType;
  };
}

export interface DeleteOrganizationResponse {
  deleteOrganization: {
    id: OrganizationIdType;
  };
}
