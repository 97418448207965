import gql from 'graphql-tag';
import { LanguageType } from 'src/types';


// Queries
export const LanguageListQuery = gql`
  query LanguageListQuery {
    languages {
      code
      name
    }
  }
`;


// Response types
export interface LanguageListResponse {
  languages: LanguageType[];
}
