import { Permission } from 'src/constants';

import { ActionHandlerProps, ActionList, GBLabelCategoryType } from 'src/types';

import { Actions } from 'src/service/labelCategories';

import Folder from '@material-ui/icons/Folder';
import Label from '@material-ui/icons/Label';

import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import { isRootCategory } from 'src/components/browser-layout';


interface LabelCategoryEditActionProps {
  labelCategory: GBLabelCategoryType;
}

interface LabelCategoryCreateActionProps {
  labelCategory?: GBLabelCategoryType;
} 

type CreateProps = ActionHandlerProps & LabelCategoryCreateActionProps;
type EditProps = ActionHandlerProps & LabelCategoryEditActionProps;


const handleCreateCategory = (props: CreateProps) => {
  const { history, match } = props;
  const url = `${match.url}/create`;
  history.push(url);
};

const handleCreateLabel = (props: CreateProps) => {
  const { history, match } = props;
  const url = `${match.url}/label`;
  history.push(url);
};

const handleEdit = (props: EditProps) => {
  const { history, match } = props;
  history.push(`${match.url}/edit`);
};

const handleDelete = async (props: EditProps) => {
  const { labelCategory, client, history } = props;
  await Actions.sendDeleteLabelCategory(labelCategory.id, client, res => {
    history.replace('/label-categories');
  });
};


const isRoot = ({ labelCategory }: CreateProps) => !!labelCategory && isRootCategory(labelCategory);

export const LabelCategoryCreateActions: ActionList<LabelCategoryCreateActionProps> = {
  CREATE_LABEL: {
    title: 'actions.newLabel',
    icon: Label,
    handler: handleCreateLabel,
    access: Permission.LABELS_CREATE,
    filter: props => !isRoot(props),
  }, 
  CREATE_LABEL_CATEGORY: {
    title: 'actions.newLabelCategory',
    icon: Folder,
    handler: handleCreateCategory,
    access: Permission.LABEL_CATEGORIES_CREATE,
    filter: isRoot,
  }
};

export const LabelCategoryEditActions: ActionList<LabelCategoryEditActionProps> = {
  EDIT: {
    title: 'actions.edit',
    icon: Edit,
    handler: handleEdit,
    access: Permission.LABEL_CATEGORIES_EDIT,
  },
  DELETE: {
    title: 'actions.delete',
    icon: Delete,
    handler: handleDelete,
    onSuccess: 'actions.notifications.labelCategoryDeleted',
    onFailed: 'actions.notifications.labelCategoryDeleteFailed',
    access: Permission.LABEL_CATEGORIES_DELETE,
    confirmation: {
      title: 'actions.confirmations.deleteLabelCategory',
      description: 'actions.confirmations.deleteLabelCategoryMessage',
    },
  }
};
