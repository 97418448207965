import * as React from 'react';

import { MaterialStatus } from 'src/types';
import { Queries } from 'src/service/materials';
import { graphql } from '@apollo/client/react/hoc';
import { DataLoaderDecorator, DataProps } from 'src/decorators/DataLoaderDecorator';
import { Location } from 'history';



interface MaterialsLayoutProps {
  location: Location;
}

type UnapprovedMaterialsDecoratorProps = MaterialsLayoutProps;
export type UnapprovedMaterialsDecoratorChildProps = 
  UnapprovedMaterialsDecoratorProps & DataProps<Queries.MaterialsCountsResponse>;

export const UnapprovedMaterialsDecorator = function <T>(
  Component: React.ComponentType<T & UnapprovedMaterialsDecoratorChildProps>
) {
  const withUnapprovedMaterialCount = graphql<UnapprovedMaterialsDecoratorProps & T, Queries.MaterialsCountsResponse>(
    Queries.MaterialsCountsQuery, {
      options: ({ location }) => {
        return {
          variables: {
            statuses: [ MaterialStatus.NEW ],
          }
        };
      },
    }
  );

  return withUnapprovedMaterialCount(
    DataLoaderDecorator<UnapprovedMaterialsDecoratorProps & T, Queries.MaterialsCountsResponse>(Component)
  );
};
