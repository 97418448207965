import * as React from 'react';

import { Dialog, DialogTitle } from '@material-ui/core';

import { reduxForm, 
  ConfigProps 
} from 'redux-form';
import { MapStateToPropsParam, connect } from 'react-redux';

import { EditorForm, EditorFormProps, FieldsComponentType } from './EditorForm';

import { RouterModalProps, FormSubmitHandler } from 'src/types';


export type EditorDialogProps<FieldTypesT, PropsT> = PropsT & RouterModalProps & {
  title: React.ReactNode,
  onSubmit: FormSubmitHandler<FieldTypesT>;
  fieldsComponent: FieldsComponentType<FieldTypesT, PropsT>;
  saveCaption?: string;
};


export function createEditorDialog<FieldTypesT extends object, PropsT extends object>(
  formId: string, 
  fieldMapper: MapStateToPropsParam<
    RecursivePartial<ConfigProps<FieldTypesT>>,
    PropsT, 
    EditorFormProps<FieldTypesT, PropsT>
  >
) {
  const parseForm = () => {
    let form = reduxForm<FieldTypesT, EditorFormProps<FieldTypesT, PropsT>>({
      form: formId,
    })(EditorForm as any);

    if (fieldMapper) {
      return connect<
        RecursivePartial<ConfigProps<FieldTypesT>>,
        {},
        EditorFormProps<FieldTypesT, PropsT>,
        any
      >(
        fieldMapper
      )(form as any);
    } else {
      return form;
    }
  };

  const Form = parseForm();

  class EditorDialog extends React.PureComponent<EditorDialogProps<FieldTypesT, PropsT>> {
    handleClose = () => {
      this.props.closeModal();
    }
  
    onSubmit = async (labelData: FieldTypesT, dispatch: any, formProps: any) => {
      const { onSubmit } = this.props;
      const success = await onSubmit(labelData, dispatch, formProps);
      if (success) {
        this.handleClose();
      }
    }
  
    render() {
      const { title, ...other } = this.props;

      return (
        <Dialog
          aria-labelledby={ `${formId}-title` }
          aria-describedby={ `${formId}-description` }
          open={ true }
          onClose={ this.handleClose }
          disableBackdropClick={ true }
        >
          <DialogTitle id="form-dialog-title">
            { title }
          </DialogTitle>
          <Form
            { ...other as any }
            onSubmit={ this.onSubmit } 
            onCancel={ this.handleClose }
          />
        </Dialog>
      );
    }
  }

  return EditorDialog;
}
