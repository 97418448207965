import gql from 'graphql-tag';


// Queries
export const CreateMaterialVariantLocalizationMutation = gql`
  mutation CreateMaterialVariantLocalizationMutation($input: CreateMaterialVariantLocalizationInput!) {
    createMaterialVariantLocalization(input: $input) {
      id
    }
  }
`;

export const UpdateMaterialVariantLocalizationMutation = gql`
  mutation UpdateMaterialVariantLocalizationMutation($id: Int!, $input: UpdateMaterialVariantLocalizationInput!) {
    updateMaterialVariantLocalization(id: $id, input: $input) {
      id
    }
  }
`;

export const DeleteMaterialVariantLocalizationMutation = gql`
  mutation DeleteMaterialVariantLocalizationMutation($id: Int!) {
    deleteMaterialVariantLocalization(id: $id) {
      id
    }
  }
`;

/*export const SyncMaterialMutation = gql`
  mutation SyncMaterialMutation($input: SyncMaterialInput!) {
    syncMaterial(input: $input) {
      id
    }
  }
`;*/



// Response types
export interface CreateMaterialVariantLocalizationResponse {
  createMaterialVariantLocalization: {
    id: number;
  };
}

export interface UpdateMaterialVariantLocalizationResponse {
  updateMaterialVariantLocalization: {
    id: number;
  };
}

export interface DeleteMaterialVariantLocalizationResponse {
  deleteMaterialVariantLocalization: {
    id: number;
  };
}
