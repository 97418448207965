import gql from 'graphql-tag';

/*import { Fragments as LabelFragmentsRaw } from 'src/service/labels/labelFragments';
import { Fragments as ManufacturerFragmentsRaw } from 'src/service/manufacturers/manufacturerFragments';
import { Fragments as MaterialCategoryFragmentsRaw } from 'src/service/materialCategories/materialCategoryFragments';

const ManufacturerFragments = ManufacturerFragmentsRaw();
const MaterialCategoryFragments = MaterialCategoryFragmentsRaw();
const LabelFragments = LabelFragmentsRaw();*/

export const Fragments = () => ({
  basic: gql`
    fragment MaterialVariantLocalizationBasic on MaterialVariantLocalization {
      id
      name
      description
      language {
        code
        name
      }
    }
  `,
});