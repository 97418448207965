import * as React from 'react';

import { 
  LabelEditorDataDecorator, LabelEditorDataDecoratorChildProps
} from '../../decorators/LabelEditorDataDecorator';

import { LabelEditorForm, mapFields } from './LabelEditorForm';

import { RouterModalProps, LabelFieldTypes } from 'src/types';
import { createEditorDialog } from 'src/components/editor';
import { Trans } from 'react-i18next';


type LabelEditorDialogProps = 
  LabelEditorDataDecoratorChildProps & RouterModalProps;

const EditorDialog = createEditorDialog<
  LabelFieldTypes, 
  LabelEditorDataDecoratorChildProps
>('labelEditor', mapFields);

class LabelEditorDialog extends React.PureComponent<LabelEditorDialogProps> {
  render() {
    const { label, ...other } = this.props;
    return (
      <EditorDialog
        title={ <Trans i18nKey={ !!label ? 'actions.editLabel' : 'actions.createLabel' }/> }
        label={ label }
        fieldsComponent={ LabelEditorForm }
        { ...other }
      />
    );
  }
}

const LabelEditorDialogDecorated = (
  LabelEditorDataDecorator(LabelEditorDialog)
);

export { LabelEditorDialogDecorated as LabelEditorDialog };
