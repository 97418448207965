import * as React from 'react';

import { RouteComponentProps } from 'react-router';
import { OrganizationTable } from './organization-table/OrganizationTable';

import { ModalRoute } from 'src/components/ModalRoute';
import { OrganizationEditorDialog } from './organization-editor/OrganizationEditorDialog';


export interface OrganizationsViewProps {

}

const OrganizationsView: React.FC<OrganizationsViewProps & RouteComponentProps> = () => (
  <>
    <OrganizationTable/>
    <ModalRoute
      component={ OrganizationEditorDialog }
      path="/admin/organizations/:organizationId(\d+)/edit"
    />
    <ModalRoute
      component={ OrganizationEditorDialog }
      path="/admin/organizations/create"
    />
  </>
);

export { OrganizationsView };