import * as React from 'react';

import { graphql } from '@apollo/client/react/hoc';

import { BrowserLayout, RootCategoryNum } from 'src/components/browser-layout';
import { GBManufacturerIdType } from 'src/types';

import { DataLoaderDecorator } from 'src/decorators/DataLoaderDecorator';

import { Queries } from 'src/service/manufacturers';
import { ManufacturerPagePropsType } from './ManufacturerPageLayout';
import { normalizePaginatedResponse } from 'src/utils/PaginationUtils';
import { defaultSort } from 'src/utils/SortUtils';


type ManufacturerHomeLayoutProps = ManufacturerPagePropsType;

const withCategories = graphql<ManufacturerHomeLayoutProps, Queries.ManufacturerListResponse>(
  Queries.ManufacturerListQuery, 
  { }
);

const ManufacturerHomeLayout = withCategories(DataLoaderDecorator(({ data, ...other }) => {
  return (
    <BrowserLayout<GBManufacturerIdType>
      { ...other }
      category={ RootCategoryNum }
      childCategories={ 
        normalizePaginatedResponse(data.manufacturers!) 
          .sort((a, b) => defaultSort(a.name, b.name))
      }
    />
  );
}));

export { ManufacturerHomeLayout };