import gql from 'graphql-tag';

import { Fragments as LabelFragmentsRaw } from 'src/service/labels/labelFragments';
import { Fragments as ManufacturerFragmentsRaw } from 'src/service/manufacturers/manufacturerFragments';
import { Fragments as MaterialCategoryFragmentsRaw } from 'src/service/materialCategories/materialCategoryFragments';

const ManufacturerFragments = ManufacturerFragmentsRaw();
const MaterialCategoryFragments = MaterialCategoryFragmentsRaw();
const LabelFragments = LabelFragmentsRaw();

export const Fragments = () => ({
  basic: gql`
    fragment MaterialBasic on Material {
      id
      status
    }
  `,
  extra: gql`
    fragment MaterialExtra on Material {
      createdAt
      gtin
      sku
      assetGUID
      legacy3dId
    }
  `,
  manufacturer: gql`
    fragment MaterialManufacturer on Material {
      manufacturer {
        ...ManufacturerBasic
      }
    }
    ${ManufacturerFragments.basic}
  `,
  materialCategory: gql`
    fragment MaterialCategory on Material {
      category {
        ...MaterialCategoryBasic
      }
    }
    ${MaterialCategoryFragments.basic}
  `,
  labels: gql`
    fragment MaterialLabels on Material {
      labels {
        ...LabelBasic
        ...LabelCategory
      }
    }
    ${LabelFragments.basic}
    ${LabelFragments.category}
  `,
});