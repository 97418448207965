import { LanguageType } from 'src/types';

// NOTE: 
// When adding/editing languages, make sure that a matching language code 
// also exist in the "Languages" table of the backend database for each instance

export const Languages = [
  {
    title: 'English',
    code: 'en',
  }, {
    title: 'Finnish',
    code: 'fi',
  }, {
    title: 'Norwegian Bokmål',
    code: 'nb',
  }, {
    title: 'Russian',
    code: 'ru',
  }, {
    title: 'Czech',
    code: 'cs',
  }, {
    title: 'Slovak',
    code: 'sk',
  }, {
    title: 'Spanish',
    code: 'es',
  }, {
    title: 'Estonian',
    code: 'et',
  }, {
    title: 'German',
    code: 'de',
  }, {
    title: 'Dutch',
    code: 'nl',
  }, {
    title: 'Lithuanian',
    code: 'lt',
  }, {
    title: 'French',
    code: 'fr',
  }, {
    title: 'Swedish',
    code: 'sv',
  }
];

// Map language to a country code (used for showing the variant language selector flags)
export const languageToCountry = (language: LanguageType) => {
  switch (language.code.toLowerCase()) {
    case 'en': return 'GB';
    case 'nb': return 'NO';
    case 'et': return 'EE';
    case 'cs': return 'CZ';
    case 'sv': return 'SI';
    default: return language.code.toUpperCase();
  } 
};
