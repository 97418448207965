import * as React from 'react';

import { 
  MaterialLinkLayoutDataDecorator, MaterialLinkLayoutDataDecoratorChildProps 
} from '../decorators/MaterialLinkLayoutDataDecorator';
import { ProviderType, DataSourceItem } from 'src/types';
import { MaterialProductInfoLink } from 'src/components/material-layout';

import PriorityHigh from '@material-ui/icons/PriorityHigh';
import { MaterialImportLayout } from './MaterialImportLayout';
import Link from '@material-ui/icons/Link';
import Edit from '@material-ui/icons/Edit';
import i18n from 'src/i18n';

import { 
  SelectedOrganizationContext
} from 'src/decorators/SelectedOrganizationProvider';
import { localMaterialToViewMaterial } from 'src/utils/MaterialVariantUtils';
import { TFunction } from 'i18next';
import { ActionDropdownMenu } from 'src/components';
import { DataSourceItemLinkAction } from 'src/actions/DataSourceItemActions';



const formatDataSourceItems = (
  items: DataSourceItem[] | undefined, 
  orgContext: SelectedOrganizationContext,
  t: TFunction,
) => {
  if (!!items && !!items.length) {
    return items.map(item => (
      <MaterialProductInfoLink
        key={ item.id }
        icon={ <Link/> }
        to={ `/materials/${item.material.id}` }
      >
        { localMaterialToViewMaterial(item.material, orgContext!, t, undefined).name }
      </MaterialProductInfoLink>
    ));
  }

  return null;
};

interface MaterialLinkLayoutProps {
  provider: ProviderType;
  t: TFunction;
}

type DataProps = MaterialLinkLayoutDataDecoratorChildProps;

const MaterialLinkLayout: React.FC<MaterialLinkLayoutProps & DataProps> = (
  { data, provider, providerMaterial, dataSourceItems, t }
) => {
  const organizationContext = React.useContext(SelectedOrganizationContext)!;
  if (dataSourceItems && dataSourceItems.length) {
    // List linked local materials
    return (
      <div>
        { formatDataSourceItems(dataSourceItems, organizationContext, t) }
      </div>
    );
  }

  if (!data || !data.material) {
    // Show import
    return (
      <MaterialImportLayout
        //providerMaterial={ providerMaterial! }
      />
    );
  }

  // List matching (unlinked) local materials
  const material = data.material!;
  const viewMaterial = localMaterialToViewMaterial(material, organizationContext, t, undefined);
  return (
    <div style={{ display: 'flex' }}>
      <MaterialProductInfoLink
        to={ `/materials/${material.id}` }
        icon={ (
          <PriorityHigh 
            style={{ maxWidth: '15px', maxHeight: '20px', color: 'darkred', marginLeft: '-4px' }}
          />
        ) }
        textStyle={{ fontWeight: 'bold' }}
      >
        { `${i18n.t('unlinked').toUpperCase()}: ${viewMaterial.name}` }
      </MaterialProductInfoLink>
      <ActionDropdownMenu
        material={ material }
        provider={ provider }
        providerMaterial={ providerMaterial }
        actions={ { LINK: DataSourceItemLinkAction } }
        caption={ <Edit/> }
        buttonProps={{
          size: 'small',
          style: {
            padding: '0px',
            marginLeft: '5px',
            minHeight: '24px',
          }
        }}
      />
    </div>
  );
};

const Decorated = MaterialLinkLayoutDataDecorator<MaterialLinkLayoutProps>(
  MaterialLinkLayout
);

export { Decorated as MaterialLinkLayout };