import * as React from 'react';
import { SnackbarContext } from 'src/decorators/NotificationDecorator';

import { graphql } from '@apollo/client/react/hoc';
import { RouteComponentProps } from 'react-router';

import { Actions, Queries } from 'src/service/materialVariants';
import { Actions as LocalizationActions } from 'src/service/materialVariantLocalizations';
import { ApolloClient } from '@apollo/client';

import { DataLoaderDecorator, DataProps } from 'src/decorators/DataLoaderDecorator';
import { 
  FormSubmitHandler, MaterialVariantFieldTypes, MaterialVariantLocalizationFieldTypes,
  MaterialVariant, GBMaterialIdType, MaterialVariantIdType, MaterialVariantLocalizationType, GBMaterialType 
} from 'src/types';

import { normalizeUpdateFields, normalizeCreateFields } from 'src/utils/FormUtils';
import { Trans } from 'react-i18next';
import { parseVariantLanguage } from 'src/utils/MaterialVariantUtils';
import { SelectedOrganizationProps, withSelectedOrganization } from 'src/decorators/SelectedOrganizationProvider';
import { Location } from 'history';
import { resetCache } from 'src/utils/MutationUtils';
import { parseIntRouteParam } from 'src/utils/BrowserUtils';
//import { MaterialVariantInput, MaterialVariantLocalizationInput } from 'src/types/input';


export enum MaterialVariantActionTypes {
  CREATE = 'create',
  EDIT = 'edit',
  COPY = 'copy',
}

type RouteProps = RouteComponentProps<{
  materialId: string;
  materialVariantId?: string;
  actionType: MaterialVariantActionTypes;
}>;

type MaterialVariantEditorDataDecoratorProps =
  RouteProps &
  SelectedOrganizationProps;

export interface MaterialVariantEditorDataDecoratorChildProps extends /*RouteProps,*/ SelectedOrganizationProps {
  location: Location;
  actionType: MaterialVariantActionTypes;
  onSubmit: FormSubmitHandler<MaterialVariantFieldTypes>;
  material?: GBMaterialType;
  materialVariant?: MaterialVariant;
  client: ApolloClient<any>;
}


const saveLocalization = async (
  materialVariantId: MaterialVariantIdType,
  formData: MaterialVariantLocalizationFieldTypes, 
  client: ApolloClient<any>,
  initialValues: Partial<MaterialVariantLocalizationFieldTypes>,
  prevLocalization: MaterialVariantLocalizationType | undefined
) => {
  const updateLocalization = !!prevLocalization && prevLocalization.language.code === initialValues.languageCode;

  // Create/update localization
  updateLocalization ? (
      await LocalizationActions.sendUpdateMaterialVariantLocalization(
        prevLocalization!.id,
        await normalizeUpdateFields(formData, initialValues),
        client
      ) 
    ) : (
      await LocalizationActions.sendCreateMaterialVariantLocalization(
        await normalizeCreateFields({
          ...formData,
          materialVariantId,
        }),
        client
      )
    );
};


export const saveVariant = async (
  materialId: GBMaterialIdType, 
  formData: MaterialVariantFieldTypes, 
  actionType: MaterialVariantActionTypes,
  client: ApolloClient<any>,
  initialValues: Partial<MaterialVariantFieldTypes>,
  prevMaterialVariant?: MaterialVariant,
) => {

  const { localization: formLocalization, ...materialVariantData } = formData;
  const updating = !!prevMaterialVariant && actionType === MaterialVariantActionTypes.EDIT;

  // Create/update variant
  const response = updating ? 
    await Actions.sendUpdateMaterialVariant(
      prevMaterialVariant!.id, 
      await normalizeUpdateFields(materialVariantData, initialValues),
      client,
      false
    ) :
    await Actions.sendCreateMaterialVariant(
      await normalizeCreateFields({
        materialId,
        ...materialVariantData,
      }), 
      client,
      false
    );

  if (formLocalization) {
    const prevLocalization = updating && !!prevMaterialVariant ? prevMaterialVariant.localization : undefined;
    await saveLocalization(response.id, formLocalization, client, initialValues.localization!, prevLocalization);
  }

  return response;
};


type Props = MaterialVariantEditorDataDecoratorProps & DataProps<Queries.MaterialVariantResponse>;

function MaterialVariantEditorDataDecorator<T>(
  Component: React.ComponentType<T & MaterialVariantEditorDataDecoratorChildProps>
) {

  const withMaterialVariantData = graphql<RouteProps & T, Queries.MaterialVariantResponse>(
    Queries.MaterialVariantQuery, 
    {
      skip: ({ match }) => {
        return !match.params.materialVariantId || !match.params.materialId;
      },
      options: ({ match, location }) => ({ 
        variables: {
          materialId: parseIntRouteParam(match.params.materialId!),
          materialVariantId: parseIntRouteParam(match.params.materialVariantId!),
          languageCode: parseVariantLanguage(location),
        } 
      }),
    }
  );

  class Decorator extends React.PureComponent<Props & T> {
    static contextType = SnackbarContext;
    context!: SnackbarContext;

    handleSubmit: FormSubmitHandler<MaterialVariantFieldTypes> = async (formData, dispatch, formProps) => {
      try {
        const { data, client, match } = this.props;
        const actionType = match.params.actionType;
    
        const response = await saveVariant(
          parseIntRouteParam(match.params.materialId), 
          formData, 
          actionType, 
          client, 
          formProps.initialValues,
          data && data.materialVariant ? data.materialVariant : undefined
        );

        resetCache(client);

        if (response) {
          this.context({
            text: (
              <Trans 
                i18nKey="actions.notifications.materialVariantSaved" 
                values={{ variant: formData }}
              />
            ), 
            //actionTitle: <Trans i18nKey="actions.viewMaterial"/>, 
            //actionHandler: () => this.props.history.push(`/materials/${materialResponse.id}`)
          });
        }
      } catch (error) {
        this.context({
          text: <Trans i18nKey="actions.notifications.materialVariantSaveFailed" values={{ error }}/>,
        });

        return false;
      }

      return true;
    }

    render() {
      const { data, match } = this.props;
      return (
        <Component
          { ...this.props }
          onSubmit={ this.handleSubmit }
          material={ data ? data.material : undefined }
          materialVariant={ data ? data.materialVariant : undefined }
          actionType={ match.params.actionType }
        />
      );
    }
  }

  return withMaterialVariantData(
    DataLoaderDecorator<RouteProps & T, Queries.MaterialVariantResponse>(
      withSelectedOrganization<RouteProps & DataProps<Queries.MaterialVariantResponse> & T>(Decorator)
    )
  );
}

export { 
  MaterialVariantEditorDataDecorator
};
