import * as React from 'react';

import { PaginatedTable, PaginatedTableColumnWidthInfo, PaginatedTableProps } from 'src/components/table';
import { ThunkedCategoryUrl } from '../../NavigationButton';
import { MaterialTableCell } from './MaterialTableCell';
import { ViewMaterialType } from 'src/types';
import { PaginationQueryType } from 'src/utils/PaginationUtils';

import { ModalRoute } from 'src/components/ModalRoute';
import { MaterialMoveDialog } from '../material-move-dialog';
import { useRouter } from 'src/effects';
import { BrowserSettings } from 'src/constants';


type MaterialColumn = 'name' | 'image' | 'category' | 'manufacturer' | 'createdAt';
// 'status' | 'gtin' | 'sku' | 'assetGUID';
export type MaterialListColumnNames = MaterialColumn[];

const Columns: MaterialListColumnNames = [
  'image',
  'name',
  'category',
  'manufacturer',
];

const DefaultColumnWidths: PaginatedTableColumnWidthInfo[] = [
  {
    columnName: 'image',
    width: '140px',
  }, {
    columnName: 'name',
    width: '40%',
  }, {
    columnName: 'category',
    width: '20%',
  }, {
    columnName: 'manufacturer',
    width: '20%',
  }
];

interface MaterialTableDataProps<MaterialType extends ViewMaterialType> extends 
  Pick<PaginatedTableProps<string[], MaterialType>, 'selectionActions'> {
    
  materials: MaterialType[];
  totalCount: number | null;
  pageTitle?: string; // strings ONLY (used for history state)
}

export interface MaterialTableConfigProps {
  browserBaseUrl?: ThunkedCategoryUrl; 
  columns?: MaterialListColumnNames;
  manufacturerSectionSupported?: boolean;
  sortableColumns?: MaterialListColumnNames;
}

type MaterialTableProps<MaterialType extends ViewMaterialType> = 
  MaterialTableDataProps<MaterialType> & MaterialTableConfigProps;


export const DefaultLocalMaterialListPagination: PaginationQueryType = {
  orderby: undefined,
  page: '1',
  pagesize: '15',
  direction: 'asc',
  pageSizeStorageKey: BrowserSettings.MATERIAL_TABLE_PAGE_SIZE,
};

export const DefaultProviderMaterialListPagination: PaginationQueryType = DefaultLocalMaterialListPagination;

const getSectionUrl = (sectionUrl: string, browserBaseUrl?: ThunkedCategoryUrl) => {
  if (browserBaseUrl) {
    return `${browserBaseUrl}${sectionUrl}`;
  }

  return sectionUrl;
};

const MaterialTable = <MaterialType extends ViewMaterialType>(props: MaterialTableProps<MaterialType>) => {
  const { match } = useRouter();
  const { 
    columns, materials, manufacturerSectionSupported, browserBaseUrl, pageTitle, selectionActions, ...other
  } = props;

  return (
    <>
      <PaginatedTable<string[], MaterialType>
        columns={ columns || Columns }
        rows={ materials }
        defaultPagination={ DefaultLocalMaterialListPagination }
        defaultColumnWidths={ DefaultColumnWidths }
        selectionActions={ selectionActions }
        cellComponent={ cellProps => (
          <MaterialTableCell
            materialSectionUrl={ getSectionUrl('/materials', browserBaseUrl) }
            categorySectionUrl={ getSectionUrl('/categories', browserBaseUrl) }
            manufacturerSectionUrl={ 
              manufacturerSectionSupported ? getSectionUrl('/manufacturers', browserBaseUrl) : undefined
            }
            pageTitle={ pageTitle }
            { ...cellProps }
          />
        ) }
        { ...other }
      />
      <ModalRoute
        component={ MaterialMoveDialog }
        path={ `${match.url}/move` }
      />
    </>
  );
};

export { MaterialTable };
