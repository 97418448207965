import * as React from 'react';

import { graphql } from '@apollo/client/react/hoc';
import { Location } from 'history';

import {
  MaterialTableConfigProps, MaterialTable, DefaultProviderMaterialListPagination
} from 'src/components/browser-layout';

import { DataLoaderDecorator } from 'src/decorators/DataLoaderDecorator';
import { Queries } from 'src/service/providers';

import { 
  parsePaginationVariables, normalizePaginatedResponse
} from 'src/utils/PaginationUtils';
import { parseSearchVariables } from 'src/utils/SearchUtils';
import { ProviderType } from 'src/types';

import i18n from 'src/i18n';


export interface ProviderSearchResultsMaterialTableProps extends MaterialTableConfigProps {
  location: Location;
  provider: ProviderType;
}

const withResults = graphql<ProviderSearchResultsMaterialTableProps, Queries.ProviderMaterialSearchListResponse>(
  Queries.ProviderMaterialSearchListQuery, {
    options: ({ location, provider }) => {
      return {
        variables: {
          providerId: provider.code,
          ...parseSearchVariables(location),
          ...parsePaginationVariables(location, DefaultProviderMaterialListPagination).query,
        }
      };
    },
  }
);


const ProviderSearchResultsMaterialTable = withResults(DataLoaderDecorator(({ data, provider }) => {
  const materials = data.provider!.search;
  return (
    <MaterialTable
      materials={ normalizePaginatedResponse(materials) }
      totalCount={ materials.total }
      pageTitle={ i18n.t('searchResults') }
      browserBaseUrl={ `/providers/${provider.code}` }
      manufacturerSectionSupported={ false }
    />
  );
}));

export { ProviderSearchResultsMaterialTable };