import * as React from 'react';

import FolderIcon from '@material-ui/icons/Folder';

import { CategoryList } from './CategoryList';

import { MainLayout } from 'src/components/main-layout';
import { Message } from 'src/components/Message';
import { ThunkedCategoryUrl } from './NavigationButton';

import { MaterialCategoryBaseType, MaterialCategoryBaseIdType, SystemCategoryType } from 'src/types';

import { isRootCategory } from './RootCategory';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { NavigationBar } from './navigation-bar';
import { Trans } from 'react-i18next';
import { FilterableTableLayout, MaterialTableType, FilterPanelType } from '../table';
import { LayoutTitle } from '../main-layout/LayoutTitle';

type TypedCategory<IdType extends MaterialCategoryBaseIdType> = 
  MaterialCategoryBaseType<IdType> & { id: IdType, parent?: TypedCategory<IdType> };

export type ActionPanelProps<IdType extends MaterialCategoryBaseIdType = MaterialCategoryBaseIdType> = { 
  category: TypedCategory<IdType>;
  systemCategory?: SystemCategoryType | null;
};

export type ActionPanelType<IdType extends MaterialCategoryBaseIdType = MaterialCategoryBaseIdType> = 
  React.ComponentType<ActionPanelProps<IdType>>;

export interface MaterialBrowserDataType<IdType extends MaterialCategoryBaseIdType = MaterialCategoryBaseIdType> {
  category: MaterialCategoryBaseType<IdType>;
  childCategories?: Array<MaterialCategoryBaseType<IdType>>;
}

export interface MaterialBrowserPropsType<IdType extends MaterialCategoryBaseIdType = MaterialCategoryBaseIdType> {
  browserBaseUrl: ThunkedCategoryUrl;
  navigationItemIcon?: React.ComponentType<SvgIconProps>;
  actionPanel?: ActionPanelType<IdType>;
  materialTable?: MaterialTableType;
  filterPanel?: FilterPanelType;
  indexTitle?: React.ReactNode;
  subTitle?: React.ReactNode;
  totalMaterialCount?: number | null;
}

export type MaterialBrowserProps<IdType extends MaterialCategoryBaseIdType = MaterialCategoryBaseIdType> = 
  MaterialBrowserDataType<IdType> & MaterialBrowserPropsType<IdType>;


class BrowserLayout<IdType extends MaterialCategoryBaseIdType = MaterialCategoryBaseIdType> extends 
  React.PureComponent<MaterialBrowserProps<IdType>> {

  static defaultProps = {
    navigationItemIcon: FolderIcon,
    manufacturersSupported: true,
  };

  render() {
    const { 
      category, childCategories,
      actionPanel: ActionPanel,
      children, navigationItemIcon, browserBaseUrl,
      indexTitle, subTitle, filterPanel, materialTable,
      totalMaterialCount
    } = this.props;

    const isLabelCategory = (childCategories:any[] | undefined) => {
      const isLabelCategory = !!childCategories && !!childCategories.length && childCategories[0].__typename === 'LabelCategory';
      return isLabelCategory;
    };

    const isSystemCategory = (category:any) => {
      const isSystemCategory = category.systemCategory === 'DECO_TOOL_CATEGORY';
      return isSystemCategory;
    }

    const getFilteredChildCategories = (childCategories:any[] | undefined) => {
        if(!isLabelCategory(childCategories)) 
          return childCategories;

        const result = childCategories!.filter(childCategory => childCategory.systemCategory == null);

        return result;
    };

    const getSystemCategoryLabelCategories = (childCategories:any[] | undefined) => {
      const result = childCategories!.filter(category => category.systemCategory)
      return result;
    };

    const filteredChildCategories = getFilteredChildCategories(childCategories);
    const systemCategoryLabelCategories = getSystemCategoryLabelCategories(childCategories);

    const title = !isRootCategory(category) ? category.name : indexTitle;

    const showSystemLabelCategoryWarning = !isRootCategory(category) && isSystemCategory(category);

    return (
      <MainLayout
        id="material-browser"
        actionBarLeft={ !isRootCategory(category) && (
          <NavigationBar
            category={ category } 
            browserBaseUrl={ browserBaseUrl }
          />
        ) }
        actionBarRight={ !!ActionPanel && <ActionPanel category={ category }/> }
        title={ title }
        //titleIcon={ category.id !== ROOT_ID ? navigationItemIcon : undefined }
        subTitle={ subTitle }
      >
        { !!children || (!!childCategories && !!childCategories.length) || !!totalMaterialCount ? (
          <>
            { children }
            {(!!filteredChildCategories && !!filteredChildCategories.length) && (
              <CategoryList 
                category={ category }
                childCategories={ filteredChildCategories }
                browserBaseUrl={ browserBaseUrl }
                navigationItemIcon={ navigationItemIcon }
              />
            )}
            { !!systemCategoryLabelCategories && !!systemCategoryLabelCategories.length && (
              <>
                <LayoutTitle>
                  { <Trans i18nKey="systemCategories"/> }
                </LayoutTitle>
                <CategoryList 
                  category={ category }
                  childCategories={ systemCategoryLabelCategories }
                  browserBaseUrl={ browserBaseUrl }
                  navigationItemIcon={ navigationItemIcon }
                />
              </>
            )}
            { !!totalMaterialCount && (
              <FilterableTableLayout
                table={ materialTable }
                filterPanel={ filterPanel }
              />
            ) }
          </>
        ) : (
          <Message
            title={ <Trans i18nKey="table.noItems"/> }
          />
        ) }
      </MainLayout>
    );
  }
}

export {
  BrowserLayout 
};
