import * as React from 'react';

import ChevronRight from '@material-ui/icons/ChevronRight';

import withStyles from '@material-ui/core/styles/withStyles';

import { NavigationButton, ThunkedCategoryUrl } from '../NavigationButton';
import { MaterialCategoryBaseType } from 'src/types';


interface NavigationSectionProps {
  category: MaterialCategoryBaseType;
  browserBaseUrl: ThunkedCategoryUrl;
  hasItems: boolean;
  isSelected: boolean;
  classes: {
    root: any;
  };
}

const styles = {
  root: {
    padding: 3,
    marginTop: 2,
    minWidth: 0,
  }
};

const NavigationBarSection: React.FC<NavigationSectionProps> = (
  { category, browserBaseUrl, classes, hasItems, isSelected }
) => (
  <>
    <NavigationButton
      classes={ classes }
      category={ category } 
      browserBaseUrl={ browserBaseUrl }
      icon={ null }
      color={ isSelected ? 'primary' : undefined }
    />
    { hasItems && (
      <ChevronRight
        style={{
          marginTop: 'auto',
          marginBottom: 'auto',
          height: '20px',
          width: '20px'
        }}
      />
    ) }
  </>
);

const NavigationBarSectionWithStyles = withStyles(styles)(NavigationBarSection);

export { NavigationBarSectionWithStyles as NavigationBarSection };
