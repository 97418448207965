import * as React from 'react';
import { SnackbarContext } from 'src/decorators/NotificationDecorator';
import { DataLoaderDecorator, DataProps } from 'src/decorators/DataLoaderDecorator';

import { graphql } from '@apollo/client/react/hoc';
import { RouteComponentProps } from 'react-router';

import { Actions, Queries } from 'src/service/labels';

import { normalizeUpdateFields, normalizeCreateFields } from 'src/utils/FormUtils';
import { GBLabelType, FormSubmitHandler, LabelFieldTypes } from 'src/types';
import { Trans } from 'react-i18next';
import { parseIntRouteParam } from 'src/utils/BrowserUtils';


type RouteProps = RouteComponentProps<{
  labelId?: string;
  labelCategoryId: string;
}>;


export type LabelEditorDataDecoratorProps = RouteProps;

export interface LabelEditorDataDecoratorChildProps extends RouteProps {
  onSubmit: FormSubmitHandler<LabelFieldTypes>;
  label?: GBLabelType;
}


type Props = LabelEditorDataDecoratorProps & DataProps<Queries.LabelResponse>;

function LabelEditorDataDecorator<T>(
  Component: React.ComponentType<T & LabelEditorDataDecoratorChildProps>
) {
  const withLabelData = graphql<RouteProps & T, Queries.LabelResponse>(
    Queries.LabelQuery, 
    {
      skip: ({ match }) => {
        return !match.params.labelId;
      },
      options: ({ match }) => ({ 
        variables: { labelId: parseIntRouteParam(match.params.labelId!) } 
      }),
    }
  );

  class Decorator extends React.Component<Props & T> {
    static contextType = SnackbarContext;
    context!: SnackbarContext;

    onSubmit: FormSubmitHandler<LabelFieldTypes> = async (formData, dispatch, formProps) => {
      const { data, client } = this.props;
      const { languageCode, ...labelData } = formData;

      // Create/update label
      try {
        const response = data && data.label ? 
          await Actions.sendUpdateLabel(
            data.label.id, 
            await normalizeUpdateFields(labelData, formProps.initialValues), 
            languageCode,
            client
          ) :
          await Actions.sendCreateLabel(
            await normalizeCreateFields(labelData), 
            languageCode,
            client
          );

        if (response) {
          this.context({
            text: <Trans i18nKey="actions.notifications.labelSaved" values={{ label: labelData }}/>, 
            actionTitle: <Trans i18nKey="actions.viewLabel"/>, 
            actionHandler: () => this.props.history.push(`/labels/${response.id}`)
          });
        }
      } catch (error) {
        this.context({
          text: <Trans i18nKey="actions.notifications.labelSaveFailed" values={{ error }}/>,
        });

        return false;
      }

      return true;
    }

    render() {
      const { data } = this.props;
      return (
        <Component
          { ...this.props }
          onSubmit={ this.onSubmit }
          label={ data ? data.label : undefined }
        />
      );
    }
  }

  return withLabelData(
    DataLoaderDecorator<LabelEditorDataDecoratorProps & T, Queries.LabelResponse>(Decorator)
  );
}

export { 
  LabelEditorDataDecorator
};
