import gql from 'graphql-tag';


// Queries
export const CreateLabelMutation = gql`
  mutation CreateLabelMutation($input: CreateLabelInput!, $languageCode: LanguageCode!) {
    createLabel(input: $input, languageCode: $languageCode) {
      id,
    }
  }
`;

export const UpdateLabelMutation = gql`
  mutation UpdateLabelMutation(
    $id: Int!, 
    $input: UpdateLabelInput!,
    $languageCode: LanguageCode!
  ) {
    updateLabel(id: $id, input: $input, languageCode: $languageCode) {
      id,
    }
  }
`;

export const DeleteLabelMutation = gql`
  mutation DeleteLabelMutation($id: Int!) {
    deleteLabel(id: $id) {
      id,
    }
  }
`;


// Response types
export interface CreateLabelResponse {
  createLabel: {
    id: number;
  };
}

export interface UpdateLabelResponse {
  updateLabel: {
    id: number;
  };
}

export interface DeleteLabelResponse {
  deleteLabel: {
    id: number;
  };
}
