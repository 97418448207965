import * as React from 'react';

import Dropzone, { DropzoneRef, FileRejection } from 'react-dropzone';
import { WrappedFieldProps, Field, GenericField } from 'redux-form';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Upload from '@material-ui/icons/CloudUpload';

import { FormLabelProps } from './types';
import { MaterialImageType } from 'src/types';
import { withTranslation, WithTranslation } from 'react-i18next';

interface State {
  pictures: Array<{
    file: File;
    preview: string;
  }>;
}

const ImageStyle = {
  maxWidth: '200px',
  maxHeight: '200px',
};

export type ImageUploadFieldProps = FormLabelProps & { disabled?: boolean };
type ImageUploadFieldInputProps = WrappedFieldProps & ImageUploadFieldProps;

class ImageUploadField extends React.Component<WithTranslation & ImageUploadFieldInputProps, State> {
  state: State = {
    pictures: []
  };

  dropzoneRef!: DropzoneRef;

  onDrop = (accepted: File[], rejected: FileRejection[]) => {
    this.props.input.onChange(accepted[0]);

    this.cleanup();

    const pictures = accepted.map(file => ({
      file,
      preview: URL.createObjectURL(file),
    }));

    this.setState({
      pictures,
    });
  }

  componentWillUnmount() {
    this.cleanup();
  }

  cleanup = () => {
    // Revoke data uris when done using the previews
    const { pictures } = this.state;

    for (const file of pictures) {
      URL.revokeObjectURL(file.preview);
    }
  }

  render() {
    const { pictures } = this.state;
    const { label, error, focused, required, input, disabled, t } = this.props;
    const value: MaterialImageType = input.value;
    return (
      <FormControl>
        { label && (
          <FormLabel 
            //component="legend"
            error={ error }
            required={ required }
            focused={ focused } 
          >
            { label }
          </FormLabel>
        ) }
        <div>
          { pictures.length > 0 && 
            pictures
              .filter(image => !!image)
              .map((image) => (
                <img 
                  key={ image.file.name } 
                  alt={ image.file.name }
                  src={ image.preview }
                  style={ ImageStyle }
                />
              ))
          }
          { !!value && !pictures.length && (
            <img
              alt={ value.name }
              src={ value.urlSmall }
              style={ ImageStyle }
            />
          ) }
        </div>
        <Dropzone
          ref={ ref => this.dropzoneRef = ref! }
          multiple={ false }
          accept="image/*"
          disabled={ disabled }
          onDrop={ this.onDrop }
        >
          { ({ getRootProps, getInputProps }) => (
            <input 
              { ...getInputProps() }
              name={ input.name }
            />
          ) }
        </Dropzone>
        <Button
          size="small"
          variant="contained"
          onClick={ evt => {
            this.dropzoneRef.open();
          }}
          disabled={ disabled }
        >
          <Upload/>
          { t('uploadImage') }
        </Button>
      </FormControl>
    );
  }
}

export const ImageUploadFieldComponent = withTranslation()(ImageUploadField);

const FieldType = Field as new() => GenericField<ImageUploadFieldProps>;

export { FieldType as ImageUploadField };


