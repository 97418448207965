import * as React from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { BrowserSettings } from 'src/constants';
import { MaterialVariant } from 'src/types';
import { parseCurrentVariant, variantSort } from 'src/utils/MaterialVariantUtils';
import { stringifyQuery } from 'src/utils/QueryUtils';
import { SelectedOrganizationContext } from 'src/decorators/SelectedOrganizationProvider';
import { MaterialVariantTitle } from 'src/components/MaterialVariantTitle';
import { WithTranslation } from 'react-i18next';
import { useRouter } from 'src/effects';


type RouteProps = { 
  materialId: string; 
  materialVariantId: string; 
};

interface MaterialVariantSelectorProps {
  variants: MaterialVariant[];
}


type Props = MaterialVariantSelectorProps & Pick<WithTranslation, 't'>;

const MaterialVariantSelector = React.memo<Props>(props => {
  const { location, match, history } = useRouter<RouteProps>();
  const organizationContext = React.useContext(SelectedOrganizationContext)!;

  const handleChange = (event: any) => {
    const { materialId } = match.params;
    localStorage.setItem(BrowserSettings.LAST_PROVIDER_ID, event.target.value);
    history.push({
      pathname: `/materials/${materialId}`,
      search: stringifyQuery({
        variant: event.target.value,
      }),
    });
  };

  const { variants, t } = props;
  if (!variants.length) {
    return null;
  }

  const currentVariant = parseCurrentVariant(variants, location, organizationContext)!;
  return (
    <Select
      value={ currentVariant.id }
      onChange={ handleChange }
      displayEmpty
      name="variant"
      style={{ margin: '5px 10px' }}
    >
      { variants!
          .slice()
          .sort(variantSort(organizationContext))
          .map(variant => (
            <MenuItem 
              key={ variant.id }
              value={ variant.id }
            >
              <MaterialVariantTitle
                variant={ variant }
                t={ t }
              />
            </MenuItem>
          ))
      }
    </Select>
  );
});

export { MaterialVariantSelector };
