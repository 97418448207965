import * as React from 'react';

import { ConfigProps, FormSection, EventWithDataHandler } from 'redux-form';

import { 
  //GBMaterialType, MaterialVariant, 
  MaterialVariantLocalizationType, OrganizationIdType, MaterialVariant, MaterialVariantFieldTypes 
} from 'src/types';
import {
  ImageUploadField, ImageUploadFieldComponent,
  TextField, TextAreaFieldComponent, TextFieldComponent 
} from 'src/components/form';

import { 
  MaterialVariantEditorDataDecoratorChildProps, MaterialVariantActionTypes 
} from '../../decorators/MaterialVariantEditorDataDecorator';
import { InjectedEditorFormProps } from 'src/components/editor/EditorForm';
import { withTranslation, WithTranslation } from 'react-i18next';
import { max250Validator } from 'src/utils/FieldValidators';
import { OrganizationSelectField, OrganizationSelectFieldComponent } from 'src/components/form/OrganizationSelectField';
import { InputAdornment, Typography } from '@material-ui/core';
import { LanguageSelectField, LanguageSelectFieldComponent } from 'src/components/form/LanguageSelectField';

import { 
  parseVariantLanguage, hasBaselineVariant
} from 'src/utils/MaterialVariantUtils';
import { formatDateTime } from 'src/utils/DateUtils';
import { Location } from 'history';
import { AuthContext, AuthContextType } from 'src/decorators/AuthDecorator';
import { Permission } from 'src/constants';
import { TFunction } from 'i18next';
import { MaterialProductInfoList, MaterialProductInfoListItem } from 'src/components/material-layout';
import { RouteComponentProps } from 'react-router';

export type MaterialVariantEditorFormProps = InjectedEditorFormProps<
  MaterialVariantFieldTypes, 
  Pick<MaterialVariantEditorDataDecoratorChildProps, 'materialVariant' | 'actionType' | 'material' | 'location'>
>;

interface State {
  selectedOrganizationId: OrganizationIdType | undefined;
}


interface SourceLocalizationProps extends Pick<RouteComponentProps, 'location'> {
  materialVariant: MaterialVariant | undefined;
  t: TFunction;
}

const SourceLocalization: React.FC<SourceLocalizationProps> = (
  { materialVariant, t, location }: SourceLocalizationProps
) => {
  if (!materialVariant || !materialVariant.localization) {
    return null;
  }

  const { localization } = materialVariant;
  if (localization.language.code === parseVariantLanguage(location)) {
    return null;
  }

  return (
    <MaterialProductInfoList
      style={{
        margin: '10px 0px'
      }}
    >
      <MaterialProductInfoListItem
        title={ t('name') + ' (' + localization.language.name + ')' } 
      >
        { localization.name }
      </MaterialProductInfoListItem>        
      { !!localization.description && (
        <MaterialProductInfoListItem
          title={ t('description') + ' (' + localization.language.name + ')' } 
        >
          { localization.description }
        </MaterialProductInfoListItem>
      ) }
    </MaterialProductInfoList>
  );
};

class MaterialVariantEditorForm extends React.PureComponent<WithTranslation & MaterialVariantEditorFormProps> {
  constructor(props: WithTranslation & MaterialVariantEditorFormProps) {
    super(props);

    this.state = {
      selectedOrganizationId: !!props.initialValues.organizationId ? props.initialValues.organizationId : undefined,
    };
  }
  
  static contextType = AuthContext;
  context!: AuthContextType;

  onOrganizationChanged: EventWithDataHandler<React.ChangeEvent<any>> = (event, value, previousValue) => {
    this.setState({
      selectedOrganizationId: value,
    });
  }

  state: State;

  render() {
    const { t, materialVariant, actionType, material, location } = this.props;
    const { selectedOrganizationId } = this.state;
    const hasMaterialEditAccess = this.context.hasGlobalAccess(Permission.MATERIALS_EDIT);
    const hasBaseline = !!material && hasBaselineVariant(material);
    return (
      <>
        { (
          <>
            { (!materialVariant || !!materialVariant!.organization || 
              actionType === MaterialVariantActionTypes.COPY) && (
              <OrganizationSelectField 
                required={ !hasMaterialEditAccess || hasBaseline }
                nullOptionLabel={ hasMaterialEditAccess && !hasBaseline ? 
                  t('baseline') : 
                  undefined 
                }
                disabled={ actionType === MaterialVariantActionTypes.EDIT }
                name="organizationId" 
                component={ OrganizationSelectFieldComponent } 
                onChange={ this.onOrganizationChanged }
                label={ t('organization') }
              />
            )}
            { !!selectedOrganizationId && (
              <TextField 
                name="description" 
                component={ TextFieldComponent } 
                label={ t('variantDescription') }
                validate={ max250Validator }
              />
            ) }
          </>
        ) }
        <ImageUploadField 
          name="image" 
          component={ ImageUploadFieldComponent } 
          label={ t('image') }
        />
        <FormSection
          name="localization"
        >
          <Typography
            variant="h6"
            style={{ marginTop: '30px' }}
          >
            { t('materialProperties') }
          </Typography>
          <SourceLocalization
            t={ t }
            materialVariant={ materialVariant }
            location={ location }
          />
          <LanguageSelectField
            required
            name="languageCode"
            component={ LanguageSelectFieldComponent }
            label={ t('language') }
            disabled={ !!materialVariant && actionType !== MaterialVariantActionTypes.COPY }
            //selectedIds={ !!materialVariant ? materialVariant.localizations.map(loc => loc.id) : undefined }
          />
          <TextField 
            required
            name="name" 
            component={ TextFieldComponent } 
            label={ t('materialName') }
            validate={ max250Validator }
          />
          <TextField 
            name="description" 
            component={ TextAreaFieldComponent } 
            label={ t('materialDescription') }
          />
        </FormSection>
        <Typography
          variant="h6"
          style={{ marginTop: '30px' }}
        >
          { t('materialPrice') }
        </Typography>
        <TextField
          variant="outlined"
          name="netPrice"
          component={ TextFieldComponent }
          label={t('net')}
          type={"number"}
          parse={value => Number(value)}
          InputProps={{
            inputMode: 'numeric',
            startAdornment: (
              <InputAdornment position="start">
                {"€"}
              </InputAdornment>
            ),
          }}
        />
        <TextField
          variant="outlined"
          name="updatedAt"
          component={ TextFieldComponent }
          label={t('updatedAt')}
        />
      </>
    );
  }
}

const localizationToFormData = (localization: MaterialVariantLocalizationType) => {
  return {
    name: localization.name,
    description: localization.description,
    languageCode: localization.language.code,
  };
};

const parseLocalization = (
  localization: MaterialVariantLocalizationType | undefined, 
  location: Location
) => {
  const selectedLanguage = parseVariantLanguage(location);
  if (!!localization && localization.language.code === selectedLanguage) {
    return localizationToFormData(localization);
  }

  return {
    languageCode: selectedLanguage,
  };
};



type ReduxFormConfigProps = RecursivePartial<ConfigProps<MaterialVariantFieldTypes>>;

interface MapperProps extends Pick<
  MaterialVariantEditorDataDecoratorChildProps, 
  'materialVariant' | 'location' | 'actionType' | 'organizationContext'
> {
  approving?: boolean;
}

const mapFields = (
  state: any,
  { 
    materialVariant, location, organizationContext, actionType, approving
  }: MapperProps 
): ReduxFormConfigProps => {
  const parseOrganizationId = () => {
    if (!approving && (!materialVariant || actionType === MaterialVariantActionTypes.COPY)) {
      return organizationContext.selectedOrganization.id;
    }

    if (!!materialVariant && !!materialVariant.organization) {
      return materialVariant.organization.id;
    }

    return undefined;
  };

  if (!materialVariant) {
    return {
      initialValues: {
        localization: parseLocalization(undefined, location),
        organizationId: parseOrganizationId(),
      },
    };
  }

  const pickLocalization = () => {
    if (approving && !!materialVariant.localizations.length) {
      return localizationToFormData(materialVariant.localizations[0]);
    }

    return parseLocalization(materialVariant.localization, location);
  };

  const { description, image, netPrice, provision, vat, updatedAt } = materialVariant;
  return {
    initialValues: {
      image,
      description,
      localization: pickLocalization(),
      organizationId: parseOrganizationId(),
      netPrice,
      provision,
      vat,
      updatedAt: formatDateTime(updatedAt, () => {})
    },
  };
};

const Decorated = withTranslation()(MaterialVariantEditorForm);

export { 
  Decorated as MaterialVariantEditorForm,
  mapFields 
};
