import * as React from 'react';

import { SnackbarContext } from 'src/decorators/NotificationDecorator';
import { DataLoaderDecorator, DataProps } from 'src/decorators/DataLoaderDecorator';

import { graphql } from '@apollo/client/react/hoc';
import { RouteComponentProps } from 'react-router';

import { Actions, Queries } from 'src/service/materialCategories';

import { normalizeUpdateFields, normalizeCreateFields } from 'src/utils/FormUtils';
import { GBMaterialCategoryType, FormSubmitHandler, MaterialCategoryFieldTypes } from 'src/types';
import { Trans } from 'react-i18next';
import { parseIntRouteParam } from 'src/utils/BrowserUtils';


type RouteProps = RouteComponentProps<{
  categoryId?: string;
  parentId?: string;
}>;

export type MaterialCategoryEditorDataDecoratorProps = RouteProps;

export interface MaterialCategoryEditorDataDecoratorChildProps extends RouteProps {
  onSubmit: FormSubmitHandler<MaterialCategoryFieldTypes>;
  category?: GBMaterialCategoryType;
}


type Props = MaterialCategoryEditorDataDecoratorProps & DataProps<Queries.MaterialCategoryResponse>;

function MaterialCategoryEditorDataDecorator<T>(
  Component: React.ComponentType<T & MaterialCategoryEditorDataDecoratorChildProps>
) {
  const withCategoryData = graphql<MaterialCategoryEditorDataDecoratorProps & T, Queries.MaterialCategoryResponse>(
    Queries.MaterialCategoryQuery, 
    {
      skip: ({ match }) => {
        return !match.params.categoryId;
      },
      options: ({ match }) => ({ 
        variables: { 
          categoryId: parseIntRouteParam(match.params.categoryId!) 
        } 
      }),
    }
  );

  class Decorator extends React.Component<Props & T> {
    static contextType = SnackbarContext;
    context!: SnackbarContext;

    handleSubmit: FormSubmitHandler<MaterialCategoryFieldTypes> = async (formData, dispatch, formProps) => {
      const { data, client } = this.props;

      const { languageCode, ...categoryData } = formData;

      // Create/update category
      try {
        const response = data && data.materialCategory ? 
          await Actions.sendUpdateMaterialCategory(
            data.materialCategory.id, 
            await normalizeUpdateFields(categoryData, formProps.initialValues), 
            languageCode,
            client
          ) :
          await Actions.sendCreateMaterialCategory(
            await normalizeCreateFields(categoryData), 
            languageCode,
            client
          );

        if (response) {
          this.context({
            text: <Trans i18nKey="actions.notifications.categorySaved" values={{ category: categoryData }}/>, 
            actionTitle: <Trans i18nKey="actions.viewCategory"/>, 
            actionHandler: () => this.props.history.push(`/categories/${response.id}`)
          });
        }
      } catch (error) {
        this.context({
          text: <Trans i18nKey="actions.notifications.categorySaveFailed" values={{ error }}/>,
        });

        return false;
      }

      return true;
    }

    render() {
      const { data } = this.props;
      return (
        <Component
          { ...this.props }
          onSubmit={ this.handleSubmit }
          category={ data ? data.materialCategory : undefined }
        />
      );
    }
  }

  return withCategoryData(
    DataLoaderDecorator<MaterialCategoryEditorDataDecoratorProps & T, Queries.MaterialCategoryResponse>(Decorator)
  );
}

export { 
  MaterialCategoryEditorDataDecorator
};
