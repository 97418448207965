import * as React from 'react';
import invariant from 'invariant';

import { Props as SelectProps } from 'react-select/base';
import { AsyncProps } from 'react-select/src/Async';
import AsyncCreatable from 'react-select/async-creatable';
import Creatable, { CreatableProps } from 'react-select/creatable';

import { WrappedFieldInputProps, EventOrValueHandler } from 'redux-form';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import { Trans } from 'react-i18next';
import { Option, CreateHandler, OnChangeHandler } from './types';
import { FormLabelProps } from '../types';
import { SuggestionSection } from './SuggestionSection';


type AsyncCreatableProps<ValueT> = AsyncProps<ValueT> & CreatableProps<ValueT, false> & SelectProps<ValueT>;

type FormSelectFieldProps = FormLabelProps & { 
  disabled?: boolean;
  selectedIds?: any[];
  suggestedValue?: string;
};

//export type OnChangeHandler<ValueT> = (value: ValueT, action: ActionMeta) => void;
 /*AsyncCreatableProps<ValueT>['onChange']*/

interface FormSelectFieldInputProps extends FormSelectFieldProps {
  input: WrappedFieldInputProps;
  onSelectValueChange?: OnChangeHandler<any>;
}

export const DefaultSuggestionLoader = (suggestedValue: string) => {
  const ret = <ValueT extends object>(props: FieldProps<ValueT>) => {
    if (!!props.loadOptions && !!suggestedValue) {
      return props.loadOptions(suggestedValue, null as any);
    }
  };

  return ret;
};


type FieldProps<ValueT> = CreatableProps<Option<ValueT>, false> & Partial<AsyncCreatableProps<Option<ValueT>>>;

function SelectFieldDecorator<PropsT, ValueT>(
  Component: React.ComponentType<FieldProps<ValueT> & PropsT>
): React.ComponentType<FieldProps<ValueT> & FormSelectFieldInputProps & PropsT> {


  const findValue = (
    value: ValueT, 
    options?: Option<ValueT>[]
  ): Option<ValueT> | undefined => {
    if (!!options && !!options.length) {
      const ret = options.find(opt => opt.value === value);
      invariant(
        !!ret || !value, 
        `Select value ${value} not found from the options (${JSON.stringify(options, null, 2)})`
      );
      return ret;
    }
  
    return undefined;
  };
  
  // Parse IDs from function values
  const parseSelectOption = (
    value: ValueT | CreateHandler<ValueT>, 
    options?: Option<ValueT>[]
  ): Option<ValueT> | Option<ValueT>[] | undefined => {
    if (Array.isArray(value)) {
      return (value as Array<ValueT | CreateHandler<ValueT>>).map(v => parseSelectOption(v, options)) as Option<ValueT>[];
    } else if (typeof value === 'function') {
      return (value as any as CreateHandler<ValueT>).getValue();
    } else {
      return findValue(value as ValueT, options);
    }
  };

  const formatOptions = (options: Option<ValueT>[] | undefined, selectedIds: any[] | undefined) => {
    if (!options || !options.length) {
      return undefined;
    }

    return options;
  };
  
  const filterOption: AsyncCreatableProps<ValueT>['filterOption'] = (option, inputValue) => {
    if (!option.label) {
      return true;
    }
  
    if (typeof option.label !== 'string') {
      // New option
      return true;
    }
  
    return option.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
  };
  
  // UTILS END

  const valueToString = (value: any) => {
    return !!value ? value.toString() : '';
  };

  const useInitialSuggestionEffect = (
    props: FieldProps<ValueT> & FormSelectFieldInputProps
  ) => {
    const [ suggestions, setSuggestions ] = React.useState([] as Option<ValueT>[]);
    const { loadOptions, suggestedValue } = props;

    React.useEffect(
      () => {
        if (!!loadOptions && !!suggestedValue) {
          (loadOptions(suggestedValue, null as any) as Promise<Option<ValueT>[]>)
            .then(res => setSuggestions(res));
        }
      },
      [ loadOptions, suggestedValue ]
    );

    return suggestions;
  };

  const Select: React.FC<FormSelectFieldInputProps & FieldProps<ValueT> & PropsT> = props => {

    const { 
      input, isMulti = false, 
      label, error, required, focused, isValidNewOption, getNewOptionData,
      onSelectValueChange: initialOnSelectValueChange, options, disabled, selectedIds,
      suggestedValue, loadOptions,
      ...other 
    } = props;

    const initialSuggestions = useInitialSuggestionEffect(props);

    const { name, value, onBlur, onChange: onInputChange, onFocus } = input;

    const onSelectValueChange = initialOnSelectValueChange ? initialOnSelectValueChange : (isMulti
      ? multiChangeHandler(onInputChange)
      : singleChangeHandler(onInputChange));

    //const tmp = parseSelectOption(value, options);
    //console.log(`[RENDER] SELECT OPTION ${name}`, tmp);
    //console.log(`[RENDER] VALUE ${name}`, value);
    //console.log(`[RENDER] OPTIONS ${name}`, options);
    return (
      <FormControl
        fullWidth={ true }
      >
        { !!label && (
          <FormLabel 
            //component="legend"
            error={ error }
            required={ required }
            focused={ focused } 
            disabled={ disabled }
            aria-label={ name }
          >
            { label }
          </FormLabel>
        ) }
        <Component
          { ...props }
          name={ name }
          inputId={ name }
          value={ parseSelectOption(value, options as Option<ValueT>[]) }
          onChange={ onSelectValueChange }
          onBlur={ () => onBlur(value) }
          onFocus={ onFocus }
          isValidNewOption={ (text, option, curOptions) => {
            const ret = !!isValidNewOption ? isValidNewOption(text, option, curOptions) : (
              !!getNewOptionData ? !!text : false
            );
            return ret;
          }}
          options={ formatOptions(options as Option<ValueT>[] | undefined, selectedIds) }
          loadOptions={ loadOptions }
          filterOption={ filterOption }


          menuPortalTarget={ document.body }
          isMulti={ isMulti }
          isDisabled={ disabled }
          components={ {
            ...(!!(other as AsyncCreatableSelectFieldProps<ValueT>).loadOptions ? {
              DropdownIndicator: () => null
            } : {}),
            // Input: (inputProps: any) => {
            //  return <components.Input required={ required } name={ name } {...inputProps}/>;
            //}
          } }
          styles={{
            menuPortal: (initialStyles: object) => ({
              ...initialStyles,
              zIndex: 100000
            }),
            control: (initialStyles: object) => ({
              ...initialStyles,
              overflow: 'hidden',
              backgroundColor: 'inherit',
            }),
            valueContainer: (initialStyles: object) => ({
              ...initialStyles,
              overflowY: 'hidden',
            }),
          }}
          getNewOptionData={ getNewOptionData }
          formatCreateLabel={ text => {
            return <Trans i18nKey="createNew" values={{ text }}/>;
          } }
          noOptionsMessage={ () => <Trans i18nKey="noOptions"/> }
        />
        {/* HACK for "required": https://github.com/JedWatson/react-select/issues/1827#issuecomment-409343434 */}
        <input
          tabIndex={ -1 }
          value={ valueToString(value) }
          required={ required }
          onChange={ onInputChange }
          aria-labelledby={ name }
          style={{
            opacity: 0,
            width: 0,
            height: 0,
            position: 'absolute',
          }}
        />
        { !valueToString(value) && !!initialSuggestions && !!suggestedValue && (
          <SuggestionSection
            initialValue={ suggestedValue }
            options={ initialSuggestions }
            onSuggestionSelected={ suggestion => {
              onSelectValueChange(suggestion, { action: 'create-option' });
            } }
          />
        ) }
      </FormControl>
    );
  };

  return Select;
}

export type CreatableSelectFieldProps<ValueT> = Omit<FormSelectFieldProps & CreatableProps<Option<ValueT>, false>, 'onChange'>;
export type AsyncCreatableSelectFieldProps<ValueT> = Omit<FormSelectFieldProps & 
  AsyncCreatableProps<Option<ValueT>>, 'onChange'>;

export const CreatableSelectField = SelectFieldDecorator<CreatableProps<any, false>, any>(Creatable as any);
export const AsyncCreatableSelectField = SelectFieldDecorator<AsyncCreatableProps<any>, any>(AsyncCreatable as any);

/**
 * onChange from Redux Form Field has to be called explicity.
 */
function singleChangeHandler(func: EventOrValueHandler<React.ChangeEvent<any>>) {
  return function handleSingleChange(value: any) {
    //console.log('[CHANGE] FORM SINGLE CHANGE', value);
    setTimeout(() => func(value ? value.value : ''));
  };
}

/**
 * onBlur from Redux Form Field has to be called explicity.
 */
function multiChangeHandler(func: EventOrValueHandler<React.ChangeEvent<any>>) {
  return function handleMultiHandler(values: any) {
    //console.log('[CHANGE] FORM MULTI CHANGE', values);
    setTimeout(() => func(values.map((value: any) => value.value)));
  };
}
