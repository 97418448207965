import * as React from 'react';
import i18n from 'src/i18n';

import { GBMaterialType, DataSourceItem } from 'src/types';
import { DataSourceItemCaption } from './DataSourceItemCaption';
import Edit from '@material-ui/icons/Edit';

import Autorenew from '@material-ui/icons/Autorenew';

import { 
  MaterialProductInfoLink 
} from 'src/components/material-layout';
import { ActionDropdownMenu } from 'src/components';
import { DataSourceItemEditActions } from 'src/actions/DataSourceItemActions';


interface MaterialDataSourceItemProps {
  dataSourceItem: DataSourceItem;
  material: GBMaterialType;
}

const MaterialDataSourceItem: React.FC<MaterialDataSourceItemProps> = (
  { dataSourceItem, material }
) => {

  return (
    <div 
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      { !!dataSourceItem.providerMaterial ? (
        <MaterialProductInfoLink
          to={ `/providers/${dataSourceItem.provider.code}/materials/${dataSourceItem.providerMaterial.id}` }
          textStyle={{ whiteSpace: 'nowrap' }}
        >
          <DataSourceItemCaption
            dataSourceItem={ dataSourceItem }
          />
        </MaterialProductInfoLink> 
      ) : (
        <DataSourceItemCaption
          dataSourceItem={ dataSourceItem }
          style={{ 
            color: 'red',
          }}
        >
          { ' ' }
          { `(${i18n.t('failedToFetch')})` }
        </DataSourceItemCaption>
      ) }
      { dataSourceItem.active && (
        <Autorenew
          style={{ color: 'green', maxWidth: '20px', maxHeight: '20px' }}
        />
       ) }
      <ActionDropdownMenu
        buttonProps={{
          size: 'small',
          style: {
            padding: '0px',
            marginLeft: '5px',
            minHeight: '24px',
          }
        }}
        dataSourceItem={ dataSourceItem }
        material={ material }
        actions={ DataSourceItemEditActions }
        caption={ <Edit/> }
      />
    </div>
  );
};

export { MaterialDataSourceItem };