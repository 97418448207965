import * as React from 'react';

import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';

import { ActionBar } from './ActionBar';
import { LayoutTitle } from './LayoutTitle';
import { SvgIconProps } from '@material-ui/core/SvgIcon';


interface MainLayoutProps {
  actionBarLeft?: React.ReactNode;
  actionBarRight?: React.ReactNode;
  id: string;
  title: React.ReactNode;
  titleIcon?: React.ComponentType<SvgIconProps>;
  subTitle?: React.ReactNode;
  description?: React.ReactNode;
}

const style: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
};


const MainLayout: React.FC<MainLayoutProps> = (
  { id, title, titleIcon, subTitle, description, actionBarLeft, actionBarRight, children }
) => (
  <div id={ id } style={ style }>
    <ActionBar
      leftContent={ actionBarLeft }
      rightContent={ actionBarRight }
    />
    <Divider/>
    <Paper elevation={ 0 } style={{ marginTop: '5px' }}>
      <LayoutTitle 
        subTitle={ subTitle }
        titleIcon={ titleIcon }
      >
        { title }
        
      </LayoutTitle>
      {description}
      <Paper className="content" style={{ marginTop: '1px', paddingTop: '10px' }}>
        { children }
      </Paper>
    </Paper>
  </div>
);

export { MainLayout };