import * as React from "react";

import { AssetInfo } from "./types";
import { Message } from "src/components/Message";
import { Trans, useTranslation } from "react-i18next";
import { Typography, Button } from "@material-ui/core";
import AssetViewer from "./AssetViewer/AssetViewer";

export interface AssetInfoAreaProps {
  assetGuid: string | undefined | null;
  showPreview?: boolean;

  style?: React.CSSProperties;
}

interface AssetAPIExportResponse {
  data: {
    name: string;
    guid: string;
    createdAt?: string,
    modifiedAt?: string,
    isModel: boolean;
  };
  error: null | {
    userMessage: string;
  };
}

const useAssetData = (assetGuid: string | undefined | null) => {
  const [currentAsset, setCurrentAsset] = React.useState<AssetInfo | null>(
    null
  );
  const [error, setError] = React.useState<string | null>(null);

  React.useEffect(
    () => {
      if (!!assetGuid) {
        if (error) {
          setError(null);
        }

        fetch(
          `${process.env.REACT_APP_3D_API_URL}/info/${assetGuid}`
        )
          .then(async (res) => {
            if (!res.ok) {
              throw new Error(res.statusText);
            }

            const data: AssetAPIExportResponse = await res.json();
            if (data.error) {
              throw new Error(data.error.userMessage);
            }

            setCurrentAsset({
              name: data.data.name,
              guid: data.data.guid,
                createdAt: data.data.createdAt ? Date.parse(data.data.createdAt) : undefined,
                modifiedAt: data.data.modifiedAt ? Date.parse(data.data.modifiedAt) : undefined
            });
          })
          .catch((e) => {
            console.error(`Failed to fetch asset info (GUID ${assetGuid})`, e);
            setError(e.toString());
          });
      } else {
        setCurrentAsset(null);
        setError(null);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [assetGuid]
  );

  return { error, currentAsset, setCurrentAsset };
};

interface PreviewViewProps {
  guid: string;
}

const PreviewView: React.FC<PreviewViewProps> = ({ guid }) => {
  const [previewOpen, setPreviewOpen] = React.useState(false);
  const { t } = useTranslation();
  const { currentAsset, setCurrentAsset } = useAssetData(guid);
  return (
    <>
      {previewOpen ? (
        <AssetViewer
          initialGuid={guid ?? undefined}
          selectedAsset={currentAsset?.guid || ""}
          onSelectAsset={(guid: string) =>
            setCurrentAsset({ guid: guid, name: "" })
          }
        />
      ) : (
        <Button onClick={() => setPreviewOpen(true)} variant="contained">
          {t("preview")}
        </Button>
      )}
    </>
  );
};

export const AssetInfoArea: React.FC<AssetInfoAreaProps> = ({
  assetGuid,
  style,
  showPreview,
}) => {
  const { error, currentAsset } = useAssetData(assetGuid);
  
  const warningDate = () => {
      
      if(currentAsset?.createdAt) {
          const d = new Date(currentAsset.modifiedAt ?? currentAsset.createdAt);
          // Date when we started tracking asset creation time
          return d.getDate() === 30 && d.getMonth() === 4 && d.getFullYear() === 2023
      }
      return false;
  }
  
  
  if (error) {
    return (
      <>
        <Message
          title={<Trans i18nKey="failedToFetch" />}
          description={
            <>
              {error}
              <br />
              {`GUID: ${assetGuid}`}
            </>
          }
        />
      </>
    );
  }

  if (!currentAsset) {
    return null;
  }

  return (
    <>
      <Typography style={style} variant="body2">
        {currentAsset.name}
      </Typography>
      {currentAsset.createdAt && <Typography style={style} variant="body2">
          <Trans i18nKey={"assetCreated"} 
              values={{date: new Date(currentAsset.createdAt).toLocaleDateString()}}/>        
      </Typography>
      }
      {// Do not display if created or modified after the first date of tracking
          warningDate() && <Typography style={{color: 'red'}}>
          <Trans i18nKey={"oldAsset"}/>
      </Typography>}
      {currentAsset.modifiedAt && <Typography style={style} variant="body2">
          <Trans i18nKey={"assetModified"}
                 values={{date: new Date(currentAsset.modifiedAt).toLocaleDateString()}}/>
      </Typography>}
      {showPreview && <PreviewView guid={currentAsset.guid} />}
    </>
  );
};
