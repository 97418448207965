import {
  RemoveMaterialLabelMutation, AddMaterialLabelMutation
} from './materialsLabelsMutations';
import { ApolloClient, ApolloError } from '@apollo/client';
import { GBLabelIdType, GBMaterialIdType, GBLabelType } from 'src/types';
import { resetCache } from 'src/utils/MutationUtils';
import { SuggestionOption } from 'src/components/form/select-field/SuggestionSection';


// Link material label
export const sendAddMaterialLabels = async (
  labelIds: GBLabelIdType[],
  suggestions: SuggestionOption<GBLabelType>[] | undefined,
  materialId: GBMaterialIdType,
  client: ApolloClient<any>,
  resetStore = true
) => {
  try {
    for (let labelId of labelIds) {
      const suggestion = !!suggestions ? suggestions.find(s => s.value.id === labelId) : undefined;
      await client.mutate({
        mutation: AddMaterialLabelMutation,
        variables: { 
          input: {
            materialId,
            labelId,
          },
          rewardId: suggestion ? suggestion.rewardId : undefined
        },
      });
    }
  } catch (err) {
    if (err instanceof ApolloError) {
      console.log('Failed to link material label', JSON.stringify(err));
    }

    throw err;
  } finally {
    if (resetStore) {
      resetCache(client);
    }
  }
};

// Unlink material label
export const sendRemoveMaterialLabels = async (
  labelIds: GBLabelIdType[],
  materialId: GBMaterialIdType,
  client: ApolloClient<any>,
  resetStore = true
) => {
  try {
    for (let labelId of labelIds) {
      await client.mutate({
        mutation: RemoveMaterialLabelMutation,
        variables: { 
          input: {
            materialId,
            labelId,
          }
        },
      });
    }
  } catch (err) {
    if (err instanceof ApolloError) {
      console.log('Failed to delete linked material label', JSON.stringify(err));
    }

    throw err;
  } finally {
    if (resetStore) {
      resetCache(client);
    }
  }
};

// Update material labels
export const sendUpdateMaterialLabels = async (
  addedLabelIds: GBLabelIdType[],
  removedLabelIds: GBLabelIdType[],
  suggestions: SuggestionOption<GBLabelType>[] | undefined,
  materialId: GBMaterialIdType,
  client: ApolloClient<any>,
) => {
  try {
    await sendAddMaterialLabels(addedLabelIds, suggestions, materialId, client, false);
    await sendRemoveMaterialLabels(removedLabelIds, materialId, client, false);
  } finally {
    resetCache(client);
  }
};