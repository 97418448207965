import { ProviderType } from 'src/types';

const ProviderFeatures = {
  BROWSER: 'browser',
  SEARCH: 'search',
};

const hasProviderFeature = (provider: ProviderType, feature: string) => {
  return provider.features.indexOf(feature) !== -1;
};

export { ProviderFeatures, hasProviderFeature };
