import { ApolloClient, ApolloError } from '@apollo/client';

import {
  ImportProviderMaterialMutation, ImportProviderMaterialResponse,
  LinkProviderMaterialMutation, LinkProviderMaterialResponse, 
  RemoveDataSourceItemMutation, RemoveDataSourceItemResponse, 
  SetActiveMaterialDataSourceItemMutation, SetActiveMaterialDataSourceItemResponse
} from './dataSourceItemMutations';
import { MaterialImportInput, GBMaterialIdType, DataSourceItemId, ProviderIdType } from 'src/types';
import { resetCache } from 'src/utils/MutationUtils';


// Import material from a provider
export const sendImportMaterial = async (
  input: MaterialImportInput, 
  client: ApolloClient<any>
) => {
  try {
    const response = (await client.mutate({
      mutation: ImportProviderMaterialMutation,
      variables: { input }
    })).data as ImportProviderMaterialResponse;

    resetCache(client);
    return response.importProviderMaterial;
  } catch (err) {
    if (err instanceof ApolloError) {
      console.log('Failed to import material', JSON.stringify(err));
    }
    
    throw err;
  }
};


// Link provider material to an existing material
export const sendAddMaterialDataSourceItem = async (
  input: { materialId: GBMaterialIdType, providerId: ProviderIdType, providerMaterialId: string },
  client: ApolloClient<any>
) => {
  try {
    const response = (await client.mutate({
      mutation: LinkProviderMaterialMutation,
      variables: { input },
    })).data as LinkProviderMaterialResponse;

    resetCache(client);

    return response.linkProviderMaterial;
  } catch (err) {
    if (err instanceof ApolloError) {
      console.log('Failed to add data source item', JSON.stringify(err));
    }

    throw err;
  }
};

export const setActiveMaterialDataSourceItem = async (
  input: { materialId: GBMaterialIdType, dataSourceItemId: DataSourceItemId | null },
  client: ApolloClient<any>
) => {
  try {
    const response = await client.mutate({
      mutation: SetActiveMaterialDataSourceItemMutation,
      variables: { input },
    });
    
    const data = response.data as SetActiveMaterialDataSourceItemResponse;

    resetCache(client);

    return data.setActiveMaterialDataSourceItem;
  } catch (err) {
    if (err instanceof ApolloError) {
      console.log('Failed to set active data source item', JSON.stringify(err));
    }

    throw err;
  }
};

// Unlink material label
export const sendRemoveDataSourceItem = async (
  id: DataSourceItemId,
  client: ApolloClient<any>
) => {
  try {
    const response = (await client.mutate({
      mutation: RemoveDataSourceItemMutation,
      variables: { id },
    })).data as RemoveDataSourceItemResponse;

    resetCache(client);

    return response.removeDataSourceItem;
  } catch (err) {
    if (err instanceof ApolloError) {
      console.log('Failed to remove data source item', JSON.stringify(err));
    }

    throw err;
  }
};
